<template>
  <div class="btn_main" :style="mainStyle">
    <!-- <img :src="$img(img)" :style="imgStyle" /> -->
    <div>
      <div :style="textStyle">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImageBtn",
  setup() {},
  props: {
    img: String,
    text: String,
    width: String,
	height: String,
	bg:String,
    textTop: String,
  },
  computed: {
    mainStyle(){
      return {
        width: this.width,
		height:this.height,
		backgroundColor:this.bg
      };
    },
    imgStyle() {
      return {
        width: this.width,
      };
    },
    textStyle(){
      return {
        top: this.textTop
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.btn_main {
  position: relative;

  > img {
    width: 100%;
    display: block;
  }
  > div {
    // top: 0;
    // left: 0;
    // right: 0;
    // bottom: 0;
	line-height: 10vw;
	text-align: center;
	
    // display: flex;
    // justify-content: center;
    // align-items: center;
	
    > div {
      position: relative;
    }
  }
}
</style>