<template>
	<red-header :title="data.ActivityName" :showBack="true" />

	<div class="main" style="padding: 3vw;color: white;height: 50vh;">
		<div class="content" v-html="data.ContentPc"></div>
		
		
				
		
		<div class="get canClick mainBtnBG" v-if="((showGet==0&&!isLogged) ||(showGet==0&&isLogged) )&&data.ActivityId!='RegisterGet'" @click='joinIn()'>{{$t('参与')}}</div>
		<div class="get canClick mainBtnBG" v-if="showGet==-1&&isLogged&&data.ActivityId!='RegisterGet'" style="background: gray;">{{$t('参与')}}</div>
		
		<div class="get canClick" v-if="showGet==1&&isLogged&&data.ActivityId!='RegisterGet'" style="background: gray;">{{$t('已参与')}}</div>
		<div style="width: 100%;height: 10px;"></div>

	</div>
</template>

<script>
	import { ref } from "vue";
	import { mapGetters, mapActions, mapMutations } from "vuex";
	import {theme} from '@/views/theme'
		import { bus, busName } from "@/components/core/bus";
		import { http, topic } from "@/components/protocol/api";
		import mqant from "@/components/protocol/mqantlib";
		import i18n from "@/locales/i18n";
		
		let RedHeader = require('@/views/'+ theme +'/components/RedHeader.vue').default
		
				let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default
	
	export default {
	  name: "Gift",
	  components:{
		HomeNav,
		RedHeader
	  },
	 
	    computed: {
	    ...mapGetters("global", [ "lobbyInfo"]),
		...mapGetters("tcp", ["isLogged","isConnect"]),
		
	  },
	  destroyed() {
	  	window.removeEventListener('popstate', this.back, false); //false阻止默认事件
	  },
	 created() {
		// console.log('543534',JSON.parse(this.$route.params.data) )
		if(this.$route.params.data){
			this.data = JSON.parse(this.$route.params.data)
			
		}else {
			
			this.$router.push({
				name: "home"
			});
		}
		if(this.isConnect){
			this.getData()
			
		}
		
		
		var that = this
		
		bus.off('onConnected')
		bus.on('onConnected',(val)=>{
				  that.getData()
		})
		   //this.getData()
		
		 if (window.history && window.history.pushState) {
		 		
		 		window.addEventListener('popstate', this.back, false); //false阻止默认事件
		 	}
	 	
		//this.activeNames = 	[this.$route.params.id]
	 },
	methods:{
		back() {
				bus.emit('hiddeTab', {
					isShow: true
				})
			},
		joinIn(){
			if(!this.isLogged){
				this.$router.push({
					name: "login"
				});
				return
			}
			let params = {
			  ActivityId: this.data.ActivityId,
			 language: i18n.global.locale
			 
			};
						 
			let that = this;
			that.$tools.loading();
			mqant.request(topic.activityJoin, params, function (res) {
			 
			  that.$tools.dismissLoading();
			  if (res.Code == 0) {
			 			that.showGet = 1
			  } else {
			    that.$tools.toast(res.ErrMsg);
			  }
			});
			
		},
			getData() {
			  let params = {
			    ActivityId: this.data.ActivityId,
			   language: i18n.global.locale
			   
			  };
			 
			  let that = this;
			  that.$tools.loading();
			  mqant.request(topic.activityGetJoin, params, function (res) {
			    that.$tools.dismissLoading();
			    if (res.Code == 0) {
			      that.showGet = res.Data
			    } else {
			      that.$tools.toast(res.ErrMsg);
			    }
			  });
			},
	},
	  data() {
	    return {
	      dataList:[],
		  data:null,
		  showGet:false
	    };
	  },
	};
</script>

<style lang="scss" scoped>
	.get{
		//background: linear-gradient(to right,rgb(1,174,246),rgb(0,109,221));
		background: linear-gradient(to right,#dbab7b,#f3e4ce,#dcb07f);
		color:#4d4b49;
		width: 210px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		font-size: 16px;
		margin: 0 auto;
		margin-top: 19px;
		margin-bottom: 50px;
		border-radius: 4px;
	}
	.main {
		// margin-top: 10vh;
		position: relative;
		padding: 0 3vw;
		flex: 1;

		>.bottom {
			width: 100%;
			position: absolute;
			bottom: 0%;
		}
	}
</style>

<style>
	.content img{
		width: 100%;
	}
	table {
		width: 100%;
		border-top: 1px solid white;
		border-left: 1px solid white;
	}

	table td,
	table th {
		border-bottom: 1px solid white;
		border-right: 1px solid white;
		padding: 3px 5px;
	}

	table th {
		border-bottom: 1px solid white;
		text-align: center;
	}
</style>
