<template>
  <div class="main">
	<img :src="codeImg" @click="getCode" style="position: absolute;right: 7vw;height: 10vw;z-index: 9999;background-color: white;bottom: 0;" alt="">
    <img
      :src="$img(eyes)"
      class="eyes"
      @click="onEyeClick"
      v-if="type == 'password'"
    />
    <input
	class="phoneMiddle"
      :type="inputType"
      :placeholder="placeholder"
      v-model="code"
		
	  @input="input"
    />
	
	
	
<!--    <img :src="$img(inputBg)" class="bg" />
 -->    <div class="error" v-if="error != ''">* {{ error }}</div>
  </div>
</template>

<script>
	import {
		req,
		qs
	} from "@/utils/request";
	import {
			http
		} from "@/components/protocol/api";
const register_eyes_normal = "icon_eyes.png";
const register_eyes_close = "icon_eyes_close.png";
const login_eyes_normal = "blue_eye.png";
const login_eyes_close = "blue_eye_close.png";
export default {
  props: {
    icon: String,
    placeholder: String,
    inputBg: String,
    kind: {
      type: String,
      default: "register",
    },
    type: {
      type: String,
      default: "text",
    },
    number: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
	
    text: String,
  },
  computed: {
    inputType() {
      if (this.type == "password") {
        if (this.eyes == login_eyes_close || this.eyes == register_eyes_close) {
          return "password";
        } else {
          return "text";
        }
      }
      return this.type;
    },
    
    // numberOnly(){
    //    if (this.number) {
    //        return "value=value.replace(/\D/g,'')"
    //    }else{
    //        return ""
    //    }
    // }
  },
  data() {
    return {
		code:'',
		codeImg:'',
      eyes: this.kind == "register"? register_eyes_close : login_eyes_close
    };
  },
  methods: {
	  input(e){
		  //this.code+=e.data
		  var that = this
		this.$emit('inputEvent',{
			'text':that.code,
			'imgId':that.imgId
		})
	  },
	  async getCode() {
	  	
	  
	  	let params = {
	  	  
	  	};
	  	var that = this
	  	let rsp = await req.get(http.captcha, qs.stringify(params));
	  	if (rsp.data.Code == 0) {
	  		that.codeImg = rsp.data.Data.img
	  		that.imgId = rsp.data.Data.id
	  	}else {
	  		this.$message({
	  		          message: rsp.data.ErrMsg,
	  		          type: 'error'
	  		        });
	  	}
	  	
	  	
	  },
    onEyeClick() {
      if (this.kind == "register") {
        if (this.eyes == register_eyes_normal) {
          this.eyes = register_eyes_close;
        } else {
          this.eyes = register_eyes_normal;
        }
      } else {
        if (this.eyes == login_eyes_normal) {
          this.eyes = login_eyes_close;
        } else {
          this.eyes = login_eyes_normal;
        }
      }
    },
  },
  mounted() {
	  this.code = this.text
	  this.getCode()
    //   console.log(typeof this.error)
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  height: 10vw;
  > .bg {
    width: 74vw;
  }
  > .error {
    text-align: left;
    padding-left: 24vw;
    color: red;
    font-size: 11px;
    position: relative;
    top: -1vh;
  }
  > .icon {
    position: absolute;
    width: 6.6vw;
    top: 14%;
    left: 15%;
  }
  input {
  	width: 80vw;
  	height: 30px;
  	border-bottom: 1px solid rgb(75, 98, 132);
  	background-color: transparent;
  	color: white;
  	padding-top: 15px;
  	font-size: 15px;
  }
 //  > input {
 //    position: absolute;
 //    top: 12%;
 //    left: 12%;
 //    padding: 3% 0px;
 //    width: 76%;
	// background-color: transparent;
	// color:white;
	// padding-left: 5%;
 //  }
  input::placeholder {
    color: #9a9a9a;
  }
  > .eyes {
    position: absolute;
    right: 15%;
    top: 25%;
    width: 5vw;
    z-index: 1;
  }
}
</style>