<template>
	<div style="width: 100%;min-width: 1200px;background-color: rgb(23, 25, 31);text-align: center;height: 800px;" >
		<div class="" style="padding-top: 50px;width: 100%;text-align: center;">
			<img :src="$img('label_left.png')" alt="" style="margin-right: 15px;width: 254px;vertical-align: middle;">
			<span style="color: rgb(233, 216, 140);vertical-align: middle;" class="bigSize">{{$t("下载区")}}</span>
			<img :src="$img('label_right.png')" alt="" style="width:254px;margin-left: 15px;vertical-align: middle;">
		</div>
		<div class="flexs_r" style="width: 1000px;margin: 40px auto;">
			<img :src="$img('down_ad.png')" alt="" style="width: 570px;margin-top: 80px;">
			<div class="flexs_colum" style="margin-left: 60px;margin-top: 60px;text-align: left;">
				<div style="color: white;font-size: 30px;">
					{{$t("homeWenAnTitle")}}
				</div>
				<div style="color: #858383;margin-top: 10px;white-space: normal;word-break: break-all;word-wrap: break-word;line-height: 25px;" class="middleSize">
					{{$t("homeWenAn")}}
					
				</div>
				<div class="flexs_sb" style="margin-top: 80px;">
					<div class="flexs_colum">
						<img :src="$img('az.png')" alt="" style="width:150px;">
						<vue-qr :text="lobbyInfo.VersionGet.android.UrlPath" :size="150" :margin='15' style="width: 150px;margin-top: 20px"></vue-qr>
						
					</div>
					<div class="flexs_colum">
						<img :src="$img('pg.png')" alt="" style="width: 150px;">
						<vue-qr :text="lobbyInfo.VersionGet.ios.UrlPath" :size="150" :margin='15' style="width: 150px;margin-top: 20px"></vue-qr>
						
					</div>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import bus from "@/utils/bus.js"
	import {
		status,
		getApiLoginUrl,
		checkFactoryUrl
	} from "@/components/protocol/api"
	import vueQr from 'vue-qr/src/packages/vue-qr.vue'
	
	export default {
		data() {
			return {
				
	
			}
		},
	
		components: {
			// HelloWorld
			vueQr
		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),
			...mapGetters("tcp", ["isLogged", "isConnect"]),
		
		},
		
		watch: {
			
		},
		created() {
			
		},
		
		methods:{
		
		}
	}
</script>

<style scoped lang="scss">
	
</style>