<template>
	<div @touchmove.prevent @mousewheel.prevent  @click="handleClose()" class="wrap"
		style="width: 100%;height: 100%;position: fixed;top: 0;left: 0;z-index: 99;background-color: rgba(0, 0, 0, .4);">
		<div class="deepBG" @click.stop="" @touchmove.stop @mousewheel.stop>
			<div v-if="objData" style="height: 60px;background-color:#242424;padding: 0 20px;" class="flexs_sb">
				<div style="color: rgb(253, 254, 255);font-size: 22px;">{{objData.ActivityName}}</div>
				<div>&nbsp;</div>
				
				<div @click="handleClose()" style="color: white;font-size: 25px;" class="canClick">x</div>
				
			</div>
			
			<div class="gameList" style="white-space: nowrap;overflow-y: auto;height: calc(80vh - 60px);;width: 100%;white-space: break-spaces;word-break: keep-all;"  v-if="objData">
				<div style="padding: 20px;font-size: 14px;" class="content" v-html="objData.ContentPc" ></div>
				<div class="mainBtnBG get canClick" v-if="((showGet==0&&!isLogged) ||(showGet==0&&isLogged))&&objData.ActivityId!='RegisterGet'&&objData.ActivityId!='SportGuess'" @click='joinIn()'>{{$t('参与').toUpperCase()}}</div>
				<div class="mainBtnBG get canClick" v-if="showGet==-1&&isLogged&&objData.ActivityId!='RegisterGet'&&objData.ActivityId!='SportGuess'" style="background: gray;">{{$t('参与').toUpperCase()}}</div>
				
				<div class="get canClick" v-if="showGet==1&&isLogged&&objData.ActivityId!='RegisterGet'&&objData.ActivityId!='SportGuess'" style="background: gray;">{{$t('已参与').toUpperCase()}}</div>
				
			</div>
			
		</div>
	</div>
	
	
</template>

<script>
	import { ref } from "vue";
	import { mapGetters, mapActions, mapMutations } from "vuex";
	import {theme} from '@/views/theme'
		import { bus, busName } from "@/components/core/bus";
		import { http, topic } from "@/components/protocol/api";
		import mqant from "@/components/protocol/mqantlib";
		import i18n from "@/locales/i18n";
		
		export default {
		  name: "Gift",
		  components:{
			
		  },
		  props: {
		  		
		  	data: {
		  		type: String,
		  		default: null
		  	}
		  },
		  setup() {
		   
		  },
		    computed: {
				...mapGetters("tcp", ["isLogged"]),
				
		  },
		 created() {
			 this.objData = JSON.parse(this.data)
			 if(this.isLogged){
				 this.getData()
				 
			 }
			 
		 },
		 methods:{
			 handleClose(){
			 	this.$emit('handleClose')
			 	
			 },
			 getData() {
			   let params = {
			     ActivityId: this.objData.ActivityId,
			    language: i18n.global.locale
			    
			   };
			   let that = this;
			   mqant.request(topic.activityGetJoin, params, function (res) {
			     if (res.Code == 0) {
			      that.showGet = res.Data
			     } else {
					 that.$message({
					 	message: that.$t(res.ErrMsg),
					 	type: 'error'
					 });
			     }
			   });
			 },
			 joinIn(){
				 if(!this.isLogged){
					 this.handleClose()
					 bus.emit('showLogin')
					 return
				 }
			 	let params = {
			 	  ActivityId: this.objData.ActivityId,
			 	 language: i18n.global.locale
			 	 
			 	};
			 				 
			 	let that = this;
			 	mqant.request(topic.activityJoin, params, function (res) {
			 	  if (res.Code == 0) {
					 
			 			that.showGet = 1
						that.handleClose()
						that.$message({
							message: that.$t('参与成功'),
							type: 'success'
						});
						
						that.$router.push({
							name:'getActivity'
						})
			 	  } else {
			 	    that.$message({
			 	    	message: that.$t(res.ErrMsg),
			 	    	type: 'error'
			 	    });
			 	  }
			 	});
			 	
			 },
		 },
		
		  data() {
		    return {
				objData:{},
		      showGet:1
		    };
		  },
		};
</script>

<style scoped>
	
	.gameList::-webkit-scrollbar {
	    display: none;
	}
	.gameList{
		 /* 隐藏滚动条 */
		   scrollbar-width: none; /* firefox */
		   -ms-overflow-style: none; /* IE 10+ */
	}
	.deepBG {
		/* background: url(@/assets/pc2/login_bg.png); */
		/* width: 386px; */
	
		/* max-width: 53.5vh;
		max-height: 70vh; */
		background-color: #2e2d2c;
		position: relative;
		top: 50%;
		
		width: 750px;
		height: 80vh;
		
		margin: auto;
		color: white;
		transform: translateY(-52%);
	
	}
	.get{
		width: 210px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		font-size: 16px;
		margin: 0 auto;
		margin-top: 0px;
		margin-bottom: 20px;
		border-radius: 4px;
	}
</style>

<style>
	.content img{
		width: 100%;
	}
</style>