<template>
  <div class="main" style="position: relative;overflow: hidden;">
    <img :src="$img('loginBg.png')" />
    <div class="panel">
      <div style="color: white;font-size: 20px;font-weight: bold;position: relative;width: 100%;">
      	<img :src="$img('login_back.png')" class="back" @click="onBackClick" />
      	<img :src="$img('kf_icon.png')" style="width: 18px;position: absolute;right: 20px;" />
      
      	{{$t('注册')}}
      </div>
	
      <div class="container" style="margin-top: 50px;">
        <div class="form">
         <img :src="$img('logo4.png')" style="width: 58px;margin:0 auto;margin-bottom:10px;" />
         
          
         <input type="text" v-model="account" :placeholder="$t('请输入账号').toUpperCase()">
         
         
         <input type="password" v-model="password" :placeholder="$t('请输入密码').toUpperCase()" style="">
         
		 <input type="password" v-model="password2" :placeholder="'Xác nhận lại mật khẩu'.toUpperCase()" style="">
		 
		 <input type="text" v-model="phone" :placeholder="'Số điện thoại'.toUpperCase()">
		 
          
        
			<code-input
			  :icon="$img('code.png')"
			  :placeholder="$t('请输入验证码').toUpperCase()"
			  type="number"
			 
			  @inputEvent='inputEvent'
			  class="login-input"
			  inputBg="register_input_bg.png"
			  
			/>
            
			<input type="text" v-model="vipInviteCode" :placeholder="$t('邀请码(选填)')+'（Không bắt buộc）'">
			
			
			<div class="loginBtn">
				<img :src="$img('ann_bottom.png')" alt="" style="width: 80vw;">
				<div @click="onSubmit" style="position: absolute;z-index: 9;width: 100%;color: white;top: 2.5vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
					{{$t('注册')}}
				</div>
				
			</div>
         
          <div style="color: white;font-size:14px;margin-top: 19px;" @click="onToLogin()">{{$t('立即登录').toUpperCase()}}</div>
          
		 
		 
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {theme} from '@/views/theme'
	let LoginInput = require('@/views/'+ theme +'/components/LoginInput.vue').default
	let codeInput = require('@/views/'+ theme +'/components/codeInput.vue').default
	
	let ImageBtn = require('@/views/'+ theme +'/components/ImageBtn.vue').default
	import {
		req,
		qs
	} from "@/utils/request";
import { mapGetters, mapActions, mapMutations } from "vuex";
		import bus from "@/utils/bus.js"

import tools from "@/utils/tools";
import { http } from "@/components/protocol/api";
export default {
  components: { LoginInput, ImageBtn ,codeInput},
  name: "RegisterView",
  data() {
    return {
      account: "",
      password: "",
      password2: "",
      phone: "",
	  code:'',
	  codeId:'',
	  vipInviteCode:''
    };
  },
  computed: {
    ...mapGetters("global", ["globalState", "uuid"]),
  },
  mounted(){

    this.init().then(uuid=>{
      if (this.isLogged){
         this.$router.push({
            name:"index"
          })
      }
    })
  },
  methods: {
    ...mapActions("user", ["doLogin"]),
    ...mapActions("global", ["init"]),
    onBackClick() {
      this.$router.push({ name: "home" });
    },
	inputEvent(e){
			
			this.code = e.text
			this.codeId = e.imgId
	},
    onSubmit() {
		

      let params = {
        account: this.account,
        password: this.password,
        phone: this.phone,
        platform: tools.platform(),
        channel: "game",
        uuid: this.uuid,
        uuid_web: this.uuid,
		code:this.code,
		codeId:this.codeId,
		vipInviteCode:this.vipInviteCode,
    prefix:'hdbet'

		
      };
	  if(!this.account.length){
		  this.$tools.toast(this.$t("请输入账号"))
		  
		  return
	  }
	  if(!this.password.length){
	  		  this.$tools.toast(this.$t("请输入密码"))
	  		  
	  		  return
	  }
	  if(!this.password2.length){
	  		  this.$tools.toast(this.$t("请输入确认密码"))
	  		  
	  		  return
	  }
	  if(!this.phone.length){
	  		  this.$tools.toast(this.$t("请输入手机号"))
	  		  
	  		  return
	  }
	  if(this.password2!=this.password){
		  this.$tools.toast(this.$t("两次输入的密码不一致"))
		  
		  return
	  }
	
      this.$tools.loading();
      this.doLogin({
        params: params,
        url: http.userRegister,
      }).then(
        (resolve) => {
          this.$tools.dismissLoading();
          this.$router.push({
            name:"index"
          })
        },
        (reject) => {
          this.$tools.dismissLoading();
          this.$log.info(reject);
          this.$tools.toast(reject.ErrMsg)
        }
      );
    },
	
		
		
	
    onToLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style lang="scss" scoped>
	.loginBtn{
		position: relative;
		margin-top: 24px;
		
	}
	input {
		width: 80vw;
		height: 35px;
		border-bottom: 1px solid rgb(75, 98, 132);
		background-color: transparent;
		color: white;
		padding-top: 10px;
		font-size: 15px;
	}
	
.title {
  color: #fff;
  margin-top: 7%;
}
.des {
  margin-top: 10%;
  color: rgb(242,209,97);
}

.back {
 width: 6vw;
 
 position: absolute;
 left: 20px;
}
.main {
  width: 100%;
  height: 100%;
  > img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
  }
  text-align: center;
  > .panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5%;
    width: 100%;

    > .container {
      position: relative;
      width: 94vw;
      height: 100px;
      > .panel_bg {
        width: 94vw;
      }
      > .form {
        position: absolute;
        top: 0%;
        left: 0%;
        right: 0%;
        //   background-color: red;
        .login-input {
          margin-top: 1.8%;
        }
      }
    }
  }
}
</style>