<template>
  <div class="info" v-if="isLogged">
    <!-- <img :src="$img('home_info.png')" class="bg" /> -->
    <div class="info-content flexs_r_c">
		  
		  <div style="display: flex;flex-direction: row-reverse;width: 50%;padding-right: 15px;align-items: center;" >
			  <div class="phoneBig boldSize whiteWord" >{{ userInfo.Account }}</div>
			  
			  <img style="width: 22px;margin-right: 10px;" :src="$img('yh_icon.png')"  />
			  
		  </div>
             
      
      <div style="display: flex;flex-direction: row;width: 50%;padding-left: 15px;align-items: center;" >
        <img :src="$img('qb_icon.png')"  style="width: 22px;" />
        <div  class="phoneBig boldSize" style="color: rgb(242,209,97);margin-left: 10px;">{{ $tools.formatNum2k(wallet.VndBalance) }}</div>
      </div>
    </div>
  </div>
  <div v-else class="btn_main">
    <div class="btn_panel">
      <div class="btn" @click="goRegister">
        <img :src="$img('home_btn_pink.png')" class="btn"  style="width: 120px;"/>
       <div class="btn_text ">
          <div class="phoneBig boldSize">{{ $t("注册") }}</div>
        </div>
      </div>
    </div>
    <div class="btn_panel space"></div>
    <div class="btn_panel">
      <div class="btn" @click="goLogin">
        <img :src="$img('home_btn_blue.png')" class="btn" style="width: 120px;"/>
        <div class="btn_text">
          <div class="phoneBig boldSize">{{ $t("登录") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
	import { bus, busName } from "@/components/core/bus";

export default {
  name: "HomeInfo",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("tcp", ["isLogged", "wallet","info"]),
    ...mapGetters("user", ["userInfo"]),
	...mapGetters("global", ["lobbyInfo"]),
	
  },
  mounted() {
  },
  methods: {
	  toVip(){
		  bus.emit('hiddeTab', {
		  	isShow: false
		  })
		  
		  this.$router.push({ name: "vip" });
		  
	  },
    goRegister() {
      this.$router.push({ name: "register" });
    },
    goLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  // display: flex;
  // justify-content: center;
  // flex-direction: column;
  position: relative;
}
.btn_text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn_main {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  
}
.space {
  width: 5vw;
}
.btn {
  width: 43vw;
}
.icon {
  width: 8vw;
}
.btn_panel {
  display: inline-block;
  padding-top: 0.5vw;
}
.panel {
	position: relative;
  display: inline-block;
  padding-top: 0.5vw;
}
.info {
  width: 100%;
  text-align: center;
  position: relative;
  .bg {
    width: 100%;
  }
  .info-content {
    // position: absolute;
    top: 0;
    bottom: 0;
	
	padding: 13px 0;
    width: 100%;
    text-align: center;
  }
  span {
    width: auto;
    background-color: red;
  }
}
</style>