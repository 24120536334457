<template>
	<div style="position: relative;margin-bottom: -14px;top: -1px;padding: 0vw 0;padding-bottom: 30px;" class="mainBg">
		<img :src="$img('baner_qp_img.png')" alt="" style="width: 100vw;min-width: 1200px;">		
		
		<div style="" class="listWrap">
			<div   class="flexs_r" style="flex-wrap:wrap;flex-wrap:wrap;width: 100%;padding: 0 2.25vw;margin-top: 30px;">
				<div v-for="(item,index) in factoryList" :key="index" style="border-radius: 5px;" :style="index>0?'margin-left:15px':''" :class="{'select mainBtnBG': selectIndex==index}" class="canClick topColum">
					<img   @click="changeFactory(index)"    :class="{ diseableImg: (item.Factory.Status==2||item.Factory.Status==3)} "  class=""  :src="$img(item.Factory.FactoryName+'_N.png')" alt="" style="width: 9.3vw;" >
					
				</div>
				
			</div>
			
			<div style="margin-top: 30px;padding: 0 2.25vw;" class="flexs_sb">
				<div style="position: relative;">
					<input v-model="searchKey" v-on:input="search"  type="text" placeholder="Vui lòng điền tên game">
					<img :src="$img('shous_img.png')" alt="" style="width: 28px;right: 10px;top: 8px;position: absolute;">
				</div>
<!-- 				<div class="canClick" style="color: #0071df;font-size: 20px;">{{$t('最近游戏')}}</div>
 -->			
			</div>
			
			<div class="listBottom">
				<div  v-if="!isSearch" v-for="(item,index) in gameData.slice((this.page - 1) * 48, this.page * 48) " @click="onClick(item)" class="colum canClick" :key="item" @mouseenter='hoverIndex=index'
							@mouseleave="hoverIndex=-1">
					<img :src="getImg(item)"  style="width:11.6vw ;height: 11.6vw;background-color: white;object-fit: cover;border-radius: 10px;" alt="">
					<div class="whiteWord bigSize" style="text-align: center;margin-top: 5px;">{{item.GameName}}</div>
					<div v-show="hoverIndex==index" class="mask">
						<div class='mainBtnBG' style="width: 80%;margin-left: 10%;text-align: center;border-radius: 12px;height: 40px;line-height: 40px;margin-top: calc(5.8vw - 20px);font-size: 20px;">{{$t('立即游戏')}}</div>
					</div>
				</div>
				
				<div  v-else v-for="(item,index) in searchData.slice((this.page - 1) * 48, this.page * 48) " @click="onClick(item)" class="colum canClick" :key="index" @mouseenter='hoverIndex=index'
							@mouseleave="hoverIndex=-1">
					<img :src="getImg(item)"  style="width:11.6vw ;height: 11.6vw;background-color: white;object-fit: cover;border-radius: 10px;" alt="">
					<div class="whiteWord bigSize" style="text-align: center;margin-top: 5px;">{{item.GameName}}</div>
					<div v-show="hoverIndex==index" class="mask">
						<div class='mainBtnBG' style="width: 80%;margin-left: 10%;text-align: center;border-radius: 12px;height: 40px;line-height: 40px;margin-top: calc(5.8vw - 20px);font-size: 20px;">{{$t('立即游戏')}}</div>
					</div>
				</div>
			</div>
			<el-pagination v-if="!isSearch" align="center" style="text-align: center;margin-top: 50px;margin-right: 46px;display: flex;flex-direction: row-reverse;"  :page-size="48" @current-change='currentChange'
				 :current-page="page" layout="prev, pager, next"
				:total="gameData.length">
			</el-pagination>
			
			<el-pagination v-else align="center" style="text-align: center;margin-top: 50px;margin-right: 46px;display: flex;flex-direction: row-reverse;"  :page-size="48" @current-change='currentChange'
				 :current-page="page" layout="prev, pager, next"
				:total="searchData.length">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import chess from "@/views/pc/chess"
	
	export default{
	  mixins:[chess]
	}
</script>

<style lang="scss" scoped>
	.listWrap {
		top: 2vw;
		
		width: 86vw;
		// padding: 0 3.8vw;
		margin-left: 7vw;
		
		
		border-radius: 1vw;
		
		// padding-top: 5vw;
		// padding-left: 3.8vw;
		padding-bottom: 2vw;
		scrollbar-face-color: #FFF;
		



	}
	.listBottom{
		display: flex;
		flex-direction: row;
		 flex-wrap: wrap;
		// white-space: nowrap;
		// overflow-y: auto;
		 margin-left: 0;
		width: 100%;
		
		// max-height: 48vw;
		// background-color: rgba(0, 0, 0,.4);
	}
	.topNavi{
		width: 100%;
		margin-left: 0;
		border-top-left-radius: 1vw;
		border-top-right-radius: 1vw;
		background-color: #1a1a1a;
		overflow: hidden;
	}

	.colum {
		margin-top: 2.25vw;
		margin-left: 2.25vw;
		position: relative;
		width:11.6vw ;height: 11.6vw;
	}
	.topImgWrap{
		background-color: #1a1a1a;
	}
	
	
	.el-pagination {
	    text-align: center; 
	}
	.mask{
		background-color: rgba(0, 0, 0, .55);
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;
		top: 0;
		left: 0;
		border-radius: 10px;
		.enter{
			width: 9vw;
			height: 2vw;
			line-height: 2vw;
			border-radius: 4px;
			text-align: center;
			font-size: 0.86vw;
			font-weight: bold;
		background-image: linear-gradient(to  top,#e0b626, #d7781e);
		color: white;
			margin-left: 1.3vw;
			margin-top: 4.6vw;
		}
	}
	.select{
		border-radius: 5px;
		// height: 100%;
	}
	input{
		width: 400px;
		height: 45px;
		color: white;
		border-radius: 5px;
		padding-left: 10px;
		font-size:20px ;
		background-color: #222120;
	}
	.topColum{
			background-color: rgb(99,99,99);
			border-radius:5px;
			
		}
</style>

<style>
/* 为了不造成样式污染，单据加一个style标签来修改第三方组件样式，pagination表格分页整体居中，上边距20px */
.el-pagination{
  text-align:center;
  margin-top:20px;
} 

</style>


