<template>
	<div class="header">


		<div style="width: 80px;height: 100%;position:relative;z-index:99;margin-top:20px;" @click="goBack()">
			<img :src="$img('icon_back.png')" class="back" v-if="showBack"
				style="" />

		</div>
		<div>
			<div class="boldSize phoneBig" style="margin-top:20px;">{{ title }}</div>
			<div v-if="rightText.length" @click="rightClick" class=" phoneMiddle"
				style="position:absolute;right: 12px;">{{ rightText }}</div>

		</div>
	</div>
</template>

<script>
	import {
		bus,
		busName
	} from "@/components/core/bus";

	export default {
		name: "RedHeader",
		props: {
			title: String,
			showBack: {
				type: Boolean,
				default: false,
			},
			rightText: {
				type: String,
				default: '',
			},
		},
		methods: {
			rightClick() {
				this.$emit('rightClick');
			},
			goBack() {
				if (this.title == this.$t("历史记录") || this.title == 'VIP') {
					bus.emit('hiddeTab', {
						isShow: false
					})
				} else {

					bus.emit('hiddeTab', {
						isShow: true
					})
				}

				window.history.back()
			},

		},
	};
</script>

<style lang="scss" scoped>
	.header {
		display: block;
		position: relative;
		height: 70px;
		
		width: 100%;
		z-index: 999;
		// background-image: linear-gradient(to right, rgb(1, 80, 197), rgb(0, 149, 200));
		background-color: rgb(37,37,37);
		>img {
			margin-top:20px;
			display: block;

		}

		>div {
			position: absolute;
			top: 0%;
			bottom: 0%;
			right: 0%;
			left: 0%;
			display: flex;
			justify-content: center;
			align-items: center;

			>div {
				color: white;
			}
		}

		.back {
			width: 9px;
			top: 15px;
			left: 25px;
			position: absolute;
			z-index: 3;
		}
	}
</style>
