<template>
	<div style="position: relative;margin-bottom:5vw;">
		<div  style="position: relative;margin-top: 2vw;" class="flexs_colum" v-for="(item,index) in topList">
			<img :src="item.PUrl" @click="hotClick(item)" class="canClick" alt="" style="width: 94vw;margin-left: 3vw;">
			
		</div>
		<van-swipe v-if="hotGames.length"  class="my-swipe" :autoplay="0"  :show-indicators="false"  lazy-render>
			<van-swipe-item v-for="item in hotGames" @click="bannerClick()" style="padding: 0vw 0;margin-top: 3vw;">
				<img :src="$img('tiyu_img.png')" alt="" style="width: 94vw;margin-left: 3vw;">
				
				<div style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;">
					<div style="position: relative;width: 100%;height: 100%;">
						<img :src="$img('hot_pd.png')" alt="" style="width: 50px;position: absolute;left: 3vw;top: -3vw;z-index: 88;">
						
						
						<div  style="font-size: 2vw;margin-top: 2.5vw;text-align: center;width: 100%;color: white;">{{item.MatchDate}}&nbsp;GMT+8</div>
						<div class="flexs_sb" style="width: 90%;margin-top: 1vw;margin-left: 5%;">
							<div style="margin-top: -3vw;position: relative;width: 33%;text-align: center;">
								<img :src="$img('HTeam.png')" alt="" style="width: 40px;margin: 0 auto;">
								<div class="whiteWord phoneMiddle" style="text-align: center;margin-top: 5px;position: absolute;width: 100%;">{{item.HomeTeamName}}</div>
								
							</div>
							
							<div style="width: 34%;">
								<div class="flexs_sb" >
									<div style="position: relative;">
										<img :src="$img('NumBG.png')" alt="" style="width: 29px;">
										<div class="phoneSmall" style="color: white;position: absolute;left: 0;top: 0;width: 100%;line-height: 16px;text-align: center;font-size: 9px;">
											{{item.HdpOddsValueHomeFt}}
										</div>
									</div>
									<div style="color: #fdfeff;" class="phoneMiddle">HDP</div>
									<div style="position: relative;">
										<img :src="$img('NumBG.png')" alt="" style="width: 29px;">
										<div class="phoneSmall" style="color: white;position: absolute;left: 0;top: 0;width: 100%;line-height: 16px;text-align: center;font-size: 9px;">
											{{item.HdpOddsValueAwayFt}}
										</div>
									</div>
								</div>
								<div class="flexs_sb" style="margin-top: 5px;">
									<div style="position: relative;">
										<img :src="$img('NumBG.png')" alt="" style="width: 29px;">
										<div class="phoneSmall" style="color: rgb(239,191,23);position: absolute;left: 0;top: 0;width: 100%;line-height: 16px;text-align: center;font-size: 9px;">
											{{item.OuOddsValueHomeFt}}
										</div>
									</div>
									<div style="color: #fdfeff;" class="phoneMiddle">O/U</div>
									<div style="position: relative;">
										<img :src="$img('NumBG.png')" alt="" style="width: 29px;">
										<div class="phoneSmall" style="color: rgb(239,191,23);position: absolute;left: 0;top: 0;width: 100%;line-height: 16px;text-align: center;font-size: 9px;">
											{{item.OuOddsValueAwayFt}}
										</div>
									</div>
								</div>
							</div>
							
							
							
							<div style="margin-top: -3vw;position: relative;width: 33%;text-align: center;">
								<img :src="$img('ATeam.png')" alt="" style="width: 40px;">
								<div class="whiteWord phoneMiddle" style="text-align: center;margin-top: 5px;position: absolute;width: 100%;">{{item.AwayTeamName}}</div>
								
							</div>
						</div>
						<!-- <div class="flexs_sb" style="margin-top: 4.5vw;">
							<div style="width: 50%;text-align: center;" class="whiteWord middleSize flexs_colum">
								<img :src="item.HomeImgUrl" alt="" style="height: 12vw;margin: 0 auto;margin-top: -4vw;">
								<span>{{item.HomeTeamName}}</span>
								
							</div>
							<div style="width: 50%;text-align: center;margin-right: 2vw;" class="whiteWord middleSize flexs_colum">
								<img :src="item.AwayImgUrl" alt="" style="height: 12vw;margin: 0 auto;margin-top: -4vw;">
								<span>{{item.AwayTeamName}}</span>
								
							</div>
							
												
							
						</div> -->
						<div @click="toUrl()" style="position: relative;width: 90px;margin: 0vw auto;">
							<img :src="$img('xz2_bottom.png')" alt="" style="width: 90px;">
							<div  class="" style="text-align: center;width: 100%;position: absolute;left: 0;top:0;line-height: 30px;">{{$t("去下注").toUpperCase()}}</div>
							
						</div>
						
						
					</div>
										
				</div>
			</van-swipe-item>
			
			
		</van-swipe>
		<div class="flexs_colum" style="margin-top: 1vw;" v-if="hotGames.length>4">
			<div v-for="(item,index) in 4" style="position: relative;width: 94vw;margin-left: 3vw;margin-top: 1vw;">
				<img :src="$img('ty_bs_img.png')" alt="" style="width: 100%;">
				<div style="position: absolute;left: 0;top: 0;height: 100%;width: 100%;" class="flexs_sb">
					<div class="flexs_r_c">
						
						<img :src="hotGames[index+1].HomeImgUrl" alt="" style="height: 8vw;margin-left: 10px;width: 8vw;">
						<img :src="$img('vs_img.png')" alt="" style="width: 15px;margin-left: 14px;">
						<img :src="hotGames[index+1].AwayImgUrl" alt="" style="height: 8vw;width: 8vw;margin-left: 14px;">
						
					</div>
					
					<div >
						<div  style="font-size: 2vw;color: white;margin-left: 14px;">{{hotGames[index+1].MatchDate}}&nbsp;GMT+8</div>
						
						
						<div class="flexs_sb" style="margin-top: 5px;margin-left: 14px;">
							<div style="position: relative;">
								<img :src="$img('NumBG.png')" alt="" style="width: 29px;">
								<div class="phoneSmall" style="color: white;position: absolute;left: 0;top: 0;width: 100%;line-height: 16px;text-align: center;font-size: 9px;">
									{{hotGames[index+1].HdpOddsValueHomeFt}}
								</div>
							</div>
							<div style="color: #fdfeff;" class="phoneMiddle">HDP</div>
							<div style="position: relative;">
								<img :src="$img('NumBG.png')" alt="" style="width: 29px;">
								<div class="phoneSmall" style="color: white;position: absolute;left: 0;top: 0;width: 100%;line-height: 16px;text-align: center;font-size: 9px;">
									{{hotGames[index+1].HdpOddsValueAwayFt}}
								</div>
							</div>
						</div>
					</div>
					
					
					
					<div style="position: relative;margin-right: 5px;" @click="toUrl()">
						<img :src="$img('xz_bottom.png')" alt="" style="width: 85px;">
						<div style="position: absolute;width: 100%;text-align: center;line-height: 34px;left: 0;top: 0;">
							{{$t("去下注").toUpperCase()}}
						</div>
						
					</div>
				</div>
			</div>
		</div>
		
		
		<div style="width: 94vw;margin-left: 3vw;margin-top: 15vw;" class="flexs_sb">
			<div style="position: relative;">
				<img :src="$img('60s.png')" alt="" style="width: 90px;position: absolute;top: 0;left: 0;z-index: 0;">
				<div style="position: relative;z-index: 8;width: 100%;">
					<div style="color: rgb(196,175,134);font-size: 8px;margin-top:34px;margin-left: 59px;">{{$t("秒").toUpperCase()}}</div>
					<div style="color: rgb(196,175,134);font-size: 8px;margin-top: 10px;text-align: center;margin-left: 11px;">{{$t("充值")}}</div>
					
				</div>			
			</div>
			<div style="position: relative;">
				<img :src="$img('90s.png')" alt="" style="width: 90px;position: absolute;top: 0;left: 0;z-index: 0;">
				<div style="position: relative;z-index: 8;width: 100%;">
					<div style="color: rgb(196,175,134);font-size: 8px;margin-top:34px;margin-left: 59px;">{{$t("秒").toUpperCase()}}</div>
					<div style="color: rgb(196,175,134);font-size: 8px;margin-top: 10px;text-align: center;margin-left: 11px;">{{$t("提现")}}</div>
					
				</div>
								
			</div>
			<div style="position: relative;margin-right: 5px;">
				<img :src="$img('24j.png')" alt="" style="width: 90px;position: absolute;top: 0;left: 0;z-index: 0;">
				<div style="position: relative;z-index: 8;width: 100%;">
					<div style="color: rgb(196,175,134);font-size: 8px;margin-top:34px;margin-left: 59px;">{{$t("家").toUpperCase()}}</div>
					<div style="color: rgb(196,175,134);font-size: 8px;margin-top: 10px;text-align: center;margin-left: 11px;">{{$t("合作")}}</div>
					
				</div>			
			</div>
		</div>
		<img :src="$img('renz_img.png')" alt="" style="width:80vw ;margin-left: 10vw;margin-top: 30px;">
		<div style="margin:0 auto;width: 270px;margin-top: 4vw;color: rgb(136,135,134);font-size: 11px;" class="flexs_sb">
			<div @click="toAbout(0)">{{$t("关于我们")}}</div>
			<div @click="toAbout(5)">{{'CHÍNH SÁCH BẢO MẬT'.toUpperCase()}}</div>
			
			
			
		</div>
		<div style="margin-top: 4vw;color: rgb(136,135,134);font-size: 11px;text-align:center;" class="flexs_sb">
			<div @click="toAbout(1)" style="margin:0px auto;">{{$t("常见问题")}}</div>
			
			
			
			
		</div>
		<div style="text-align: center;font-size: 11px;color: rgb(120,118,116);margin: 15px 0;margin-bottom:70px;">COPYRIGHT @ HDBet88</div>
	</div>
</template>

<script>
	import {
		bus,
		busName
	} from "@/components/core/bus";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
import {
		status,
		getApiLoginUrl,
		checkFactoryUrl
	} from "@/components/protocol/api"
	import action from '@/components/core/action'
	
	export default {

		methods: {
			toProblem(){
				bus.emit('hiddeTab', {
					isShow: false
				})
				this.$router.push({
					name: 'problem',
					
				
				
				})
			},
			toPrivate(){
				bus.emit('hiddeTab', {
					isShow: false
				})
				this.$router.push({
					name: 'private',
					
				
				
				})
			},
			toAbout(type){
				bus.emit('hiddeTab', {
					isShow: false
				})
				
				
				this.$router.push({
					name: 'aboutUs',
					params: {
						selectIndex:type
					},
				
				
				})
			},
			sortData(data) {
				var arr = []
				for (let key in data) {
					arr.push(data[key])
			
				}
			
				return arr.sort((a, b) =>  b.Sort - a.Sort)
			
			},
			hotClick(val){
				if(val.PType==''){
					return
				
				}else if(val.PType=='LinkUrl'){
					if(val.GameCode.length){
    				action.openBrowser(val.GameCode,'')
					}
				}else if(val.PType=='Activity'){
					if(val.GameCode.length){
						this.$router.push({
							name: 'gift',
							params: {
								id:val.GameCode
							}
						
						
						})
					}else {
						this.$router.push({
							name: 'gift',
							params: {
								
							}
						
						
						})
					}
				}
				else {
					if(val.PType=='LiveCasino'||val.PType=='Lottery'||val.PType=='LiveSports'){
						if(val.Factory.length){
							if(!this.isLogged){
								this.$router.push({ name: "login" });
								
								return
							}
							let name = val.Factory;
							let url = getApiLoginUrl(name, val.GameCode?val.GameCode:"");
						action.openPage(url,'')
						}else {
							var name
							var type 
							if(val.PType=='LiveCasino'){
								name = 'game'
								type = 1

							}else if(val.PType=='LiveSports'){
								name = 'game'
								type = 0
							}
							this.$router.push({
								name: name,
								params: {
									type:type,
								}
							})
						}
					}else if(val.PType=='Card'||val.PType=='Fish'||val.PType=='Slot'){
						if(val.Factory.length){
							if(val.GameCode.length){
								if(!this.isLogged){
									this.$router.push({ name: "login" });
									
									return
								}
								let url = getApiLoginUrl(val.Factory, val.GameCode);
								action.openPage(url,'')
								
							}else {
								var name
								var type
								if(val.PType=='Fish'){
									name = 'game'
									type = 3
								}else if(val.PType=='Slot'){
									name = 'game'
									type = 2
									
								}else {
									name = 'game'
									type = 4
									
								}
								this.$router.push({
									name: name,
									params: {
										type:type,
										FactoryName:val.Factory
									}
								})
							}
						}
					}
				}
			},
			bannerClick(){
				
			},
			toUrl(){
				if(!this.isLogged){
					this.$router.push({name: 'login'})
				
					return
				}
				let name = "apiCmd"
				let url = getApiLoginUrl(name, "");
				this.$act.openPage(url);
			}
		},
		computed: {
			...mapGetters("global", ['lobbyInfo']),
			...mapGetters("tcp", ["isLogged"]),
			
		},
		created() {
			
			
			
			var that = this
			bus.on(busName.lobbyInfo, (val) => {
				this.hotGames = this.lobbyInfo.HotMatch
				this.hotImgList = this.sortData(this.lobbyInfo.HotRecommend)
				var CasinoRecommend = this.lobbyInfo.CasinoRecommend
				var arr = []
				for(var i=0;i<CasinoRecommend.length;i++){
					var obj = CasinoRecommend[i]
					if(obj.PlatformType=='APP'){
						arr.push(obj)
						
					}
				}
				this.topList = this.sortData(arr)
			});
			if(this.lobbyInfo.CasinoRecommend){
				var CasinoRecommend = this.lobbyInfo.CasinoRecommend
				var arr = []
				for(var i=0;i<CasinoRecommend.length;i++){
					var obj = CasinoRecommend[i]
					if(obj.PlatformType=='APP'){
						arr.push(obj)
						
					}
				}
				this.topList = this.sortData(arr)
				this.hotImgList = this.sortData(this.lobbyInfo.HotRecommend)
				
				if(this.lobbyInfo.HotMatch.length){
					this.hotGames = this.lobbyInfo.HotMatch
					
				}
			}
			
		},
		data() {
			return {
				hotGames: [],
				currentIndex: 0,
				hotImgList:[],
				topList:[],
				
			};
		},
	};
</script>

<style lang="scss" scoped>

</style>
