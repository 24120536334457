import {theme} from '@/views/theme'
	let HomeCat = require('@/views/'+ theme +'/components/HomeCat.vue').default
	let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default
	let Maintena = require('@/views/'+ theme +'/components/Maintena.vue').default
	import i18n from "@/locales/i18n";

import { mapGetters, mapActions, mapMutations } from "vuex";
import { status, getApiLoginUrl } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
export default {
  components: { HomeNav, Maintena, HomeCat },
  props: {
    cutType: String,
    active: String,
  },
  data() {
    return {
      act: 0,
      imgJson: "",
    };
  },
  computed: {
    ...mapGetters("global", [
      "maintenaText",
      "staticPic",
      "factoryCat",
      "lobbyInfo",
    ]),

    apiStatus() {
      return status;
    },
  },
  methods: {
	  language() {
	    return i18n.global.locale;
	  },
	  getImg(item){
	  		return this.lobbyInfo.HotDomain + '/gameIcon2'+ '/' + item.FactoryName + '/' + this.language() + '/' + item.GameCode + '.png' 
	  },
	  currentChange(e) {
	  	this.page = e
	  },
    onChange(index, item) {
      this.act = index;
    },
    onClick(item) {
      if (!this.$act.checkLogged()) return;
      let url = getApiLoginUrl(item.FactoryName, item.GameCode);
      this.$act.openPage(url);
    },
  },
};