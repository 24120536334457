<template>
	<div class="form">
<!-- 		<img :src="$img('recharg_bg.png')" alt="" style="width: 100%;position: absolute;z-index: 0;top: 0;left: 0;">
 -->		<div class="form_panel2" style="position: relative;z-index: 3;padding-top: 4vw;padding-bottom: 10vw;">
			<div class="row"
				style="border-bottom: 1px solid rgb(74,74,73);width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;">
				<div class="down_menu">
					<div class="bank_menu phoneMiddle flexs_r_c" @click="showPicker = true" style="color: white;">
						 <div style='width:100px;color:#afa194;font-size:14px;'>Ngân hàng</div>  {{ curBank.BankName }}
						<img :src="$img('icon_dropdown.png')" />
					</div>
					
<!-- 					<van-action-sheet :cancel-text="$t('取消')" v-model:show="showPicker" :actions="actions" @select="onSelect" />
 -->					
					<van-popup v-model:show="showPicker" round  position="bottom">
						<van-picker class="phoneMiddle" :columns="receiveBankList" @cancel="showPicker = false"
							@confirm="onConfirm" confirm-button-text="Xác nhận" cancel-button-text="Hủy" />
					</van-popup>
				</div>
			</div>
			<div class="row"
				style="border-bottom: 1px solid rgb(74,74,73);width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;">

				<div class="input blue_border phoneMiddle whiteWord flexs_r_c">
				<div style='width:100px;color:#afa194;font-size:14px;'>Tên tài khoản</div>  
				
				{{curBank.AccountName}}</div>
				<img :src="$img('fz_icon.png')" alt="" @click="copyText(curBank.AccountName)">

			</div>
			<div class="row"
				style="border-bottom: 1px solid rgb(74,74,73);width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;">

				<div class="input blue_border phoneMiddle whiteWord flexs_r_c">
				<div style='width:100px;color:#afa194;font-size:14px;'>Số tài khoản</div>
				
				{{curBank.CardNumber}}</div>

				<img :src="$img('fz_icon.png')" alt="" @click="copyText(curBank.CardNumber)">

			</div>

			<div class="row"
				style="border-bottom: 1px solid rgb(74,74,73);width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;">

				<div class="input blue_border phoneMiddle whiteWord flexs_r_c">
					<div style='width:100px;color:#afa194;font-size:14px;'>Nội dung</div>
					
				{{userInfo.Account }}</div>

				<img :src="$img('fz_icon.png')" alt="" @click="copyText(userInfo.Account)">

			</div>


			<div class="row phoneSmall" style="color: red;padding:0 3vw;">
				LƯU Ý: Nội dung chuyển khoản vui lòng điền TÊN ĐĂNG NHẬP
			</div>

			<div class="form_panel">
				<div class="row" style="border-bottom: 1px solid rgb(74,74,73);width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;">
					<input class="input blue_border phoneMiddle boldSize whiteWord" type="number" :placeholder="
	          $tools.formatNum2k(getMethod().Mini) +
	          '-' +
	          $tools.formatNum2k(getMethod().Max)
	        " v-model="amount" />
					<div class="vndk phoneSmall" style="color:white;">VND(K)</div>
				</div>
				
				<div class="  phoneSmall" style="color: white;text-align: left;margin-left: 3vw;">= <span style="color:rgb(241,175,47)">{{ amountValue }}</span> VND</div>
				<div class="row" style="width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;border-bottom: 1px solid rgb(64,87,127);">
					<input class="input blue_border middleSize whiteWord" type="text"
						placeholder="Nhập họ và tên người chuyển" v-model="account" />
				</div>
			</div>
		</div>


		<div class="loginBtn">
			<img :src="$img('ann_bottom.png')" alt="" style="width: 100%;">
			<div @click="onSubmit" :style="showPicker?'color:white':'color:black'" style="text-align: center;position: absolute;z-index: 9;width: 100%;top: 3.5vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
				{{$t('提交')}}
			</div>
			
		</div>

	
	</div>
	<my-dialog v-if="showDialog" :close="
      function () {
        showDialog = false;
      }
    " :title="dialogTitle" :msg="dialogMsg" />
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import bankPay from "@/views/mobile/bankPay";
	import {
		ref
	} from "vue";

	export default {
		mixins: [bankPay],
		setup() {
			const showPicker = ref(false);
			return {
				showPicker,
			};
		},
		computed: {
			
			...mapGetters("user", ["userInfo"])
		
		
		},
	};
</script>

<style lang="scss" scoped>
	.loginBtn{
		position: relative;
		margin-top: 24px;
		
	}
	.down_menu {
		flex: 1;
		margin-right: 3%;

	}

	.bank_menu {
		border-radius: 12px;
		padding: 2.5vw 3vw;
		position: relative;

		>img {
			width: 2vw;
			position: absolute;
			top: 46%;
			right: 0%;
		}
	}

	.copy {
		width: 17vw;
		height: 6vw;
		background-color: rgb(213, 188, 145);
		;
		border-radius: 9px;
		color: white;
		margin-left: 1vw;
		margin-right: 1vw;
		border: 0;
	}

	.pink_border {
		// border: 1px solid #ec808d;
		color: #ec808d;
		text-align: center;
		width: 60vw;
	}

	.input {
		border-radius: 11px;
		padding: 2vw 2vw;

		color: white;
	}

	.blue_border {
		flex: 1;
		//   border: 1px solid #65c6e9;
		//
	}

	.row {
		display: flex;
		align-items: center;
		justify-content: center;
		//margin-top: 5%;
		position: relative;

		>.label {
			width: 25vw;
			text-align: right;
			color: white;
			padding-right: 4px;
		}

		>.vndk {
			position: absolute;
			top: auto;
			bottom: auto;
			right: 0%;
			color: #f2d161;
		}
	}

	.form {
		position: relative;
		padding: 2vw 4vw;

		>.form_panel {
			border-radius: 11px;
			margin-top: 3vh;
			padding: 1vw 11vw;

			>.tips {
				justify-content: start;
				margin-left: 6%;
				margin-top: 0%;
			}
		}

		>.form_panel2 {
			margin-top: 2vh;
			// padding-top: .5vw;
			background-color: rgb(42,42,42);
			// border: 1px solid #65c6e9;
			padding-bottom: 1vw;
			border-radius: 5px;

			>.row {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 5%;
			}
		}
	}

	.submit_tips {
		color: red;
	}

	.submit {
		>button {
			background-color: rgb(213, 188, 145);
			;
			color: white;
			border: 0;
			border-radius: 12px;
			padding: 2vw 6vw;
			margin-bottom: 10vw;
		}
	}
</style>
