<template>
	<div style="position: relative;top: -1px;background-color: #343230;padding: 0vw 0;margin-bottom: -5px;" class="">
		<img :src="$img('banner_tyuss.png')" alt="" style="width: 100vw;min-width: 1200px;">

		<div style="position: relative;" class="listWrap">
			<img :src="$img('tiyu_beijing.png')" alt="" style="width: 100vw;min-width: 1200px;">

			<div 
				style="width: 81.5%;position: absolute;z-index: 9;left: 9.25vw;top: 30px;">
				<div class="flexs_r" style="flex-wrap:wrap;">
					<div v-for="(item,index) in listData" :key="index"
						style="border-radius: 5px;position: relative;"
						:style="index>0?'margin-left:15px':''" class="canClick topColum">
						<img @click="columClick(index)"
							:class="{ diseableImg: (item.Factory.Status==2||item.Factory.Status==3), 'mainBtnBG select': selectIndex==index}"
							class="" :src="$img(item.Factory.FactoryName+'_N.png')" alt="" style="width: 9.3vw;">
					
					
						<div class="noClick" v-if="item.Factory.Status==2||item.Factory.Status==3">
							<img :src="$img('weihu_icon.png')" alt="" style="width: 37px;margin: 2vw auto;">
						</div>
					</div>
				</div>
				
				<iframe :src="getUrl()" frameborder="0"
					style="width: 100%;height: 33vw;background-color: white;margin-top: 30px;"></iframe>

				<div class="mainBG send canClick middleSize mainBtnBG" @click="play()">
					{{$t('进入游戏')}}
				</div>

			</div>

		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import bus from "@/utils/bus.js"
	import {
		status,
		getApiLoginUrl,
		checkFactoryUrl
	} from "@/components/protocol/api";

	export default {
		components: {

		},
		data() {
			return {
				listData: [],
				selectIndex: 0,
				hoverIndex: 0,
				wrapStyle: {
					height: 0
				},
				urlList: ['https://t5x4ob.kh5688.com/Newindex?OType=2&amp;lang=vn', '',
					'https://sport.api-ugaming.com/page/guest/demoPC.jsp',
					'https://sports-bsi.sswwkk.com/web-root/restricted/default.aspx?loginname=&lang=vi-vn&sportId=1'
				]
			}
		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),
			...mapGetters("tcp", ["isLogged"]),

		},

		created() {
			if (document.documentElement.clientWidth > 1200) {
				this.wrapStyle.height = document.documentElement.clientWidth / 1920 * 1138 + 'px'
			} else {
				this.wrapStyle.height = 1200.0 / 1920 * 1138 + 'px';

			}
			this.listData = this.sortData(this.lobbyInfo.StaticPicture.LiveSports)
		},
		methods: {
			getUrl(){
				var item = this.listData[this.selectIndex]
				if(item.Factory.FactoryShowName=='SABA'){
					return 'https://t5x4ob.kh5688.com/Newindex?OType=2&amp;lang=vn'
				}else if(item.Factory.FactoryShowName=='SBO'){
					return 'https://sports-bsi.sswwkk.com/web-root/restricted/default.aspx?loginname=&lang=vi-vn&sportId=1'
				}else if(item.Factory.FactoryShowName=='UG'){
					return 'https://sport.api-ugaming.com/page/guest/demoPC.jsp'
				}
				return ''
			},
			columClick(index) {
				if (this.listData[index].Factory.Status == 2) {
					this.$message({
						message: this.$t('维护中'),
						type: 'info'
					});
					return
				}
				if (this.listData[index].Factory.Status == 3) {
					this.$message({
						message: this.$t('即将上线'),
						type: 'info'
					});
					return
				}
				this.selectIndex = index

				if (!this.getUrl().length) {
					var item = this.listData[index]
					let name = item.Factory.FactoryName;
					let url = getApiLoginUrl(name, "");
					this.$act.openPage(url);
					return
				}

				if (!this.isLogged && this.listData[index].Factory.FactoryName == 'apiCmd') {
					//this.toLogin()
					return
				}

			},
			play(item) {
				if (!this.isLogged) {
					bus.emit('showLogin')
					return
				}
				if(!item){
					item = this.listData[this.selectIndex]
				}

				let name = item.Factory.FactoryName;
				let url = getApiLoginUrl(name, "");
				this.$act.openPage(url);
			},
			toLogin() {
				this.$router.push({
					name: 'login',



				})
			},
			iconClick(index) {
				if (this.listData[index].Factory.Status == 2 || this.listData[index].Factory.Status == 3) {
					return
				}
				this.selectIndex = index
			},
			sortData(data) {
				var arr = []
				for (let key in data) {
					arr.push(data[key])

				}

				return arr.sort((a, b) => a.Factory.Status - b.Factory.Status || b.Factory.Sort - a.Factory.Sort)

			},
		},

	};
</script>

<style lang="scss" scoped>
	.send {
		width: 150px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		border-radius: 8px;

		margin: 0 auto;
		margin-top: 20px;
		background-color: #df8300;
		font-size: 20px;
	}

	.noClick {
		position: absolute;
		background-color: rgba(27, 29, 35, .5);
		top: 0;
		text-align: center;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.listWrap {}

	.listBottom {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		// white-space: nowrap;
		// overflow-y: auto;
		margin-left: 0;
		width: 100%;

		// max-height: 48vw;
		// background-color: rgba(0, 0, 0,.4);
	}

	.topNavi {
		width: 100%;
		margin-left: 0;
		border-top-left-radius: 1vw;
		border-top-right-radius: 1vw;
		background-color: #1a1a1a;
		overflow: hidden;
	}

	.colum {
		margin-top: 2.25vw;
		margin-left: 2.25vw;
		position: relative;
		width: 11.6vw;
		height: 11.6vw;
	}

	.topImgWrap {
		background-color: #1a1a1a;
	}


	.el-pagination {
		text-align: center;
	}

	.mask {
		background-color: rgba(0, 0, 0, .55);
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;
		top: 0;
		left: 0;
		border-radius: 10px;

		.enter {
			width: 9vw;
			height: 2vw;
			line-height: 2vw;
			border-radius: 4px;
			text-align: center;
			font-size: 0.86vw;
			font-weight: bold;
			background-image: linear-gradient(to top, #e0b626, #d7781e);
			color: white;
			margin-left: 1.3vw;
			margin-top: 4.6vw;
		}
	}

	.select {
		border-radius: 5px;
		height: 100%;
	}

	input {
		width: 400px;
		height: 45px;
		color: white;
		border-radius: 5px;
		padding-left: 10px;
		font-size: 20px;
		background-color: #0f192f;
	}
	.topColum{
			background-color: rgb(99,99,99);
			border-radius: 5px;
			
			
		}
</style>

<style>
	/* 为了不造成样式污染，单据加一个style标签来修改第三方组件样式，pagination表格分页整体居中，上边距20px */
	.el-pagination {
		text-align: center;
		margin-top: 20px;
	}
</style>
