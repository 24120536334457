<template>
	<div style="position: relative;width: 100%;min-width: 1200px;" class='mainBg'>
		<div v-if="showNavi">
			<div style="height: 42px;width: 100%;">
				<div class="flexs_sb" style="width: 1100px;height: 100%;margin: 0 auto;">
					<div class="flexs_r_c topColum canClick " @click="topClick(index)" v-for="(item,index) in leftItems" :key="item" :class="selectIndex==index?'selected':'nol'" >
						<img :src="$img(item.img)" alt="" :style="index==7?'width:30px;':''">
						<div class="" style="margin-left: 10px;">{{item.title.toUpperCase()}}</div>

						<div v-if="index==7&&readNum>0" class="read">{{readNum}}</div>
					</div>
				</div>
			</div>
			<div style="height: 38px;background-color: #302e2b;" v-if="showBill">
				<div style="width:350px;margin: 0 auto;height: 100%;" class="flexs_sb">
					<div style="color: white;font-size: 14px;padding: 5px 0;" @click="billClick(0)" :class="billIndex==0?'bottomLine':''" class="canClick">{{$t('账单记录').toUpperCase()}}</div>
					<div style="color: white;font-size: 14px;padding: 5px 0;" @click="billClick(1)" :class="billIndex==1?'bottomLine':''" class="canClick">{{$t('游戏记录').toUpperCase()}}</div>
					
				</div>
			</div>
			
			
			<div style="height: 38px;background-color: #302e2b;" v-if="showAct||isRecord">
				<div style="width:460px;margin: 0 auto;height: 100%;" class="flexs_sb">
					<div style="color: white;font-size: 14px;padding: 5px 0;" @click="actClick(0)" :class="actIndex==0?'bottomLine':''" class="canClick">{{$t('活动列表').toUpperCase()}}</div>
					<div style="color: white;font-size: 14px;padding: 5px 0;" @click="actClick(1)" :class="actIndex==1?'bottomLine':''" class="canClick">{{$t('活动领取').toUpperCase()}}</div>
					<div style="color: white;font-size: 14px;padding: 5px 0;" @click="actClick(2)" :class="actIndex==2?'bottomLine':''" class="canClick">{{$t('领取记录').toUpperCase()}}</div>
					
				</div>
			</div>
		</div>
		
		
		
		
		<div v-if="(showAct||showVip)&&!isRecord">
			<router-view ></router-view>
		</div>
		
		<div v-else style='position:relative;'>
			<img :src="$img('huiyzx_bg.png')" alt="" style="width: 100%;">
<!-- 			<div style="height: 50vw;min-height: 750px;" class="mainBg"></div>
 -->			<div style="position: absolute;top: 90px;left: calc(50% - 550px);">
				<!-- <img :src="$img('user_bgImg.png')" alt="" style="width:1100px;height: 45vw;min-height: 650px;"> -->
				<div style="border-radius: 16px;background-color: #2e2d2c;width:1100px;height: 45vw;min-height: 650px;position: absolute;left: 0;top: 0;white-space: nowrap;overflow-y: auto;">
					<router-view></router-view>
					
				</div>
			</div>
		</div>
		
		
	</div>
	<addBank v-if="showAddBankVisible" @handleClose='showAddBankVisible=false'></addBank>
	

</template>

<script>
	import {theme} from '@/views/theme'
		
		
		let addBank = require('@/views/'+ theme +'/components/addBank.vue').default
		let confirm = require('@/views/'+ theme +'/components/confirm.vue').default
		
	
	
		import {
			req,
			qs
		} from "@/utils/request";
		import mqant from "@/components/protocol/mqantlib";
		import {
			mapGetters,
			mapActions,
			mapMutations
		} from "vuex";
		import bus from "@/utils/bus.js"
	
		import {
			topic
		} from "@/components/protocol/api";
	
		export default {
			name: "userCenter",
			
			components: {
				
				addBank,
				
				confirm
			},
			data() {
				return {
					showNavi:true,
					leftItems: [{title:this.$t('基本资料'),img:'user_zl.png'},
					{title:this.$t('检查流水'),img:'user_ls.png'},
					{title:this.$t('线上存款'),img:'user_ck.png'},
					{title:this.$t('线上取款'),img:'user_qk.png'},
					{title:this.$t('账户记录'),img:'user_zh.png'},
					{title:'KHUYẾN MÃI',img:'user_yh.png'},
					{title:'VIP',img:'user_vip.png'},
					{title:this.$t('消息'),img:'pc_mail.png'}
						
					],
					showAct:false,
					showVip:false,
					isRecord:false,
					billIndex:0,
					actIndex:0,
					showBill:false,
					selectIndex: '',
					showConfirm: false,
					showAddBankVisible: false,
					bankList: [],
					readNum:0
				}
			},
			computed: {
				...mapGetters("tcp", ["isLogged","info","isConnect"]),
				...mapGetters("user", ["token", "tcpInfo","userInfo"]),
				...mapGetters("global", ["version","lobbyInfo"]),
				// ...mapGetters("global", ["globalState", "uuid","lobbyInfo"]),
				// ...mapGetters("user", ["token","userInfo","tcpInfo"]),
	
			},
			mounted() {
				//this.messageList = [{title:'这是一个公告',time:'2022-06-18'}];
	
			},
			created() {
				
				if(this.$route.path!='/activity'&&this.$route.path!='/vip'){
					console.log('fffeee',this.token)
					if (this.token.AccessToken&&!this.token.AccessToken.length) {
						
						this.$router.push({
							name: 'home',
						
						
						
						})
					}
				}
				
				if(this.$route.path=='/activity'||this.$route.path=='/vip'){
					if (!this.isLogged) {
						
						this.showNavi = false
					}
				}
				
				bus.off('hasLogin')
				
				
				bus.on('hasLogin', () => {
						this.showNavi = true
				})
				
				if(theme=='pc3'){
					this.leftItems.splice(7,0,this.$t('VIP福利'))
				}
				this.selectIndex = this.$route.params.selectIndex ? this.$route.params.selectIndex : 0
				var that = this
				bus.off('bindBank')
				bus.off('showAddBank')
				bus.off('userCenter')
				
				
				bus.on('showAddBankVisible', () => {
					that.showAddBankVisible = true
				})
				bus.on('bindBank', () => {
					that.getUserInfo()
				})
				bus.on('showAddBank', () => {
					that.confirm()
				})
				bus.on('userCenter', (val) => {
					//that.selectIndex = val.selectIndex
					that.itemClick(val.selectIndex)
				})
				if(this.$route.path=='/activity'){
					this.selectIndex=5
					this.showAct = true
					this.actIndex=0
				}else if(this.$route.path=='/getActivity'){
					this.selectIndex=5
					this.showAct = true
					
					
					this.actIndex=1
					
				}else if(this.$route.path=='/getActivityRecord'){
					this.selectIndex=5
					this.showAct = false
					this.isRecord = true
					this.actIndex=2
					
					}else if(this.$route.path=='/checkLS'){
					this.selectIndex=1
					
				}else if(this.$route.path=='/userInfo'){
					this.selectIndex=0
				}else if(this.$route.path=='/recharge'){
					this.selectIndex=2
				}else if(this.$route.path=='/withdraw'){
					this.selectIndex=3
				}else if(this.$route.path=='/gameRecord'){
					this.selectIndex=4
					this.billIndex = 1
					this.showBill = true
					this.showAct = false
				}else if(this.$route.path=='/billRecord'){
					this.selectIndex=4
					this.showBill = true
					this.showAct = false
					
					this.billIndex = 0
				}else if(this.$route.path=='/vip'){
					this.selectIndex=6
					this.showVip = true
				}
				
				
				
			

			bus.on('onConnected', () => {
				that.getMessageList()
				that.getUserInfo()
				
			})
			if(this.isConnect){
				this.getMessageList()
					this.getUserInfo()
				
			}
			bus.on('changeRead',()=>{
				that.getMessageList()
			})
				
	
			},
			watch:{
				$route(to,from){
					
					
					this.showAct = false
					this.showBill = false
					this.showVip = false
					if(to.path=='/activity'){
						this.selectIndex=5
						this.actIndex=0
						this.showAct = true
						this.isRecord = false
					}else if(to.path=='/getActivity'){
					this.selectIndex=5
					this.actIndex=1
					this.showAct = true
					this.isRecord = false
					}else if(to.path=='/getActivityRecord'){
					this.selectIndex=5
					this.actIndex=2
					this.showAct = false
					this.isRecord = true
					}else if(to.path=='/checkLS'){
						this.selectIndex=1
						
					}else if(to.path=='/userInfo'){
						this.selectIndex=0
					}else if(to.path=='/recharge'){
						this.selectIndex=2
					}else if(to.path=='/withdraw'){
						this.selectIndex=3
					}else if(to.path=='/gameRecord'){
						this.showBill = true
						this.billIndex = 1
						
						this.selectIndex=4
					}else if(to.path=='/billRecord'){
						this.billIndex = 0
						this.showBill = true
						
						this.selectIndex=4
					}else if(to.path=='/vip'){
						this.selectIndex=6
						this.showVip = true
					}
					
					
					
				}
			},
			methods: {
				getMessageList() {
				var that = this
				this.readNum = 0

				var num = 0
				mqant.request(topic.mailList, {}, function(data, topicName, msg) {
					that.messageData = []
					for(var obj of data.Data.mail){
						if(obj.ReadState!='read'){
							num ++
						}
					}
					that.readNum = num
				
				});
			},
				billClick(index){
					
					if(this.$route.path!='/activity'&&this.$route.path!='/vip'){
						if (!this.isLogged) {
							
							bus.emit('showLogin')
							// this.$router.push({
							// 	name: 'home',
							
							return
							
							// })
						}
					}
						this.billIndex = index;
						if(index==0){
							this.$router.push({
								name:'billRecord'
							})
						}else {
							this.$router.push({
								name:'gameRecord'
							})
						}
				},
				actClick(index){
					if(this.$route.path=='/activity'&&index!=0){
						if (!this.isLogged) {
							bus.emit('showLogin')
							// this.$router.push({
							// 	name: 'home',
							
							return
							
							// })
						}
					}
					this.actIndex = index
					if(index==0){
						this.$router.push({
							name:'activity'
						})
					}else if(index==1){
						this.$router.push({
							name:'getActivity'
						})
					}else {
						this.$router.push({
							name:'getActivityRecord'
						})
					}
				},
				topClick(index){
					if((this.$route.path=='/activity'||this.$route.path=='/vip')&&(index!=5&&index!=6)){
						if (!this.isLogged) {
							bus.emit('showLogin')
							// this.$router.push({
							// 	name: 'home',
							
							return
							
							// })
						}
					}
					
					
					if (index == 2 || index == 3) {
						var that = this
						mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
							that.bankList = data.Data.myDouDouBT
							
							if (!that.bankList.length) {
								that.$message({
									message: that.$t('请绑定银行卡'),
									type: 'info'
								});
								that.showAddBankVisible = true
								return
							} 
						});
					
					}
					
					
					this.showAct = false
					this.showBill = false
					this.isRecord = false
						var name = ''
						if(index==0){
							name = 'userInfo'
						}else if(index==1){
							name = 'checkLS'
						}else if(index==2){
							name = 'recharge'
						}else if(index==3){
							name = 'withdraw'
						}else if(index==4){
							this.showBill = true
							name = 'billRecord'
						}
						else if(index==5){
							this.showAct = true
							this.isRecord = false
							name = 'activity'
						}else if(index==6){
							name = 'vip'
							this.showVip = true
							
						}else if(index==7){
							name = 'userMessage'
							
						}
						
						
						if (index == 3 || index == 2) {
							var that = this
							mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
							
								that.bankList = data.Data.myDouDouBT
								if (!that.bankList.length) {
									that.showConfirm = true
								} else {
									that.$router.push({
										name:name
									})
									
								 	that.selectIndex = index
							
								}
							});
							
						} else {
							this.$router.push({
								name:name
							})
							 this.selectIndex = index
							// bus.emit('updateIndex', {
							// 	selectIndex: index
							// })
							
							
						}
							
				},
				getUserInfo() {
					var that = this
					mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
						that.bankList = data.Data.myDouDouBT
					});
				},
				confirm() {
					this.showAddBankVisible = true
				},
	
				itemClick(index) {
					if(this.$route.path!='/activity'&&this.$route.path!='/vip'){
						if (!this.isLogged) {
							bus.emit('showLogin')
							// this.$router.push({
							// 	name: 'home',
							
							return
							
							// })
						}
					}
					var name = ''
					if(index==0){
						name = 'userInfo'
					}else if(index==1){
						name = 'checkLS'
					}else if(index==2){
						name = 'recharge'
					}else if(index==3){
						name = 'withdraw'
					}else if(index==4){
						name = 'billRecord'
					}else if(index==5){
						name = 'activity'
					}else if(index==6){
						name = 'vip'
					}
					
					
					if (index == 3 || index == 4) {
						var that = this
						mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
	
							that.bankList = data.Data.myDouDouBT
							if (!that.bankList.length) {
								that.showConfirm = true
							} else {
								that.$router.push({
									name:name
								})
								
							 	that.selectIndex = index
							
							// 	bus.emit('updateIndex', {
									
							// 		selectIndex: index
							// 	})
							}
						});
	
					} else {
						this.$router.push({
							name:name
						})
						 this.selectIndex = index
						// bus.emit('updateIndex', {
						// 	selectIndex: index
						// })
						
	
					}
	
				}
				// addBank(){
				// 	this.showAddBank = true
				// 	console.log(11111)
				// }
				// close(){
				// 	this.show
				// }
	
	
			}
		}
</script>

<style lang="scss" scoped>
	.listWrap {
		white-space: nowrap;
		overflow-y: auto;
		// position: absolute;
		// top: 4vw;
		// left: 0;
		width: 270px;
		display: flex;
		
		// min-height: 538px;
		background-color: rgba(0, 0, 0, 0);
		flex-direction: column;
		// width: 100%;
		border: 1px solid rgba(245, 232, 155,.5);

		min-height: 48vw;
	}

	.right {
		position: relative;
		width: 815px;
		margin-left: 15px;
		// min-width: 682px;
		// min-height:758px ;
		height: 658px;
		border: 1px solid rgba(245, 232, 155,.5);
		min-height: 48vw;
		
		background-color: rgba(0, 0, 0, 0.5);
	}

	.rightView {
		// white-space: nowrap;
		overflow-y: auto;
		// position: absolute;
		// top: 4vw;
		//  left: 0vw;

		padding: 0 45px;
		padding-top: 30px;
		display: flex;
		flex-direction: column;
		// width: 100%;

		// 	max-height: 53.5vw;
		// 
	}

	.listWrap::-webkit-scrollbar {
		display: none;
	}

	.toolLeft {
		width: 100%;
		height: 50px;
		text-align: center;
		line-height: 50px;
	}

	.selected {
		color:#dedbdd; 
		
		
	}

	.nol {
		color:#afa194;

	}
	.topColum{
		font-size: 16px;
		position:relative;
	}
	.topColum:hover {
		color: white;
	}
	.bottomLine{
		border-bottom: 3px solid #d4ac7e;
	}
	.read{
		width:20px;
		height:20px;
		background-color: red;
		color:white;
		border-radius: 10px;
		font-size: 12px;
		text-align: center;
		line-height: 20px;
		position: absolute;
		right:-20px;
		top:-10px;
	}
</style>
