import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import bus from "@/utils/bus.js"
	import {
		getApiLoginUrl
	} from "@/components/protocol/api";
	import vueQr from 'vue-qr/src/packages/vue-qr.vue'
	
	export default {
		components:{
			vueQr
			
		},
		data(){
			return {
			 
			}
		},
		props:{
			
			// diaLoginVisible:{
			// 	type:Boolean,
			// 	default:true
			// }
		},
		computed: {
		
		...mapGetters("tcp", ["isLogged", "isConnect"]),
		
			...mapGetters("global", [ "lobbyInfo"]),
		
		},
		created() {
		},
		methods:{
			sortData(data){
				var arr = []
				for (let key in data) {
					arr.push(data[key]) 
				
				}
				// this.factoryList = this.factoryList.sort((a, b) => {
				// 	return  a.Factory.Status - b.Factory.Status||b.Factory.Sort- a.Factory.Sort
					
				// })
				return arr.sort((a, b) => a.Factory.Status - b.Factory.Status||b.Factory.Sort- a.Factory.Sort)
				
			},
			toDetail(val, type) {
				var name;
				var factoryList;
				if (type == 2) {
					name = 'chess'
					factoryList = this.lobbyInfo.StaticPicture.Card
				} else if (type == 3) {
					name = 'slot'
					factoryList = this.lobbyInfo.StaticPicture.Slot
			
				} else if (type == 4) {
					name = 'fishing'
					factoryList = this.lobbyInfo.StaticPicture.Fish
			
				} else {
					
					if (!this.isLogged) {
						bus.emit('showLogin', {})
			
						return
					}
					let name = val.Factory.FactoryName;
					let url = getApiLoginUrl(name, "");
					this.$act.openPage(url);
					return
				}
					this.$router.push({
						name: name,
						params: {
							data: JSON.stringify(val),
							factoryList:JSON.stringify(factoryList)
						}
			
			
					})
				
			},
			
			
		}
	}