
import { mapGetters, mapActions, mapMutations } from "vuex";
import { bus, busName } from "@/components/core/bus";

export default {
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters("pay", ["payInfo"]),
  },
  methods: {
    showCardNum(num) {
      let res = ""
      for (let k in num) {
        if (k < num.length - 3) {
          res += "*";
          continue
        }
        res += num[k];
      }
      return res
    },
    chooseCard(item) {
      for (let i = 0; i < this.payInfo.myDouDouBT.length; ++i) {
        if (this.payInfo.myDouDouBT[i] == item) {
          this.payInfo.myDouDouBT[i].active = true;
        } else {
          this.payInfo.myDouDouBT[i].active = false;
        }
      }
      this.$emit("onChooseCard",item)
      // this.onChooseCard?.call(this, item);
    },
    bindCard() {
		bus.emit('hiddeTab',{
			isShow:false
		})
      this.$router.push({ name: "bindbank" });
    },
  },
  mounted() {
  },
};