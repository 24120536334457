<template>
  <home-nav active="lottery" />
  <div class="item" @click="onClick(item)">
   <img
      :src="$img('lottery_bg.png')"
      class="bg"
      :class="{ img_gray: isGrey(item) }"
    />
    <img
      :src="$img('lottery_icon.png')"
      class="icon"
      :class="{ img_gray: isGrey(item) }"
    />
    <maintena v-if="isGrey(item)" :text="maintenaText(item)" textWidth="60%" />
  </div>
</template>

<script>
	import {theme} from '@/views/theme'
		
		let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default
		let Maintena = require('@/views/'+ theme +'/components/Maintena.vue').default
		
	
	import home from "../../mobile/home";
	export default {
	  components: { HomeNav, Maintena },
	  mixins: [home],
	  data() {
	    return {
	      curType: "Lottery",
	      item: {
	        id: "apiVsl",
	      }
	    };
	  },
	
	};
</script>

<style lang="scss" scoped>
.item {
  position: relative;
  margin-top: 3vw;
  img {
    display: block;
  }
  > .bg {
    width: 87vw;
    margin-left: auto;
    margin-right: auto;
  }
  > .icon {
    width: 21vw;
    position: absolute;
    top: 6%;
    left: 13%;
  }
}
.maintena {
  font-size: 12px;
  transform: scale(0.9);
  line-height: 12px;
  > div {
    width: 99%;
  }
}
</style>