<template>
	<div style="display: flex;flex-direction: row;align-items: center;height: 44px;">
		<img :src="$img('laba.webp')" alt="" style="width: 24px;margin-right: 10px;margin-left: 10px;">
		<div class="m-slide" @mouseenter="onStop" @mouseleave="onStart">

			<!-- <div :style="`will-change: transform; transform: translateX(${-left}px);`" class="u-slide-title bigSize"
				v-for="(item, index) in sliderData" :key="index" :title="item.Content" @click="onClickTitle(index)">
				{{ item.Content }}
			</div> -->
			
			<marquee 
			direction="left" 
			behavior="scroll" 
			 
			scrollamount="6" 
			 
			
			:width='sliderWidth'
			 style="color:white;"
			
			hspace="0" 
			vspace="0" 
			> 
				<span v-for="(item, index) in sliderData" @click="onClickTitle(index)" :key="index"
			    class="bigSize canClick whiteWord " style='height:44px;' :style="index>0?'margin-left:300px;':''">{{ item.Content }}</span>
			 </marquee>
	
			
		</div>
	</div>

</template>
<script>
	import Slider from "@/views/pc/Slider"
	import bus from "@/utils/bus.js";
	
	export default{
	  mixins:[Slider],
	  data() {
	  	return {
	  		sliderWidth:450
	  	}
	  },
	  created(){
		  var that = this;
		  console.log('ddddd',document.documentElement.clientWidth )
		  if (document.documentElement.clientWidth > 1700) {
		   
		      this.sliderWidth = 650
		      
		  } else {
			  if(document.documentElement.clientWidth > 1200){
				  this.sliderWidth = (document.documentElement.clientWidth / 3000) * 350
				  
			  }else {
				  this.sliderWidth = (document.documentElement.clientWidth / 1920) * 150
				  
			  }
		  }
		  
		  bus.on("windowWidth", (val) => {
		    if (val.windowWidth > 1700) {
		     that.sliderWidth = 650
		     
		    } else {
				if(val.windowWidth > 1200){
					that.sliderWidth = (val.windowWidth / 3000) * 350
					
				}else {
					that.sliderWidth = (val.windowWidth / 1920) * 150
					
				}
				
		    }
		  });
	  }
	}
</script>
<style lang="scss" scoped>
	.m-slide {
		width: 500px;
		margin: 0px auto;
		position: relative;
		height: 44px;
		white-space: nowrap;
		overflow: hidden;
		display: flex;
		
		flex-direction: row;
		align-items: center;

		.u-slide-title {
			color: white;
			display: inline-block;
			margin-left: 50px;
			// width: 300px;
			// overflow: hidden;
			// text-overflow: ellipsis;
			// white-space: nowrap;
			cursor: pointer;

			&:hover {
				color: #1890FF;
			}
		}
	}
	.canClick:hover {
		color: #1890FF;
	}
</style>
