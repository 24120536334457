<template>
  <div class="flexs_colum" style="position: relative; padding: 0 40px">
    <div class="flexs_sb" style="margin-top: 20px; width: 100%">
      <div class="item canClick" @click="selectIndex = 0" :class="selectIndex == 0 ? 'chooseBG' : 'nolBG'" style="
          text-align: center;
          border-radius: 10px;
          width: 15%;
          height: 110px;
          position: relative;
        ">
        <div style="z-index: 9">
          <img :src="$img('yinh_icon.png')" style="height: 60px; margin: 10px auto; margin-bottom: 3px" />

          <div style="font-size: 20px" :style="selectIndex == 0 ? 'color:black' : 'color:white'">
            {{ "Nạp nhanh".toUpperCase() }}
          </div>
        </div>
        <img :src="$img('goux_img.png')" v-if="selectIndex == 0" style="position: absolute; bottom: 0; right: 0" />
      </div>

      <!-- <div class="item canClick" @click="selectIndex = 1" :class="selectIndex == 1 ? 'chooseBG' : 'nolBG'" style="
          text-align: center;
          border-radius: 10px;
          width: 15%;
          height: 110px;
          position: relative;
        ">
        <div style="position: relative; z-index: 9">
          <img :src="$img('momo_icon.png')" style="height: 60px; margin: 10px auto; margin-bottom: 3px" />
          <div style="font-size: 20px" :style="selectIndex == 1 ? 'color:black' : 'color:white'">
            {{ "MOMO".toUpperCase() }}
          </div>
        </div>
        <img :src="$img('goux_img.png')" v-if="selectIndex == 1" style="position: absolute; bottom: 0; right: 0" />
      </div>

      <div class="item canClick" @click="selectIndex = 2" :class="selectIndex == 2 ? 'chooseBG' : 'nolBG'" style="
          text-align: center;
          border-radius: 10px;
          width: 15%;
          height: 110px;
          position: relative;
        ">
        <div style="position: relative; z-index: 9">
          <img :src="$img('code_icon.png')" style="height: 60px; margin: 10px auto; margin-bottom: 3px" />
          <div style="font-size: 20px" :style="selectIndex == 2 ? 'color:black' : 'color:white'">
            {{ "Quét QR".toUpperCase() }}
          </div>
        </div>
        <img :src="$img('goux_img.png')" v-if="selectIndex == 2" style="position: absolute; bottom: 0; right: 0" />
      </div>

      <div class="item canClick" @click="selectIndex = 3" :class="selectIndex == 3 ? 'chooseBG' : 'nolBG'" style="
          text-align: center;
          border-radius: 10px;
          width: 15%;
          height: 110px;
          position: relative;
        ">
        <div style="position: relative; z-index: 9">
          <img :src="$img('lianjie_icon.png')" style="height: 60px; margin: 10px auto; margin-bottom: 3px" />
          <div style="font-size: 20px" :style="selectIndex == 3 ? 'color:black' : 'color:white'">
            {{ "Trực tiếp".toUpperCase() }}
          </div>
        </div>
        <img :src="$img('goux_img.png')" v-if="selectIndex == 3" style="position: absolute; bottom: 0; right: 0" />
      </div>

      <div class="item canClick" @click="selectIndex = 4" :class="selectIndex == 4 ? 'chooseBG' : 'nolBG'" style="
          text-align: center;
          border-radius: 10px;
          width: 15%;
          height: 110px;
          position: relative;
        ">
        <div style="position: relative; z-index: 9">
          <img :src="$img('duihuan_icon.png')" style="height: 60px; margin: 10px auto; margin-bottom: 3px" />
          <div style="font-size: 20px" :style="selectIndex == 4 ? 'color:black' : 'color:white'">
            {{ "Code".toUpperCase() }}
          </div>
        </div>
        <img :src="$img('goux_img.png')" v-if="selectIndex == 4" style="position: absolute; bottom: 0; right: 0" />
      </div>

      <div class="item canClick" @click="selectIndex = 5" :class="selectIndex == 5 ? 'chooseBG' : 'nolBG'" style="
          text-align: center;
          border-radius: 10px;
          width: 15%;
          height: 110px;
          position: relative;
        ">
        <div style="position: relative; z-index: 9">
          <img :src="$img('shouji_zhifu.png')" style="height: 60px; margin: 10px auto; margin-bottom: 3px" />
          <div style="font-size: 20px" :style="selectIndex == 5 ? 'color:black' : 'color:white'">
            {{ "THẺ CÀO".toUpperCase() }}
          </div>
        </div>
        <img :src="$img('goux_img.png')" v-if="selectIndex == 5" style="position: absolute; bottom: 0; right: 0" />
      </div> -->
    </div>

    <div class="" v-if="selectIndex == 0" style="margin-top: 38px; color: #fdfeff">
      <div class="flexs_r">
        <div class="userBG" style="
            width: 40%;
            height: 400px;
            padding: 0 20px;
            position: relative;
            border-radius: 8px;
          ">
          <div @click="showChosee = !showChosee" style="
              position: relative;
              height: 60px;
              border-bottom: 1px solid #2a3e68;
            " class="flexs_sb">
            <div style="font-size: 14px" class="flexs_r_c">
              <div style="width: 150px; color: #afa194; font-size: 16px">
                Ngân hàng
              </div>

              {{ bankName }}
            </div>
            <img :src="$img('rechargeDown.png')" alt="" style="width: 20px" />
          </div>

          <div style="height: 60px; border-bottom: 1px solid #2a3e68" class="flexs_sb">
            <div style="font-size: 14px" class="flexs_r_c" v-if="bankList.length > 0">
              <div style="width: 150px; color: #afa194; font-size: 16px">
                Tên tài khoản
              </div>

              {{ bankList[bankSelectIndex].AccountName }}
            </div>

            <img class="canClick" @click="copyUrl(bankList[bankSelectIndex].AccountName)" :src="$img('fz_icon.png')" alt="" style="width: 39px" />
          </div>
          <div style="height: 60px; border-bottom: 1px solid #2a3e68" class="flexs_sb">
            <div style="font-size: 14px" class="flexs_r_c" v-if="bankList.length > 0">
              <div style="width: 150px; color: #afa194; font-size: 16px">
                Số tài khoản
              </div>

              {{ bankList[bankSelectIndex].CardNumber }}
            </div>

            <img class="canClick" @click="copyUrl(bankList[bankSelectIndex].CardNumber)" :src="$img('fz_icon.png')" alt="" style="width: 39px" />
          </div>
          <div style="height: 60px; border-bottom: 1px solid #2a3e68" class="flexs_sb">
            <div style="font-size: 14px" class="flexs_r_c">
              <div style="width: 150px; color: #afa194; font-size: 16px">
                Nội dung
              </div>

              {{ userInfo.Account }}
            </div>

            <img class="canClick" @click="copyUrl(userInfo.Account)" :src="$img('fz_icon.png')" alt="" style="width: 39px" />
          </div>
          <div style="
              white-space: nowrap;
              white-space: break-spaces;
              word-break: keep-all;
              margin-top: 20px;
              width: 100%;
              text-align: left;
              font-size: 15px;
              color: red;
            ">
            LƯU Ý: Nội dung chuyển khoản vui lòng điền TÊN ĐĂNG NHẬP
          </div>

          <div v-if="showChosee" class="selectArea" style="top: 60px; left: 20px">
            <div @click="itemClick(index)" v-for="(item, index) in bankList" class="selectItem bigSize boldSize" :key="item">
              {{ item.BankName }}
            </div>
          </div>
        </div>
        <div class="userBG" style="margin-left: 5%; width: 55%; height: 400px; border-radius: 8px">
          <div style="
              white-space: nowrap;
              white-space: break-spaces;
              word-break: keep-all;
              margin-top: 20px;
              width: 80%;
              text-align: left;
              font-size: 15px;
              color: red;
              margin-left: 7%;
            ">
            Vui lòng chuyển tiền trước khi bấm xác nhận
          </div>

          <div class="flexs_sb inputBG" style="
              margin: 0 auto;
              width: 80%;
              margin-top: 36px;
              height: 70px;
              border-radius: 8px;
              padding: 0 20px;
            ">
            <input type="text" class="middleSize" v-model="money" @input="money = checkNum(money)" :placeholder="
                $tools.formatNum2k(cardObj.Mini) +
                '-' +
                $tools.formatNum2k(cardObj.Max)
              " style="font-size: 14px" />
            <div style="font-size: 14px; color: #fdfeff">VND(K)</div>
          </div>

          <div style="
              margin-top: 10px;
              margin-left: 7%;
              font-size: 12px;
              color: #808691;
            " class="">
            =
            <span style="color: #f2d161">{{
              $tools.formatNum(money * 1000)
            }}</span>
            VND
          </div>

          <div class="flexs_sb inputBG" style="
              margin: 0 auto;
              width: 80%;
              margin-top: 30px;
              height: 70px;
              border-radius: 8px;
              padding: 0 20px;
            ">
            <input type="text" class="middleSize" v-model="name" :placeholder="'Nhập họ và tên người chuyển'" style="width: 100%; font-size: 14px; height: 100%" />
          </div>

          <div @click="reCharge()" class="canClick send mainBtnBG" style="font-size: 14px">
            {{ $t("提交") }}
          </div>
        </div>
      </div>
    </div>
    <div class="" v-if="selectIndex == 1" style="margin-top: 38px">
      <div style="height: 360px; border-radius: 8px" class="userBG">
        <div class="flexs_sb inputBG" style="
            margin: 0 auto;
            width: 60%;
            margin-top: 66px;
            height: 70px;
            border-radius: 8px;
            padding: 0 20px;
          ">
          <input type="text" class="middleSize" v-model="momoMoney" @input="momoMoney = checkNum(momoMoney)" :placeholder="
              $tools.formatNum2k(momoObj.Mini) +
              '-' +
              $tools.formatNum2k(momoObj.Max)
            " style="font-size: 14px" />
          <div style="font-size: 14px; color: #fdfeff">VND(K)</div>
        </div>

        <div style="
            margin-top: 10px;
            margin-left: 20%;
            font-size: 12px;
            color: #808691;
          " class="">
          =
          <span style="color: #f2d161">{{
            $tools.formatNum(momoMoney * 1000)
          }}</span>
          VND
        </div>

        <div class="flexs_sb inputBG" @click="showChoseeMomo = !showChoseeMomo" style="
            margin: 0 auto;
            width: 60%;
            margin-top: 30px;
            height: 70px;
            border-radius: 8px;
            padding: 0 20px;
            position: relative;
          ">
          <input type="text" class="middleSize" v-model="momoChoose" :placeholder="$t('快捷选择充值金额')" style="width: 100%; font-size: 14px; height: 100%" disabled />

          <img :src="$img('rechargeDown.png')" alt="" style="width: 20px; position: absolute; right: 20px; top: 30px" />

          <div v-if="showChoseeMomo" class="selectArea" style="top: 70px; left: 0px; width: 100%">
            <div @click="momoClick(index)" v-for="(item, index) in itemData" class="selectItem bigSize boldSize" :key="item">
              {{ item.num }}
            </div>
          </div>
        </div>
      </div>

      <div class="mainBG send canClick middleSize boldSize mainBtnBG" style="font-size: 14px" @click="reCharge()">
        {{ $t("提交") }}
      </div>
    </div>
    <div class="" v-if="selectIndex == 2" style="margin-top: 38px">
      <div style="height: 360px; border-radius: 8px" class="userBG">
        <div class="flexs_sb inputBG" style="
            margin: 0 auto;
            width: 60%;
            margin-top: 66px;
            height: 70px;
            border-radius: 8px;
            padding: 0 20px;
          ">
          <input type="text" class="middleSize" v-model="scanMoney" @input="scanMoney = checkNum(scanMoney)" :placeholder="
              $tools.formatNum2k(scanObj.Mini) +
              '-' +
              $tools.formatNum2k(scanObj.Max)
            " style="font-size: 14px" />
          <div style="font-size: 14px; color: #fdfeff">VND(K)</div>
        </div>

        <div style="
            margin-top: 10px;
            margin-left: 20%;
            font-size: 12px;
            color: #808691;
          " class="">
          =
          <span style="color: #f2d161">{{
            $tools.formatNum(scanMoney * 1000)
          }}</span>
          VND
        </div>

        <div class="flexs_sb inputBG" @click="showThirdChoose = !showThirdChoose" style="
            margin: 0 auto;
            width: 60%;
            margin-top: 30px;
            height: 70px;
            border-radius: 8px;
            padding: 0 20px;
            position: relative;
          ">
          <input type="text" class="middleSize" v-model="scanBankName" :placeholder="$t('选择银行卡')" style="width: 100%; font-size: 14px; height: 100%" disabled />

          <img :src="$img('rechargeDown.png')" alt="" style="width: 20px; position: absolute; right: 20px; top: 30px" />

          <div v-if="showThirdChoose" class="selectArea" style="top: 70px; left: 0px; width: 100%">
            <div @click.stop="thirdItemClick(item)" v-for="(item, index) in scanBankList" class="selectItem bigSize" :key="item" style="font-size: 14px">
              {{ item.Name }}
            </div>
          </div>
        </div>
      </div>

      <div class="mainBG send canClick middleSize boldSize mainBtnBG" style="font-size: 14px" @click="reCharge()">
        {{ $t("提交") }}
      </div>
    </div>
    <div v-if="selectIndex == 3" style="margin-top: 38px">
      <div class="userBG" style="height: 360px; border-radius: 8px">
        <div class="flexs_sb inputBG" style="
            margin: 0 auto;
            width: 60%;
            margin-top: 66px;
            height: 70px;
            border-radius: 8px;
            padding: 0 20px;
          ">
          <input type="text" class="middleSize" v-model="linkMoney" @input="linkMoney = checkNum(linkMoney)" :placeholder="
              $tools.formatNum2k(linkObj.Mini) +
              '-' +
              $tools.formatNum2k(linkObj.Max)
            " style="font-size: 14px" />
          <div style="font-size: 14px; color: #fdfeff">VND(K)</div>
        </div>

        <div style="
            margin-top: 10px;
            margin-left: 20%;
            font-size: 12px;
            color: #808691;
          " class="">
          =
          <span style="color: #f2d161">{{
            $tools.formatNum(linkMoney * 1000)
          }}</span>
          VND
        </div>

        <div class="flexs_sb inputBG" @click="showLinkChoose = !showLinkChoose" style="
            margin: 0 auto;
            width: 60%;
            margin-top: 30px;
            height: 70px;
            border-radius: 8px;
            padding: 0 20px;
            position: relative;
          ">
          <input type="text" class="middleSize" v-model="linkBanKName" :placeholder="$t('选择银行卡')" style="width: 100%; font-size: 14px; height: 100%" disabled />

          <img :src="$img('rechargeDown.png')" alt="" style="width: 20px; position: absolute; right: 20px; top: 30px" />

          <div v-if="showLinkChoose" class="selectArea" style="top: 70px; left: 0px; width: 100%">
            <div @click.stop="linkItemClick(item)" v-for="(item, index) in linkBankList" class="selectItem bigSize" :key="item" style="font-size: 14px">
              {{ item.Name }}
            </div>
          </div>
        </div>
      </div>

      <div class="mainBG send canClick middleSize boldSize mainBtnBG" style="font-size: 14px" @click="reCharge()">
        {{ $t("提交") }}
      </div>
    </div>
    <div class="" v-if="selectIndex == 4" style="margin-top: 38px">
      <div class="userBG" style="height: 360px; border-radius: 8px">
        <div style="
            text-align: center;
            color: #f1af2f;
            margin-top: 87px;
            font-size: 16px;
          " class="middleSize boldSize">
          {{ $t("输入兑换码") }}
        </div>

        <div class="flexs_sb inputBG" style="
            margin: 0 auto;
            width: 60%;
            margin-top: 30px;
            height: 70px;
            border-radius: 8px;
            padding: 0 20px;
          ">
          <input type="text" class="middleSize" v-model="giftCode" :placeholder="$t('请输入兑换码')" style="width: 100%; font-size: 14px; height: 100%" />
        </div>
      </div>

      <!-- <div class="centerWrap" style="padding: 30px 0;padding-top: 10px;">
				<div style="text-align: center;color:white" class="middleSize boldSize">{{$t('输入兑换码')}}</div>
				<div class="flexs_sb blueWrap" style="margin-left: 80px;">
					<input type="text" v-model="giftCode" placeholder="" class="middleSize" style="width: 100%;">
				</div>


			</div> -->

      <div class="mainBG send canClick middleSize boldSize mainBtnBG" style="font-size: 14px" @click="codeGift()">
        {{ $t("提交") }}
      </div>
    </div>

    <div class="frame-common" v-if="selectIndex == 5" style="position: relative;">
      <div class="flexs_r" style="justify-content: center">
        <div v-for="(item, index) in topImgArr" class="canClick" @click="columClick(index)" :key="index" style="position: relative; margin-top: 28px" :style="index > 0 ? 'margin-left:10px' : ''">
          <img :src="$img(item)" style="width: 126px" />
          <div v-if="selectTopIndex != index" style="
              z-index: 9;
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.3);
            "></div>
        </div>
      </div>
      <div style='color:red;text-align:right;margin-top:10px;'>LƯU Ý: Nếu chọn sai mệnh giá thì HDBET88 sẽ không chịu trách nhiệm !</div>

      <div class="flexs_r" style="margin-top: 37px;justify-content: center;">
        <div>
          <div class="flexs_r_c" style="width: 500px">
            <div style="
                width: 50%;
                height: 32px;
                text-align: center;
                line-height: 32px;
                color: #8491a5;
                font-size: 16px;
                background-color: #212121;
              ">
              {{ $t("金额") }}
            </div>
            <div style="
                width: 50%;
                height: 32px;
                text-align: center;
                line-height: 32px;
                color: #8491a5;
                font-size: 16px;
                background-color: #212121;
              ">
              {{ $t("兑换") }}
            </div>
          </div>
          <div class="flexs_r_c" v-for="(item, index) in bottomData[selectTopIndex]" style="width: 500px; border-bottom: 1px solid #363d4e">
            <div style="
                width: 50%;
                height: 32px;
                text-align: center;
                line-height: 32px;
                color: white;
                font-size: 14px;
                background-color: #272726;
              ">
              {{ $tools.formatNum(item.Amount) }}
            </div>
            <div style="
                width: 50%;
                height: 32px;
                text-align: center;
                line-height: 32px;
                color: white;
                font-size: 14px;
                background-color: #272726;
              ">
              {{
                $tools.formatNum(
                  item.Amount - (item.FeePerThousand * item.Amount) / 1000.0
                )
              }}
            </div>
          </div>
        </div>
        <div style="margin-left:20px;">
          <div class="colum canClick" style="" @click="showPicker = !showPicker">
            {{
              selectObj
                ? $tools.formatNum(selectObj.Amount)
                : $t("选择话费金额")
            }}

            <img :src="$img('rechargeDown.png')" style="width: 13px; position: absolute; right: 16px; top: 21.5px" />

            <div v-if="showPicker" class="selectArea" style="width: 522px; top: 47px; left: 0">
              <div @click.stop="onConfirm(item)" :style="selectObj == item ? 'background-color: #4a69ff' : ''" v-for="(item, index) in bottomData[selectTopIndex]" class="selectItem middleSize boldSize" :key="item">
                {{ $tools.formatNum(item.Amount) }}
              </div>
            </div>
          </div>

          <div class="colum">
            <input v-model="cardNum" type="text" placeholder="Vui lòng nhập mã thẻ cào" />
          </div>
          <div class="colum">
            <input v-model="phoneCode" type="text" :placeholder="$t('请输入卡密')" />
          </div>

          <div class="mainBG send canClick middleSize boldSize mainBtnBG" @click="sure()" style="margin: 30px auto;font-size: 14px">
            {{ $t("提交") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { req, qs } from "@/utils/request";
import mqant from "@/components/protocol/mqantlib";
import bus from "@/utils/bus.js";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { Loading } from "element-plus";

import { topic } from "@/components/protocol/api";

export default {
  data() {
    return {
      selectIndex: 0,
      money: "",
      momoMoney: "",
      scanMoney: "",
      linkMoney: "",
      name: "",
      bankName: "",
      showChosee: false,
      showLinkChoose: false,
      bankList: [],
      remark: "",
      giftCode: "",
      bankSelectIndex: -1,
      scanBankList: [],
      scanBanKName: "",
      linkBankList: [],
      linkBanKName: "",
      showChoseeMomo: false,
      itemData: [
        {
          title: "100.00",
          num: 100,
        },
        {
          title: "500.00",
          num: 500,
        },
        {
          title: "1,000.00",
          num: 1000,
        },
        {
          title: "2,000.00",
          num: 2000,
        },
        {
          title: "5,000.00",
          num: 5000,
        },
        {
          title: "10,000.00",
          num: 10000,
        },
      ],

      showThirdChoose: false,
      thirdName: "",
      momoChoose: "",
      code: "",
      cardObj: "",
      momoObj: "",
      scanObj: "",
      linkObj: "",
      giftObj: "",
      topImgArr: ["yys_1.png", "yys_2.png", "yys_3.png"],
      selectTopIndex: 0,
      selectObj: null,
      bottomData: [[], [], []],
      showPicker: false,
      cardNum: "",
      phoneCode: "",
      phoneObj: null
    };
  },
  props: {
    // diaLoginVisible:{
    // 	type:Boolean,
    // 	default:true
    // }
  },
  computed: {
    ...mapGetters("tcp", ["isLogged", "isConnect"]),
    ...mapGetters("user", ["userInfo"]),

    // ...mapGetters("user", ["token","tcpInfo"]),
  },

  created() {
    var that = this;
    bus.off("giftCodeResult");
    bus.on("giftCodeResult", (val) => {
      if (val.data.Code == 0) {
        that.giftCode = "";
        that.$message({
          message: that.$t("兑换成功") + "!",
          type: "success",
        });
        that.$router.push({
          name: "getActivityRecord",
        });
      } else {
        that.$message({
          message: val.data.ErrMsg,
          type: "error",
        });
      }
    });
    var that = this;
    bus.on("onConnected", () => {
      that.getPayInfo();
    });
    if (this.isConnect) {
      this.getPayInfo();
    }
  },
  methods: {
    sure() {
      if (!this.selectObj) {
        this.$message({
          type: "info",
          message: "Chọn mệnh giá",
        });
        return;
      }

      if (!this.cardNum.length) {
        this.$message({
          type: "info",
          message: "vui lòng nhập số tài khoản ngân hàng",
        });
        return;
      }

      if (!this.phoneCode.length) {
        this.$message({
          type: "info",
          message: "Vui lòng nhập số seri",
        });
        return;
      }

      var obj = {
        amount: this.selectObj.Amount,
        methodId: this.phoneObj.MethodId,
        serial: this.phoneCode,
        code: this.cardNum,
        telco: this.selectObj.Name,
      };
      //console.log('11',obj)
      var that = this
      mqant.request(topic.payCharge, obj, function (res) {
        //console.log('22',res)
        if (res.Code == 0) {
          that.$message({
            type: "success",
            message: "Gửi lệnh thành công!",
          });

          that.phoneCode = "";
          that.cardNum = "";
        } else {
          that.$message({
            type: "error",
            message: res.ErrMsg,
          });
        }
      });
    },
    onConfirm(e) {
      this.selectObj = e;
      this.showPicker = false;
    },
    columClick(index) {
      this.selectTopIndex = index;
      this.selectObj = null;
    },
    momoClick(index) {
      this.momoChoose = this.itemData[index].num;
      this.momoMoney = this.itemData[index].num;
    },
    codeGift() {
      if (!this.giftCode.length) {
        // this.$message({
        // 	message: this.$t('请输入兑换码！'),
        // 	type: 'info'
        // });
        return;
      }
      var that = this;
      mqant.request(
        topic.giftCode,
        {
          code: this.giftCode,
        },
        function (data, topicName, msg) { }
      );
    },
    reCharge() {
      var methodId;
      var obj;
      var money;
      var bankName = this.bankName;

      if (this.selectIndex == 0) {
        obj = this.cardObj;
        money = this.money * 1000;
      } else if (this.selectIndex == 1) {
        obj = this.momoObj;
        money = this.momoMoney * 1000;
      } else if (this.selectIndex == 2) {
        obj = this.scanObj;
        money = this.scanMoney * 1000;
        bankName = this.scanBankName;
      } else if (this.selectIndex == 3) {
        obj = this.linkObj;
        money = this.linkMoney * 1000;
        bankName = this.linkBanKName;
      }
      methodId = obj.MethodId;

      if (money < obj.Mini || money > obj.Max) {
        this.$message({
          message: "Nhập số tiền từ " + obj.Mini + "-" + obj.Max,
          type: "warning",
        });
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: "加载中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });

      var obj = {
        amount: money,
        methodId: methodId,
        bankName: bankName,
        saveType: "",
        accountName: this.name,
        receiveId: this.bankList[this.bankSelectIndex].Oid,
        //accountName:this.bankList[this.bankSelectIndex].AccountName
      };
      console.log("asdads", obj);
      var that = this;
      mqant.request(topic.recharge, obj, function (data, topicName, msg) {
        loading.close();
        if (data.Code == 0) {
          if (that.selectIndex == 0) {
            that.money = "";
          } else if (that.selectIndex == 1) {
            that.momoMoney = "";
          } else if (that.selectIndex == 2) {
            that.scanMoney = "";
          } else if (that.selectIndex == 3) {
            that.linkMoney = "";
          }
          if (
            that.selectIndex == 1 ||
            that.selectIndex == 2 ||
            that.selectIndex == 3
          ) {
            var param = "";
            for (var key in data.Data.ReqParam) {
              param += key + "=";
              param += data.Data.ReqParam[key];
              param += "&";
            }
            param = param.substr(0, param.length - 1);
            var url = "https://hdbet88.com/static/918PAY.html?" + param;
            window.open(url);
          }
          that.$router.push({
            name: "billRecord",
          });
          that.$message({
            message: that.$t("提交成功") + "!",
            type: "success",
          });
        } else {
          that.$message({
            message: data.ErrMsg,
            type: "error",
          });
        }
      });
    },
    getPayInfo() {
      var that = this;
      mqant.request(topic.payInfo, {}, function (data, topicName, msg) {
        console.log("vvv", data);

        that.bankList = data.Data.receiveBankList;
        that.bankSelectIndex = 0;
        that.bankName = that.bankList[0].BankName;
        that.remark = data.Data.code;

        var arr = data.Data.phoneChargeConf;

        for (var obj of arr) {
          obj.text = obj.Amount;

          if (obj.Name == "VIETTEL") {
            that.bottomData[1].push(obj);
          } else if (obj.Name == "VINAPHONE") {
            that.bottomData[0].push(obj);
          } else {
            that.bottomData[2].push(obj);
          }
        }
        console.log("ggg", that.bottomData);

        for (var i = 0; i < data.Data.vgPayBankList.length; i++) {
          var obj = data.Data.vgPayBankList[i];
          if (obj._id == "bankQr") {
            that.scanBankList = obj.bankList;
            that.scanBankName = that.scanBankList[0].Name;
          } else if (obj._id == "direct") {
            that.linkBankList = obj.bankList;
            that.linkBanKName = that.linkBankList[0].Name;
          }
        }

        for (var i = 0; i < data.Data.payList.length; i++) {
          var obj = data.Data.payList[i];
          if (obj.MethodType == "bank") {
            that.cardObj = obj;
          } else if (obj.MethodType == "MomoPay") {
            that.momoObj = obj;
          } else if (obj.MethodType == "bankQr") {
            that.scanObj = obj;
          } else if (obj.MethodType == "direct") {
            that.linkObj = obj;
          } else if (obj.MethodType == "giftCode") {
            that.giftObj = obj;
          } else if (obj.MethodType == "naptudong") {
            that.phoneObj = obj;
          }
        }
      });
    },
    itemClick(e) {
      this.showChosee = false;

      this.bankName = this.bankList[e].BankName;
      this.bankSelectIndex = e;
    },
    thirdItemClick(e) {
      this.showThirdChoose = false;
      this.scanBankName = e.Name;
    },
    linkItemClick(e) {
      this.showLinkChoose = false;
      this.linkBanKName = e.Name;
    },
    copyUrl(text) {
      if (!text) {
        return;
      }
      // 模拟 输入框
      let cInput = document.createElement("input");
      cInput.value = text;
      document.body.appendChild(cInput);
      // 选取文本框内容
      cInput.select();

      // 执行浏览器复制命令
      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
      // Input要在正常的编辑状态下原生复制方法才会生效
      document.execCommand("copy");

      this.$message({
        type: "success",
        message: this.$t("复制成功"),
      });
      // 复制成功后再将构造的标签 移除
      document.body.removeChild(cInput);
    },
    checkNum(data) {
      if (data) {
        return (data = Number(data.replace(/[^\d.%\\]/g, "")));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 80px;
  height: 80px;
  text-align: center;
  margin-left: 20px;
  background: transparent;
}

.borderImg {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 5px;
}

.centerWrap {
  width: 442px;
  // height: 18.43vw;
  background-color: #282828;
  margin-top: 14px;
  padding-bottom: 5px;
  padding-top: 10px;
  margin-left: 40px;
}

.blueWrap {
  // border: 1px solid #31c9ed;
  width: 277px;
  height: 36px;
  border-radius: 5px;
  margin: 10px auto;
  margin-left: 80px;
  margin-bottom: 0;
  background-color: #404040;
  color: white;
}

input {
  background-color: transparent;
  color: white;
}

.redWrap {
  background-color: white;
  width: 194px;
  height: 36px;
  line-height: 36px;
  border-radius: 5px;
  margin: 1vw auto;
  margin-bottom: 0;
  text-align: center;
  position: absolute;
  left: 130px;
}

.copy {
  width: 60px;
  height: 24px;
  text-align: center;
  border-radius: 5px;
  color: white;
  line-height: 24px;
  margin-top: 20px;
  margin-right: 40px;
  background-color: #f2d161;
}

.selectArea {
  white-space: nowrap;
  overflow-y: auto;
  width: calc(100% - 40px);
  height: 300px;
  background-color: #e0dcd7;
  position: absolute;
  top: 188px;

  z-index: 9999;

  display: flex;
  flex-direction: column;
  // background-color: #1e1e1e;

  .selectItem {
    text-align: center;
    height: 60px;
    line-height: 60px;
    cursor: pointer;
    color: black;
  }

  .selectItem:hover {
    color: white;
    background-color: #ae9978;
  }
}

.bottomWrap {
  // width: 34.3vw;

  margin-top: 10px;
  margin-left: 45px;
  border-radius: 10px;
}

.send {
  width: 150px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border-radius: 8px;

  margin: 0 auto;
  margin-top: 30px;
  background-color: #0071df;
  font-size: 22px;
}

.items {
  position: relative;
  z-index: 99;
  text-align: center;
  margin-top: 10px;
  width: 100%;
  height: 100%;
  line-height: 28px;
  background-color: #f2d161;
}
.nolBG {
  background-color: rgb(77, 74, 70);
}
.chooseBG {
  background-color: rgb(219, 215, 209);
}
.colum {
  width: 500px;
  height: 47px;
  line-height: 47px;
  padding-left: 22px;
  background-color: #444342;
  color: white;
  position: relative;
  border-radius: 8px;
  input {
    width: 90%;
    height: 100%;
  }
}
.colum:nth-child(n + 2) {
  margin-top: 48px;
}
</style>
