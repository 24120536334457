<template>
  <div class="form">
<!-- 	  <img :src="$img('recharg_bg.png')" alt="" style="width: 100%;position: absolute;z-index: 0;top: 0;left: 0;">
 -->	  
    <div class="form_panel" style="position: relative;z-index: 3;padding-top: 4vw;padding-bottom: 10vw;">
      <div class="row phoneMiddle boldSize" style="color: white;">Vui lòng nhập mã Code !</div>
      <div class="row" style="border-bottom: 1px solid rgb(74,74,73);width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;">
        <input
          class="input blue_border phoneMiddle"
          type="text"
		  :placeholder="$t('输入兑换码')"
          v-model="code"
        />
      </div>
    </div>
   <div class="loginBtn">
   	<img :src="$img('ann_bottom.png')" alt="" style="width: 100%;">
   	<div @click="onSubmit" style="text-align: center;position: absolute;z-index: 9;width: 100%;top: 3.5vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
   		{{$t('提交')}}
   	</div>
   	
   </div>
  </div>
  <my-dialog
    v-if="showDialog"
    :close="
      function () {
        showDialog = false;
      }
    "
    :title="dialogTitle"
    :msg="dialogMsg"
  />
</template>

<script>
	
import giftPay from "@/views/mobile/giftPay";

export default {
  mixins: [giftPay],
};
</script>

<style lang="scss" scoped>
	.loginBtn{
		position: relative;
		margin-top: 24px;
		
	}
.input {
 border-radius: 11px;
 color: white;
 padding: 2vw 0vw;
}
.blue_border {
  flex: 1;
}
.row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  position: relative;
  > .label {
    width: 25vw;
    font-size: 12px;
    text-align: right;
    padding-right: 4px;
  }
}
.form {
  padding: 2vw 6vw;
  position: relative;
  > .form_panel {
   margin-top: 2vh;
   // padding: 7vw 11vw;
			background-color: rgb(42,42,42);
   padding-bottom: 3vw;
   border-radius: 5px;
  }
}
.submit {
  > button {
    background-color: rgb(213, 188, 145);
    color: white;
    border: 0;
    border-radius: 12px;
    padding: 2vw 6vw;
    margin-bottom: 10vw;
  }
}
input{
	color: white;
}
</style>