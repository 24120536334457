<template>
	<div @touchmove.prevent @mousewheel.prevent @click="cancel()" class="wrap" style="width: 100%;height: 100%;font-weight: bold;position: fixed;z-index: 99999;background-color: rgba(0, 0, 0, .4);top: 0;left: 0;">
		<div style="position: relative;width: 450px;margin: 300px auto;background-color: #282828;" @click.stop="">
<!-- 			<img :src="$img('confirm.webp')" alt="" style="width:450px;position: absolute;left: 0;">
 -->			<div class="title bigSize boldSize" style="color: white;">{{title}}</div>
			<div style="padding: 25px 60px;position: relative;z-index: 99;background-color: rgb(40, 40, 40);">
				<div class="cancel canClick middleSize boldSize" @click="sure()">{{$t(sureStr)}}</div>
				<!-- <div class="sure canClick middleSize boldSize" @click="sure()">{{$t(sureStr)}}</div> -->
			</div>
		</div>
	</div>
	
</template>

<script>
	export default {
		data(){
			return {
			
			}
		},
		props:{
			
			title:{
				type:String,
				default:'1111'
			},
			cancelStr:{
				type:String,
				default:'确认'
			},
			sureStr:{
				type:String,
				default:'确认'
			},
		},
		created() {
	
		},
		methods:{
			cancel(){
				this.$emit('cancel')
			},
			sure(){
				this.$emit('sure')
				
			}
			
			
		}
	}
</script>

<style scoped lang="scss">
	.title{
		
		
		color:#999999;
		position: relative;
		z-index: 88;
		text-align: center;
		//margin-top: 30px;
		padding-top: 20px;
		// top: 30px;
	}
	.cancel{
		width: 144px;
		height: 40px;
		color: white;
		margin: 0 auto;
		line-height: 40px;
		text-align: center;
		border-radius: 20px;
		background-color: #d3b35c;
	}
	.sure{
		width: 144px;
		height: 40px;
		color: white;
		line-height: 40px;
		text-align: center;
		border-radius: 20px;
		background-color: #3a8c38;
	}
</style>