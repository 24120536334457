	import bus from "@/utils/bus.js"

export default {
		data() {
			return {
				selfIndex:0,
			}
		},
		props: {
			
			messageList:{
				type:Array,
				default:[]
			},
			
			selectIndex:{
				type:Number,
				default:0
			}
		},
		mounted() {
			//this.messageList = [{title:'这是一个公告',time:'2022-06-18'}];
		},
		created() {
			this.selfIndex = this.selectIndex
		},
		watch: {
			dataList(val) {
				var _this = this;
				setTimeout(() => {

				}, 500);
			}
		},
		methods: {
		handleClose(){
			this.$emit('handleClose')
		},
		leftClick(index){
			bus.emit('toMessage',{
				index:index
			})
		},
		countDate(val) {
			if(!val){
				return ''
			}
			return val.split('T')[0] +' ' + val.split('T')[1].substr(0,8)
		},


		}
	}