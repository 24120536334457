<template>
	<div class="flexs_colum" style="position: relative;padding: 0 40px;">

		<div class="centerWrap userBG" >
			<div style="margin: 25px auto;width: 60%;text-align: left;line-height: 22px;margin-left: 18%;font-size:12px;">
				<div class="redWord ">{{$t('取款规则：')}}</div>
				
				<div class="redWord ">*Số tiền rút tối thiểu:{{$tools.formatNum2k(payInfo.doudouMin)}}</div>
				<div class="redWord ">*Số tiền rút tối đa:{{$tools.formatNum2k(payInfo.doudouMax)}}</div>
				<div class="redWord ">*Số lần rút tối đa trong ngày:{{payInfo.doudouMaxCnt}}&nbsp;lần</div>
			</div>

			<div class="flexs_sb inputBG" style="margin: 0 auto;width: 60%;margin-top: 20px;height: 70px;border-radius: 8px;padding: 0 20px;">
				<input type="text" class="middleSize" v-model="money" @input="money=checkNum(money)"
					:placeholder="$tools.formatNum2k(payInfo.doudouMin)+'-'+$tools.formatNum2k(payInfo.doudouMax)" style="font-size: 14px;color: white;">
				<div style="font-size: 14px;color: #fdfeff;">VND(K)</div>
			</div>
			
			
			<div style="margin-top: 10px;margin-left: 18.5%;font-size: 12px;color: #808691;" class="">= <span style="color:#f2d161;">{{$tools.formatNum(money*1000)}}</span>  VND
			</div>
			
			<div class="flexs_sb" style="margin-top: 20px;margin-left: 18.5%;">
				<div class="redWord boldSize" style="font-size: 12px;color:#7a8cc4;">{{$t('余额：')}}
					<span style="color: #dfb971;">{{$tools.formatNum2k(wallet.VndBalance)}}</span>
				</div>
				<span @click="money=wallet.VndBalance/1000" class="redWord boldSize canClick"
					style="cursor: pointer;color:#dfb971;font-size: 12px;margin-right: 21.5%;">{{$t('全部提现')}}</span>
			</div>

		</div>
		<div class="flexs_r" style="margin-top: 0px;">
			<div class="flexs_r">
				<div style="display: flex;flex-direction: column;">
					<div class="flexs_sb" style="margin-top: 25px;">
						<div style="position: relative;width: 30%;">
							<img :src="$img('bank_img.png')" alt="" style="width: 100%;">
							<div class="bankBG canClick" style="">
								<div @click="chooseCard(0)" v-if="userData.myDouDouBT.length>0"
									style="display: flex;flex-direction: column;margin-left: 15px;">
									<span style="margin-top: 12px;font-size:18px;" class="">{{userData.myDouDouBT[0].BtName}}</span>
									<span style="margin-top: 40px;font-size:14px;" class="">{{userData.myDouDouBT[0].AccountName}}</span>
									<span style="margin-top: 5px;font-size:16px;" class="smallSize">{{userData.myDouDouBT[0].CardNum}}</span>

								</div>
								<div v-else @click="showAddBank()"
									style="display: flex;flex-direction: column;text-align: center;align-items: center;">
									<img :src="$img('bankAdd.png')" alt=""
										style="margin: 0 auto;width: 20px;margin-top: 45px;">
									<span style="margin: 0 auto;margin-top: 5px;" class="middleSize boldSize">{{$t('添加银行卡')}}</span>
								
								</div>
							</div>
							<img :src="$img('card_se.png')" v-if="selectIndex==0" alt=""
								style="z-index: 99;width: 25px;position: absolute;right: 0;bottom: 5px;">


						</div>
						<div style="position: relative;width: 30%;">
							<img :src="$img('bank_img.png')" alt="" style="width: 100%;">
							<div class="bankBG canClick">
								<div @click="chooseCard(1)" v-if="userData.myDouDouBT.length>1"
									style="display: flex;flex-direction: column;margin-left: 15px;">
									<span style="margin-top: 12px;font-size:18px;" class="">{{userData.myDouDouBT[1].BtName}}</span>
									<span style="margin-top: 40px;font-size:14px;" class="">{{userData.myDouDouBT[1].AccountName}}</span>
									<span style="margin-top: 5px;font-size:16px;" class="smallSize">{{userData.myDouDouBT[1].CardNum}}</span>
									
								</div>
								<div v-else @click="showAddBank()"
									style="display: flex;flex-direction: column;text-align: center;align-items: center;">
									<img :src="$img('bankAdd.png')" alt=""
										style="margin: 0 auto;width: 20px;margin-top: 45px;">
									<span style="margin: 0 auto;margin-top: 5px;" class="middleSize boldSize">{{$t('添加银行卡')}}</span>
								
								</div>
							</div>
							<img :src="$img('card_se.png')" v-if="selectIndex==1" alt=""
								style="z-index: 99;width: 25px;position: absolute;right: 0;bottom: 5px;">

						</div>
						<div style="position: relative;width: 30%;">
							<img :src="$img('bank_img.png')" alt="" style="width: 100%;">
							<div class="bankBG canClick">
								<div @click="chooseCard(2)" v-if="userData.myDouDouBT.length>2"
									style="display: flex;flex-direction: column;margin-left: 15px;">
								<span style="margin-top: 12px;font-size:18px;" class="">{{userData.myDouDouBT[2].BtName}}</span>
								<span style="margin-top: 40px;font-size:14px;" class="">{{userData.myDouDouBT[2].AccountName}}</span>
									<span style="margin-top: 5px;font-size:16px;" class="smallSize">{{userData.myDouDouBT[2].CardNum}}</span>
								
								</div>
								<div v-else @click="showAddBank()"
									style="display: flex;flex-direction: column;text-align: center;align-items: center;">
									<img :src="$img('bankAdd.png')" alt=""
										style="margin: 0 auto;width: 20px;margin-top: 45px;">
									<span style="margin: 0 auto;margin-top: 5px;" class="middleSize boldSize">{{$t('添加银行卡')}}</span>
								
								</div>
							</div>
							<img :src="$img('card_se.png')" v-if="selectIndex==2" alt=""
								style="z-index: 99;width: 25px;position: absolute;right: 0;bottom: 5px;">

						</div>
					</div>
				</div>


			</div>
		</div>
		<div class="mainBG send canClick middleSize mainBtnBG" style="font-size: 14px;"  @click="withDraw()">
			{{$t('提交')}}
		</div>
		<setWithdrawPw v-if="showSetWithdrawPw" @handleClose='showSetWithdrawPw=false'></setWithdrawPw>
		<withdrawPw v-if="showWithdrawPw" @handleClose='showWithdrawPw=false' @confirm='comfirmPw'></withdrawPw>
		


	</div>

</template>

<script>
	import {
			mapGetters,
			mapActions,
			mapMutations
		} from "vuex";
	
		import {
			req,
			qs
		} from "@/utils/request";
		import mqant from "@/components/protocol/mqantlib";
		import bus from "@/utils/bus.js"
		let setWithdrawPw = require('@/views/'+ 'pc5' +'/components/setWithdrawPw.vue').default
		let withdrawPw = require('@/views/'+ 'pc5' +'/components/withdrawPw.vue').default
		
		import {
			topic
		} from "@/components/protocol/api";
		export default {
			data() {
				return {
					showSetWithdrawPw:false,
					showWithdrawPw:false,
					
					money: '',
					name: '',
					bankName: '',
					showChosee: false,
					bankList: ['中国银行', '建设银行', '建设银行', '建设银行', '建设银行', '建设银行'],
					itemData: ['100', '100', '100', '100', '100', '100'],
					showThirdChoose: false,
					thirdName: '',
					code: '',
					selectIndex: 0,
					payInfo: '',
					userData: {
						user: {
	
						},
						myDouDouBT: []
					}
				}
			},
			props: {
	
				// diaLoginVisible:{
				// 	type:Boolean,
				// 	default:true
				// }
			},
			components:{
				setWithdrawPw,
				withdrawPw
			},
			computed: {
				...mapGetters("tcp", ["wallet", "user","isLogged","isConnect"]),
	
	
			},
			created() {
				
				var that = this
				bus.on('onConnected', () => {
						this.getPayInfo()
						this.getUserInfo()
					
				})
				if(this.isConnect){
					this.getPayInfo()
					this.getUserInfo()
					
				}
				
				bus.on('setWithdrawPw', () => {
						that.getUserInfo()
					
				})
				bus.off('bindBank')
				
				bus.on('bindBank', (val) => {
					this.getUserInfo()
					
				})		
				
				
			},
			methods: {
				withDraw() {
					
					
					if(this.money*1000<this.payInfo.doudouMin){
						this.$message({
						          message:'Số tiền rút tối thiểu '+this.$tools.formatNum2k(this.payInfo.doudouMin),
						          type: 'warning'
						        });
								return
					}
					if(this.money*1000>this.payInfo.doudouMax){
						this.$message({
						          message: 'Số tiền rút tối đa '+this.$tools.formatNum2k(this.payInfo.doudouMax),
						          type: 'warning'
						        });
								return
					}
					if(this.userData.DoudouPwd.length==0){
						this.$message({
						          message: this.$t('设置取款密码'),
						          type: 'warning'
						        });
						this.showSetWithdrawPw = true		
								
						return
					}
					this.showWithdrawPw = true
					//this.submit()
					
				},
				comfirmPw(e){
					
					if(e==this.userData.DoudouPwd){
						this.showWithdrawPw = false
						this.submit()
					}else {
						this.$message({
						          message: this.$t('取款密码输入错误'),
						          type: 'error'
						        });
					}
				},
				submit(){
					var obj = {
						amount: this.money*1000,
						btOid: this.userData.myDouDouBT[this.selectIndex].Oid
					}
					var that = this
					const loading = this.$loading({
					          lock: true,
					          text: '加载中',
					          spinner: 'el-icon-loading',
					          background: 'rgba(0, 0, 0, 0.7)'
					        });
					mqant.request(topic.withDraw, obj, function(data, topicName, msg) {
						loading.close();
						console.log('fff',data)
						if(data.Code==0){
							
								that.$router.push({
																name: 'billRecord'
															});
							that.$message({
							          message: that.$t('提现成功')+'！',
							          type: 'success'
							        });
						}else {
							that.$message({
							          message: data.ErrMsg,
							          type: 'error'
							        });
						}
					});
				},
				chooseCard(index) {
					this.selectIndex = index
				},
				getUserInfo() {
					var that = this
					mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
						that.userData = data.Data
					});
				},
				getPayInfo() {
					var that = this
					mqant.request(topic.payInfo, {}, function(data, topicName, msg) {
	
						that.payInfo = data.Data
	
					});
				},
				itemClick(e) {
					this.showChosee = false
					this.bankName = e
				},
				thirdItemClick(e) {
					this.showThirdChoose = false
					this.thirdName = e
				},
				showAddBank() {
					bus.emit('showAddBankVisible')
					//this.$emit('showAddBank')
				},
				checkNum(data) {
	
					if (data) {
	
						return (data = Number(data.replace(/[^\d.%\\]/g, '')))
	
					}
	
				}
	
	
			}
		}
</script>

<style lang="scss" scoped>
	.wrap {
		position: relative;
		width: 8.18vw;
		height: 8.18vw;
		text-align: center;
		margin-left: 2vw;
		background: transparent;
	}

	.borderImg {
		width: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: .5vw;
	}

	.centerWrap {
		height: 380px;
		margin-top: 40px;
		border-radius: 8px;
	}
	input{
		background-color: transparent;
	}
	.blueWrap {
		width: 302px;
		height: 40px;
		background-color:#404040;color:white;
		border-radius: 5px;
		margin: 10px auto;
		margin-bottom: 0;
	}

	.redWrap {
		border: 1px solid rgb(222, 133, 143);
		width: 16.7vw;
		height: 3.08vw;
		border-radius: 1vw;
		margin: 1vw auto;
		margin-bottom: 0;
		text-align: center;
		position: absolute;
		left: 9vw;
	}

	.redWord {
		color: #e82b2b;

	}

	.send {
		width: 150px;
		height: 45px;
		text-align: center;
		line-height: 45px;
		border-radius: 8px;
		
		margin: 0 auto;
		margin-top: 30px;
		background-color: #0071df;
		font-size: 22px;
	}

	.bankBG {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		color: white;

		span {
		}
	}
</style>
