<template>
  <home-nav active="foot" />
  <div class="pannel">

	<div style="background-color: black;position: relative;padding: 0 5vw;margin-top: 2vw;" v-for="item in list">
          <van-image   @click="toSports(item)" :src="$img(item.icon)" lazy-load :class="{ img_gray: isGrey(item) }"/>
		<maintena v-if="isGrey(item)" :text="maintenaText(item)" textWidth="60%" />
		
	</div>
    <!-- <van-grid :border="false" :column-num="1" :gutter="0" >
        <van-grid-item v-for="item in list" @click="onClick(item)" style="background-color: black;">
          <van-image :src="$img(item.icon)" lazy-load :class="{ img_gray: isGrey(item) }"/>
           <maintena v-if="isGrey(item)" :text="maintenaText(item)" textWidth="60%" />
        </van-grid-item>
      </van-grid> -->
	  <Confirm :title="$t('请选择版本')" :sureStr="$t('电脑版')"  @right="right()" @left="left()" @close="showConfirm=false" :cancelStr="$t('手机版')" v-if="showConfirm"></Confirm>
	  
  </div>
</template>

<script>
	import {theme} from '@/views/theme'
		import {
			status,
			getApiLoginUrl,
			checkFactoryUrl
		} from "@/components/protocol/api";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
		let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default
		let Maintena = require('@/views/'+ theme +'/components/Maintena.vue').default
		let Confirm = require('@/views/'+ theme +'/components/confirm.vue').default
		import action from '@/components/core/action'
		
	import home from "../../mobile/home"
	
	export default {
	  components: { HomeNav ,Maintena,Confirm},
	  name: "FootView",
	  mixins:[home],
	  computed: {
	  	...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),
	  
	  	
	  },
	  methods:{
		  left(){
			 this.showConfirm = false
			 if (this.isGrey(this.selectItem)) return;
			 if (!this.$act.checkLogged()) return;
			 
			 let name = this.staticPic[this.curType][this.selectItem.id].Factory.FactoryName;
			 
			 
			 let url = getApiLoginUrl(name, "","",0);
			 
			 action.openPage(url,'')
			  
		  },
		  right(){
			  this.showConfirm = false
			  if (this.isGrey(this.selectItem)) return;
			  if (!this.$act.checkLogged()) return;
			  
			  let name = this.staticPic[this.curType][this.selectItem.id].Factory.FactoryName;
			  
			  
			  let url = getApiLoginUrl(name, "","",1);
			  
			  action.openPage(url,'')
		  },
		  toSports(item){
			  this.selectItem = item;
			  this.showConfirm = true
			  // if (this.isGrey(item)) return;
			  // if (!this.$act.checkLogged()) return;
			  
			  // let name = this.staticPic[this.curType][item.id].Factory.FactoryName;
			  
			  
			  // let url = getApiLoginUrl(name, "");
			  
			  // action.openPage(url,'')
			  
		  },
			// async onClick(item) {
			// 	if (this.isGrey(item)) return;
			// 	if (!this.$act.checkLogged()) return;
			
			// 	let name = this.staticPic[this.curType][item.id].Factory.FactoryName;
			
			
			// 	let url = getApiLoginUrl(name, "");
				
			// 	action.openPage(url,'')
			
			
			// },
	  },
	  data() {
	    return {
			selectItem:'',
	      curType:"LiveSports",
		  showConfirm:false,
	      list: [
	        {
	          id: "apiCmd",
	          icon: "mLiveSports_apiCmd.png",
	        },
	        {
	          id: "apiSaBa",
	          icon: "mLiveSports_apiSaBa.png",
	        },
	        {
	          id: "apiSbo",
	          icon: "mLiveSports_apiSbo.png",
	        },
	        {
	          id: "apiUg",
	          icon: "mLiveSports_apiug.png",
	        },
	      ],
	    };
	  },
	  
	  
	};
</script>

<style lang="scss" scoped>
	
.pannel {
  margin-top: 1vh;
  width: 100%;
  text-align: center;
  background-color: black }
.item {
  margin-top: 1vh;
  position: relative;
   background-color: black;
  > .bg {
    width: 87vw;
  }
  > .icon {
    position: absolute;
    top: 6%;
    left: 11%;
    width: 22vw;
  }
  > .logo {
    position: absolute;
    top: 14%;
    right: 1%;
    width: 44vw;
  }
  > .maintena {
    position: absolute;
    top: 16%;
    width: 100%;
    text-align: center;
    > img {
      width: 11vw;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    > div {
      margin-left: auto;
      margin-right: auto;
      background-color: #ffd21e;
      border-radius: 7px;
      width: 60%;
      border: solid 2px #373737;
      padding: 7px 0;
      margin-top: 1%;
      font-weight: bold;
      white-space: pre-wrap;
    }
  }
}
</style>