<template>

	<div style="width: 100%;">

		<div class="flexs_sb homeTop" style="min-width: 1200px;">
			<div style="position: relative;width: 100%;height: 153px;background: linear-gradient(to bottom,#464241,#4b4a48,#454140);">
				<img :src="$img('logo.png')" class="canClick" @click="toHome()" alt=""
					style="width: 184px;position: absolute;left: 10vw;top: 18px;z-index: 999;">
<!-- 				<img :src="$img('xincun_yuansu.png')" alt="" style="position: absolute;left: 0;top: 0;z-index: 111;width: ;">
 -->				<div class="" style="position: absolute;left: 0;top: 0;z-index: 333;left: 35%;right: 10vw;">
					<div
						style="display: flex;flex-direction: row-reverse;width: 100%;font-size: 16px;color: #afa194;align-items: center;margin-top: 23px;position: relative;">
						<!-- <img v-if="$i18n.locale=='vi'&&!isProduct" class="canClick" @click="changeLanguage('cn')"
							src="@/assets/pc1/yuenan.png" alt="" style="width: 30px;">
						<img v-if="$i18n.locale=='cn'&&!isProduct" class="canClick" @click="changeLanguage('vi')"
							src="@/assets/pc1/zhongwen.jpg" alt="" style="width: 30px;"> -->
											
							
						
						<!-- 	<div  class="fishHot"
									style="text-align: center;width: 40px;position: absolute;right:330px;top: -16px;height: 16px;line-height: 16px;border-radius: 4px;">HOT</div>
													
 -->
						<div class="canClick beClick" @click="showNotice()" style="margin-right: 30px;">{{$t("公告").toUpperCase()}}</div>
						<div class="canClick changeColor" style="margin-right: 30px;font-size: 16px;" @click="toVIP">VIP</div>
						<div class="canClick changeColor" @click="toTurntable()" style="margin-right: 30px;font-size: 16px;">{{$t("转盘")}}</div>
						<div class="canClick  changeColor" style="margin-right: 30px;font-size: 16px;" @click="toActivity">{{'KHUYẾN MÃI'.toUpperCase()}}
						</div>


					</div>


					<div class="flexs_sb tab" style="width: 100%;margin-top: 26px;margin-left: -100px;">
						<div v-for="(item,index) in naviData" @click="toDetail()" @mouseenter='hoverEvent(index)'
							@mouseleave='closeDraw()' class="tabColum canClick"
							:class="index==selectIndex?'select':'nolmal'">
							{{item.title}}
						<!-- 	<img v-if="index==1" :src="$img('sportHot.png')" alt=""
								style="width: 33px;position: absolute;right: -11.5px;top: -20px;"> -->
							<div v-if="index==5" class="fishHot"
								style="width: 60px;position: absolute;right: -30px;top: -20px;height: 16px;line-height: 16px;border-radius: 4px;">{{$t('补贴')}}</div>
							<div v-if="index==1||index==2||index==3||index==4" class="fishHot"
										style="width: 40px;position: absolute;right: -30px;top: -20px;height: 16px;line-height: 16px;border-radius: 4px;">HOT</div>
								
						</div>
					</div>
				</div>

				<!-- <div style="width: 100%;height: 2px;background-color:#121b2b ;position: absolute;top: 109px;"></div> -->
				<div style="width: 100%;position: absolute;height: 44px;bottom: 0;z-index: 334;background-color:#302e2b;min-width: 1200px;" class="flexs_sb">
					<div class="flexs_r_c" style="position: relative;">
						<div style="color: #fdfeff;font-size: 14px;margin-left: 100px;">{{dateFormat(date)}}&nbsp;GMT+7</div>
						<Slider  style="position:absolute;left:300px;"/>
						
					</div>
					<div class="flexs_r_c" style="margin-right: 12vw;position: relative;z-index:99;" v-if="!isLogged">
						<input type="text" v-model="account" :placeholder="$t('请输入账号')">
						<div style="position: relative;" class="flexs_r_c">
							<input type="text" v-model="pw" v-if="canSee" :placeholder="$t('请输入密码')"
								style="margin-left:13px ;">
							<input type="password" v-model="pw" v-else :placeholder="$t('请输入密码')"
								style="margin-left:13px;">

							<img style="width: 22px;position: absolute;right: 5px;top: 8px;z-index: 88;"
								:src="$img('cannotSee.png')" v-if="canSee" @click="canSee=!canSee" alt=""
								class="canClick">
							<img style="width: 22px;position: absolute;right: 5px;top: 8px;z-index: 88;"
								:src="$img('canSee.png')" v-else alt="" @click="canSee=!canSee" class="canClick">

						</div>
						<div class="btn mainBtnBG login" @click="login">{{$t('登录')}}</div>
						<div class="btn regist mainBtnBG" @click="diaRigistVisible=true">{{$t('注册')}}</div>

					</div>
					<div class="flexs_r_c" style="margin-right: 12vw;color: white;" v-else>
						<img :src="$img('yh_icon.png')" alt="" style="width: 27px;">
						<div style="color: #fdfeff;font-size: 14px;margin-left: 5px;">{{userInfo.Account}}</div>

						<img :src="$img('qb_icon.png')" alt="" style="width: 26px;margin-left: 45px;">
						<div style="color: #f1af2f;font-size: 14px;margin-left: 5px;">
							{{$tools.formatNum2k(wallet.VndBalance)}}
						</div>


						<div style="font-size: 14px;margin-left: 10px;width: 90px;text-align: center;border-right: 1px solid white;"
							class="canClick beClick" @click="toUser()">{{$t('会员中心').toUpperCase()}}</div>



						<div style="font-size: 14px;width: 90px;text-align: center;border-right: 1px solid white;"
							class="canClick beClick" @click="toRecharge()">{{$t('存款')}}</div>
						<div style="font-size: 14px;width: 90px;text-align: center;"
							class="canClick beClick" @click="toWithdraw()">{{$t('取款').toUpperCase()}}</div>
						<img :src="$img('icon_tc.png')" alt="" style="width: 22px;" class="canClick"
							@click="loginOut2()">
					</div>

				</div>
			</div>

			<div class="draw" v-if="showDraw" @mouseenter='openDraw()' @mouseleave='closeDraw()'>
				<div class="drawWrap">
					<img   class="cannotClick diseableImg" :src="$img(naviData[hoverIndex].img)" v-if="hoverIndex==3&&(lobbyInfo.StaticPicture.CockFight.apiSea.Factory.Status==2||lobbyInfo.StaticPicture.CockFight.apiSea.Factory.Status==3)" alt="" style="width: 273px;margin: 0 auto;">
					<img @click="toDouji()"  class="canClick" :src="$img(naviData[hoverIndex].img)" v-else-if="hoverIndex==3&&lobbyInfo.StaticPicture.CockFight.apiSea.Factory.Status!=2&&lobbyInfo.StaticPicture.CockFight.apiSea.Factory.Status!=3" alt="" style="width: 273px;margin: 0 auto;">
					
					
					<img :src="$img(naviData[hoverIndex].img)" v-else alt="" style="width: 300px;">
					
					
					<div v-if="hoverIndex!=3" style="width: calc(100% - 300px);flex-wrap: wrap;display: flex;flex-direction: row;height: 0;"
						class="">
						<div v-if="hoverIndex>0" class="imgWrap  canClick" @click="play(item)"
							v-for="(item,index) in sortData(lobbyInfo.StaticPicture[naviData[hoverIndex].key])"
							:key="index" style="width: 16.66%;position: relative;">

							<img class="diseableImg cannotClick" v-if="item.Factory.Status==2||item.Factory.Status==3"
								:src="$img(item.Factory.FactoryName+'_T.png')" alt="" style="width: 100%;">




							<img v-else :src="$img(item.Factory.FactoryName+'_T.png')" alt="" style="width: 100%;">
							<div v-if="item.Factory.Status==2||item.Factory.Status==3"
								style="width: 100%;text-align: center;font-size: 16px;position: absolute;bottom: 16px;left: 0;color: #797979;">
								{{item.Factory.FactoryShowName.toUpperCase()}}</div>
							<div v-else
								style="width: 100%;text-align: center;font-size: 16px;position: absolute;bottom: 16px;left: 0;">
								{{item.Factory.FactoryShowName.toUpperCase()}}</div>

						</div>
					</div>





				</div>
			</div>

		</div>



	</div>

	<Rigist @showLogin='showLogin()' @handleClose='closeEvent()' v-if="diaRigistVisible"></Rigist>

	<Login @handleClose='closeLogin()' @showRegist='showRegist()' v-if="dialoginVisible"></Login>
	<addBank v-if="showAddBankVisible" @handleClose='showAddBankVisible=false'></addBank>
	<zhuanPan v-if="showZhuanPan" @handleClose='showZhuanPan=false'></zhuanPan>

	<requestDelegate v-if="showDelegate" @handleClose='showDelegate=false'></requestDelegate>

	
</template>

<script>
	let Slider = require('@/views/'+ 'pc5' +'/components/Slider.vue').default

	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import bus from "@/utils/bus.js"
	import {
		status,
		getApiLoginUrl,
		checkFactoryUrl
	} from "@/components/protocol/api";
	import {
		req,
		qs
	} from "@/utils/request";
	import tools from "@/utils/tools";

	import {
		http
	} from "@/components/protocol/api";
	import {
		theme
	} from '@/views/theme'

	import mqant from "@/components/protocol/mqantlib";
	
		
	import {
		topic
	} from "@/components/protocol/api";

	let Rigist = require('@/views/' + theme + '/components/Rigist.vue').default
	let Login = require('@/views/' + theme + '/components/Login.vue').default
		let addBank = require('@/views/'+ theme +'/components/addBank.vue').default
		let zhuanPan = require('@/views/'+ theme +'/components/zhuanPan.vue').default
		let requestDelegate = require('@/views/'+ theme +'/components/requestDelegate.vue').default

		
	export default {
		data() {
			return {
				selectIndex: 0,
				showAddBankVisible:false,
				showZhuanPan:false,
				topHeightStyle: {
					height: 0
				},
				account: '',
				diaRigistVisible: false,
				dialoginVisible: false,
				pw: '',
				timer: '',
				hoverIndex: -1,
				canSee: false,
				showDelegate:false,
				date: new Date(),
				currentKey: '',
				isProduct: process.env.NODE_ENV == "production",
				topIndex: -1,
				showDraw: false,
				naviData: [{
						title: this.$t('首页'),
						src: 'home',

					}, {
						title: this.$t('体育'),
						key: 'LiveSports',
						img: 'xila_tiyu_img.png'

					},
					{
						title: this.$t('真人视讯'),
						key: 'LiveCasino',
						img: 'xila_qpai_img.png'


					}, {
						title: this.$t('斗鸡'),
						key: 'CockFight',
						img: 'xialaCockFight.png'


					}, {
						title: this.$t('老虎机'),
						key: 'Slot',
						img: 'xila_lhji_img.png'


					}, {
						title: this.$t('捕鱼'),
						key: 'Fish',
						img: 'xila_buyu_img.png'


					}, {
						title: this.$t('棋牌'),
						key: 'Card',
						img: 'xila_sx_img.png'


					}, {
						title: this.$t('彩票'),
						key: 'Lottery',
						img: 'xila_lottery_img.png'

					}, {
						title: this.$t('申请代理').toUpperCase(),
						key: '',
						img: ''

					}
					// {
					// 	title: this.$t('会员'),
					// 	src: 'vip',

					// }, {
					// 	title: this.$t('优惠'),
					// 	key: '',

					// 	src: 'discount',
					// }
				]

			}
		},

		components: {
			Rigist,
			addBank,
			Login,
			zhuanPan,
			Slider,
			requestDelegate
		},


		watch: {

		},
		created() {
			


			//显示当前日期时间
			let _this = this // 声明一个变量指向Vue实例this，保证作用域一致
			this.timer = setInterval(() => {
				_this.date = new Date(); // 修改数据date
			}, 1000)

			var that = this;
			bus.off('showLogin')
			bus.on('showLogin', (val) => {
				that.showLogin()
			})
			
			
			bus.off('loginOut')
			bus.on('loginOut', (val) => {
				that.toHome()
			})
			//this.time =new Date();
			// <!--把window.onresize事件挂在到mounted函数上-->
			if (document.documentElement.clientWidth > 1200) {
				this.topHeightStyle.height = document.documentElement.clientWidth / 1920 * 105 + 'px'
			} else {
				this.topHeightStyle.height = 1200.0 / 1920 * 105 + 'px';

			}

			bus.on('sendDelegate',()=>{

			})

			bus.on('windowWidth', (val) => {
				if (val.windowWidth > 1200) {
					that.topHeightStyle.height = document.documentElement.clientWidth / 1920 * 105 + 'px'
				} else {
					that.topHeightStyle.height = 1200.0 / 1920 * 105 + 'px';

				}
			})

			if (this.$route.path == '/liveCasino') {
				this.selectIndex = 2
			} else if (this.$route.path == '/liveSport') {
				this.selectIndex = 1
			} else if (this.$route.path == '/chess') {
				this.selectIndex = 6

			} else if (this.$route.path == '/slot') {
				this.selectIndex = 4

			} else if (this.$route.path == '/fishing') {
				this.selectIndex = 5

			}
			 else if (this.$route.path == '/Douji') {
			 	this.selectIndex = 3
			 
			 }else if (this.$route.path == '/') {
				this.selectIndex = 0

			} else {
				this.selectIndex = -1

			}
		},
		computed: {

			...mapGetters("tcp", ["isLogged", "wallet"]),

			...mapGetters("global", ["globalState", "uuid", "lobbyInfo"]),
			...mapGetters("user", ["token", "userInfo"]),

		},
		beforeDestroy() {
			if (this.timer) {
				clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
			}
		},
		watch: {
			$route(to, from) {
				if (to.path == '/liveCasino') {
					this.selectIndex = 2
				} else if (to.path == '/liveSport') {
					this.selectIndex = 1
				} else if (to.path == '/chess') {
					this.selectIndex = 6

				} else if (to.path == '/slot') {
					this.selectIndex = 4

				} else if (to.path == '/fishing') {
					this.selectIndex = 5

				}else if (to.path == '/Douji') {
					this.selectIndex = 3

				} else if (to.path == '/') {
					this.selectIndex = 0

				} else {
					this.selectIndex = -1

				}

			}
		},
		methods: {
			...mapActions("user", ["doLogin", "onLogged"]),

			...mapMutations("user", ["loginOut"]),

			...mapActions("global", ["formatNumber"]),
			// ...mapMutations("user", ["loginOut"]),
			...mapActions("tcp", ["disConnect"]),
			showMessage() {
				bus.emit('toMessage')
				
			},
			
			toDouji(){
				let name = this.lobbyInfo.StaticPicture.CockFight.apiSea.Factory.FactoryName;
				let url = getApiLoginUrl(name, "");
				//console.log('ssszz',url)
				this.$act.openPage(url);
			},
			toTurntable(){
				
				if(!this.isLogged){
					this.showLogin()
					return
				}
				this.$router.push({
					name: 'turntable',
				
				})
					
			},
			showNotice() {
				if (this.isLogged) {
					bus.emit('loginSuccess', {
			
					})
				} else {
					bus.emit('showLogin', {})
				}
			
			},
			dateFormat(time) {
				var date = new Date(time);
				var year = date.getFullYear();
				/* 在日期格式中，月份是从0开始的，因此要加0
				 * 使用三元表达式在小于10的前面加0，以达到格式统一  如 09:11:05
				 * */
				var month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
				var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
				var hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
				var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
				var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
				// 拼接
				return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
			},
			toActivity() {

				// if(!this.isLogged){
				// 	this.showLogin()
				// 	return
				// }
				this.$router.push({
					name: 'activity',

				})
			},
			toVIP() {

				// if(!this.isLogged){
				// 	this.showLogin()
				// 	return
				// }
				this.$router.push({
					name: 'vip',

				})
			},
			openDraw() {
				this.showDraw = true

			},
			toUser() {
				// const id = window.open('http://localhost:8014/#/userCenter','_blank','height=600,width=1400');
				//     id.document.title=this.$t('会员中心');


				this.selectIndex = -1;

				this.$router.push({
					name: 'userInfo',



				})
			},
			toRecharge() {
				if (!this.isLogged) {
					this.toLogin()
					return
				}
				
				var that = this
				mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
					if (!data.Data.myDouDouBT.length) {
						that.showAddBankVisible = true
						that.$message({
							message: that.$t('请绑定银行卡'),
							type: 'info'
						});
					} else {
						that.selectIndex = -1;
						
						that.$router.push({
							name: 'recharge',
						
						
						
						})
				
					}
				});
				
				
			},
			toWithdraw() {
				if (!this.isLogged) {
					this.toLogin()
					return
				}
				
				var that = this
				mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
					if (!data.Data.myDouDouBT.length) {
						that.showAddBankVisible = true
						that.$message({
							message: that.$t('请绑定银行卡'),
							type: 'info'
						});
					} else {
						that.selectIndex = -1;
						
						that.$router.push({
							name: 'withdraw',
						
						
						
						})
				
					}
				});
				
				
				
			},
			async login() {
				var that = this
				if (!this.account.length || !this.pw.length) {
					that.$message({
						message: this.$t('账号密码不能为空'),
						type: 'error'
					});
					return
				}
				let params = {
					account: this.account,
					password: this.pw,
					platform: tools.platform(),
					uuid: this.uuid,
					uuid_web: this.uuid,
				};
				this.doLogin({
					params: params,
					url: http.userLogin,
				}).then(
					(resolve) => {

					},
					(reject) => {
						that.$message({
							message: reject.ErrMsg,
							type: 'error'
						});
					}
				);


			},
			toLogin() {
				this.showLogin()
				// this.$router.push({
				// 	name: 'login',



				// })
			},
			toHome() {
				this.selectIndex = 0

				this.$router.push({
					name: 'home'



				})
			},
			play(item) {

				var factoryList = this.lobbyInfo.StaticPicture[this.naviData[this.hoverIndex].key]
				if (item.Factory.Status == 3 || item.Factory.Status == 2) {
					return
				}
				if (this.hoverIndex == 6) {
					name = 'chess'
					this.selectIndex = 6
					if (this.$route.path == '/' + name) {
						bus.emit('freshData', {
							data: JSON.stringify(item),
							factoryList: JSON.stringify(factoryList)

						})

						return
					}
					this.$router.push({
						name: name,
						params: {

							data: JSON.stringify(item),
							factoryList: JSON.stringify(factoryList)
						}


					})
				} else if (this.hoverIndex == 4) {
					name = 'slot'
					this.selectIndex = 4
					if (this.$route.path == '/' + name) {
						bus.emit('freshData', {
							data: JSON.stringify(item),
							factoryList: JSON.stringify(factoryList)

						})
						return
					}
					this.$router.push({
						name: name,
						params: {
							data: JSON.stringify(item),
							factoryList: JSON.stringify(factoryList)
						},
					})
				} else if (this.hoverIndex == 5) {
					name = 'fishing'
					this.selectIndex = 5
					if (this.$route.path == '/' + name) {
						bus.emit('freshData', {
							data: JSON.stringify(item),
							factoryList: JSON.stringify(factoryList)

						})
						return
					}
					this.$router.push({
						name: name,
						params: {
							data: JSON.stringify(item),
							factoryList: JSON.stringify(factoryList)
						},
					})
				} else {
					if (!this.isLogged) {
						this.toLogin()
						return
					}
					let name = item.Factory.FactoryName;
					let url = getApiLoginUrl(name, "");
					//console.log('ssszz',url)
					this.$act.openPage(url);
				}

			},
			toRegist() {
				this.$router.push({
					name: 'regist',

				})
			},
			async toDetail(val) {
				if (this.hoverIndex == 0) {
					this.toHome()
					return
				}
				if (this.hoverIndex == 8) {
					if (!this.isLogged) {
						this.dialoginVisible = true

						return
					}

					var that = this
				mqant.request(topic.queryAgentRegister, {}, function(data, topicName, msg) {
					console.log('ss',data)
					if(data.Data.status==-1){

						that.showDelegate = true

					}else if(data.Data.status==4){
						//	提示
						that.$message({
							message: that.$t('申请失败')+' '+that.$t('原因')+':'+data.Data.remark,
							type: 'info'
						});
					}else if(data.Data.status==9){
						//已是代理
						that.$message({
							message: that.$t('您已是代理'),
							type: 'info'
						});
					}else {
						//审核中
						that.$message({
							message: that.$t('客服审核中'),
							type: 'info'
						});
					}
				});

					
					return
				}

				if(this.hoverIndex == 7){
					this.$router.push({
						name: 'lottery'
						
					
					
					})
					return
				}
				var name;

				if (this.hoverIndex == 1 || this.hoverIndex == 2) {
					var name;
					if (this.hoverIndex == 1) {
						this.selectIndex = 1
						name = 'liveSport'
					} else {
						name = 'liveCasino'
						this.selectIndex = 2

					}



					this.$router.push({
						name: name,
						params: {

						}


					})

					return
				}
				if (this.hoverIndex == 6) {
					name = 'chess'
					this.selectIndex = 6
				} else if (this.hoverIndex == 4) {
					name = 'slot'
					this.selectIndex = 4

				} else if (this.hoverIndex == 5) {
					name = 'fishing'
					this.selectIndex = 5

				}else if (this.hoverIndex == 3) {
					name = 'Douji'
					this.selectIndex = 3

				} else {
					if (!this.isLogged) {
						this.dialoginVisible = true

						return
					}
					let name = val.Factory.FactoryName;
					let url = getApiLoginUrl(name, "");
					this.$act.openPage(url);

					return
				}
				if (name) {
					var factoryList = this.lobbyInfo.StaticPicture[this.naviData[this.hoverIndex].key]


					if (this.$route.path == '/' + name) {
						bus.emit('freshData', {
							isPC3: 1

						})
						return
					}
					this.$router.push({
						name: name,
						params: {
							//data: JSON.stringify(val),
							factoryList: JSON.stringify(factoryList),

						}


					})
				}
			},
			closeDraw() {
				this.showDraw = false
			},
			loginOut2() {
				this.account = ''
				this.pw = ''
				// this.$router.push({
				// 	name: 'login',

				// })
				bus.emit('tcdl')
				this.loginOut()
				this.disConnect()
				this.toHome()

			},
			showLogin() {
				//this.diaRigistVisible = false

				// this.tcpConnect().then((resolve) => {
				//   this.tcpLogin();
				// });

				this.diaRigistVisible = false
				this.dialoginVisible = true
			},
			showRegist() {
				this.diaRigistVisible = true
				this.dialoginVisible = false
			},
			closeLogin() {
				this.dialoginVisible = false
			},
			
			closeEvent() {
				this.diaRigistVisible = false;
			},
			hoverEvent(e) {
				// if (e != 0&&e != 7) {//彩票
				// 	this.showDraw = true
				// 	this.currentKey = this.naviData[e].key

				// }
				if (e != 0&&e != 8) {//彩票
					this.showDraw = true
					this.currentKey = this.naviData[e].key
				
				}
				this.hoverIndex = e
				console.log(this.currentKey ,this.hoverIndex)
				
			},
			getLength(val) {
				var num = 0
				for (let key in val) {
					num++

				}

				return num
			},
			countMargin(num) {
				var obj = {
					marginLeft: 0
				}
				if (num == 1) {
					obj.marginLeft = '337.5px'
				} else if (num == 2) {
					obj.marginLeft = '150px'

				} else if (num == 3) {
					obj.marginLeft = '56.25px'

				} else {

				}
				return obj
			},
			sortData(data) {
				var arr = []
				for (let key in data) {
					arr.push(data[key])

				}

				return arr.sort((a, b) => a.Factory.Status - b.Factory.Status || b.Factory.Sort - a.Factory.Sort)

			},
		}
	}
</script>

<style lang="scss" scoped>
	input {
		background-color: #283145;
		border-radius: 4px;
		width: 160px;
		height: 26px;
		padding-left: 10px;
		font-size: 14px;
		color: white;
	}

	.homeTop {


		width: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
		align-items: center;
		z-index: 99;






		.btn {
			width: 129px;
			height: 36px;
			border-radius: 5px;
			// box-shadow: 5px 5px 5px #DDDDDD;

			text-align: center;
			line-height: 36px;
			cursor: pointer;
			
			font-size: 16px;
		}

		.login {
			margin-left: 24px;
		}

		.regist {
			margin-left: 10px;
			background-color: #f04444;
		}

		.redWord {
			background-color: #3a8c38;
			color: white;
		}

		.blueWord {
			background-color: #f2d161;
			color: white;

		}


	}

	.fishHot {
			font-size: 8px;
			animation-name: fishHot; // 动画名称
					animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
					animation-delay: 0s; // 动画延迟时间
					animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
					animation-duration: .5s; // 动画完成时间
		}

		@keyframes fishHot {
			0% {
				background-color: red;
				color: white;
			}

	

		

			100% {
				background-color: yellow;
				color: red;
			}

		}
	.beClick:hover {
		color: white;
	}
	.changeColor{
		font-size: 8px;
		animation-name: changeColor; // 动画名称
				animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
				animation-delay: 0s; // 动画延迟时间
				animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
				animation-duration: 1s; // 动画完成时间
	}
	@keyframes changeColor {
		0% {
			
			color: white;
		}
	
		
	
	
	
		100% {
			
			color: rgb(103, 103, 103);
		}
	
	}

	.leftWord {
		color: #ffd053;
	}

	.tab {


		position: relative;

		.tabColum {
			position: relative;
			text-align: center;
			padding-bottom: 16px;
			// width: 100px;
			// margin-left: 50px;
			display: flex;
			flex-direction: column;
			align-items: center;
			color: #fdfeff;
			font-size: 18px;

		}

	

		.tabColum:hover {
			color: white;
		}

		.home {
			margin-right: 47px;
			// margin-top: 0px;
			// margin-bottom: 5px;
			bottom: 3px;
			position: absolute;
			right: 0;
		}


		.select {
			color: #dedbdd;
			border-bottom: 4px solid #d1b794;
		}

		.nolmal {
			color: #afa194;
		}



		.columSelect {
			color: white;
		}



	}

	.draw {
		position: absolute;
		top: 105px;
		width: 100%;
		left: 0;
		z-index: 8800;
		background-color: #222121;
			opacity: 0.95;

		
		.drawWrap {
			display: flex;
			flex-direction: row;

			width: 1000px;
			margin: 0 auto;
			margin-top: 0px;


			.imgWrap {
				color: #fdfeff;
			}

			.imgWrap:hover {

				color: #0f97ff;
			}

			// margin-left: -70px;
			img {
				margin-top: 25px;
			}


		}
	}


	.forget {
		background-color: rgb(103, 103, 103);
		color: white;
		width: 95px;
		height: 19px;
		text-align: center;
		line-height: 19px;
		position: absolute;
		bottom: 2px;
		right: 0px;
		border-radius: 5px;
		cursor: pointer;
	}

	.cz {
		width: 36px;
		height: 36px;
		border-radius: 18px;
		text-align: center;
		line-height: 36px;
		color: white;
		font-size: 23px;
		position: relative;
		// z-index: 88;
		font-weight: bold;
	}



	.tip {
		border: 3px solid black;
		border-radius: 10px;
		width: 183px;
		margin: 5px auto;
		height: 20px;
		margin-left: 21px;
		line-height: 20px;
		text-align: center;
		font-size: 11.5px;
		background-color: #ffd21e;
	}
</style>
