<template>
  <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" lazy-render>
    <!-- <van-swipe-item>
      <van-image :src="$img('banner.png')" />
    </van-swipe-item>
    <van-swipe-item>
      <van-image :src="$img('banner.png')" />
    </van-swipe-item>
    <van-swipe-item> <van-image :src="$img('banner.png')" /></van-swipe-item>
    <van-swipe-item> <van-image :src="$img('banner.png')" /></van-swipe-item> -->
    <van-swipe-item v-for="item in lobbyInfo.FirstPageBanner"  @click="bannerClick(item)">
      <van-image :src="item.PUrl" />
    </van-swipe-item>
  </van-swipe>
</template>

<script>

	import FullWidthBanner from "@/views/mobile/FullWidthBanner"
	
	export default{
	  mixins:[FullWidthBanner]
	}
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}
</style>