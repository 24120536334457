<template>
  <div style="width: 70%; margin-left: 15%; padding-bottom: 50px">
	<div class="flexs_sb" style="padding-top: 20px;">
		<div v-for="(item,index) in topList" class="canClick topList" @click="topIndex=index" :class="topIndex==index?'select mainBtnBG':'nol'" >
			
			{{ item.ActivityClass }}

		</div>
	</div>
    <div style="flex-wrap: wrap" class="flexs_r_c">
      <div
        v-for="(item, index) in getDataList()"
        @click="toInfo(item)"
        class="canClick"
        :key="item"
        style="position: relative; width: 31%; margin-top: 3vw"
        :style="index % 3 != 0 ? 'margin-left:3.5%' : ''"
      >
        <img :src="item.TitleUrl" v-if="item.TitleUrl.length" alt="" style="width: 100%" />
		<img :src="item.TitleUrlP" v-else alt="" style="width: 100%" />

        <!-- 					  <div style="color: white;position: absolute;width: 100%;bottom: 10px;font-size: 16px;text-align: center;">{{item.ActivityName}}</div>
 -->
      </div>
    </div>
    <!-- <div style="margin-top: 30px;color:rgb(251,41,68) ;font-size: 16px;line-height: 25px;">
				  *{{$t("giftWord1")}}<br>
				  *{{$t("giftWord2").replace('88BET','HDBET88')}}
				   
		</div> -->
  </div>
  <activity-info
    v-if="showAct"
    @handleClose="showAct = false"
    :data="selectAct"
  ></activity-info>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { theme } from "@/views/theme";
let activityInfo = require("@/views/" +
  "pc5" +
  "/components/activityInfo.vue").default;

import { bus, busName } from "@/components/core/bus";
export default {
  components: {
    activityInfo,
  },
  setup() {
    const activeNames = ref(["0"]);
    const themeVars = {
      cellVerticalPadding: 0,
      cellHorizontalPadding: 0,
      // collapseItemContentPadding: 0,
    };
    return { activeNames, themeVars };
  },
  computed: {
    ...mapGetters("global", ["lobbyInfo"]),
  },
  created() {
    var list = this.lobbyInfo.ActivityConf.Rebate.concat(
      this.lobbyInfo.ActivityConf.Bonus
    );
    for (var obj of list) {
      var hasContain = false;
      for (var newObj of this.topList) {
        if (newObj.ActivityClass == obj.ActivityClass) {
          hasContain = true;
        }
      }
      if (!hasContain) {
        this.topList.push(obj);
      }
    }
    this.topList = this.topList.sort((a, b) => a.Sort - b.Sort);
	console.log('sasdas',this.topList)

    list = list.sort((a, b) => a.Sort - b.Sort);
    this.dataList = list;

    if (this.$route.params.id) {
      this.selectId = this.$route.params.id;
    }

    bus.off("activity");
    bus.on("activity", (val) => {
      that.selectId = val.id;
      for (var i = 0; i < that.dataList.length; i++) {
        var obj = that.dataList[i];
        if (obj.ActivityId == that.selectId) {
          that.selectAct = JSON.stringify(obj);
          that.showAct = true;
        }
      }
    });
    var that = this;
    bus.off(busName.lobbyInfo);
    bus.on(busName.lobbyInfo, (val) => {
      var list = that.lobbyInfo.ActivityConf.Bonus;

      list = list.sort((a, b) => b.Sort - a.Sort);
      that.dataList = list;
      for (var i = 0; i < that.dataList.length; i++) {
        var obj = that.dataList[i];
        if (obj.ActivityId == that.selectId) {
          that.selectAct = JSON.stringify(obj);
          that.showAct = true;
        }
      }
    });

    if (that.lobbyInfo) {
      var list = that.lobbyInfo.ActivityConf.Bonus;

      list = list.sort((a, b) => b.Sort - a.Sort);
      that.dataList = list;
      for (var i = 0; i < that.dataList.length; i++) {
        var obj = that.dataList[i];
        if (obj.ActivityId == that.selectId) {
          that.selectAct = JSON.stringify(obj);
          that.showAct = true;
        }
      }
    }

    //this.activeNames = 	[this.$route.params.id]
  },
  methods: {
	getDataList(){
	
		if(this.topList[this.topIndex].ActivityId=='q1'){
			console.log('eeee',this.dataList)
        return this.dataList
      }
      var className = this.topList[this.topIndex].ActivityClass
      var arr = []
        for(var obj of this.dataList){
          if(obj.ActivityClass==className){
            arr.push(obj)
          }
        }
        return arr
			},
    toGet() {
      this.$router.push({
        name: "getActivity",
      });
      bus.emit("hiddeTab", {
        isShow: false,
      });
    },

    toInfo(val) {
      this.selectAct = JSON.stringify(val);
      this.showAct = true;
    },
  },
  //  mounted() {
  //    // this.$refs["c1"].toggle(false);
  // var list = this.lobbyInfo.ActivityConf.Rebate.concat(this.lobbyInfo.ActivityConf.Bonus)

  // list = list.sort((a, b) => b.Sort- a.Sort)
  // this.dataList = list
  // console.log('121231232',this.dataList)
  // this.activeNames = 	[this.$route.params.id]

  //  },
  data() {
    return {
      dataList: [],
      selectId: null,
      showAct: false,
      selectAct: "",
      topList: [],
	  topIndex:0
    };
  },
};
</script>

<style lang="scss" scoped>
.topList {
  width: 136.875px;
  height: 46px;
  text-align: center;
  line-height: 46px;
  border-radius: 8px;
  font-size: 14px;
  margin-left: 14px;
  margin-top: 15px;
}

.topList:first-child {
  margin-left: 0;
}

.topList:nth-child(9) {
  margin-left: 0;
}

.select {
  background-color: #4a69ff;

}

.nol {
  background-color: #3b3b3a;
  color: #dedbdd;

}
</style>
