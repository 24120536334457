
import pay from "@/views/mobile/pay";
import { http, topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {theme} from '@/views/theme'

export default {
  components: {},
  mixins: [pay],

  data() {
    return {
      curBank: "name1",
      amount: "",
      amountValue: "0",
    };
  },
  computed: {
    ...mapGetters("pay", ["payInfo"]),
  },
  watch: {
    amount(n, o) {
      this.amountValue = this.$tools.formatNum(n * 1000);
    },
  },
  mounted() {
    this.curBank = this.getBankList()[0];
  },
  methods: {
    getBankList() {
      for (let k in this.payInfo.vgPayBankList) {
        let item = this.payInfo.vgPayBankList[k];
        if (item._id == "bankQr") {
          let res = [];
          for (let k2 in item.bankList) {
            res.push(item.bankList[k2].Name);
          }
          return res;
        }
      }
      this.$log.warn("not found bankList for bankQr of vgPay");
      return [];
    },
    onConfirm(value) {
		console.log('asdfsdfs')
      this.curBank = value;
      this.showPicker = false;
    },
    getMethod() {
      return this.getPayMethod("VgPay", "bankQr");
    },
    onSubmit() {
      if (this.amount <= 0) {
        return;
      }
      let method = this.getMethod();
      if (!method) {
        this.$log.warn("not found pay method of MomoPay ");
        return;
      }
      let params = {
        amount: this.amount * 1000,
        methodId: method.MethodId,
        bankName: this.curBank,
      };
	  
      let that = this;
      that.$tools.loading();
	  let oWindow
	  if(theme=='mobile4'||theme=='mobile1'||theme=='mobile5'||theme=='mobile3'){
	   oWindow = window.open('', '_blank');
	  
	  }
	  
      mqant.request(topic.payCharge, params, function (res) {
        that.$tools.dismissLoading();
		
        if (res.Code == 0) {
			that.amount = ''
			if(theme=='mobile4'||theme=='mobile1'){
				
				var param = ''
				for(var key in res.Data.ReqParam){
					param += (key + '=')
					param += res.Data.ReqParam[key]
					param += '&'
				}
				param = param.substr(0,param.length-1)
				
				var url = 'https://88bet88bet.com/static/918PAY.html?'+param
				
				//that.$act.openBrowser(url);
				oWindow.location = url;
				
				return
				
			}else if (theme=='mobile5'){
				var param = ''
				for(var key in res.Data.ReqParam){
					param += (key + '=')
					param += res.Data.ReqParam[key]
					param += '&'
				}
				param = param.substr(0,param.length-1)
				
				var url = 'https://hdbet88.com/static/918PAY.html?'+param
				//that.$act.openBrowser(url);
				oWindow.location = url;
				
				return
			}
			oWindow.location = res.Data.TargetUrl;
			
         // that.$act.openBrowser(res.Data.TargetUrl);
        } else {
          that.$tools.toast(res.ErrMsg);
        }
      });
    },
  },
};