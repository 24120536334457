<template>
  <div class="main" v-if="curVersion != false && show">
    <div>
      <img :src="$img('close_blue.png')" class="close" @click="close" />
    </div>
	<img :src="$img('ZBETLOGO.png')" class="logo" />
	
    <div>
    </div>
    <div class="tips">Trải nghiệm tốt hơn trên ứng dụng</div>
    <div class="btn" @click="download">TẢI NGAY</div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "download-banner",
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapGetters("global", ["curVersion"]),
  },
  methods: {
    close() {
      this.show = false;
    },
    download() {
      window.open(this.curVersion.UrlPath)
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background: #0f97ff;
  padding: 1.5% 4%;
  border-radius: 6px;
  color: white;
  margin-right: 2%;
}
.tips {
  color: #0f97ff;
  font-size: 12px;
  flex: 1;
  margin-left: 3%;
}
.logo {
  width: 8vw;
  margin-top: 0vw;
}
.close {
  padding: 2vw;
  width: 4vw;
}
.main {
  width: 100%;
  display: flex;
  flex-direction: row;
  z-index: 10000;
  align-items: center;
  background-color: rgb(7, 15, 38);
  position: fixed;
  //padding: 2.5vh 0;
  top: 0;
  > div {
    display: inline-block;
  }
}
</style>