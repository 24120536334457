<template>
  <home-nav :active="active" />
  <home-cat
    :act="act"
    :imgList="factoryCat(cutType)"
    :onChange="onChange"
    style="margin-top: 1%"
    v-if="factoryCat(cutType).length > 1"
  />
  <div style="display: flex;flex-direction: row;flex-wrap: wrap;margin-bottom: 2vw;">
	  <van-image  @click="onClick(item)" style="width: 30vw;margin-left: 2.5vw;margin-top: 2vw;" v-for="item in factoryCat(cutType)[act].gameList" :src="getImg(item)" lazy-load />
  </div>
 <!-- <van-grid
    :border="false"
    :column-num="3"
    :gutter="0"
    v-if="!factoryCat(cutType)[act].maintena"
  >
    <van-grid-item
      v-for="item in factoryCat(cutType)[act].gameList"
      @click="onClick(item)"
    >
      <van-image :src="$act.gameIconUrl(item.GameCode)" lazy-load />
    </van-grid-item>
  </van-grid> -->
</template>

<script>
import panel from '@/views/mobile/panel'
export default {
  mixins:[panel]
}
</script>

<style lang="scss" scoped>
</style>