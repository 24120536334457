<template>
	<HomeNav style="position: fixed;top: 0;z-index: 9999;" />

	<div class="main" style="margin-top: 22vw;height: 82vh;overflow: hidden;">
<!-- 		<img :src="$img('recharg_bg.png')" alt="" style="width: 100%;position: absolute;z-index: 0;top: 0;left: 0;">
 -->		<div class="form" style="position: relative;z-index: 3;">
			<div class="tips phoneMiddle">
				*Số tiền rút tối thiểu: {{ $tools.formatNum2k(payInfo.doudouMin)
        }}<br />
				*Số tiền rút tối đa: {{ $tools.formatNum2k(payInfo.doudouMax) }}<br />
				*Số lần rút tối đa trong ngày:  {{ payInfo.doudouMaxCnt }} &nbsp;lần<br />
			</div>
			<div class="row">
				<input class="input  phoneMiddle" type="text" :placeholder="
            $tools.formatNum2k(payInfo.doudouMin) +
            '-' +
            $tools.formatNum2k(payInfo.doudouMax)
          " v-model="amount" />
				<div class="vndk phoneSmall whiteWord">VND(K)</div>
			</div>
			<div class="row tips unit phoneSmall"
				style="justify-content: start; margin-top: 1%; color: rgb(242,209,97)">
				= {{ amountValue }} <span style="color: rgb(128,134,145);">&nbsp;VND</span>
			</div>
			<div class="last_row row">
				<div class="sum phoneMiddle boldSize">
					SỐ DƯ: <span style="color: rgb(241,175,47);">{{$tools.formatNum2k(wallet.VndBalance) }}</span>
				</div>
				<div class="right phoneMiddle boldSize" style="color:rgb(223,185,113);" @click="allIn">TOÀN BỘ</div>
			</div>
			<div class="row"
				style="border-bottom: 1px solid rgb(64,87,127);width: 94%;margin-left: 3%;margin-top: 10px;padding:5px 0;">
				<div class="bank_menu phoneMiddle" @click="showPicker = true">
					{{ selectCardNum }}
					<img :src="$img('icon_dropdown.png')" />
				</div>
				
			</div>
		</div>
		<div class="label phoneMiddle">THÔNG TIN ĐÃ LIÊN KẾT NGÂN HÀNG</div>
		<!-- <bank-card @onChooseCard="onChooseCard" /> -->

		<div class="loginBtn">
			<img :src="$img('ann_bottom.png')" alt="" style="width: 100%;">
			<div @click="onSubmit"
				style="text-align: center;position: absolute;z-index: 9;width: 100%;top: 3.5vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
				{{$t('提交')}}
			</div>

		</div>
	</div>
	<van-popup v-model:show="showPicker" position="bottom"  :style="{ height: '30%' }">
	
		<div v-for="(item,index) in getBankList()" @click="itemClick(index)" :key="index"
			style="border-bottom: 1px solid rgb(244, 244, 244);width: 100%;text-align: center;height: 40px;line-height: 40px;">
			{{item}}
		</div>
	
	</van-popup>
	<setWithdrawPw v-if="showSetWithdrawPw" @handleClose='showSetWithdrawPw=false'></setWithdrawPw>
	<withdrawPw v-if="showWithdrawPw" @handleClose='showWithdrawPw=false' @confirm='comfirmPw'></withdrawPw>
	
	<my-dialog v-if="showDialog" :close="
      function () {
        showDialog = false;
      }
    " :title="dialogTitle" :msg="dialogMsg" />
</template>

<script>
	import {theme} from '@/views/theme'
		let RedHeader = require('@/views/'+ theme +'/components/RedHeader.vue').default
		let BankCard = require('@/views/'+ theme +'/components/BankCard.vue').default
		let MyDialog = require('@/views/'+ theme +'/components/MyDialog.vue').default
		let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default
		let setWithdrawPw = require('@/views/'+ 'mobile5' +'/components/setWithdrawPw.vue').default
		let withdrawPw = require('@/views/'+ 'mobile5' +'/components/withdrawPw.vue').default
		
	import { mapGetters, mapActions, mapMutations } from "vuex";
	 import { bus, busName } from "@/components/core/bus";
	 
	import { http, topic } from "@/components/protocol/api";
	import mqant from "@/components/protocol/mqantlib";
	import auth from "@/components/core/auth";
	
	export default {
	  mixins: [auth],
	  
	  components: { RedHeader,BankCard, MyDialog, HomeNav,setWithdrawPw,withdrawPw},
	  data() {
	    return {
			showSetWithdrawPw:false,
			showWithdrawPw:false,
	      amount: "",
	      amountValue: "0",
	      showDialog: false,
	      dialogTitle: "",
	      dialogMsg: "",
			userData:{
				
			},
	      curMyBank: null,
		  showPicker:false,
		  
		  selectCardNum:this.$t('选择到账卡')
	    };
	  },
	  computed: {
		  
	    ...mapGetters("tcp", ["wallet","isConnect"]),
	    ...mapGetters("pay", ["payInfo"]),
	  },
	  watch: {
	    amount(n, o) {
	      this.amountValue = this.$tools.formatNum(n * 1000);
	    },
	  },
	  created(){
		  var that= this
		  bus.on('onConnected', () => {
		  		that.getUserInfo()
		  	
		  })
		  bus.on('setWithdrawPw', () => {
		  		that.getUserInfo()
		  	
		  })
		  if(this.isConnect){
		  	this.getUserInfo()
		  	
		  }
		  if(this.payInfo.myDouDouBT.length>0){
		  	this.curMyBank = this.payInfo.myDouDouBT[0]
		  	this.selectCardNum = this.getBankList()[0]
		  }
	  },
	  methods: {
		  comfirmPw(e){
		  	
		  	if(e==this.userData.DoudouPwd){
		  		this.showWithdrawPw = false
		  		this.submit()
		  	}else {
				this.$tools.toast(this.$t('取款密码输入错误'));
				
		  		
		  	}
		  },
		getBankList(){
			var arr = []
			for(var i=0;i<this.payInfo.myDouDouBT.length;i++){
				var CardNum = this.payInfo.myDouDouBT[i].CardNum
				var num = this.showCardNum(CardNum)
				num += ('('+this.payInfo.myDouDouBT[i].BtName+')')
				arr.push(num)
				
				
			}
			
			
			if(this.payInfo.myDouDouBT.length<3){
				arr.push('+ '+this.$t('添加到账卡'))
			}
			return arr
		},
		itemClick(index){
			this.showPicker = false;
			
			if((this.getBankList()[index]).indexOf(this.$t('添加到账卡'))>=0){
				this.bindCard()
				return
			}
			
			this.curMyBank = this.payInfo.myDouDouBT[index]
			this.selectCardNum = this.getBankList()[index]
		},
		bindCard() {
			bus.emit('hiddeTab',{
				isShow:false
			})
		  this.$router.push({ name: "bindbank" });
		},
		onConfirm(value) {
		  this.curMyBank = value;
		  this.showPicker = false;
		},
		showCardNum(num) {
		  let res = ""
		  for (let k in num) {
		    if (k < num.length - 3) {
		      res += "*";
		      continue
		    }
		    res += num[k];
		  }
		  return res
		},
	    allIn() {
	      this.amount = (this.wallet.VndBalance / 1000).toFixed(2);
	    },
	    onChooseCard(item) {
	      this.curMyBank = item;
	    },
		getUserInfo() {
			var that = this
			mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
				that.userData = data.Data
			});
		},
		submit(){
			let params = {
			  btOid: this.curMyBank.Oid,
			  amount: this.amount * 1000,
			  btName: "",
			  cardNum: "",
			  accountName: "",
			};
			let that = this;
			that.$tools.loading();
			mqant.request(topic.payDoudou, params, function (res) {
			  that.$tools.dismissLoading();
			  if (res.Code == 0) {
			    that.showDialog = true;
			    that.dialogTitle = that.$t("提示");
			    that.dialogMsg = that.$t("提交成功") + '!!!' 
			  } else {
			    that.$tools.toast(res.ErrMsg);
			  }
			});
		},
	    onSubmit() {
	      if (this.amount <= 0) {
	        return;
	      }
	      if (this.curMyBank == false) {
	        this.$tools.toast("please choose your bank.");
	        return;
	      }
		  if(this.userData.DoudouPwd.length==0){
			  this.$tools.toast(this.$t('设置取款密码'));
			  
		  
		  	this.showSetWithdrawPw = true		
		  			
		  	return
		  }
		  this.showWithdrawPw = true

	    },
	  },
	};
</script>

<style lang="scss" scoped>
	.loginBtn {
		position: relative;
		margin-top: 0px;

	}

	.bank_menu {
		border-radius: 12px;
		padding: 2.5vw 0vw;
		position: relative;
		color: rgb(128, 134, 145);
		width: 100%;

		>img {
			width: 2vw;
			position: absolute;
			top: 46%;
			right: 0%;
		}
	}

	.submit {
		>button {
			background-color: rgb(213, 188, 145);
			;
			color: white;
			border: 0;
			border-radius: 12px;
			padding: 2vw 6vw;
			margin-bottom: 10vw;
		}
	}

	.last_row {
		color: rgb(128, 134, 145);
		padding: 0 3vw;
		margin-top: 1%;

		>.sum {
			flex: 1;

			>span {
				margin-left: 1%;
				color: #ff9600;
			}
		}

		>.right {
			text-decoration: underline;
		}
	}

	.main {
		padding: 3vw 3vw;
		
		position: relative;
	}

	.main_bg {
		opacity: 0.15;
		width: 94vw;
		position: absolute;
		top: 0%;
	}

	.label {
		font-weight: bold;
		color:red;
		margin-top: 3vh;
	}

	.form {
		width: 100%;
		border-radius: 5px;
		padding-bottom: 10vw;
		padding-top: 2vw;
		// background-color: rgb(14, 31, 68);
		background-color: rgb(42,42,42);
		
		>.tips {

			color: red;
			margin-left: 3%;
			padding-top: 3vw;
		}
	}

	.row {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 5%;
		position: relative;

		>.vndk {
			position: absolute;
			top: auto;
			bottom: auto;
			right: 3%;
		}
	}

	.input {
		border-radius: 5px;
		padding: 2vw 0;
		width: 94%;
		color: white;
		border-bottom: 1px solid rgb(64, 87, 127);

	}

	.blue_border {
		width: 75%;
		border: 1px solid #65c6e9;
	}
</style>
