import {
	theme
} from '@/views/theme'

let home = require('../views/' + theme + '/HomeView.vue').default


let homeIndex = require('../views/' + theme + '/home/IndexView.vue').default
let FootView = require('../views/' + theme + '/home/FootView.vue').default
let Douji = require('../views/' + theme + '/home/Douji.vue').default
let LiveView = require('../views/' + theme + '/home/LiveView.vue').default
let SlotView = require('../views/' + theme + '/home/SlotView.vue').default
let GamesView = require('../views/' + theme + '/home/GamesView.vue').default
let FishView = require('../views/' + theme + '/home/FishView.vue').default
let LotteryView = require('../views/' + theme + '/home/LotteryView.vue').default
let WithdrawView = require('../views/' + theme + '/WithdrawView.vue').default
let RechargeView = require('../views/' + theme + '/RechargeView.vue').default
let BankView = require('../views/' + theme + '/recharge/BankView.vue').default
let MomoView = require('../views/' + theme + '/recharge/MomoView.vue').default
let QrView = require('../views/' + theme + '/recharge/QrView.vue').default
let LinkView = require('../views/' + theme + '/recharge/LinkView.vue').default
let PhoneView = require('../views/' + theme + '/recharge/PhoneView.vue').default


let GiftView = require('../views/' + theme + '/recharge/GiftView.vue').default
let Gift = require('../views/' + theme + '/GiftView.vue').default
let PersonView = require('../views/' + theme + '/PersonView.vue').default
let DiscountDraw = require('../views/' + theme + '/person/DiscountDraw.vue').default
let LoginView = require('../views/' + theme + '/LoginView.vue').default
let RegisterView = require('../views/' + theme + '/RegisterView.vue').default
let BindBankView = require('../views/' + theme + '/BindBankView.vue').default
let game = require('../views/' + theme + '/game.vue').default
let giftInfo = require('../views/' + theme + '/giftInfo.vue').default
let getActivity = require('../views/' + theme + '/getActivity.vue').default

let aboutUs = require('../views/' + theme + '/aboutUs.vue').default
let turntable = require('../views/' + theme + '/turntable.vue').default
let BeDelegate = require('../views/' + theme + '/BeDelegate.vue').default

let casino = require('../views/' + theme + '/casinoIframe.vue').default


let RootView = require('../views/' + theme + '/RootView.vue').default

let rootChilren = [{
		path: '/home',
		name: 'home',
		component: home,
		redirect: '/home/index',
		children: [{
				path: 'index',
				name: 'index',
				component: homeIndex,
				meta: {
					keepAlive: true
				},
			},
			{
				path: 'foot',
				name: 'foot',
				component: FootView,
				meta: {
					keepAlive: true
				},
			},
			{
				path: 'douji',
				name: 'douji',
				component: Douji,
				meta: {
					keepAlive: true
				},
			},
			
			{
				path: 'live',
				name: 'live',
				component: LiveView,
				meta: {
					keepAlive: true
				},
			},
			{
				path: 'slot',
				name: 'slot',
				component: SlotView,
				meta: {
					keepAlive: true
				},
			},
			{
				path: 'games',
				name: 'games',
				component: GamesView,
				meta: {
					keepAlive: true
				},
			},
			{
				path: 'fish',
				name: 'fish',
				component: FishView,
				meta: {
					keepAlive: true
				},
			},
			{
				path: 'lottery',
				name: 'lottery',
				component: LotteryView,
				meta: {
					keepAlive: true
				},
			},
			{
				path: '/navigator',
				name: 'casino',
				component: casino
				
			}
		]
	},
	{
		path: '/withdraw',
		name: 'withdraw',
		component: WithdrawView,

	},
	{
		path: '/game',
		name: 'game',
		component: game,
		
	},
	{
		path: '/recharge',
		name: 'recharge',
		component: RechargeView,
		redirect: '/recharge/bank',
		children: [{
				path: 'bank',
				name: 'charge_bank',
				component: BankView,

			},
			{
				path: 'momo',
				name: 'charge_momo',
				component: MomoView,

			},
			{
				path: 'qr',
				name: 'charge_qr',
				component: QrView,

			},
			{
				path: 'link',
				name: 'charge_link',
				component: LinkView,

			},
			{
				path: 'Gift',
				name: 'charge_gift',
				component: GiftView,

			},
			{
				path: 'phone',
				name: 'charge_phone',
				component: PhoneView,

			}
			
			
		],
	},
	{
		path: '/gift',
		name: 'gift',
		component: Gift,
		meta: {
			keepAlive: true
		},
	},
	{
		path: '/bindbank',
		name: 'bindbank',
		component: BindBankView
	},
	{
		path: '/BeDelegate',
		name: 'BeDelegate',
		component: BeDelegate
	},
	
	{
		path: '/person',
		name: 'person',
		component: PersonView,
		meta: {
			keepAlive: true
		},
	},
	{
		path: '/DiscountDraw',
		name: 'DiscountDraw',
		component: DiscountDraw
	},
	{
		path: '/CheckWater',
		name: 'CheckWater',
		component: () => import('../views/' + theme + '/person/CheckWater.vue')
	},
	{
		path: '/UserInfo',
		name: 'UserInfo',
		component: () => import('../views/' + theme + '/person/UserInfo.vue')
	},
	{
		path: '/GameRecord',
		name: 'GameRecord',
		component: () => import('../views/' + theme + '/person/GameRecord.vue')
	},
	{
		path: '/BillRecord',
		name: 'BillRecord',
		component: () => import('../views/' + theme + '/person/BillRecord.vue')
	},
	{
		path: '/Message',
		name: 'Message',
		component: () => import('../views/' + theme + '/person/Message.vue')
	},
	{
		path: '/notice',
		name: 'notice',
		component: () => import('../views/' + theme + '/person/NoticeView.vue')
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('../views/' + theme + '/person/AboutView.vue')
	},
	

]

if(theme=='mobile1'){
	// let Douji = require('../views/' + 'mobile3' + '/home/Douji.vue').default
	// rootChilren.push({
	// 			path: 'douji',
	// 			name: 'douji',
	// 			component: Douji,
	// 			meta: {
	// 				keepAlive: true
	// 			},
	// 		})
rootChilren.push({
		path: '/giftInfo',
		name: 'giftInfo',
		component: giftInfo
	})
	rootChilren.push({
		path: '/getActivity',
		name: 'getActivity',
		component: getActivity
	})
	rootChilren.push({
		path: '/aboutUs',
		name: 'aboutUs',
		component: aboutUs
	})
}

if(theme=='mobile4'||theme=='mobile1'){
	let ChangePhone = require('../views/' + theme + '/ChangePhone.vue').default
	rootChilren.push({
		path: '/ChangePhone',
		name: 'ChangePhone',
		component: ChangePhone
	})
}


if(theme=='mobile4'||theme=='mobile5'||theme=='mobile6'||theme=='mobile7'){
	//rootChilren[0].
	
	// rootChilren.push({
	// 	path: '/bankList',
	// 	name: 'bankList',
	// 	component: bankList
	// })
	
	rootChilren.push({
		path: '/giftInfo',
		name: 'giftInfo',
		component: giftInfo
	})
	rootChilren.push({
		path: '/getActivity',
		name: 'getActivity',
		component: getActivity
	})
	
	
	
	
	
}

if (theme == 'mobile3'||theme=='mobile4'||theme=='mobile5'||theme=='mobile1'||theme=='mobile6') {
	let vipRecord = require('../views/' + theme + '/vipRecord.vue').default

	let vip = require('../views/' + theme + '/vip.vue').default
	let vipInfo = require('../views/' + theme + '/vipInfo.vue').default

rootChilren.push({
		path: '/turntable',
		name: 'turntable',
		component: turntable
	})


	rootChilren.push({
		path: '/vip',
		name: 'vip',
		component: vip
	})

	rootChilren.push({
		path: '/vipRecord',
		name: 'vipRecord',
		component: vipRecord
	})
	rootChilren.push({
		path: '/vipInfo',
		name: 'vipInfo',
		component: vipInfo
	})

}


let routes = [{
		path: '/login',
		name: 'login',
		component: LoginView,
		meta: {
			keepAlive: true
		},
	},
	{
		path: '/register',
		name: 'register',
		component: RegisterView,
		meta: {
			keepAlive: true
		},
	},
	{
		path: '/changePW',
		name: 'changePW',
		component: () => import('../views/' + theme + '/changePW.vue'),
		meta: {
			keepAlive: true
		},
	},
	{
		path: '/',
		name: 'root',
		component: RootView,
		redirect: '/home',
		children: rootChilren
	},
	{
		path: '/test_nav',
		name: 'test_nav',
		component: () => import('../views/test/testNative.vue')
	},
	{
		path: '/:pathMatch(.*)*',
		redirect: '/'
	}
]

export {
	routes,
}
