<template>
  <div class="main">
    <!-- <img :src="icon" class="icon" /> -->
    <img
      :src="$img(eyes)"
      class="eyes"
      @click="onEyeClick"
      v-if="type == 'password'"
    />
    <input
	class="phoneMiddle"
      :type="inputType"
      :placeholder="placeholder"
      :value="text"
      @input="$emit('update:text', $event.target.value)"
    />
<!--   <img :src="$img(inputBg)" class="bg" />
  -->  <div class="error" v-if="error != ''">* {{ error }}</div>
  </div>
</template>

<script>
const register_eyes_normal = "icon_eyes.png";
const register_eyes_close = "icon_eyes_close.png";
const login_eyes_normal = "blue_eye.png";
const login_eyes_close = "blue_eye_close.png";
export default {
  props: {
    icon: String,
    placeholder: String,
    inputBg: String,
    kind: {
      type: String,
      default: "register",
    },
    type: {
      type: String,
      default: "text",
    },
    number: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    text: String,
  },
  computed: {
    inputType() {
      if (this.type == "password") {
        if (this.eyes == login_eyes_close || this.eyes == register_eyes_close) {
          return "password";
        } else {
          return "text";
        }
      }
      return this.type;
    },
    
    // numberOnly(){
    //    if (this.number) {
    //        return "value=value.replace(/\D/g,'')"
    //    }else{
    //        return ""
    //    }
    // }
  },
  data() {
    return {
      eyes: this.kind == "register"? register_eyes_close : login_eyes_close
    };
  },
  methods: {
    onEyeClick() {
      if (this.kind == "register") {
        if (this.eyes == register_eyes_normal) {
          this.eyes = register_eyes_close;
        } else {
          this.eyes = register_eyes_normal;
        }
      } else {
        if (this.eyes == login_eyes_normal) {
          this.eyes = login_eyes_close;
        } else {
          this.eyes = login_eyes_normal;
        }
      }
    },
  },
  mounted() {
    //   console.log(typeof this.error)
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  height: 10vw;
  > .bg {
    width: 74vw;
  }
  > .error {
    text-align: left;
    padding-left: 24vw;
    color: red;
    font-size: 11px;
    position: relative;
    top: -1vh;
  }
  > .icon {
    position: absolute;
    width: 6.6vw;
    top: 14%;
    left: 15%;
  }
  > input {
    // position: absolute;
    top: 12%;
    left: 12%;
    padding: 3% 0px;
    width: 76%;
	background-color: rgb(64, 64, 64);
	color:white;
	padding-left: 5%;
  }
  input::placeholder {
    color: #9a9a9a;
  }
  > .eyes {
    position: absolute;
    right: 15%;
    top: 25%;
    width: 5vw;
    z-index: 1;
  }
}
</style>