<template>

	<div style="position: relative;">
		<img :src="$img('vip_bg.png')" alt="" style="width: 100vw;z-index: 0;position: absolute;left: 0;top: 0;min-width: 1200px;">
		<div style="text-align: center;height: 1960px;background-color:#09090d;">
			<div style="position: relative;overflow-x: hidden;padding-top: 25vw;width: 1350px;margin-left:calc(50vw - 675px) ;">



				<div class="flexs_sb" style="width: 80%;margin-left: 10%;">
					<img v-if="userData.VipLevel==0" :src="$img('VIP1.png')" class="diseableImg" alt=""
						style="width: 100px;">

					<img v-else :src="$img('VIP'+userData.VipLevel+'.png')" alt="" style="width: 100px;">


					<div style="width: 76%;" v-if="isLogged" class="flexs_colum">
						
						<div style="text-align: right;color: #808691;font-size: 20px;">{{$t("升级还需")}}&nbsp; <span
								style="color:#f1af2f;">{{$tools.formatNum2k(userData.GoalCharge-userData.CurCharge)}}</span></div>
						<van-progress :show-pivot='false' color="rgb(251,158,49)" :percentage=" userData.CurCharge/parseFloat(userData.GoalCharge) * 100"
							style="margin-top: 10px;" />
						<div style="margin-top: 10px;color: #fdfeff;font-size: 20px;text-align: left;">{{$tools.formatNum2k(userData.CurCharge)}}/{{$tools.formatNum2k(userData.GoalCharge)}}</div>
					</div>
					
					<div style="width: 76%;" v-else class="flexs_colum">
						
						<div style="text-align: right;color: #808691;font-size: 20px;">{{$t("升级还需")}}&nbsp; <span
								style="color:#f1af2f;">5000.00</span></div>
						<van-progress :show-pivot='false' color="rgb(251,158,49)" :percentage="0"
							style="margin-top: 10px;" />
						<div style="margin-top: 10px;color: #fdfeff;font-size: 20px;text-align: left;">{{$tools.formatNum2k(userData.CurCharge)}}/5000</div>
					</div>
					<div v-if="userData.ActivityMemberNum==0">
						<img class="diseableImg cannotClick" 
							:src="$img('liwu_img.png')" alt="" style="width: 72px;">
						<div style='color:white;font-size:20px;'>Nhận</div>
							
					</div>
					<div  v-else >
						<img class="get canClick" @click="getVipFL()"  :src="$img('liwu_img.png')" alt=""
							style="width: 72px;">
							<div style='color:white;font-size:20px;text-align:center;'>Nhận</div>
							
					</div>
					
				</div>

				<!-- <div class="flexs_r_c topBtn canClick" @click="toRecharge()" style="position: relative;">
			
						<img :src="$img('VIP_img.png')" alt="" style="width: 19px;position: absolute;left: 20px;top: 8px;">
			
						<div style="width: 100%;text-align: center;" v-if="userData.VipLevel==0||!isConnect">{{$t("成为")}}VIP</div>
						<div style="width: 100%;text-align: center;" v-else>{{$t("邀请码")}}:{{userData.InviteCode}}</div>
			
					</div> -->

				<div @click="toRecharge()" :class="userData.VipLevel==0||!isConnect?'canClick':''" style="width: 372px;height: 122px;margin: 0 auto;margin-top: -10px;position: relative;">
					<img :src="$img('getVipBG.png')" alt="" style="width: 100%;">
					<div   v-if="userData.VipLevel==0||!isConnect"
						style="font-weight: bold;position: absolute;top: 50px;left: 0;text-align: center;color: white;width: 100%;font-size: 30px;">
						{{$t("成为").toUpperCase()}}&nbsp;VIP
					</div>
					<div v-else
						style="font-weight: bold;position: absolute;top: 50px;left: 0;text-align: center;color: white;width: 100%;font-size: 30px;">
						{{$t("邀请码")}}:{{userData.InviteCode}}
					</div>
				</div>

				

				<!-- <div style="color: rgb(251,41,68);margin-top: 12px;text-align: center;font-size: 11px;"
					v-if="userData.VipLevel==0">{{$t("马上充值一笔100万即可成为VIP")}}</div> -->


				<div style="width: 100%;" class="flexs_sb">
					<img :src="$img('vipImgLeft.png')" alt="" style="width: 53%;">
					<img :src="$img('vipImgRight.png')" alt="" style="width: 37%;">
					
				</div>
				<div style="width: 100%;flex-wrap: wrap;margin-top: -30px;" class="flexs_r_c">
					<div style="position: relative;width: 45%;margin-top: 10px;">
						<img :src="$img('info_img.png')" alt="" style="width: 100%;">
						<div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;" class="flexs_r_c">
							<img :src="$img('01.png')" alt="" style="width: 34px;margin-left: 24px;">
							<div style="text-align: left;margin-left: 20px;padding-right: 20px;">
								<div style="color: white;font-size: 14px;">{{$t('绑定银行卡')}}</div>
								<div style="color: white;font-size: 12px;margin-top: 12px;">
									{{$t('vipInfo1')}}
								</div>
							</div>
						</div>
					</div>
					<div style="position: relative;width: 45%;margin-top: 10px;margin-left: 10%;">
						<img :src="$img('info_img.png')" alt="" style="width: 100%;">
						<div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;" class="flexs_r_c">
							<img :src="$img('04.png')" alt="" style="width: 34px;margin-left: 24px;">
							<div style="text-align: left;margin-left: 20px;padding-right: 20px;">
								<div style="color: white;font-size: 14px;">{{'THƯỞNG HÀNG THÁNG'.toUpperCase()}}</div>
								<div style="color: white;font-size: 12px;margin-top: 12px;">
									Mỗi tháng bạn sẽ được số tiền thưởng tương ứng với cấp bậc của mình 
									<br>
									Ví dụ: Bạn đã là vip 4 thì mỗi tháng ngày 1 bạn sẽ nhận được  288.000
								</div>
							</div>
						</div>
					</div>
					<div style="position: relative;width: 45%;margin-top: 10px;">
						<img :src="$img('info_img.png')" alt="" style="width: 100%;">
						<div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;" class="flexs_r_c">
							<img :src="$img('02.png')" alt="" style="width: 34px;margin-left: 24px;">
							<div style="text-align: left;margin-left: 20px;padding-right: 20px;">
								<div style="color: white;font-size: 14px;">{{'THƯỞNG THĂNG CẤP'.toUpperCase()}}</div>
								<div style="color: white;font-size: 12px;margin-top: 12px;">
									 Sau khi bạn đạt được tổng nạp theo yêu cầu thì hệ thống sẽ tự động tăng cấp và bạn cần vào mục VIP bấm vào hộp quà để nhận thưởng, tiền thưởng cần cược 1 vòng cược 
								</div>
							</div>
						</div>
					</div>
					<div style="position: relative;width: 45%;margin-top: 10px;margin-left: 10%;">
						<img :src="$img('info_img.png')" alt="" style="width: 100%;">
						<div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;" class="flexs_r_c">
							<img :src="$img('05.png')" alt="" style="width: 34px;margin-left: 24px;">
							<div style="text-align: left;margin-left: 20px;padding-right: 20px;">
								<div style="color: white;font-size: 14px;">{{'TRỢ CẤP THUA LỖ'.toUpperCase()}}</div>
								<div style="color: white;font-size: 12px;margin-top: 12px;">
									<div style="color: white;font-size: 12px;margin-top: 12px;">
										Khi bạn không may mắn, chúng tôi sẽ đền bù dựa trên số tiền thua của bạn để động viên. Tiền đền bù sẽ được trả vào sau 0h thứ 2 mỗi tuần
										<br>
										Ví dụ: Bạn đã trở thành khách hàng VIP9. Nếu hôm nay bạn thua 10.000.000, bạn sẽ nhận được 40.000 đồng tiền động viên. Tiền trợ cấp cần nhận trong ngày thứ hai nếu không hệ thống sẽ hủy bỏ.
									</div>
								</div>
							</div>
						</div>
						
					</div>
					<div style="position: relative;width: 45%;margin-top: 10px;">
						<img :src="$img('info_img.png')" alt="" style="width: 100%;">
						
						<div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;" class="flexs_r_c">
							<img :src="$img('03.png')" alt="" style="width: 34px;margin-left: 24px;">
							<div style="text-align: left;margin-left: 20px;padding-right: 20px;">
								<div style="color: white;font-size: 14px;">{{'HOA HỒNG LỜI MỜI '.toUpperCase()}}</div>
								<div style="color: white;font-size: 12px;margin-top: 12px;">
									<div style="color: white;font-size: 12px;margin-top: 12px;">
										VIP2 bắt đầu được thưởng ưu đãi này, tiền thưởng sẽ được cộng vào tài khoản mỗi tháng ngày 01 

										<br>
										 ví dụ: Khi bạn giới thiệu người chơi đã thua 100 triệu trong tháng 03 và khi đó bạn đang là VIP2 thì bạn sẽ nhận được 1% của 100 triệu vào tháng 04
									</div>
								</div>
							</div>
						</div>
						
					</div>
					<div style="position: relative;width: 45%;margin-top: 10px;margin-left: 10%;">
						<img :src="$img('info_img.png')" alt="" style="width: 100%;">
						<div style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;" class="flexs_r_c">
							<img :src="$img('06.png')" alt="" style="width: 34px;margin-left: 24px;">
							<div style="text-align: left;margin-left: 20px;padding-right: 20px;">
								<div style="color: white;font-size: 14px;">{{'NẠP TIỀN BẢO LƯU CẤP'.toUpperCase()}}</div>
								<div style="color: white;font-size: 12px;margin-top: 12px;">
									<div style="color: white;font-size: 12px;margin-top: 12px;">
										Mỗi tháng tiền nạp tích lũy của hội viên cần đạt theo yêu cầu của từng cấp, nếu không đạt yêu cầu sẽ bị hạ 1 cấp
										<br>
										Ví dụ: Bạn đã là VIP 3 thì mỗi tháng bạn cần phải nạp đủ  15.000.000 để bao lưu cấp, nếu không đạt yêu cầu thì bị hạ xuống VIP 2
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			




		</div>

<!-- 		<div class="flexs_colum"
			style="overflow-x: hidden;position: relative;z-index: 3;padding: 3vw;margin-left: 5vw;">
			<div class="flexs_r_c">
				<div
					style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
					1
				</div>
				<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('绑定银行卡')}}</div>
			</div>
			<div style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;">
				{{$t('vipInfo1')}}
			</div>

			<div class="flexs_r_c" style="margin-top: 20px;">
				<div
					style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
					2
				</div>
				<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('邀请积分').toUpperCase()}}</div>
			</div>
			<div
				style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
				{{$t('vipInfo2')}}
				<br>
				{{$t('vipInfo3')}}

			</div>

			<div class="flexs_r_c" style="margin-top: 20px;">
				<div
					style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
					3
				</div>
				<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('邀请回报').toUpperCase()}}</div>
			</div>
			<div
				style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">

				{{$t('vipInfo4')}}
				<br>
				{{$t('vipInfo5')}}
			</div>

			<div class="flexs_r_c" style="margin-top: 20px;">
				<div
					style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
					4
				</div>
				<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('充值优惠').toUpperCase()}}</div>
			</div>
			<div
				style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
				{{$t('vipInfo6')}}
				<br>
				{{$t('vipInfo7')}}
			</div>

			<div class="flexs_r_c" style="margin-top: 20px;">
				<div
					style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
					5
				</div>
				<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('亏损补贴').toUpperCase()}}</div>
			</div>
			<div
				style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
				{{$t('vipInfo8')}}
				<br>
				{{$t('vipInfo9')}}
			</div>

			<div class="flexs_r_c" style="margin-top: 20px;">
				<div
					style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
					6
				</div>
				<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('生日福利').toUpperCase()}}</div>
			</div>
			<div
				style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
				{{$t('vipInfo10')}}
				<br>
				{{$t('vipInfo11')}}
			</div>


		</div> -->

	</div>
	<confirm :title="title" v-if='showConfirm' @cancel='showConfirm=false' ></confirm>
	

</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import mqant from "@/components/protocol/mqantlib";
	import bus from "@/utils/bus.js"
	import i18n from "@/locales/i18n";
	import {
		theme
	} from '@/views/theme'
	let confirm = require('@/views/'+ theme +'/components/confirm.vue').default


	import {
		req,
		qs
	} from "@/utils/request";
	import {
		topic
	} from "@/components/protocol/api";
	export default {
		components: {
			confirm
		},
		data() {
			return {
				title:'',
				selectIndex: 0,
				showConfirm:false,
				progressWidth: {
					width: "50%"
				},

				progressWidth2: {
					width: "50%"
				},
				endDate: '',
				startDate: '',
				page: 1,
				configData: '',
				recordData: [],
				userData: {
					VipLevel: 0,
					ActivityMemberNum: 0,
					CurCharge:0,
					GoalCharge:0
				},
				tableData: [
					['VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6', 'VIP7', 'VIP8', 'VIP9'],
					['-', '-', '√', '√', '√', '√', '√', '√', '√'],
					['0.5', '0.5', '1', '1', '2', '2', '5', '5', '10'],
					['0.1%', '0.2%', '0.3%', '0.4%', '0.6%', '0.8%', '1.0%', '1.5%', '2.0%'],
					['0.1%', '0.2%', '0.3%', '0.4%', '0.5%', '0.6%', '0.7%', '1.0%', '1.2%'],
					['-', '-', '-', '-', '2%', '2%', '3%', '3%', '5%'],
					['88k', '88k', '188k', '288k', '1.2倍', '1.4倍', '1.6倍', '1.8倍', '2倍']
				],
				tableData2: [
					['VIP1', 'VIP2', 'VIP3', 'VIP4', 'VIP5', 'VIP6', 'VIP7', 'VIP8', 'VIP9'],
					['1,000.00', '10,000.00', '50,000.00', '100,000.00', '500,000.00', '1,000,000.00', '3,000,000.00',
						'5,000,000.00', '10,000,000.00'
					],
					['-', '1,000.00', '5,000.00', '10,000.00', '50,000.00', '100,000.00', '300,000.00', '500,000.00',
						'永久'
					],
					['-', '降为VIP1', '降为VIP2', '降为VIP3', '降为VIP4', '降为VIP5', '降为VIP6', '降为VIP7', '永久']

				],
				header1Arr: [this.$t('等级'), this.$t('客服优先'), this.$t('邀请积分'), this.$t('邀请回报'), this.$t('充值优惠'), this.$t(
					'亏损补贴'), this.$t('生日福利')],
				header2Arr: [this.$t('等级'), this.$t('累计充值金额'), this.$t('保级月累计充值'), this.$t('降级')],
				keyArr: ["InvitePoint", "InviteRebate", "ChargeGift", "LoseRebate", "BirthDayGift"],

			}
		},
		computed: {
			...mapGetters("tcp", ["isLogged", "isConnect", "info"]),
			...mapGetters("global", ["lobbyInfo"]),

		},
	
		created() {
			console.log('adfwef')
			if (this.isConnect) {
				this.getUserInfo()

			}else {
				this.userData = {
					VipLevel: 0,
					ActivityMemberNum: 0
				}
			}
			var that = this
			bus.off('receiveMemberAll')
			bus.on('receiveMemberAll', (val) => {
				let arr = val.data.Data

				let str = ''
				for (var i = 0; i < arr.length; i++) {

					var obj = arr[i]
					str += obj.Type
					str += that.$t('获得了')
					if (obj.Gold > 0) {
						str += obj.Gold
					}
					if (obj.Point > 0) {
						str += obj.Point
						str += '\n'

					}

				}
				that.getUserInfo()
				that.title = str
				that.showConfirm = true
				
				// that.$message({
				// 	message: str,
				// 	type: 'info'
				// });

			})


		},
		methods: {
			getVipFL() {
				var that = this
				mqant.request(topic.receiveMemberAll, {
					language: i18n.global.locale
				}, function(data, topicName, msg) {
					//console.log(topicName,data)

				});

			},
			getUserInfo() {
				var that = this
				mqant.request(topic.userInfo, {
					language: i18n.global.locale
				}, function(data, topicName, msg) {
					console.log('s231',data)
					that.userData = data.Data
				});
			},
			toRecharge() {
				if (!this.isLogged) {
					bus.emit('showLogin')
					return
				}
				if (this.userData.VipLevel == 0) {
					
					var that = this
					mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
						
						if (!data.Data.myDouDouBT.length) {
							
							that.$message({
								message: that.$t('请绑定银行卡'),
								type: 'info'
							});
							bus.emit('showAddBankVisible')
							
						} else {
							
							that.$router.push({
								name: 'recharge'
							});
						}
					});
					
				}


			},
			toVipInfo() {
				bus.emit('hiddeTab', {
					isShow: false
				})
				this.$router.push({
					name: 'vipInfo'
				});
			},
			toHistory() {

				this.$router.push({
					name: 'vipRecord'
				});
			},
			currentChange(e) {
				this.page = e
			},
			getRecord() {

				var that = this

				mqant.request(topic.getGiftRecord, {}, function(data, topicName, msg) {
					//console.log(11111,data)
					that.recordData = data.Data
				});
			},
			getVIPScore() {
				var that = this

				mqant.request(topic.getVIPScore, {}, function(data, topicName, msg) {
					that.getVipConfig()
				});
			},
			getMonthGift() {
				var that = this

				mqant.request(topic.getMonthGift, {
					'level': this.configData.CurVipLevel,
					'language': i18n.global.locale
				}, function(data, topicName, msg) {

				});
			},
			getVipConfig() {
				var that = this

				mqant.request(topic.getVIPConfig, {}, function(data, topicName, msg) {

					that.configData = data.Data

					if (that.configData.CurVipBet > that.configData.GoalVipBet) {
						that.progressWidth.width = '100%'

					} else {
						that.progressWidth.width = that.configData.CurVipBet / parseFloat(that.configData
							.GoalVipBet) * 100 + '%'
					}
					if (that.configData.CurVipCharge > that.configData.GoalVipCharge) {
						that.progressWidth.width = '100%'

					} else {
						that.progressWidth2.width = that.configData.CurVipCharge / parseFloat(that.configData
							.GoalVipCharge) * 100 + '%'
					}
				});
			},
			endDateChange(val) {
				this.endDate = this.dateFormat(val)
			},
			dateChange(val) {
				this.startDate = this.dateFormat(val)
			},

		},

	};
</script>

<style scoped lang="scss">
	.header {
		color: rgb(255, 183, 89);
		font-size: 10px;
		width: 14.28%;
		width: 200px;
		text-align: center;
		height: 40px;
		background-color: rgb(21, 34, 63);
		line-height: 40px;
	}

	.colum {
		color: white;
		font-size: 10px;
		width: 100%;

		// width: 200px;
		text-align: center;
		height: 35px;
		//background-color: rgb(32, 43, 69);
		line-height: 35px;
	}



	// .header {
	// 	background-color: rgb(126, 103, 70);
	// 	color: #d4bb78;
	// 	width: 25%;
	// 	height: 60px;
	// 	line-height: 60px;
	// 	text-align: center;

	// }
	.topBtn {
		width: 300px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		color: white;
		font-size: 16px;
		background-image: linear-gradient(to right, rgb(1, 174, 246), rgb(0, 109, 221));
		margin: 37px auto;
		border-radius: 4px;
		margin-bottom: 0;
	}

	.columBtn {
		background-color: #d3b35c;
		color: white;
		border-radius: 3px;
		height: 18px;
		line-height: 18px;
		text-align: center;
		width: 86%;
		margin-left: 7%;
	}

	.level {
		width: 90%;
		margin-left: 5%;
		height: 50vw;
		border-radius: 4px;
		background-color: rgb(73, 73, 73);
		margin-top: 4vw;
		// padding: 2vw;
	}

	.select {
		background-color: #fdb000;
		color: white;
	}

	.btn {
		padding: 3px 30px;
		margin-right: 10px;
		border-radius: 4px;
	}

	.nolmal {
		background-color: #323232;
		color: #c6c6c6;
	}

	.enable {
		background-color: #d3b35c;
		cursor: pointer;
	}

	.disable {
		background-color: gray;
	}

	.borderRight {
		border-right: 1px solid #364665;
		border-bottom: 1px solid #364665;

	}

	.header {
		color: #d3b35c;
		width: 14.28%;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}
	
	@-webkit-keyframes shake {
	  10% {
	    transform: rotate(15deg);
	  }
	  20% {
	    transform: rotate(-10deg);
	  }
	  30% {
	    transform: rotate(5deg);
	  }
	  40% {
	    transform: rotate(-5deg);
	  }
	  50%,
	  100% {
	    transform: rotate(0deg);
	  }
	}
	
	     
	      .get {
	       
	        animation: shake 1s 0.15s linear infinite;
	        -moz-animation: shake 1s 0.15s linear infinite; /* Firefox */
	        -webkit-animation: shake 1s 0.15s linear infinite; /* Safari and Chrome */
	        -o-animation: shake 1s 0.15s linear infinite; /* Opera */
	      }
	    
	
</style>

<style>
/* 	body,html{
		min-width: 1200px;
	} */
</style>
