import {
	mapGetters,
	mapActions,
	mapMutations
} from "vuex";

import action from '@/components/core/action'

import {
	status,
	getApiLoginUrl,
	checkFactoryUrl
} from "@/components/protocol/api";
import {
	req,
	qs
} from "@/utils/request";
import axios from 'axios' // 引入axios
import {
	bus,
	busName
} from "@/components/core/bus";
import tools from '@/utils/tools'

export default {
	computed: {
		...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),

		apiStatus() {
			return status;
		},
	},
	
	methods: {
		maintenaText(item) {
			if (this.factoryStatus(item) == status.maintena) {
				return this.$t("正在维护")
			} else {
				return this.$t("即将上线")
			}
		},
		async onClick(item) {
			if (this.isGrey(item)) return;
			if (!this.$act.checkLogged()) return;

			let name = this.staticPic[this.curType][item.id].Factory.FactoryName;


			let url = getApiLoginUrl(name, "");
			
			action.openPage(url,'')


		},
		isGrey(item) {
			return this.factoryStatus(item) == status.maintena || this.factoryStatus(item) == status.pre
		},
		factoryStatus(item) {
			
			
			if(!this.staticPic[this.curType]){
				return status.maintena;
			}
			
			if (typeof this.staticPic[this.curType][item.id] == "undefined") {
				this.$log.warn("staticPic.LiveSports[item.id] is undefined, id:" + item.id)
				return status.maintena;
			}
			return this.staticPic[this.curType][item.id].Factory.Status;
		},
	},
}
