<template>
	<div @touchmove.prevent @mousewheel.prevent
		style="width: 100%;height: 100%;font-weight: bold;position: fixed;top: 0;left: 0;z-index: 99;background-color: rgba(0, 0, 0, .7);text-align: center;border: 1px solid rgb(41, 52, 72);">
		<div class="" style="width: 92%;margin: 0 auto;margin-top: 15vh;position: relative;">
			<div class="flexs_sb" style="width:80%;margin-left:10%;">
				<img class="canClick" :src="$img('question.png')" @click="toInfo()" alt="" style="width: 35px;">
				<img class="canClick" :src="$img('jingcaiTitle.png')" alt="" style="width: 200px;">
				<img class="canClick" :src="$img('jingcaiclose.png')" @click="handleClose()" alt=""
					style="width: 35px;">

			</div>
			<div style="position: relative;margin-top: 10px;width: 100%;">
				<img :src="$img('sport_bg_img.png')" alt="" style="width: 100%;">
				<div style="position: absolute;left: 0;top: 0vw;width: 100%;color: white;" class="" v-if="gameData">
					<div class="flexs_sb">
						<div style="font-size: 10px;width: 34%;text-align: center; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{gameData.HomeTeamName}}</div>
						<div style="font-size: 10px;width: 34%;text-align: center; white-space: nowrap;text-overflow: ellipsis;overflow: hidden;">{{gameData.AwayTeamName}}</div>
										
						
					</div>
					<div class="flexs_sb" style="width: 80%;margin-left: 10%;margin-top: 9vw;">
						<div>
							<img :src="$img('HTeam.png')" alt="" style="width: 15vw;">
							
						</div>
						
						<div style="width: 50%;">
							<div class="flexs_sb" >
								<div style="position: relative;">
									<img :src="$img('NumBG.png')" alt="" style="width: 50px;">
									<div style="color: white;font-size: 10px;position: absolute;left: 0;top: 0;width: 100%;line-height: 28px;text-align: center;">
										{{gameData.HdpOddsValueHomeFt}}
									</div>
								</div>
								<div style="color: #fdfeff;font-size: 14px;">HDP</div>
								<div style="position: relative;">
									<img :src="$img('NumBG.png')" alt="" style="width: 50px;">
									<div style="color: white;font-size: 10px;position: absolute;left: 0;top: 0;width: 100%;line-height: 28px;text-align: center;">
										{{gameData.HdpOddsValueAwayFt}}
									</div>
								</div>
							</div>
							<div class="flexs_sb" style="margin-top: 10px;">
								<div style="position: relative;">
									<img :src="$img('NumBG.png')" alt="" style="width: 50px;">
									<div style="color: rgb(239,191,23);font-size: 10px;position: absolute;left: 0;top: 0;width: 100%;line-height: 28px;text-align: center;">
										{{gameData.OuOddsValueHomeFt}}
									</div>
								</div>
								<div style="color: #fdfeff;font-size: 14px;">O/U</div>
								<div style="position: relative;">
									<img :src="$img('NumBG.png')" alt="" style="width: 50px;">
									<div style="color: rgb(239,191,23);font-size: 10px;position: absolute;left: 0;top: 0;width: 100%;line-height: 28px;text-align: center;">
										{{gameData.OuOddsValueAwayFt}}
									</div>
								</div>
							</div>
						</div>
						
						
						
						<div>
							<img :src="$img('ATeam.png')" alt="" style="width: 15vw;;">
							
						</div>
					</div>
							
				</div>
				<div  style="color: red;font-size: 10px;text-align: left;padding: 0 15px;">Lưu ý : Chọn đội thắng thua và điền tỷ số trước khi vào đặt cược</div>
				
				
				
				<div class="flexs_r_c" style="margin-top: 20px;margin-left: 2vw;">
					<img :src="$img('sportTitle1.png')" alt="" style="width: 20vw;">
					<div style="margin-left: 30px;" :class="Status==1?'':'canClick'" @click="teamClick(0)">
						<img  v-if="selectIndex==0" :src="$img('sports_select.png')" alt="" style="width: 9vw;">
						<img  v-else :src="$img('sports_nomal.png')" alt="" style="width: 9vw;">

					</div>
					<div style="margin-left: 60px;" :class="Status==1?'':'canClick'" @click="teamClick(1)">
						<img v-if="selectIndex==1" :src="$img('sports_select.png')" alt="" style="width: 9vw;">
						<img v-else :src="$img('sports_nomal.png')" alt="" style="width: 9vw;">

					</div>


				</div>
				<div class="flexs_r_c" style="margin-top: 20px;">
					<img :src="$img('sportTitle2.png')" alt="" style="width: 16vw;margin-left: 2vw;">
					<div style="margin-left: 35px;position: relative;">
						<img :src="$img('inputWrap.png')" alt="" style="width: 60px;">
						<input v-if="Status==1" disabled v-model="allData.ScoreA" @input="num1=checkNum(num1)" type="text">
						<input  v-else v-model="num1" @input="num1=checkNum(num1)" type="text">
						
					</div>
					<div style="font-size: 30px;color: white;margin-left: 14px;">:</div>
					<div style="margin-left: 14px;position: relative;">
						<img :src="$img('inputWrap.png')" alt="" style="width: 60px;">
						<input v-if="Status==1" disabled v-model="allData.ScoreB" @input="num2=checkNum(num2)" type="text">
						 
						<input v-else v-model="num2" @input="num2=checkNum(num2)" type="text">

					</div>


				</div>
<!-- 				<div class="confirm" @click="confirm()" :class="Status==0?'can canClick':'noCan cannotClick'">XÁC NHẬN</div>
 -->				<div  @click="confirm()" class="can canClick confirm" v-if="Status==0" >XÁC NHẬN</div>
				<div  @click="toCMD()" class="can canClick confirm" v-if="Status==1">{{$t("马上投注").toUpperCase()}}</div>
				<div   class="noCan cannotClick confirm" v-if="Status==-1">XÁC NHẬN</div>
				
			
			</div>


		</div>

	</div>
</template>

<script>
	import {
		ref
	} from "vue";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import { http, topic } from "@/components/protocol/api";
	import mqant from "@/components/protocol/mqantlib";
	import {
			status,
			getApiLoginUrl,
			checkFactoryUrl
		} from "@/components/protocol/api"
	import {
		theme
	} from '@/views/theme'

	import {
		bus,
		busName
	} from "@/components/core/bus";
	export default {
		components: {

		},
		data() {
			return {
				selectIndex: 0,
				num1:0,
				num2:0,
				gameData:null,
				Status:null,
				allData:null
			}
		},
		setup() {

		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),
		},
		mounted() {
			this.getStatus()
		},
		methods: {
			toCMD(){
				let name = "apiCmd"
				let url = getApiLoginUrl(name, "");
				this.$act.openPage(url);
			},
			teamClick(index){
				if(this.Status==1){
					return
				}
				this.selectIndex = index
			},
			toInfo() {
				this.handleClose()
				
				var list = this.lobbyInfo.ActivityConf.Rebate.concat(this.lobbyInfo.ActivityConf.Bonus)
				
				list = list.sort((a, b) => b.Sort - a.Sort)
				
				for (var i = 0; i < list.length; i++) {
					var obj = list[i]
					if (obj.ActivityId == 'SportGuess') {
						bus.emit('hiddeTab', {
							isShow: false
						})
						this.$router.push({
							name: 'giftInfo',
							params: {
								data: JSON.stringify(obj)
							}
						
						
						})
						
					}
				}
				
			},
			confirm(){
				var obj = {
					scoreA:this.num1,
					scoreB:this.num2
				}
				
				if(this.selectIndex==0){
					obj.winTeam = 'A'
				}else {
					obj.winTeam = 'B'
					
				}
				let that = this;
				mqant.request(topic.guessSportActivity, obj, function(res) {
					if (res.Code == 0) {
						that.$message({
							message: that.$t('竞猜成功'),
							type: 'success'
						});
						that.getStatus()
						
						//that.handleClose()
					} else {
						that.$message({
							message: that.$t(res.ErrMsg),
							type: 'error'
						});
					}
				});
				
				
			},
			getStatus() {
				let params = {


				};
				let that = this;
				mqant.request(topic.getGuessSportStatus, params, function(res) {
					if (res.Code == 0) {
						
						that.gameData = res.Data.MatchInfo
						that.Status = res.Data.Status
						that.allData = res.Data
						if(that.allData.WinTeam=='B'){
							that.selectIndex=1
						}
					} else {
						that.$message({
							message: that.$t(res.ErrMsg),
							type: 'error'
						});
					}
				});
			},
			handleClose() {
				this.$emit('handleClose')

			},
			checkNum(data) {
				
				if (data) {
				
					return (data = Number(data.replace(/[^\d.%\\]/g, '')))
				
				}
				
			}
		}

	}
</script>

<style lang="scss" scoped>
	.chooseWrap {
		width: 32px;
		height: 32px;
		border: 1px solid white;
		border-radius: 2px;
	}

	input {
		text-align: center;
		
		// height: 100%;
		position: absolute;
		font-size: 20px;
		left: 0;
		bottom: 0;
		right: 0;
		top: 0;

	}

	.confirm {
		width: 120px;
		height: 45px;
		text-align: center;
		margin: 0 auto;
		margin-top: 30px;
		
		line-height: 45px;
		
		border-radius: 5px;
		font-size: 14px;
		color: white;

	}
	.can{
		background: linear-gradient(0deg,
				#0152c5 0%,
				#0194c8 100%);
		
	}
	.noCan{
		background: gray;
	}
</style>
