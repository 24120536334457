<template>
	<div style="display: flex;flex-direction: column;margin-top: 0px;padding: 20px 40px;position: relative;">
		<div style="position: absolute;width: 168px;right: 105px;top: 80px;" v-if="userData.VipLevel!=0">
			<img :src="$img('VIP'+userData.VipLevel+'.png')" alt="" style="width: 100%;">
		</div>
		<div class="flexs_r" style="margin-top: 50px;">
			<div>
				<div class="bigSize mainTitle" >
					{{$t('注册日期')}}</div>
				<div class="bigSize whiteWord inputWrap" style="font-size: 12px;">{{countDate(userData.RegisterTime)}}</div>
				
			</div>
			<div style="margin-left: 218px;">
				<div class="bigSize mainTitle" >
						{{$t('最后登录日期')}}</div>
					<div class="bigSize whiteWord inputWrap" style="font-size: 12px;">{{countDate(userData.LastLoginTime)}}</div>
				
			</div>	
						
		</div>
		
		
		<div class="flexs_r" style="margin-top: 50px;">
			<div style="position: relative;">
				<div class="bigSize mainTitle">
					{{$t('生日')}}</div>
				<div class="inputWrap" style="border: none;" v-if="countDate(userData.Birthday).indexOf('1970-01-01')>=0" >
					<el-date-picker style="position: absolute;left: 0;top: 0;font-size: 12px;"
						
						:clearable='false' :editable='false' v-model="birthday" type="date" :placeholder="$t('设置生日')" @change="dateChange">
					</el-date-picker >	
				</div>
				
					
				<div class="bigSize whiteWord inputWrap" v-else>{{countDate(userData.Birthday).split(' ')[0]}}</div>
				
				<div style="position: absolute;left:250px;top: 30px;" @click="onConfirm()" class="send canClick" v-if="showSetBirth">{{$t('确认')}}</div>
				
			</div>
			<div style="margin-left: 218px;">
				<div class="bigSize mainTitle" >
						{{$t('密码')}}</div>
				<div class="inputWrap" style="line-height: 0;">
					<input type="password" class="bigSize" disabled v-model="userData.Password" style="height: 100%;width: 100%;font-size: 12px;">
					<img :src="$img('xiug_icon.png')" @click="showChangePW=true" class="canClick" alt="" style="width: 28px;position: absolute;right: 10px;top: 5px;">
				</div>
				
			</div>	
						
		</div>
		
		<div class="flexs_r" style="margin-top: 50px;">
			<div>
				<div class="bigSize mainTitle" >
					{{$t('绑定电话').toUpperCase()}}</div>
				<div class="bigSize whiteWord inputWrap" style="font-size: 12px;" v-if="(userInfo.Phone+'').length==9">0{{userInfo.Phone}}</div>
				<div class="bigSize whiteWord inputWrap" style="font-size: 12px;" v-else>{{userInfo.Phone}}</div>
				
				
			</div>
			
			<div style="margin-left: 218px;">
				<div class="bigSize mainTitle" >
						{{$t('取款密码')}}</div>
				<div class="inputWrap" style="line-height: 0;" v-if='userData'>
					<input type="password" class="bigSize" disabled  v-if='userData.DoudouPwd.length' v-model="userData.DoudouPwd" style="height: 100%;width: 100%;font-size: 12px;">
					<input type="password" class="bigSize" disabled  v-else :placeholder="$t('请设置')" style="height: 100%;width: 100%;font-size: 12px;">
					
					<img :src="$img('xiug_icon.png')" v-if='userData.DoudouPwd.length' @click="showChangeWithdrawPw=true" class="canClick" alt="" style="width: 28px;position: absolute;right: 10px;top: 5px;">
					<img :src="$img('xiug_icon.png')" v-else @click="showSetWithdrawPw=true" class="canClick" alt="" style="width: 28px;position: absolute;right: 10px;top: 5px;">
					
					
				</div>
				
			</div>	
			
						
		</div>
		
		
		<div style="margin-top: 50px;">
			<div class="bigSize mainTitle"  v-if="userData">
					{{$t('绑定邀请码').toUpperCase()}}</div>
					<div class="bigSize whiteWord inputWrap" style="font-size: 12px;" v-if="!userData.BindInviteCode.length">------</div>
					
				<div v-else class="bigSize whiteWord inputWrap" style="font-size: 12px;">{{userData.BindInviteCode}}</div>
				
		</div>	
		
		<div style="width: 100%;height: 1px;border-bottom: 1px dashed #a4927c;margin-top: 60px;"></div>
		
		
		
		

	

		<div class="flexs_r" style="margin-top: 38px;">
			<div class="flexs_r">
				<div style="display: flex;flex-direction: column;">
					<div class="flexs_r_c">
						<div style="width:3px;height: 20px;background-color: #b29969;"></div>
						<span style="margin-left: 10px;color:#dedbdd;" class="bigSize boldSize">{{$t('绑定银行卡')}}</span>
						
					</div>
					<div class="flexs_sb" style="margin-top: 10px;">
						<div style="position: relative;width: 30%;">
							<img :src="$img('bank_img.png')" alt="" style="width: 100%;">
							<div class="bankBG">


								<div v-if="userData.myDouDouBT.length>0"
									style="display: flex;flex-direction: column;margin-left: 15px;">
									<span style="margin-top: 12px;font-size: 18px;"
										class="bigSize">{{userData.myDouDouBT[0].BtName}}</span>
									
									<span style="margin-top: 40px;font-size: 14px;"
										class="middleSize">{{userData.myDouDouBT[0].AccountName}}</span>
									<span style="margin-top: 5px;font-size: 16px;"
										class="middleSize">{{userData.myDouDouBT[0].CardNum}}</span>
								</div>
								<div v-else @click="showAddBank()" class="canClick"
									style="display: flex;flex-direction: column;text-align: center;align-items: center;">
									<img :src="$img('bankAdd.png')" alt=""
										style="margin: 0 auto;width: 20px;margin-top: 45px;">
									<span style="margin: 0 auto;margin-top: 5px;"
										class="middleSize boldSize">{{$t('添加银行卡')}}</span>

								</div>
							</div>

						</div>
						<div style="position: relative;margin-left: 15px;width: 30%;">
							<img :src="$img('bank_img.png')" alt="" style="width: 100%;">
							<div class="bankBG">
								<div v-if="userData.myDouDouBT.length>1"
									style="display: flex;flex-direction: column;margin-left: 15px;">
								<span style="margin-top: 12px;font-size: 18px;"
										class="bigSize">{{userData.myDouDouBT[1].BtName}}</span>
									
									<span style="margin-top: 40px;font-size: 14px;"
										class="middleSize">{{userData.myDouDouBT[1].AccountName}}</span>
									<span style="margin-top: 5px;font-size: 16px;"
										class="middleSize">{{userData.myDouDouBT[1].CardNum}}</span>
								
								</div>
								<div v-else @click="showAddBank()" class="canClick"
									style="display: flex;flex-direction: column;text-align: center;align-items: center;">
									<img :src="$img('bankAdd.png')" alt=""
										style="margin: 0 auto;width: 20px;margin-top: 45px;">
									<span style="margin: 0 auto;margin-top: 5px;"
										class="middleSize boldSize">{{$t('添加银行卡')}}</span>

								</div>
							</div>

						</div>
						<div style="position: relative;margin-left: 15px;width: 30%;">
							<img :src="$img('bank_img.png')" alt="" style="width: 100%;">
							<div class="bankBG">
								<div v-if="userData.myDouDouBT.length>2"
									style="display: flex;flex-direction: column;margin-left: 15px;">
									<span style="margin-top: 12px;font-size: 18px;"
											class="bigSize">{{userData.myDouDouBT[2].BtName}}</span>
										
										<span style="margin-top: 40px;font-size: 14px;"
											class="middleSize">{{userData.myDouDouBT[2].AccountName}}</span>
										<span style="margin-top: 5px;font-size: 16px;"
										class="middleSize">{{userData.myDouDouBT[2].CardNum}}</span>
									
								</div>
								<div v-else @click="showAddBank()" class="canClick"
									style="display: flex;flex-direction: column;text-align: center;align-items: center;">
									<img :src="$img('bankAdd.png')" alt=""
										style="margin: 0 auto;width: 20px;margin-top: 45px;">
									<span style="margin: 0 auto;margin-top: 5px;"
										class="middleSize boldSize">{{$t('添加银行卡')}}</span>

								</div>
							</div>

						</div>
					</div>
				</div>


			</div>
		</div>
		<changePW v-if="showChangePW" @handleClose='showChangePW=false'></changePW>
		<setWithdrawPw v-if="showSetWithdrawPw" @handleClose='showSetWithdrawPw=false'></setWithdrawPw>
		<changeWithdrawPw v-if="showChangeWithdrawPw" @handleClose='showChangeWithdrawPw=false'></changeWithdrawPw>
		
		
	</div>
</template>

<script>
	import {
			req,
			qs
		} from "@/utils/request";
		import mqant from "@/components/protocol/mqantlib";
		import { mapGetters, mapActions, mapMutations } from "vuex";
		import bus from "@/utils/bus.js"
		import {theme} from '@/views/theme'
			
			
			let changePW = require('@/views/'+ theme +'/components/changePW.vue').default
		let setWithdrawPw = require('@/views/'+ theme +'/components/setWithdrawPw.vue').default
		let changeWithdrawPw = require('@/views/'+ theme +'/components/changeWithdrawPw.vue').default
		
		import {
			topic
		} from "@/components/protocol/api";
	
		export default {
			components:{
				changePW,
				setWithdrawPw,
				changeWithdrawPw
			},
			data() {
				return {
					showChangePW:false,
					showSetBirth:false,
					showSetWithdrawPw:false,
					showChangeWithdrawPw:false,
					
					birthday:'',
					
					userData: {
						VipLevel:0,
						DoudouPwd:'',
						BindInviteCode:'',
						user:{
							
						},
						myDouDouBT:[]
					}
				}
			},
			
			computed:{
				...mapGetters("tcp", ["user","isLogged","isConnect"]),
				...mapGetters("global", ["lobbyInfo"]),
				
				...mapGetters("user", ["token","userInfo"]),
				
			},
			created() {
				var that = this
				bus.off('bindBank')
				bus.off('updateIndex')
				
				bus.on('bindBank',()=>{
					that.getUserInfo()
				})
				
				var that = this
				bus.on('onConnected', () => {
						that.getUserInfo()
					
				})
				bus.on('setWithdrawPw', () => {
						that.getUserInfo()
					
				})
				
				if(this.isConnect){
					this.getUserInfo()
					
				}
				
			},
			methods: {
				countYear(birthday){
					 var dateBegin = new Date(birthday);
					      var dateEnd = new Date();
						  
					      var dateDiff = dateEnd.getTime() - dateBegin.getTime(); //时间差的毫秒数
					      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
						if(dayDiff/365.0 <18){
							return false
						}else {
							return true
						}
				},
				onConfirm() {
					
						
						
							
							let that = this;
							var parmes = {
								date: this.birthday
							}
							mqant.request(topic.setBirthday, parmes, function(res) {
							
								
								if (res.Code == 0) {
									that.showSetBirth = false
									that.getUserInfo()
									that.$message({
										message: that.$t('修改成功'),
										type: 'success'
									});
								} else {
									that.$message({
										message: reject.ErrMsg,
										type: 'error'
									});
									
								}
							});
				},
				dateFormat(dateData) {
				        var date = new Date(dateData)
				        var y = date.getFullYear()
				        var m = date.getMonth() + 1
				        m = m < 10 ? ('0' + m) : m
				        var d = date.getDate()
				        d = d < 10 ? ('0' + d) : d
				        const time = y + '-' + m + '-' + d
				        return time
				        },
				
				dateChange(val){
					if(!this.countYear(this.dateFormat(val))){
						this.$message({
							message: this.$t('生日必须超过18岁'),
							type: 'error'
						});
						return
					}
						this.birthday = this.dateFormat(val)
						
						this.showSetBirth = true
				},
				openService(){
						window.open(this.lobbyInfo.CustomerInfo.customerOnline)
				},
				showAddBank() {
					//userData.myDouDouBT
					bus.emit('showAddBank')
				},
				countDate(val) {
					if(!val){
						return ''
					}
					return val.split('T')[0] +' ' + val.split('T')[1].substr(0,8)
				},
				getUserInfo() {
					var that = this
					mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
						that.userData = data.Data
					});
				},
	
	
			}
		}
</script>

<style lang="scss" scoped>
	.bigSize{
		font-size: 14px;
	}
	.infoWrap {
		width: 192px;
		height: 40px;
		border-radius: 5px;
		background-color: rgb(31, 31, 31);
		border: .5px solid white;
		margin-top: 7px;
		color: white;
	}

	.rightBtn {
		width: 110px;
		height: 30px;
		border-radius: 5px;
		color: white;
		margin-top: 12px;
		text-align: center;
		line-height: 30px;
		margin-right: 50px;
		background-color: rgb(38, 38, 38);

	}

	.bankBG {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		color: white;


	}
	.inputWrap{
		margin-top: 10px;
		width: 221px;
		height: 40px;
		padding-left: 10px;
		border-radius: 5px;
		color: #7d838e;
		background-color: #444342;
		line-height: 40px;
		border: 1px solid #585449;
		position: relative;
	}
	
	.send {
		width: 150px;
		height: 40px;
		color: white;
		text-align: center;
		line-height: 40px;
		border-radius: 5px;
		
		
		
		background-color: #df8300;
		font-size: 16px;
	}
	::v-deep .el-input__inner {
		  background-color: #444342 !important;
		  
		  width: 190px;
		  height: 40px;
		}
	::v-deep .el-input__wrapper{
		background-color: #444342 !important;
		
		
		height: 40px;
	}

</style>
