import { Tabbar, TabbarItem } from 'vant';
import 'vant/es/tabbar/style';
import 'vant/es/tabbar-item/style';

import { Swipe, SwipeItem } from 'vant';
import 'vant/es/swipe/style';
import 'vant/es/swipe-item/style';

import { Circle } from 'vant';
import 'vant/es/circle/style';

import { Grid, GridItem } from 'vant';
import 'vant/es/grid/style';
import 'vant/es/grid-item/style';

import { Image as VanImage } from 'vant';
import 'vant/es/image/style';

import { Collapse, CollapseItem } from 'vant';
import 'vant/es/collapse/style';
import 'vant/es/collapse-item/style';

import { Icon } from 'vant';
import 'vant/es/icon/style';

import { ConfigProvider } from 'vant';
import 'vant/es/config-provider/style';

// import { DropdownMenu, DropdownItem } from 'vant';
// import 'vant/es/dropdown-menu/style';
// import 'vant/es/dropdown-item/style';
import { Progress } from 'vant';
import 'vant/es/progress/style';

import { Picker } from 'vant';
import 'vant/es/picker/style';


import { DatetimePicker } from 'vant';


import { Popup } from 'vant';
import 'vant/es/popup/style';

import { ActionSheet } from 'vant';
import 'vant/es/action-sheet/style';


import { Field } from 'vant';
import 'vant/es/field/style';

import { Calendar } from 'vant';
import 'vant/es/calendar/style';

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import '@/views/mobile5/components/common.css'

import { Lazyload } from 'vant';
import 'vant/es/lazyload/style';

import { Toast } from 'vant';
import 'vant/es/toast/style';


import { Locale } from 'vant';
// 引入英文语言包
import enUS from 'vant/es/locale/lang/en-US';

Locale.use('en-US', enUS);

let install = function (app) {
	
	
	app.use(DatetimePicker)
	app.use(Progress)
	app.use(ActionSheet)
    app.use(Toast)
    app.use(Lazyload, { lazyComponent: true})
    app.use(Calendar)
    app.use(ElementPlus)
    app.use(Popup)
    app.use(Field)
    app.use(Picker)
    app.use(Tabbar)
    app.use(TabbarItem)
    app.use(Swipe)
    app.use(SwipeItem)
    app.use(Grid)
    app.use(GridItem)
    app.use(VanImage)
    app.use(Collapse)
    app.use(CollapseItem)
    app.use(Icon)
	app.use(Circle)
	
	
    app.use(ConfigProvider)
}

export {
    install
}