<template>
  <div style="overflow-x: hidden;">
<!-- 	  <download-banner v-if="isLogged&&getPlatform()=='android'" />
 -->	  
	  <HomeNav style="position: fixed;top: 0;z-index: 9999;"/>
	  
	  <div  style="position: relative;margin-top: 22vw;">
		  <full-width-banner/>
		  <HomeInfo />
		  
		  	  <Sportsgame />
	  </div>
   
	  <!-- <float /> -->
  </div>
</template>
<script>
	import {theme} from '@/views/theme'
	import { mapGetters, mapActions, mapMutations } from "vuex";
	
	
	let FullWidthBanner = require('@/views/'+ theme +'/components/FullWidthBanner.vue').default
		let HomeInfo = require('@/views/'+ theme +'/components/HomeInfo.vue').default
		let Sportsgame = require('@/views/'+ theme +'/components/Sportsgame.vue').default
		let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default
		let DownloadBanner = require('@/views/' + theme + '/components/DownloadBanner.vue').default
		let Float = require('@/views/'+ theme +'/components/Float.vue').default
		
		
		
export default {
  components: {
	  HomeNav,
	  DownloadBanner,
	  Float,
   HomeInfo,
   FullWidthBanner,
   Sportsgame
	
	
  },
  computed: {
  		...mapGetters("tcp", ["isLogged"]),
  		
  },
  setup() {},
  name: "HomeView",
  data() {
    return {};
  },
  methods: {
	  getPlatform() {
	  	let u = navigator.userAgent;
	  	 if (u.indexOf('Android') > -1 || u.indexOf('Adr') > -1) {
	  	    return "android"
	  	} else if (!!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
	  	    return "ios"
	  	}
	  }
  },
};
</script>
<style lang="scss" scoped>
</style>