<template>
	<div class="main" style="position: relative;overflow: hidden;">

		
		<img :src="$img('loginBg.png')" style="width: 100%;" />
		<div class="panel">
			


			<div style="color: white;font-size: 20px;font-weight: bold;position: relative;width: 100%;margin-top:30px;">
				<img :src="$img('login_back.png')" class="back" @click="onBackClick" />
				<img :src="$img('kf_icon.png')" style="width: 18px;position: absolute;right: 20px;" />

				{{$t('登录')}}
			</div>
			<div class="container" style="margin-top: 100px;">

				<div class="form">
					<img :src="$img('logo4.png')" style="width: 58px;margin:0 auto;" />
					

					<input type="text" v-model="account" :placeholder="$t('请输入账号').toUpperCase()">


					<input type="password" v-model="password" :placeholder="$t('请输入密码').toUpperCase()" style="">

					<div class="loginBtn">
						<img :src="$img('ann_bottom.png')" alt="" style="width: 80vw;">
						<div @click="onSubmit" style="position: absolute;z-index: 9;width: 100%;top: 2.5vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
							{{$t('登录')}}
						</div>
						
					</div>
					<div style="color: white;font-size:14px;margin-top: 19px;" @click="onToRegister">{{$t('立即注册').toUpperCase()}}</div>
					<div style="width: 180px;margin: 100px auto;margin-bottom: 23px;" class="flexs_sb">
						<img :src="$img('tg_icon.png')" alt="" style="width: 36px;">
						<img :src="$img('fbook_icon.png')" alt="" style="width: 36px;">
						<img :src="$img('Youtube_icon.png')" alt="" style="width: 36px;">
						
					</div>
					<div style="text-align: center;color: rgb(158,169,189);">
					  version {{version}}
					</div>

				</div>

			</div>
			
			
			
		</div>
	
	</div>
</template>
<script>
	import login from "@/views/mobile/login";

	export default {
		mixins: [login],
	};
</script>
<style lang="scss" scoped>
	.loginBtn{
		position: relative;
		margin-top: 24px;
		
	}
	input {
		width: 80vw;
		height: 40px;
		border-bottom: 1px solid rgb(75, 98, 132);
		background-color: transparent;
		color: white;
		padding-top: 15px;
		font-size: 15px;
	}

	.title {
		color: #fff;
		margin-top: 5%;
	}

	.des {
		margin-top: 22%;
		color: red;
	}

	.back {
		width: 6vw;

		position: absolute;
		left: 20px;
		// align-self: start;
	}

	.main {
		width: 100%;
		height: 100%;

		>img {
			width: 100%;
			min-width: 100%;
			min-height: 100%;
		}

		text-align: center;

		>.panel {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			position: absolute;
			top: 5%;
			width: 100%;

			>.container {
				position: relative;
				width: 94vw;
				height: 100px;

				>.panel_bg {
					width: 94vw;
				}

				>.form {
					position: absolute;
					top: 0%;
					left: 0%;
					right: 0%;

					//   background-color: red;
					.login-input {
						margin-top: 5%;
					}
				}
			}
		}
	}
</style>
