<template>
  <panel active="fish" cutType="Fish"/>
</template>

<script>
import Panel from './panel.vue';
export default {
  components: {  Panel },
};
</script>

<style lang="scss" scoped>

</style>