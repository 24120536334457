<template>
  <div class="form">
<!-- 	  <img :src="$img('recharg_bg.png')" alt="" style="width: 100%;position: absolute;z-index: 0;top: 0;left: 0;">
 -->	  
    <div class="form_panel"  style="position: relative;z-index: 3;padding-top: 4vw;padding-bottom: 10vw;">
      <div class="row" style="border-bottom: 1px solid rgb(74,74,73);width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;">
        <input
          class="input blue_border phoneMiddle"
          type="text"
          :placeholder="
            $tools.formatNum2k(getMethod().Mini) +
            '-' +
            $tools.formatNum2k(getMethod().Max)
          "
          v-model="amount"
        />
        <div class="vndk phoneSmall" style="color: #f2d161;">VND(K)</div>
      </div>
      <div class="row tips phoneSmall" style="color: white;">= {{ amountValue }} VND</div>
      <div class="row" style="border-bottom: 1px solid rgb(74,74,73);width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;">
        <div class="bank_menu phoneMiddle" style="color: white;" @click="showPicker = true">
          {{ curBank }}
          <img :src="$img('icon_dropdown.png')" />
        </div>
        <van-popup v-model:show="showPicker" round position="bottom">
          <van-picker
            :columns="getBankList()"
            @cancel="showPicker = false"
            @confirm="onConfirm"
            confirm-button-text="Xác nhận" cancel-button-text="Hủy"
          />
        </van-popup>
      </div>
    </div>
   <div class="loginBtn">
   	<img :src="$img('ann_bottom.png')" alt="" style="width: 100%;">
   	<div @click="onSubmit" :style="showPicker?'color:white':'color:black'" style="text-align: center;position: absolute;z-index: 9;width: 100%;top: 3.5vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
   		{{$t('提交')}}
   	</div>
   	
   </div>
  </div>
</template>

<script>
import linkPay from "@/views/mobile/linkPay";
import { ref } from "vue";

export default {
  mixins: [linkPay],
  setup() {
    const showPicker = ref(false);
    return {
      showPicker,
    };
  },
};
</script>

<style lang="scss" scoped>
	.loginBtn{
		position: relative;
		margin-top: 24px;
		
	}
.bank_menu {
  border-radius: 12px;
  padding: 2.5vw 0vw;
  position: relative;
  width: 100%;
  > img {
    width: 2vw;
    position: absolute;
    top: 46%;
    right: 0%;
  }
}
.input {
  border-radius: 11px;
  color: white;
  padding: 2vw 0vw;
}
.blue_border {
  flex: 1;
  // border: 1px solid #65c6e9;
}
.row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  position: relative;
  > .label {
    width: 25vw;
    text-align: right;
    padding-right: 4px;
  }
  > .vndk {
    position: absolute;
    top: auto;
    bottom: auto;
    right: 0%;
  }
}
input{
	color: white;
}

.form {
  padding: 2vw 6vw;
  position: relative;
  > .form_panel {
   margin-top: 2vh;
   // padding: 7vw 11vw;
			background-color: rgb(42,42,42);
   padding-bottom: 3vw;
   border-radius: 5px;
    > .tips {
      justify-content: start;
      margin-left: 6%;
      margin-top: 0%;
      padding: 2vw 0vw;
    }
  }
  > .form_panel2 {
    margin-top: 2vh;
    // border: 1px solid #65c6e9;
    padding-bottom: 1vw;
    > .row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0%;
    }
  }
}
.submit_tips {
  color: red;
}
.submit {
  > button {
    background-color: rgb(213, 188, 145);;
    color: white;
    border: 0;
    border-radius: 12px;
    padding: 2vw 6vw;
    margin-bottom: 10vw;
  }
}
</style>