import { createApp } from 'vue'

import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import log from '@/utils/logger'
import "@/assets/js/momentum";
import i18n from './locales/i18n'
import 'lib-flexible'
import { img } from '@/utils/img'
// import '@/assets/css/base.css'
import '@/components/core/native'
import "@/utils/date";


import {
		theme
	} from '@/views/theme'
	
	if(theme=='pc5'){
		require('@/assets/css/base1.css') 
		
	}else {
		require('@/assets/css/base.css') 
		
	}

import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

const app = createApp(App)

import tools from '@/utils/tools'
import action from '@/components/core/action'
app.config.globalProperties.$tools = tools
app.config.globalProperties.$act = action

// import TraceKit from '@/utils/tracekit'
// TraceKit.report.subscribe(errorReport => {
//     action.reportError(errorReport)
// });

app.config.globalProperties.$img = img
app.config.globalProperties.$log = log
app.use(i18n)
app.use(store)
app.use(router)
app.use(VXETable);

if (tools.isMobile()) {
    require('./main_mobile').install(app)
} else {
    app.config.globalProperties.$currentVersion = 'pc1'
    require('./main_pc').install(app)
}
app.mount('#app')

