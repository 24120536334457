<template>
 <router-view v-if="isRouterAlive"/>
<!-- <div v-if='showMask' style='width:100vw;height:100vh;background-color: rgba(0,0,0,.4);position:fixed;z-index:10000;left:0;top:0;color:white;line-height: 100vh;text-align: center;'>
	 {{$t('正在连接')}}...
 </div> -->
</template>

<script>
	import bus from "@/utils/bus.js"

	export default {
		name:'App',
		 provide () {
		    return {
		      reload: this.reload
		    }
		  },
		components:{},
		data(){
			return { 
				isRouterAlive: true,
				showMask:false,
			}
		},
		
		created() {
			var that = this
			
			bus.off('freshLan')
			
			bus.on('freshLan', (val) => {
				bus.emit('noShowNotice', {
					
					
				})
				that.reload()
			})	
				
				
			bus.off('onDisaConnected')
			
			bus.on('onDisaConnected', (val) => {
				
				that.showMask = true
			})	
				
				
			bus.off('onConnected')
			 
			bus.on('onConnected', (val) => {
				that.showMask = false
				that.$tools.dismissLoading();
				
				that.reload()
			})		
		},
		methods:{
			
			reload () {
			      this.isRouterAlive = false
			      this.$nextTick(function () {
			        this.isRouterAlive = true
			      })
			    }
		}
	}
</script>

<style lang="scss">
  * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  input:active {
    background-color: transparent;
  }
  input{
	  background-color: transparent;
  }

  input {
    outline: none;
    -webkit-tap-highlight-color: black;
    /*-webkit-appearance: none;*/
  }

  /*input,select,button{*/
  /*  -webkit-appearance: none;*/
  /*  appearance: none;*/
  /*}*/
  img, a {
    -webkit-touch-callout: none;
  }

  html, body {
    margin: 0;
    padding: 0;
     height: 100%;
	
  }

  #app {
    height: 100%;
  }
  body{
    position: relative;
    box-sizing: border-box;
    word-break: keep-all;
  }
  
</style>
<style >
    @mixin iphonex-css body { 
    padding-bottom: constant(safe-area-inset-bottom);
  }
</style>
