<template>
  <div style="background-color: black">
	  
    <el-carousel
      :height="carouselHeight"
      arrow="never"
      style="min-width: 1200px"
    >
      <el-carousel-item
        v-for="item in bannerData"
        @click="bannerClick(item)"
        style=""
      >
        <img
          :src="item.PUrl"
          alt=""
          style="width: 100%; display: block; object-fit: contain"
        />
      </el-carousel-item>
    </el-carousel>
 <recommend></recommend>
 

  <!--  <div class="message">
      <Slider @getTitle="getSliderTitle" />
    </div>
    <recommend></recommend>
    <dowload></dowload> -->
  </div>
</template>

<script>

import { theme } from "@/views/theme";

let recommend = require("@/views/" +
  theme +
  "/components/recommend.vue").default;



let bottomNav = require("@/views/" +
  theme +
  "/components/bottomNav.vue").default;
let Slider = require("@/views/" + theme + "/components/Slider.vue").default;
let dowload = require("@/views/" + theme + "/components/dowload.vue").default;

import {
  status,
  getApiLoginUrl,
  checkFactoryUrl,
} from "@/components/protocol/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import mqant from "@/components/protocol/mqantlib";
import { req, qs } from "@/utils/request";
import { topic } from "@/components/protocol/api";
import bus from "@/utils/bus.js";

import { getCurrentInstance } from "vue";
export default {
  components: {
    recommend,
    Slider,
    bottomNav,
    dowload,
	
  },
  data() {
    return {
      visible: false,
	  showSportGame:false,
      carouselHeight: "",
      imgUrl: "https://www.baidu.com",
      sliderData: [
        {
          Content: this.$t("欢迎来到88bet"),
        },
      ],
      bannerData: [],
      messsageList: [
        {
          title: "公告1",
        },
        {
          title: "公告2",
        },
        {
          title: "公告3",
        },
      ],
      overIndex: -1,
      diaLoginVisible: false,
      diaRigistVisible: false,
    };
  },
  created() {
    //bus.on('windowWidth')

    // <!--把window.onresize事件挂在到mounted函数上-->
    var that = this;

    if (document.documentElement.clientWidth > 1200) {
      this.carouselHeight =
        (document.documentElement.clientWidth / 1920) * 580 + "px";
    } else {
      this.carouselHeight = (1200.0 / 1920) * 580 + "px";
    }

    bus.on("windowWidth", (val) => {
      if (val.windowWidth > 1200) {
        this.carouselHeight =
          (document.documentElement.clientWidth / 1920) * 580 + "px";
      } else {
        this.carouselHeight = (1200.0 / 1920) * 580 + "px";
      }
    });
    var banner = [];

    banner = this.lobbyInfo.FirstPageBanner.sort((a, b) => {
      return b.Sort - a.Sort;
    });
    this.bannerData = banner;
    // if (this.isConnect) {
    // 	this.getNotice()
    // }
  },

  mounted() {},
  computed: {
    ...mapGetters("global", ["lobbyInfo","liveChatId"]),
    ...mapGetters("tcp", ["isLogged", "isConnect"]),
  },
  inject: ["reload"],
  methods: {
    bannerClick(val) {
      if (val.PType == "") {
        return;
      } else if (val.PType == "LinkUrl") {
        if (val.GameCode.length) {
          window.open(val.GameCode);
        }
      } else if (val.PType == "Activity") {
        if (val.GameCode.length) {
			if(val.GameCode=='Member'){
				this.$router.push({
				  name: "vip"
				  
				});
				return
			}
          this.$router.push({
            name: "activity",
            params: {
              id: val.GameCode,
            },
          });
        } else {
          this.$router.push("activity");
        }
      } else {
        if (
          val.PType == "LiveCasino" ||
          val.PType == "Lottery" ||
          val.PType == "LiveSports"
        ) {
          if (val.Factory.length) {
            if (!this.isLogged) {
              bus.emit("showLogin");

              return;
            }
            let name = val.Factory;
            let url = getApiLoginUrl(name, "");
            this.$act.openPage(url);
          } else {
          }
        } else if (
          val.PType == "Card" ||
          val.PType == "Fish" ||
          val.PType == "Slot"
        ) {
          if (val.Factory.length) {
            if (val.GameCode.length) {
              if (!this.isLogged) {
                bus.emit("showLogin");
                return;
              }
              let url = getApiLoginUrl(val.Factory, val.GameCode);
              this.$act.openPage(url);
            } else {
              var name;
              if (val.PType == "Fish") {
                name = "fishing";
              } else if (val.PType == "Slot") {
                name = "slot";
              } else {
                name = "chess";
              }
              this.$router.push({
                name: name,
                params: {
                  FactoryName: val.Factory,
                },
              });
            }
          }
        }
      }
    },
    toBrief() {
      this.$router.push({
        name: "brief",
      });
    },
    toDiscount() {
      bus.emit("showDiscout");
      this.$router.push("/discount");
    },
    showMessage() {
      if (this.isLogged) {
        bus.emit("loginSuccess", {});
      } else {
        bus.emit("showLogin", {});
      }
    },
    getNotice() {
      var that = this;

      mqant.request(topic.notice, {}, function (data, topicName, msg) {
        //console.log('data',data)
        //if(data.Data.length){
        that.sliderData = data.Data;
        that.reload();
        //}
      });
    },

    handleClose() {
      this.diaRigistVisible = false;
    },
    getSliderTitle(title) {
      // 获取点击的标题
    },
  },
};
</script>
<style lang="scss" scoped>
.message {
  height: 58px;
  background-color: #303030;
  width: 100%;
  min-width: 1200px;
}

.recommond {
  position: relative;
}
.ad {
  // padding: 0 6vw;
  // width: 1000px;
  text-align: center;
  margin: 0 auto;
  margin-top: 17px;
  background-color: black;
  width: 100%;

  .word {
    background-color: rgb(125, 125, 125);
    color: white;
    position: absolute;
    bottom: 10px;
    left: 80px;
    z-index: 99;
    width: 183px;
    height: 31px;
    border-radius: 15.5px;
    line-height: 31px;
    text-align: center;
  }
}

body,
html {
  background-color: black;
}

#app {
  background-color: black;
}
</style>
