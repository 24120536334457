<template>
	<div @touchmove.prevent @mousewheel.prevent  class="wrap"
		style="width: 100%;height: 100%;font-weight: bold;position: fixed;top: 0;left: 0;z-index: 200;background-color: rgba(0, 0, 0, .7);text-align: center;border: 1px solid rgb(41, 52, 72);">
		
		<div style="position: relative;width: 320px;margin: 130px auto;">
			<div class="canClick" @click="cancel()" style="width:30px;height: 30px;border-radius: 15px;background-color: gray;text-align: center;position: absolute;right: 0;top: 0;z-index: 99;">
				<img :src="$img('closeBig.png')" alt="" style="width: 20px;margin-top: 5px;">
				
			</div>
			<img :src="$img('showAward1.png')" alt="" style="width: 320px;">
			<div style="position: absolute;left: 0;top: 0;width: 100%;color:white;">
				<div style='font-size: 9px;margin-top: 20px;'>Chúc mừng nhận được</div>
				<div style='font-size: 17px;' v-if="awardData.GetVnd>0">{{awardData.GetVnd/1000}}K</div>
				<div style='font-size: 17px;' v-else>{{awardData.GetPoints}}Tích điểm</div>
				
				<div style="position: relative;margin: 0 auto;">
					
					<img :src="$img('showAward3.png')" alt="" style="width: 150px;">
					
				</div>
				<div style="position: relative;width: 140px;margin: 60px auto;" class="canClick" @click="cancel()">
					<img :src="$img('ok.png')" alt="" style="width: 100%;">
					<div style="position: absolute;left: 0;top: 0;width: 100%;line-height: 40px;color: white;font-size: 20px;">
						OK
					</div>
				</div>
			</div>
			
			
			
				
				
				
			
		</div>
	</div>
</template>

<script>
	import {
		ref
	} from "vue";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import {
		theme
	} from '@/views/theme'

	import {
		bus,
		busName
	} from "@/components/core/bus";
	export default {
		components: {

		},
		data() {
			return {
				
			};
		},
		props:{
			awardData:{
				
			}
			
		},
		setup() {

		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),
		},
		 mounted() {
			
		},
		methods: {
			cancel(){
					this.$emit('close')
			},
		
	},

	};
</script>

<style lang="scss" scoped>

</style>
