
<template>
	<HomeNav style="position: fixed;top: 0;z-index: 9999;"/>
	
  <div class="main" >
	 <!-- <div style="margin-top: 3vw;color:rgb(251,41,68) ;font-size: 13px;">
		  *{{$t("giftWord1")}}<br>
		  *{{$t("giftWord2").replace('88BET','HDBET88')}}
		  
	  </div> -->
    <div class="flexs_r_c" style="margin-top: 10px;overflow-y:scroll;white-space:nowrap;">
      <div v-for="(item,index) in topList" class="canClick topList" @click="topIndex=index" :class="topIndex==index?'select':'nol'" >
        
        {{ item.ActivityClass }}
  
        <div v-if="topIndex==index" style="position:absolute;left:0;bottom:0;height:2px;background-color:#fb322a;width:100%;"></div>
      </div>
    </div>
	  <div style="position: absolute;right: 5vw;top: 20vw;text-align:center;" @click="toGet()">
		  <img :src="$img('lingqu.png')" alt="" style="width: 32px;margin: 0 auto;">
		  <div style="font-size: 10px;color: rgb(253,254,255);">{{$t('领取')}}</div>
		  
	  </div>
	  <div style="flex-wrap: wrap;margin-top: 18vw;" class="flexs_r_c" v-if="topList.length">
		  <div v-for="(item,index) in getDataList()" @click="toInfo(item)" :key="item" style="position: relative;width: 48%;margin-top: 3vw;" :style="index%2==1?'margin-left:4%':''">
			  <img :src="item.TitleUrl" alt="" style="width: 100%;">
<!-- 			  <div style="color: white;position: absolute;width: 100%;bottom: 10px;font-size: 16px;text-align: center;">{{item.ActivityName}}</div>
 -->		  </div>
	  </div>
	  

  </div>
</template>

<script>
import { ref } from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {theme} from '@/views/theme'
	import { bus, busName } from "@/components/core/bus";
	
	
			let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default

export default {
  name: "Gift",
  components:{
	HomeNav
  },
  setup() {
    const activeNames = ref(["0"]);
    const themeVars = {
      cellVerticalPadding: 0,
      cellHorizontalPadding: 0,
      // collapseItemContentPadding: 0,
    };
    return { activeNames, themeVars };
  },
    computed: {
		...mapGetters("tcp", ["isLogged", "isConnect","info"]),
		
    ...mapGetters("global", [ "lobbyInfo"]),
  },
 created() {
 	var that = this
	bus.off(busName.lobbyInfo)
	bus.on(busName.lobbyInfo, (val) => {
    var list = that.lobbyInfo.ActivityConf.Rebate.concat(
      that.lobbyInfo.ActivityConf.Bonus
    );
	   list = list.sort((a, b) => b.Sort- a.Sort)
	   that.dataList = list

    
    for (var obj of list) {
      var hasContain = false;
      for (var newObj of that.topList) {
        if (newObj.ActivityClass == obj.ActivityClass) {
          hasContain = true;
        }
      }
      if (!hasContain) {
        that.topList.push(obj);
      }
    }
    that.topList = that.topList.sort((a, b) => a.Sort - b.Sort);
    console.log('tttt',that.topList)
	   
	})
	 if(that.lobbyInfo){
    var list = this.lobbyInfo.ActivityConf.Rebate.concat(
      this.lobbyInfo.ActivityConf.Bonus
    );		 
		 list = list.sort((a, b) => b.Sort- a.Sort)
		 that.dataList = list

    
    for (var obj of list) {
      var hasContain = false;
      for (var newObj of this.topList) {
        if (newObj.ActivityClass == obj.ActivityClass) {
          hasContain = true;
        }
      }
      if (!hasContain) {
        this.topList.push(obj);
      }
    }
    this.topList = this.topList.sort((a, b) => a.Sort - b.Sort);


	 }
 	
	//this.activeNames = 	[this.$route.params.id]
 },
 methods:{
  getDataList(){
  if(this.topList[this.topIndex].ActivityId=='q1'){
   
      return this.dataList
    }
    var className = this.topList[this.topIndex].ActivityClass
    var arr = []
      for(var obj of this.dataList){
        if(obj.ActivityClass==className){
          arr.push(obj)
        }
      }
      return arr
    },
	 toGet(){
		 if(!this.isLogged){
		 	this.$router.push({
		 		name: "login"
		 	});
		 	return
		 }
		 this.$router.push({
		 	name: 'getActivity'
		 			
		 
		 });
		 bus.emit('hiddeTab', {
		 	isShow: false
		 })
	 },
	 
	 toInfo(val){
		 this.$router.push({
		 	name: 'giftInfo',
			params: {
			
				data: JSON.stringify(val) 
				
			}
		 });
		 bus.emit('hiddeTab', {
		 	isShow: false
		 })
	 },
 },
 //  mounted() {
 //    // this.$refs["c1"].toggle(false);
	// var list = this.lobbyInfo.ActivityConf.Rebate.concat(this.lobbyInfo.ActivityConf.Bonus)
	
	// list = list.sort((a, b) => b.Sort- a.Sort)
	// this.dataList = list
	// console.log('121231232',this.dataList)
	// this.activeNames = 	[this.$route.params.id]
	
 //  },
  data() {
    return {
      dataList:[],
      topList: [],
	  topIndex:0
    };
  },
};
</script>

<style lang="scss" scoped>
.topList{	
	position: relative;
	text-align:center;
	padding:0px 10px;
  height:40px;
  line-height:40px;
	color:white;
	border-radius: 4px;
	font-size:12px;

	margin-top:10px;
  
  }

	.flexs_r_c::-webkit-scrollbar {
    display: none;
}
.flexs_r_c{
   /* 隐藏滚动条 */
     scrollbar-width: none; /* firefox */
     -ms-overflow-style: none; /* IE 10+ */
}
.con {
  width: 100%;
  object-fit: contain;
  
}
.main {
  margin-top: 10vh;
  position: relative;
  padding: 0 3vw;
  flex: 1;
  > .bottom {
    width: 100%;
    position: absolute;
    bottom: 0%;
  }
}
.content {
  object-fit: contain;

  border-bottom-left-radius: 11px;
  border-bottom-right-radius: 11px;
  padding: 6px;
  white-space: pre-wrap;
}
.content1{
  white-space: nowrap;
  overflow-y: auto;
  
  
  white-space: break-spaces;
  word-break: keep-all;
  // white-space: normal;
  // word-break: break-all;
  // word-wrap: break-word;
}
.content_border_blue {
  border-bottom: solid 1px #31c9ed;
  border-left: solid 1px #31c9ed;
  border-right: solid 1px #31c9ed;
}
.content_border_pink {
  border-bottom: solid 1px #ec808d;
  border-left: solid 1px #ec808d;
  border-right: solid 1px #ec808d;
}
.item {
  width: 97vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3vw;
}
.item_title {
  position: relative;
  width: 100%;
  text-align: center;
  img {
    display: block;
  }
  > .bg {
    width: 97vw;
    object-fit: contain;
  }
  > .logo {
    width: 16vw;
    position: absolute;
    top: 23%;
    left: 4%;
  }
  > .icon {
    width: 19vw;
    position: absolute;
    bottom: 6%;
    right: 1%;
  }

  > .text {
    position: absolute;
    top: 0%;
    bottom: 0%;
    left: 0%;
    right: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
      font-size: 12px;
      font-weight: bold;
      color: #31c9ed;
      transform: scale(0.9);
      position: relative;
      top: -6%;
    }
  }
}
</style>
<style>
/* .van-cell {
  padding: 0;
} */
.van-collapse-item__content {
  padding: 0 5px;
  /* object-fit: contain;
  border-bottom: solid 3px #31c9ed;
  border-left: solid 3px #31c9ed;
  border-right: solid 3px #31c9ed; */
}
</style>
<style>
	table {
		width: 100%;
		border-top: 1px solid black;
		border-left: 1px solid black;
	  }
	  table td,
	  table th {
		border-bottom: 1px solid black;
		border-right: 1px solid black;
		padding: 3px 5px;
	  }
	  table th {
		border-bottom: 1px solid black;
		text-align: center;
	  }
</style>