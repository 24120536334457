import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  computed: {
    ...mapGetters("pay", ["payInfo", "receiveBankList"]),
  },
  methods: {
    getPayMethod(merchant,methodType){
          for (let i in this.payInfo.payList){
            let item = this.payInfo.payList[i]
            if (item['MethodType'] == methodType && item['Merchant'] == merchant){
              return item
            }
          }
          return false
      }
  },
};