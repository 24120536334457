<template>
	<div v-if="isFactory">
		<router-view  />
	</div>
	<div v-else>
		<div class="load" v-if="!hasLoad">
			<Splash></Splash>
		</div> 
		<div v-if="hasLoad"> 
			<Nav></Nav>
		</div>	
		<router-view v-if="hasLoad"  class="mainBg"/>
		<bottomBar v-if="hasLoad"></bottomBar>
		<rightTool v-if="hasLoad"></rightTool>
		<message v-if="isLogged&&showMessage&&!isFresh" :selectIndex='messageSelectIndex' :messageList='messageList'
			@handleClose='showMessage=false'></message>
			<LiveChatWidget v-if="liveChatId" :license="liveChatId" group="0" />
	</div>

		
		
	<!-- 	
		<img :src="$img('dengl_2.png')" alt="" style="position: absolute;left: 0;top: 700px;width: 300px;">
		<img :src="$img('dengl_1.png')" alt="" style="position: absolute;right: 0;top: 700px;width: 300px;">
 -->	<!-- <bottomBar v-if="hasLoad"></bottomBar>
		<rightTool v-if="hasLoad"></rightTool>
	<div class="load" v-if="!hasLoad">
		<img :src="$img('loading.gif')" alt="">
	</div> -->
	<!-- <LiveChatWidget v-if="liveChatId" :license="liveChatId" group="0" /> -->
	
	
	<!-- <img  @click="showSport()" class="canClick" :src="$img('jingcai.png')" alt="" style="z-index: 999;;width: 200px;position: fixed;bottom: 50px;left: 30px;">
	
	<sportGame v-if="showSportGame" @handleClose='showSportGame=false'></sportGame>
 -->	
</template>

<script>
	
	import {
			mapGetters,
			mapActions,
			mapMutations
		} from "vuex";
		import {theme} from '@/views/theme'
		
		
		let Nav = require('@/views/'+ theme +'/components/Nav.vue').default
		let bottomBar = require('@/views/'+ theme +'/components/bottomBar.vue').default
		let message = require('@/views/'+ theme +'/components/message.vue').default
		let rightTool = require('@/views/'+ theme +'/components/rightTool.vue').default
		let Splash = require('@/views/'+ theme +'/Splash.vue').default

		import { LiveChatWidget } from '@livechat/widget-vue'
		
		
		let sportGame = require("@/views/" +
		  theme +
		  "/components/sportGame.vue").default;
		import {
			http
		} from "@/components/protocol/api";
		import log from "@/utils/logger";
		import bus from "@/utils/bus.js"
		import mqant from "@/components/protocol/mqantlib";
		import {
			req,
			qs
		} from "@/utils/request";
		import {
			topic
		} from "@/components/protocol/api";
	
		export default {
			name: "App",
			components: {
				Nav,
				bottomBar,
				message,
				rightTool,
				LiveChatWidget,
				sportGame,
				Splash
				
			},
			data() {
				return {
					showFloat:process.env.NODE_ENV != 'production',
					showSportGame:false,
					hasLoad: false,
					isLogin: false,
					banlace: '',
					showMessage: true,
					offset:0,
					messageList: [],
					messageSelectIndex: 0,
					isFresh:false,
					isFactory:false

				}
			},
			computed: {
				...mapGetters("tcp", ["isLogged","isConnect","notice"]),
				...mapGetters("user", ["token", "tcpInfo"]),
				...mapGetters("global", ["liveChatId","lobbyInfo"]),
	
				// ...mapGetters("global", ["globalState", "uuid","lobbyInfo"]),
				// ...mapGetters("user", ["token","userInfo","tcpInfo"]),
	
			},
			watch: {
				showMessage(newVal, oldVal) {
					
					// if(newVal&&this.isLogged) {
			
					// 	let cssStr = "overflow-y: auto; height: auto;";
					// 	document.getElementsByTagName('html')[0].style.cssText = cssStr;
					// 	document.body.style.cssText = cssStr;
					// } else {
					// 	let cssStr = "overflow-y: auto; height: auto;";
					// 	document.getElementsByTagName('html')[0].style.cssText = cssStr;
					// 	document.body.style.cssText = cssStr;
					// }
			
					// // 下面需要这两行代码，兼容不同浏览器
					// document.body.scrollTop = this.pageScrollYoffset;
					// window.scroll(0, this.pageScrollYoffset);
				},
				isLogged(newVal, oldVal) {
					
					// if(newVal&&this.showMessage) {
			
					// 	let cssStr = "overflow-y: auto; height: auto;";
					// 	document.getElementsByTagName('html')[0].style.cssText = cssStr;
					// 	document.body.style.cssText = cssStr;
					// } else {
					// 	let cssStr = "overflow-y: auto; height: auto;";
					// 	document.getElementsByTagName('html')[0].style.cssText = cssStr;
					// 	document.body.style.cssText = cssStr;
					// }
			
					// // 下面需要这两行代码，兼容不同浏览器
					// document.body.scrollTop = this.pageScrollYoffset;
					// window.scroll(0, this.pageScrollYoffset);
				},
			},
			created() {
				if(this.liveChatId){
					//console.log('ddd',this.liveChatId)
					
				}
				if(this.$route.path.indexOf('navigator')>=0){
			this.isFactory = true
			return 
		}
				window.addEventListener('scroll', this.handleScroll, true)

				
				var that = this
				window.onresize = () => {
					return (() => {
						window.fullWidth = document.documentElement.clientWidth;
						bus.emit('windowWidth', {
							windowWidth: document.documentElement.clientWidth
							
						})
						
					})()
				};
				bus.on('loginSuccess', () => {
					that.getNotice()
				})
				//var path = this.$route.path
				
				
				var that = this
				bus.off('toMessage')
				bus.off('noShowNotice')
				
				bus.on('toMessage', (val) => {
					if(val){
						that.messageSelectIndex = val.index
						
					}
					that.showMessage = true
				})
				
				bus.on('noShowNotice', (val) => {
					that.isFresh = true
				})		
				
				
				
				
				
				if (that.isConnect) {
					
					that.getNotice()
				}else {
				
				}
	
			},
			methods: {
				...mapActions("user", ["autoLogin"]),
				...mapActions("global", ["init", "getLobbyInfo", "versionGet"]),
				handleScroll() {
				//方法一
				var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
				//console.log(top)
				this.offset = top
				},
				showSport(){
						if(this.isLogged){
							this.showSportGame = true
						}else {
							bus.emit('showLogin')
						}
				},
				toTop(){
					document.documentElement.scrollTop = 0;
					
				},
				getNotice() {
					if(this.isFresh){
						return
					}
					
					var that = this
					that.showMessage = true
					
					mqant.request(topic.notice, {}, function(data, topicName, msg) {
						that.messageList = data.Data
					});
				},
	
			},
			 mounted() {
				if(this.$route.path.indexOf('navigator')>=0){
			this.isFactory = true
			return 
		}
				this.init().then((uuid) => {
					if (!this.isLogged) {
						this.autoLogin();
	
					}
				});
				var that = this
				 this.getLobbyInfo().then(response => {
					that.hasLoad = true
				});
				//show content
			},
		};
	
</script>

<style lang="scss" scoped>
	html, body {
	  margin: 0;
	  padding: 0;
	  height: 100%;
	  overflow-x: hidden;
		background-color: #070f26;
		
	}
	#app {
	  height: 100%;
	  overflow-x: hidden;
	  
	  background-color: #070f26;
	}
	.load {
		width: 100vw;
		height: 100vh;
		line-height: 100vh;
		text-align: center;

		img {
			width: 162px;
			// margin: 150px auto;
		}
	}
</style>
