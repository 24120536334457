<template>
	<div class="recommend" style="background-color:#44423f;" >
		
		<div style="width: 1200px;margin: 0 auto;">
			<div class="flexs_r" style="width: 100%;">
				<div style="width: 61.5%;position: relative;z-index: 88;">
					<img :src="$img('ty_bg_img.png')" alt="" style="width: 100%;position: absolute;top: 0;left: 0;">
					<img :src="$img('hot_pd.png')" alt="" style="width: 153px;position: absolute;left: -3px;top: -8px;z-index: 88;">
					
					<el-carousel ref="swiper"  :interval="3000" height="502px" indicator-position="none"
						arrow="never" style="width: 100%;">
						<el-carousel-item
						  v-for="item in hotGames"
						  
						  style=""
						>
						 <div style="width: 100%;text-align: center;position: relative;height: 100%;">
						 	<div style="color: #fdfeff;font-size: 24px;margin-top: 20px;">{{$t("比赛时间").toUpperCase()}}</div>
						 	<div style="color: #c4b69d;font-size: 20px;margin-top: 13px;">{{item.MatchDate}}&nbsp;GMT+8</div>
						 	
						 	
							
						 	<div class="flexs_sb" style="width: 90%;margin-top: 1vw;margin-left: 5%;">
						 		<div>
									<img :src="$img('HTeam.png')" alt="" style="width: 112px;">
									<div class="whiteWord" style="text-align: center;font-size: 20px;margin-top: 20px;">{{item.HomeTeamName}}</div>
									
								</div>
								
								<div style="width: 40%;">
									<div class="flexs_sb" >
										<div style="position: relative;">
											<img :src="$img('NumBG.png')" alt="" style="width: 82px;">
											<div style="color: white;font-size: 24px;position: absolute;left: 0;top: 0;width: 100%;line-height: 44px;text-align: center;">
												{{item.HdpOddsValueHomeFt}}
											</div>
										</div>
										<div style="color: #fdfeff;font-size: 24px;">HDP</div>
										<div style="position: relative;">
											<img :src="$img('NumBG.png')" alt="" style="width: 82px;">
											<div style="color: white;font-size: 24px;position: absolute;left: 0;top: 0;width: 100%;line-height: 44px;text-align: center;">
												{{item.HdpOddsValueAwayFt}}
											</div>
										</div>
									</div>
									<div class="flexs_sb" style="margin-top: 10px;">
										<div style="position: relative;">
											<img :src="$img('NumBG.png')" alt="" style="width: 82px;">
											<div style="color: white;font-size: 24px;position: absolute;left: 0;top: 0;width: 100%;line-height: 44px;text-align: center;">
												{{item.OuOddsValueHomeFt}}
											</div>
										</div>
										<div style="color: #fdfeff;font-size: 24px;">O/U</div>
										<div style="position: relative;">
											<img :src="$img('NumBG.png')" alt="" style="width: 82px;">
											<div style="color: white;font-size: 24px;position: absolute;left: 0;top: 0;width: 100%;line-height: 44px;text-align: center;">
												{{item.OuOddsValueAwayFt}}
											</div>
										</div>
									</div>
								</div>
								
								
								
								<div>
									<img :src="$img('ATeam.png')" alt="" style="width: 112px;">
									<div class="whiteWord" style="text-align: center;font-size: 20px;margin-top: 20px;">{{item.AwayTeamName}}</div>
									
								</div>
						 	</div>
						 	<div style="width: 210px;position: relative;margin: 70px auto;height:64px;border-radius: 8px;" class="mainBtnBG">
								<div style="line-height: 64px;font-size: 26px;" @click="toUrl()" class="canClick">{{$t("马上投注").toUpperCase()}}</div>
							</div>
						 	
						 </div>
						</el-carousel-item>
					</el-carousel>
					
					
				</div>
				<div style="width: 36%;position: relative;margin-left: 3%;">
					<img :src="$img('tzxq_img.png')" alt="" style="width: 100%;">
					<div style="width: 100%;height: 95%;z-index: 8;position: absolute;left: 0;top: 0;overflow: hidden;">
						<div style="color: #fdfeff;font-size: 24px;margin-top: 20px;width: 100%;text-align: center;">{{$t("投注详情").toUpperCase()}}</div>
						<div style="overflow: hidden;">
							<div  class="gameList"  :style="{'margin-top': tableTop + 'px'}">
								<div v-for="(item,index) in tzList" :key="index" class="flexs_sb" style="height: 58px;width: 90%;margin-left: 5%;border-bottom: 1px solid #787673;position: relative;" >
									<div style="width: 70%;">
										<div style="color:#f0f0f0;font-size: 14px;text-overflow:ellipsis;overflow: hidden;white-space: nowrap;width: 100%;">{{item.NickName.substr(0,2)+'***'+item.NickName.substr(item.NickName.length-2,2)}}</div>
										
										<div style="margin-top: 5px;color:#95a0b4;font-size: 14px;text-overflow:ellipsis;overflow: hidden;white-space: nowrap;width: 100%;">{{$t("投注了赛事")+' '+item.LeagueName}}</div>
										
									</div>
									<div  @click="toUrl()" style="font-size: 14px;width: 28%;height: 28px;border-radius: 8px;text-align: center;line-height: 28px;" class="canClick mainBtnBG">{{$t("跟注").toUpperCase()}}</div>
									
								</div>
							</div>
						</div>
						
						
					</div>
				</div>
			</div>
			<div class="flexs_sb" style="width: 90%;margin-left: 5%;margin-top: 30px;">
				<div style="position: relative;width: 22%;" v-for="(item,index) in hotImgList" class="canClick guang" @click="hotClick(item)"  @mouseenter='hoverIndex=index'
							@mouseleave="hoverIndex=-1">
					<img :src="$img('recommonHot.png')" alt="" style="width: 62px;position: absolute;left: -8px;top: 8px;">
<!-- 					<img :src="$img('wrapImg.png')" class="guang" alt=""  v-if="hoverIndex==index" style="width: 100%;position: absolute;top: 0;left: 0;">
 -->					
					<img :src="item.PUrl" alt="" style="width: 100%;">
					<!-- <div v-show="hoverIndex==index" class="mask">
						<div style="width: 80%;margin-left: 10%;text-align: center;background-color: #006ddd;border-radius: 12px;height: 40px;line-height: 40px;color: white;margin-top: calc(7vw - 20px);font-size: 20px;">{{$t('立即进入')}}</div>
					</div> -->
			</div>
				
					
			</div>
			
			<el-carousel ref="swiper" :loop="false" :interval="0" height="203" indicator-position="none"
				arrow="never" style="width: 1200px;margin-top: 30px;">
			
				<el-carousel-item v-for="(item,index) in 2" :key="item">
					<div style="position: relative;">
						<img v-if="index==0" @click="toDetail(0)" class="canClick" :src="$img('bottomBanner1.png')" alt="" style="width: 100%;">
						<!-- <div class="flexs_r_c" style="position: absolute;left: 350px;top: 140px;width: 500px;text-align: center;" :style="'padding-left:'+(250-numWidth/2)+'px'">
							<div v-for="item in imgList" :key='item'>
								<img :src="$img(item.img)" v-if='item.isNum'  alt="" style="width:42px;">
								<img :src="$img(item.img)" v-else  alt="" style="width:20px;">
							</div>
							
							
						</div> -->
					</div>
			
				</el-carousel-item>
			
			
			</el-carousel>
			
<!-- 			<img :src="$img('youhui_img.png')" alt="" style="width: 100%;margin-top: 30px;">
 -->		</div>
		
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import bus from "@/utils/bus.js"
	import {
		status,
		getApiLoginUrl,
		checkFactoryUrl
	} from "@/components/protocol/api"
	import { req, qs } from "@/utils/request";
		import  tools from "@/utils/tools";
		import { http } from "@/components/protocol/api";
	export default {
		data() {
			return {
				wrapStyle:{
					height:0
				},
				hotGames:[],
				currentIndex:0,
				hotIndex:0,
				hotImgList:[],
				showGif:false,
				jackpotData:'',
				tableTop:0,
				timer:null,
				tzList:[],
				timer2:null,
				hoverIndex:-1,
				imgList:[],
				numWidth:0
			}
		},
	
		components: {
			// HelloWorld
		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),
			...mapGetters("tcp", ["isLogged", "isConnect"]),
		
		},
		
		watch: {
			
		},
		created() {
			// var str = '2348267341'
			// for(var i=0;i<str.length;i++){
			// 	console.log('zzzz',str[i])
			// }
			this.getList()
			this.getJackpotData()
			this.hotGames = this.lobbyInfo.HotMatch
			this.hotImgList = this.sortData(this.lobbyInfo.HotRecommend) 
			var that = this;
			//this.getJackpotData()
			this.timer = setInterval(() => {
				that.imgList = []
				that.numWidth = 0
				that.getJackpotData()
			}, 30000)
			
			// <!--把window.onresize事件挂在到mounted函数上-->
			if (document.documentElement.clientWidth > 1200) {
				this.wrapStyle.height = document.documentElement.clientWidth / 1920 * 1037 + 'px'
			} else {
				this.wrapStyle.height = 1200.0 / 1920 * 1037 + 'px';
			
			}
			
				bus.on('windowWidth',(val)=>{
					if (val.windowWidth > 1200) {
						that.wrapStyle.height = val / 1920 * 1037 + 'px'
					} else {
						that.wrapStyle.height = 1200.0 / 1920 * 1037 + 'px';
					
					}
				})
				
			this.timer = setInterval(() => {
                that.changeIndex(1)  //你的方法
            }, 3000)
			
			var count = 0;
			      this.timer2 = setInterval(() => {
					  if( -(this.tableTop)>this.tzList.length*58 ){
						  this.tableTop = 0;
					  }else {
						  this.tableTop -= 1;
						  
					  }
			      }, 100);
			
			
		},
		beforeDestroy(){
				clearInterval(this.timer);
				this.timer = null
				
		},
		methods:{
			
			toDetail(index){
				if(index==0){
					// this.$router.push({
					// 	name: 'slot',
					
					
					
					// })
					// document.documentElement.scrollTop = 0;
					if(!this.isLogged){
								bus.emit('showLogin')
						return
					}
					let name = "apiDg"
					let url = getApiLoginUrl(name, "");
					//console.log('ssszz',url)
					this.$act.openPage(url);
				}else {
					this.$router.push({
						name: 'fishing',
					
					
					
					})
				}
			},
			async getList(){
					let params = {
					 BetDetail:'',
					  platformType: tools.platform(),
					}
					var that = this
					let rsp = await req.post(http.lobbyConf, qs.stringify(params));
					
					if (rsp.status == 200 && rsp.data.Code == 0) {
					  // this.onLogged(rsp.data.Data);
					  that.tzList = rsp.data.Data.BetDetail
					} else {
					 
					 }
			},
			async getJackpotData(){
					let params = {
					 JackpotData:'',
					  platformType: tools.platform(),
					}
					var that = this
					let rsp = await req.post(http.lobbyConf, qs.stringify(params));
					if (rsp.status == 200 && rsp.data.Code == 0) {
					  var jackpotNum = that.$tools.formatNum(rsp.data.Data.JackpotData.GrandJackpot) 
					  that.getCurrentNum(jackpotNum)
					} else {
					 
					 }
			},
			getCurrentNum(num){
				
				for(var i=0;i<num.length;i++){
					var str = num[i]
					var obj = {}
					
					if(str==','){
						this.numWidth += 20
						obj.img = 'douhao.png'
						obj.isNum = false
					}else if(str=='.'){
						this.numWidth += 20
						
						obj.img = 'dian.png'
						obj.isNum = false
					}else {
						this.numWidth += 42
						
						obj.img = 'num_'+ num[i]+ '.png'
						obj.isNum = true
						
					}
					this.imgList.push(obj)
				}
			},
			getXing(str){
				
				
				let text = ''
				for (let i = 0;i < str .length ;  i++) {
				
				 text += '*'
			
				}
				return text
				
			},
			hotClick(val){
				if(val.PType==''){
					return
				
				}else if(val.PType=='LinkUrl'){
					if(val.GameCode.length){
						window.open(val.GameCode)
						
					}
				}else if(val.PType=='Activity'){
					if(val.GameCode.length){
						this.$router.push({
							name: 'activity',
							params: {
								id:val.GameCode
							}
						
						
						})
					}else {
						this.$router.push('activity')
					}
				}
				else {
					if(val.PType=='LiveCasino'||val.PType=='Lottery'||val.PType=='LiveSports'){
						if(val.Factory.length){
							if(!this.isLogged){
								bus.emit('showLogin')
								
								return
							}
							let name = val.Factory;
							let url = getApiLoginUrl(name, val.GameCode?val.GameCode:"");
							this.$act.openPage(url);
						}else {
							
						}
					}else if(val.PType=='Card'||val.PType=='Fish'||val.PType=='Slot'){
						if(val.Factory.length){
							if(val.GameCode.length){
								if(!this.isLogged){
									bus.emit('showLogin')
									return
								}
								let url = getApiLoginUrl(val.Factory, val.GameCode);
								this.$act.openPage(url);
							}else {
								var name
								if(val.PType=='Fish'){
									name = 'fishing' 
								}else if(val.PType=='Slot'){
									name = 'slot'
									
								}else {
									name = 'chess'
									
								}
								this.$router.push({
									name: name,
									params: {
										FactoryName:val.Factory
									}
								})
							}
						}else {
							var name
							if(val.PType=='Fish'){
								name = 'fishing' 
							}else if(val.PType=='Slot'){
								name = 'slot'
								
							}else {
								name = 'chess'
								
							}
							this.$router.push({
								name: name,
							
							})
						}
					}
				}
			},
			hoverEvent(index){
				this.hotIndex = index
			},
			toUrl(){
				if(!this.isLogged){
								bus.emit('showLogin')

					return
				}
				
				let name = "apiCmd"
				let url = getApiLoginUrl(name, "");
				this.$act.openPage(url);
				
			},
			sortData(data) {
				var arr = []
				for (let key in data) {
					arr.push(data[key])
			
				}
			
				return arr.sort((a, b) =>  b.Sort - a.Sort)
			
			},
			changeIndex(type){
				var that = this
				clearInterval(this.timer);
				this.timer = null
				if(type==0){
					if(this.currentIndex==0){
						this.currentIndex=this.hotGames.length-1
						return
					}
					this.currentIndex --
				}else {
					if(this.currentIndex==this.hotGames.length-1){
						this.currentIndex=0
						return
					}
					this.currentIndex ++ 
				}
				 this.timer = setInterval(() => {
				                that.changeIndex(3)  //你的方法
				            }, 3000)
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.gameList::-webkit-scrollbar {
    display: none;
}
.gameList{
	 /* 隐藏滚动条 */
	   scrollbar-width: none; /* firefox */
	   -ms-overflow-style: none; /* IE 10+ */
}
	.recommend{
		position: relative;
		width: 100vw;
		min-width: 1200px;
		padding: 30px 0;
	}
	.toUrl:hover{
		color: white;
	}
	.content{
		position: absolute;
		top: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		left: 0;
		bottom: 0;
	}
	.select{
		width: 50%;
		height: 100%;
		animation: big .3s;
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			
		}
	}
	.redWrap{
		width: 310px;
		margin-left: 25px;
		border-radius: 10px;
		height: 140px;
		margin-top: 15px;
		background: linear-gradient(to right,rgb(180,40,45),rgb(201,54,68),rgb(147,36,40));
	}
	@keyframes small{
		from{width: 50%;}
		to{width: 12.5%;}
	}
	@keyframes big{
		from{width: 12.5%;}
		to{width: 50%;}
		
	}
	.guang:hover{
		scale: 1.1;
		
	}
	.nolmal{
		width: 12.5%;
		height: 100%;
		animation: small .5s;
		
		img{
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: left;
		}
	}
	.list{
		height: 40px;
		margin-top: 10px;
	}
	.double{
		background-color: black;
	}
	.single{
		background-color: rgb(55, 55, 57);
	}
	.topJ{
		background: linear-gradient(to right,rgb(152,119,69),rgb(243,216,129),rgb(152,119,69));
	}
	.mask{
		background-color: rgba(0, 0, 0, .55);
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;
		top: 0;
		left: 0;
		border-radius: 10px;
		.enter{
			width: 9vw;
			height: 2vw;
			line-height: 2vw;
			border-radius: 4px;
			text-align: center;
			font-size: 0.86vw;
			font-weight: bold;
		background-image: linear-gradient(to  top,#e0b626, #d7781e);
		color: white;
			margin-left: 1.3vw;
			margin-top: 6vw;
		}
	}
</style>