<template>
	<red-header :title="$t('权益说明')" :showBack="true" />

	<div class="flexs_colum"
		style="overflow-x: hidden;position: relative;z-index: 3;height: calc(100vh - 44px);padding: 3vw;">
		<div class="flexs_r_c">
			<div
				style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(242,211,143);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				1
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('绑定银行卡')}}</div>
		</div>
		<div style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;">
			{{$t('vipInfo1')}}
		</div>

		<div class="flexs_r_c" style="margin-top: 20px;">
			<div
				style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(242,211,143);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				2
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">THƯỞNG THĂNG CẤP</div>
		</div>
		<div
			style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
			Sau khi bạn đạt được tổng nạp theo yêu cầu thì hệ thống sẽ tự động tăng cấp và bạn cần vào mục VIP bấm vào hộp quà để nhận thưởng, tiền thưởng cần cược 1 vòng cược 
		</div>

		<div class="flexs_r_c" style="margin-top: 20px;">
			<div
				style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(242,211,143);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				3
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{'HOA HỒNG LỜI MỜI'.toUpperCase()}}</div>
		</div>
		<div
			style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
			VIP2 bắt đầu được thưởng ưu đãi này, tiền thưởng sẽ được cộng vào tài khoản mỗi tháng ngày 01 <br>
			ví dụ: Khi bạn giới thiệu người chơi đã thua 100 triệu trong tháng 03 và khi đó bạn đang là VIP2 thì bạn sẽ nhận được 1% của 100 triệu vào tháng 04
		</div>

		<div class="flexs_r_c" style="margin-top: 20px;">
			<div
				style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(242,211,143);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				4
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{'THƯỞNG HÀNG THÁNG'.toUpperCase()}}</div>
		</div>
		<div
			style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
			 Mỗi tháng bạn sẽ được số tiền thưởng tương ứng với cấp bậc của mình<br> 
				Ví dụ: Bạn đã là vip 4 thì mỗi tháng ngày 1 bạn sẽ nhận được  288.000 
		</div>

		<div class="flexs_r_c" style="margin-top: 20px;">
			<div
				style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(242,211,143);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				5Thau lỗ
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{'TRỢ CẤP THUA LỖ'.toUpperCase()}}</div>
		</div>
		<div
			style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
			Khi bạn không may mắn, chúng tôi sẽ đền bù dựa trên số tiền thua của bạn để động viên. Tiền đền bù sẽ được trả vào sau 0h thứ 2 mỗi tuần<br>
			Ví dụ: Bạn đã trở thành khách hàng VIP9. Nếu hôm nay bạn thua 10.000.000, bạn sẽ nhận được 40.000 đồng tiền động viên. Tiền trợ cấp cần nhận trong ngày thứ hai nếu không hệ thống sẽ hủy bỏ.
		</div>

		<div class="flexs_r_c" style="margin-top: 20px;">
			<div
				style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(242,211,143);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				6
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{'NẠP TIỀN BẢO LƯU CẤP'.toUpperCase()}}</div>
		</div>
		<div
			style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
			Mỗi tháng tiền nạp tích lũy của hội viên cần đạt theo yêu cầu của từng cấp, nếu không đạt yêu cầu sẽ bị hạ 1 cấp<br>
			Ví dụ: Bạn đã là VIP 3 thì mỗi tháng bạn cần phải nạp đủ  15.000.000 để bao lưu cấp, nếu không đạt yêu cầu thì bị hạ xuống VIP 2
		</div>

	</div>
</template>

<script>
	import {
		theme
	} from '@/views/theme'
	let RedHeader = require('@/views/' + theme + '/components/RedHeader.vue').default
	import {
		defineComponent,
		reactive
	} from 'vue'
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import mqant from "@/components/protocol/mqantlib";
	import bus from "@/utils/bus.js"
	import i18n from "@/locales/i18n";

	import {
		req,
		qs
	} from "@/utils/request";
	import {
		topic
	} from "@/components/protocol/api";

	export default {
		components: {
			RedHeader
		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),

		},
		destroyed() {
			window.removeEventListener('popstate', this.back, false); //false阻止默认事件
		},
		mounted() {
			if (window.history && window.history.pushState) {
					
					window.addEventListener('popstate', this.back, false); //false阻止默认事件
				}
			//this.getVipConfig()
		},
		methods: {
			back() {
					bus.emit('hiddeTab', {
						isShow: true
					})
				},
			change(e) {
				this.selectIndex = e
			},
			getVipConfig() {
				var that = this

				mqant.request(topic.getVIPConfig, {}, function(data, topicName, msg) {
					that.configData = data.Data

				});
			},
		},
		data() {
			return {
				selectIndex: 0,
				configData: '',
				titleArr: [this.$t("贵宾级别"), this.$t("有效投注"), this.$t("总充值"), this.$t("有效投注仍然存在"), this.$t("升级奖金"), this.$t(
					"每月奖金"), this.$t("贵宾护理专员")],
				titleArr2: [this.$t("贵宾级别"), this.$t("老虎机"), this.$t("捕鱼"), this.$t("体育"), this.$t("真人视讯"), this.$t(
					"棋牌")],
				headerType: ["BetNeed", "ChargeNeed", "KeepGradeNeed", "VipGetGold", "WeekGet"],

			};
		},
	};
</script>

<style scoped>
	.indicator {
		width: 2vw;
		height: 2vw;
		border-radius: 1vw;
		margin-left: 1vw;
	}

	.select {
		background-color: #e9a12e;
	}

	.nolmal {
		background-color: #2f2f2f;
	}

	/* .header {
		background-color: rgb(126, 103, 70);
		color: #d4bb78;
		width: 25%;
		height: 60px;
		line-height: 60px;
		text-align: center;
	
	} */

	.colum {
		color: white;
		height: 30px;
		line-height: 30px;
		text-align: center;
	}

	.borderRight {
		border-right: 1px solid black;
	}

	.double {
		background-color: rgb(24, 26, 31);

	}

	.single {
		background-color: rgb(17, 20, 26);

	}
</style>
