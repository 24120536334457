import {theme} from '@/views/theme'
	let LoginInput = require('@/views/'+ theme +'/components/LoginInput.vue').default
	let ImageBtn = require('@/views/'+ theme +'/components/ImageBtn.vue').default


import { http } from "@/components/protocol/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import tools from "@/utils/tools";

export default {
  components: { LoginInput, ImageBtn },
  name: "RegisterView",
  data() {
    return {
      account: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters("global", ["uuid","version"]),
    ...mapGetters("tcp", ["isLogged"]),
    ...mapGetters("user", ["userInfo"]),
  },
  
  methods: {
    ...mapActions("user", ["doLogin"]),
    ...mapActions("global", ["init"]),
    onBackClick() {
      // window.history.back()
      // console.log("go back");
      this.$router.push({ name: "home" });
    },
    onSubmit() {
      let params = {
        account: this.account,
        password: this.password,
        platform: tools.platform(),
        channel: "game",
        uuid: this.uuid,
        uuid_web: this.uuid,
      };
      this.$tools.loading();
      this.doLogin({
        params: params,
        url: http.userLogin,
      }).then(
        (resolve) => {
          this.$tools.dismissLoading();
          this.$router.push({
            name: "index",
          });
        },
        (reject) => {
          this.$tools.dismissLoading();
          this.$log.info(reject);
          this.$tools.toast(reject.ErrMsg);
        }
      );
    },
    onToRegister() {
      this.$router.push({ name: "register" });
    },
  },
  mounted() {
    this.init().then((uuid) => {
      if (this.isLogged) {
        this.$router.push({
          name: "index",
        });
      }
      this.account = this.userInfo.Account;
    });
  },
};