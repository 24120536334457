<template>
  <div class="nav_main">
<!-- 	 <img :src="$img('xinchun_img.png')"  style="height:18vw;position: absolute;left: 0px;bottom: 0;z-index: -1;"/>
 -->	 
	<div class="flexs_sb" style="width: 245px;margin: 0 auto;margin-bottom: 2vh;">
		<div v-for="(item,index) in list" @click="itemClick(index)" :key="index" :style="selectIndex==index?'border-bottom:2px solid rgb(223,185,113)':'border-bottom:none'" style="color:rgb(148,148,148) ;font-size: 16px;padding-bottom: 5px;">
			{{item}}
		</div>
	</div>
	<img :src="$img('phoneLOGO.png')"  style=";width: 32px;position: absolute;left: 10px;bottom: 1.8vh;"/>
	<div style="position: absolute;right: 20px;bottom: 1.5vh;color:rgb(148,148,148) ;" @click='toService()'>
		<div>Liên hệ</div>
		<img style='width: 18px;margin-top: 5px;' :src="$img('kf_icon.png')"  />
		
		
	</div>
	<img v-if="$tools.platform()=='h5_android'" :src="$img('download.png')" @click="download()"  style="width: 25px;position: absolute;left: 20px;bottom: 2.5vh;"/>
	<rightTool v-if='showService'></rightTool>
  </div>
</template>

<script>
	import { mapGetters, mapActions, mapMutations } from "vuex";
	import action from '@/components/core/action'
	import tools from '@/utils/tools'
	import {
		theme
	} from '@/views/theme'
	let rightTool = require('@/views/' + theme + '/components/rightTool.vue').default
	
import bus from '@/utils/bus';
export default {
	
  components: { rightTool },
  // props: {
	 //  selectIndex: {
	 //    type: Number,
	 //    default: -5,
	 //  },
    
  // },

  computed: {
   ...mapGetters("global", ["curVersion"]),
   ...mapGetters("global", ["lobbyInfo"]),
   ...mapGetters("tcp", ["isLogged", "wallet"]),
   
   
  },
  
  data() {
    return {
		showService:false,
      list: [
            'KHUYẾN MÃI',
			this.$t("转盘"),
			'VIP'
			 ],
			 selectIndex:-5
	  
    };
  },
  created() {
	if(this.$route.path=='/gift'){
		this.selectIndex=0
	}else if(this.$route.path=='/vip'){
		this.selectIndex=2
		
	}else if(this.$route.path=='/turntable'){
		this.selectIndex=1
		
	}
  },
  watch: {
  	$route(to, from) {
  		if (to.path == '/gift') {
  			this.selectIndex = 0
  		} else if (to.path == '/vip') {
  			this.selectIndex = 2
  		}else if (to.path == '/turntable'){
			this.selectIndex = 1
			
		}
  
  	}
  },
  mounted() {
     this.showService = false
  },
  methods:{
	  toService(){
		  this.showService = !this.showService
		  //action.openBrowser(this.lobbyInfo.CustomerInfo.customerLiveChat, '')
		  
		 // window.open(this.lobbyInfo.CustomerInfo.customerLiveChat)
		  
	  },
	  download(){
		  window.open(this.curVersion.UrlPath)
		  
	  },
      itemClick(index){
		  var name
		  if(index==2){
			  name = 'vip'
		  }else if(index==0){
			  name = 'gift'
		  }else {
			  name = 'turntable'
			  // this.$tools.toast(this.$t("即将上线"))
			   
			  if(!this.isLogged){
				this.$router.push({name: 'login'})
			  	return
			  }
		  }
		  this.selectIndex = index
		  
		  this.$router.push({name: name})
      }
  }
};
</script>

<style lang="scss" scoped>
.row {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
}
.btn {
  color: rgb(137,137,137);
  font-size: 15px;
  // -webkit-text-stroke: 0.3px #fff;
  font-family: SVN-Arial;
  display: inline-block;
  border: solid 1px #fff;
}
.act {
  border: solid 1px #ec808d;
  border-radius: 5px;
}
.space {
  width: 1vw;
  display: inline-block;
}
.list{
	width: 31vw;
	position: relative;
	
}
.leftMargin{
	margin-left: 1.75vw;
}
.topMargin{
	margin-top: 1vw;
}

.content{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	// right: 0;
	// bottom: 0;
}
.nolColor{
	color: rgb(232,192,122);
}
.seColor{
	color:white
}
.nav_main {
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  text-align: center;
  position: relative;
   height: 22vw;
  background-color: rgb(37, 37, 37);
  // padding: 2vw 2vw;
  //   > div {
  //     display: inline-block;
  //   }
}

</style>