import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import { Vue3Marquee } from "vue3-marquee";
	import "vue3-marquee/dist/style.css";
import { bus, busName } from "@/components/core/bus";
	import mqant from "@/components/protocol/mqantlib";
	import {
		req,
		qs
	} from "@/utils/request";
	import {
		theme
	} from '@/views/theme'
	import {
		topic
	} from "@/components/protocol/api";

	export default {
		name: 'Slider',
		// props: {
		//   sliderData: {
		//     type: Array,
		//     default: () => {
		//       return [{
		// 				Content: this.$t('欢迎来到88bet')
		// 			},

		// 		]
		//     }
		//   }
		// },
		components:{
			Vue3Marquee
		},
		data() {
			return {
				left: 0,
				duration:0,
				sliderData: [{
						Content: this.$t('欢迎来到88bet')
					},

				],
				moveTimer: null,
				path: this.$route.path
			}
		},
		computed: {
			...mapGetters("tcp", ["isLogged", "isConnect"]),
			...mapGetters("global", [ "lobbyInfo"]),

		},
		created() {
			
			var that = this
			bus.on('onConnected', () => {
					that.getNotice()
				
			})
			if(this.isConnect){
				this.getNotice()
				
			}else {
				
				if(theme=='pc1'){
					that.sliderData = [{
							Content: this.$t('欢迎来到88bet')
						}]
						return
				}
				that.sliderData =
						 this.lobbyInfo.LobbyNotice
					that.duration = 100
					
			}
			
			bus.on('tcdl',()=>{
				if(theme=='pc1'){
					that.sliderData = [{
							Content: this.$t('欢迎来到88bet')
						}]
						return
				}
				that.sliderData = this.lobbyInfo.LobbyNotice
				that.duration = 100
				
			})
		},
		

		methods: {
			
			getNotice() {
				var that = this
				mqant.request(topic.notice, {}, function(data, topicName, msg) {
					//if(data.Data.length){
						that.duration = 100
						
					that.sliderData = data.Data
					that.onStart()
					//}
				});
			},
			onClickTitle(index) { // 通知父组件点击的标题
				bus.emit('toMessage', {
					index: index
				})

			},
			onStop() { // 暂停滚动
				clearInterval(this.moveTimer)
			},
			onStart() { // 开始滚动
				//if (this.sliderData.length > 4) { // 超过4条开始滚动
				this.getSlide()
				//}
			},
			getSlide() {
				this.moveTimer = setInterval(() => {
					this.moveLeft()
				}, 5) // 1s移动200次
			},
			moveLeft() {
				if (this.left >= 320) {
					this.sliderData.push(this.sliderData[0])
					this.sliderData.splice(0, 1)
					// this.sliderData.push(this.sliderData.shift()) // 将第一条数据放到最后
					this.left = 0
				} else {
					this.left += 0.2 // 每次移动0.2px
				}
			}
		}
	}