<template>
	<casinoIframe v-if="isFactory"></casinoIframe>
	<div v-else style="width:100%;height:100%;">
		<div class="content_main" :style="contentStyle" style='background-color:rgb(54,53,53);'>
		
		
			<router-view v-slot="{ Component }">
				<keep-alive v-if="$route.meta.keepAlive">
					<component :is="Component" />
				</keep-alive>
				<component :is="Component" v-else />
			</router-view>
		</div>
		 
		<van-tabbar v-if="showTab" style="background-color:rgb(36,36,36);" class="tabbar" v-model="active" @change="onTabChange"
			:active-color="activeColor" inactive-color="#fff" route>
			<van-tabbar-item v-for="(item, index) in tabs" :key="index" :dot="item.dot" replace :to="item.to"
				@click="onTabItemClick(item)">
				<span>{{ item.name }}</span>
				<template #icon="props">
					<img :src="props.active && showActive ? item.active : item.inactive" />
				</template>
			</van-tabbar-item>
		</van-tabbar>
		<!-- <img @click="showSport()" class="canClick" :src="$img('jingcai.png')" alt="" style="z-index: 9999;;width: 80px;position: fixed;bottom: 80px;left: 10px;">
		<sportGame v-if="showSportGame" @handleClose='showSportGame=false'></sportGame>
	 -->	
		<div v-if="isIos">
			<div v-if="showDownload" style="z-index:9999;width:100vw;text-align: center;position: fixed;bottom:8vh;left:0;height:323px;border-top-left-radius: 5px;border-top-right-radius: 5px;background-color: white;">
				<div style="position: relative;width: 100%;">
					<img :src="$img('neirong_img.png')" alt="" style="width:296px;margin: 6px auto;">
					<img @click='showDownload=false' :src="$img('downloadClose.png')" alt="" style="width:12px;position: absolute;right:8px;top:8px">
					
				</div>
					
			</div>
			<img v-else @click='showDownload=true' :src="$img('xiazai_tishi.png')" alt="" style="width:121px;position: fixed;bottom:10vh;z-index:9999;left:calc(50vw - 60.5px);">
			
		</div>
			<Notice @close="showNotice=false" v-if="notice.length&&showNotice&&isLogged"></Notice>
	</div>

</template>

<script>
	import {
		ref
	} from "vue";
	import {
		theme
	} from '@/views/theme'
	 let Notice = require('@/views/' + theme + '/components/Notice.vue').default
	 let sportGame = require('@/views/' + theme + '/components/sportGame.vue').default
	 let casinoIframe = require('@/views/mobile5/casinoIframe.vue').default


	import {
		img
	} from "@/utils/img";
	import {
		bus,
		busName
	} from "@/components/core/bus";
	import tools from '@/utils/tools'
	import {
		Dialog
	} from 'vant';
	import 'vant/es/dialog/style';
	import '../../components/core/action.js'
	import action from '@/components/core/action'
	import native from '@/components/core/native'
	import mqant from "@/components/protocol/mqantlib";
import {
		req,
		qs
	} from "@/utils/request";
	import {
		topic
	} from "@/components/protocol/api";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	// import VConsole from 'vconsole';
	//  const vConsole = new VConsole();
	// or init with options
	export default {
		components: {
			
			[Dialog.Component.name]: Dialog.Component,
			Notice,
			sportGame,
			casinoIframe
		// 
		},
		name: "RootView",

		data() {
			return {
				showSportGame:false,
				
				showTab: true,
				showNotice: true,
				offset:0,
				showDownload:true,
				isIos:false,
				isFactory:false,
				contentStyle: {
					height: '92%'
				},
				tabs: [{
						name: this.$t("首页"),
						active: img("nav_icon_home_act.png"),
						inactive: img("nav_icon_home.png"),
						dot: false,
						to: "/home",
					},
					{
						name: this.$t("游戏").toUpperCase(),
						active: img("nav_icon_money_act.png"),
						inactive: img("nav_icon_money.png"),
						dot: false,
						to: "/game",
					},
					{
						name: this.$t("充值"),
						active: img("nav_icon_bank_act.png"),
						inactive: img("nav_icon_bank.png"),
						dot: false,
						to: "/recharge",
					},
					{
						name: this.$t("提现"),
						active: img("nav_icon_gift_act.png"),
						inactive: img("nav_icon_gift.png"),
						dot: false,
						to: "/withdraw",
					},
					{
						name: this.$t("个人"),
						active: img("nav_icon_person_act.png"),
						inactive: img("nav_icon_person.png"),
						dot: false,
						to: "/person",
					},
				],
				showActive: true,
			};
		},
		setup() {
			const active = ref(0);
			return {
				active,
			};
		},
		// watch:{
		// 	$route(to,from){
		// 		if(to.path=='/chess'){
		// 			this.selectIndex=2
		// 		}else if(to.path=='/slot'){
		// 			this.selectIndex=3
		// 		}else if(to.path=='/fishing'){
		// 			this.selectIndex=4

		// 		}

		// 	}
		// },
		mounted() {
			if(this.$route.path.indexOf('navigator')>=0){
			this.isFactory = true
			return 
		}
			//document.documentElement.webkitRequestFullScreen()
		if(window.plus){
		                plus.navigator.setStatusBarBackground('rgb(7, 15, 38)');
		            }
			if (this.$route.path == '/DiscountDraw' || this.$route.path == '/CheckWater' || this.$route.path ==
				'/UserInfo' || this.$route.path == '/CheckWater' || this.$route.path == '/GameRecord' || this.$route
				.path == '/BillRecord' || this.$route.path == '/Message' || this.$route.path == '/bindbank'||this.$route.path == '/vipRecord') {
				this.showTab = false;
				this.contentStyle.height = '100%'

			}


			bus.on('hiddeTab', (val) => {
				this.showTab = val.isShow
				if (this.showTab) {
					this.contentStyle.height = '92%'
				} else {
					this.contentStyle.height = '100%'

				}
			});
			bus.on(busName.homeChildView, (name) => {
				this.showActive = false;
			});
			// console.log(
			//   "mounted actve:" + this.active + ", showActive:" + this.showActive
			// );
			this.init().then((uuid) => {
				if (!this.isLogged) {
					this.autoLogin().then(
						(succ) => {
							this.$log.info("auto login :" + succ);
						},
						(reject) => {}
					);
				}
			});
			this.getLobbyInfo();
		},
		computed: {
			...mapGetters("global", ["lobbyInfo", "version", "staticPic"]),

			...mapGetters("tcp", ["isLogged", "notice"]),
			activeColor() {
				return this.showActive ? "rgb(222,189,154)" : "rgb(138,137,136)";
			},
		},
		created() {
			if(this.$route.path.indexOf('navigator')>=0){
			this.isFactory = true
			return 
		}
			//window.addEventListener('scroll', this.handleScroll, true)
			
			//console.log('eeee',window.navigator.standalone,navigator.standalone)
			if(tools.platform()=='h5_ios'){
				if(!window.navigator.standalone){
					this.isIos = true
					
				}
			}
			var that = this
			// if(this.lobbyInfo.hasInit){
			// 	console.log('111111')

			// }else {
			// 	console.log('222222')
			// }
			bus.on(busName.lobbyInfo, () => {

				var showDia = false
				var version
				var platform = tools.platform()
				var url
				if (that.version.indexOf('_') >= 0) {
					var currentVersion = that.version.split('_')[1]
					if (platform == 'ios') {
						if (parseInt(that.lobbyInfo.VersionGet.ios.VersionCode) > parseInt(currentVersion)) {
							url = that.lobbyInfo.VersionGet.ios.UrlPath
							showDia = true
							version = that.lobbyInfo.VersionGet.ios.VersionCode
						}
					} else if (platform == 'android') {
						
						if (parseInt(that.lobbyInfo.VersionGet.android.VersionCode) > parseInt(currentVersion)) {
							url = that.lobbyInfo.VersionGet.android.UrlPath

							showDia = true
							version = that.lobbyInfo.VersionGet.android.VersionCode

						}
					}

					if (showDia) {
						Dialog.confirm({
								title: that.$t("提示"),
								showCancelButton: false,
								message: that.$t("发现新版本") + version,
								confirmButtonText: that.$t("更新")
							})
							.then(() => {
								action.openBrowser(url, '')


							})
							.catch(() => {

							});
					}
				}



			})

		},
		methods: {
			...mapActions("user", ["autoLogin"]),
			...mapActions("global", ["init", "getLobbyInfo"]),
			handleScroll() {
			//方法一
			var top = Math.floor(document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset)
			//console.log(top)
			this.offset = top
			},
			showSport(){
					if(this.isLogged){
						this.showSportGame = true
					}else {
						this.$router.push({
							name: "login"
						});
					}
			},
			toTop(){
				document.documentElement.scrollTop = 0;
				
			},
			onTabChange(index) {
				// console.log("onTabChange:" + index);
				// console.log(this.active);
				
				if (!this.isLogged && (index == 1 || index == 2 || index == 4)) {
					this.$router.push({
						name: "login"
					});

				}
			},
			onTabItemClick(item) {
				if(item.to=='/person'&&!this.isLogged){
					this.$router.push({
						name: "login"
					});
					return
				}
				
				if(item.to=='/withdraw'||item.to=='/recharge'){
					var that = this
						
					mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
						
						if (!data.Data.myDouDouBT.length) {
							that.$router.push({
								name: 'bindbank'
							});
							bus.emit('hiddeTab', {
								isShow: false
							})
							return
						} 
					});
				}
				
				if (!this.showActive && item.name == "home") {
					this.$router.push({
						name: item.name
					});
				}
				this.showActive = true;
			},
		},
	};
</script>
<style scoped>
	.tabbar {
		height: 8vh;
		position: fixed;
		z-index: 888;
		bottom: 0vh;
		background-color: transparent;
	}

	.van-tabbar--fixed,
	.van-tabbar-item--active {
		background-color: transparent;
	}

	.van-tabbar--fixed {
		position: static;
	}

	.content_main {
		flex: 1;
		height: 92vh;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
	}

	.van-safe-area-bottom {
		padding-bottom: 0;
	}
</style>
<style scoped>
	#app {
		display: flex;
		flex-direction: column;

		overflow: hidden;
	}

	html,
	body {
		margin: 0;
		padding: 0;
		height: 100%;
		overflow: hidden;
		background-color: rgb(7, 15, 38);
	}
</style>
