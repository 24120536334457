<template>
	<div style="height: 100%;white-space: nowrap;overflow-y: auto;margin-top: 0px;" class="flexs_colum ">
		<div style="text-align: center;">
			<img :src="$img('ul1.png')" alt="" style="margin-right: 15px;width: 254px;vertical-align: middle;">
			<span style="color: rgb(233, 216, 140);vertical-align: middle;" class="bigSize">{{$t("账户总览")}}</span>
			<img :src="$img('ur1.png')" alt="" style="width:254px;margin-left: 15px;vertical-align: middle;">

		</div>
		<div class="flexs_sb" style="padding: 30px 100px;">
			<div style="text-align: center;">
				<div class="whiteWord smallSize">{{$t("余额")}}</div>
				<div class="whiteWord bigSize boldSize" style="margin-top: 10px;">{{$tools.formatNum2k(wallet.VndBalance)}}</div>
			</div>
			<div style="text-align: center;">
				<div class="whiteWord smallSize">{{$t("红利")}}</div>
				<div class="whiteWord bigSize boldSize" style="margin-top: 10px;">{{$tools.formatNum2k(wallet.BonusBalance)}}</div>
			</div>
			<div style="text-align: center;">
				<div class="whiteWord smallSize">{{$t("返水")}}</div>
				<div class="whiteWord bigSize boldSize" style="margin-top: 10px;">{{$tools.formatNum2k(wallet.AgentBalance)}}</div>
			</div>
		</div>
		<div style="text-align: center;">
			<img :src="$img('ul1.png')" alt="" style="margin-right: 15px;width: 254px;vertical-align: middle;">
			<span style="color: rgb(233, 216, 140);vertical-align: middle;" class="bigSize">{{$t("奖励申请")}}</span>
			<img :src="$img('ur1.png')" alt="" style="width:254px;margin-left: 15px;vertical-align: middle;">

		</div>

		<div class="flexs_sb" style="padding: 30px 0;width: 340px;margin: 0 auto;">
			<div @click="selectIndex=0" class="topBtn canClick bigSize boldSize" style=""
				:class="selectIndex==0?'select':'nolmal'">{{$t('红利申请')}}</div>

			<div @click="selectIndex=1" class="topBtn canClick bigSize boldSize"
				:class="selectIndex==1?'select':'nolmal'">{{$t('返水申请')}}</div>


		</div>



		<div class="flexs_r_c list" v-for="(item,index) in getList()" :key="item">
			<img :src="$img('uAc1.png')" alt="" style="position: absolute;left: 0;top:0;z-index: 1;width: 160px;">
			<div class="flexs_r_c" >
				<div class="topBtn  item middleSize boldSize" style="width: 160px;">
					{{item.ActivityName}}
				</div>
				<div class="   middleSize boldSize" style="color: rgb(242, 179, 62);margin-left: 40px;">{{$tools.formatNum2k(item.Get)}}</div>
				<div class=" lingqu canClick middleSize boldSize" @click="lingqu(item)">{{$t('领取')}}</div>
				
			</div>
			
		</div>

	</div>
</template>

<script>
	import userDiscount from "@/views/pc/userDiscount"
	
	export default {
		mixins: [userDiscount]
	}
</script>

<style lang="scss" scoped>
	.topBtn {
		color: white;
		width: 150px;
		height: 43px;
		text-align: center;
		line-height: 43px;
		border-radius: 21.5px;
	}

	.select {
		color: white;
		background-color: rgb(78, 78, 78);
		border: none;

	}

	.nolmal {
		background-color: rgb(38, 38, 38);
		color: rgb(200, 200, 200);
	}

	.item {
		height: 60px;
		line-height: 60px;
		
		position: relative;
		z-index: 99;
	}
	.list{
		width: 400px;
		margin: 0 auto;
		margin-top: 20px;
		position: relative;
		background-color: rgb(26, 23, 20);
		border: 1px solid rgba(245, 232, 155,.5);
		height: 60px;
		overflow: hidden;
	}
	.item2 {
		width: 180px;
		height: 50px;
		line-height: 60px;
		margin-top: 28px;
		margin-left: -5px;
		position: relative;
		z-index: 1;
		background-color: #282828;
	}

	.lingqu {
		width: 90px;
		height: 30px;
		color: white;
		text-align: center;
		border-radius: 15px;
		line-height: 30px;
		position: absolute;
		left: 300px;
		top: 15px;
		background-color: #f7af24;
	}
</style>
