<template>

	<div @touchmove.prevent @mousewheel.prevent @click="handleClose()" class="wrap"
		style="width: 100%;height: 100%;font-weight: bold;position: fixed;top: 0;left: 0;z-index: 9999;background-color: rgba(0, 0, 0, .7);text-align: center;">
		<div class="deepBG" @click.stop="" style="border: 1px solid rgb(41, 52, 72);border-radius: 20px;">
			<!-- <img :src="wrap" style="width: 386px;height: 461px;" alt=""> -->
			<div style="position: absolute;top: 0;left: 0;z-index: 999;width: 100%;height: 100%;">
				<img @click="handleClose()" class="canClick" :src="$img('guanbi_img.png')" alt=""
					style="width: 14px; position: absolute;right:15px;top:15px;z-index: 999;">


				<div style="font-size: 15px;font-weight: bold;padding-top: 18px;text-align: center;color:white;">
					{{$t("修改密码")}}</div>


				<div  style="margin-top: 80px;">
					<!-- <div class="inputWrap">
						<div style="position: relative;z-index: 99;height: 100%;top:0px;width: 100%;" class="flexs_r_c">
							<div style="height: 80%;width: 45%;text-align: center;border-right: 1px solid #3e4d6e;line-height: 40px;color: #9ea9bd;font-size: 12px;">{{$t('旧密码')}}</div>
							<input v-model="oldpw" :placeholder="$t('请输入旧密码')" :type="oldCanSee?'text':'password'" type="text"
								style="font-size: 12px;height: 100%;">
							
						
						</div>
					</div> -->
					<div class="inputWrap">
						<input v-model="oldpw" type="text" :placeholder="$t('请输入旧密码')" class="bigSize boldSize">
						
					</div>
					<div class="inputWrap">
						<input v-model="newPw" type="text" :placeholder="$t('请输入新密码')" class="bigSize boldSize">
						
					</div>
					<div class="inputWrap">
						<input v-model="confirmPw" type="text" :placeholder="$t('请再次输入密码')" class="bigSize boldSize">
						
					</div>
					<!-- <div class="inputWrap" style="margin-top: 35px;">
						
						<div style="position: relative;z-index: 99;height: 100%;top:0px;width: 100%;" class="flexs_r_c">
							<div style="height: 80%;width: 45%;text-align: center;border-right: 1px solid #3e4d6e;line-height: 40px;color: #9ea9bd;font-size: 12px;">{{$t('新密码')}}</div>
							<input v-model="newPw" :placeholder="$t('请输入新密码')" :type="oldCanSee?'text':'password'" type="text"
								style="font-size: 12px;height: 100%;">
							
						
						</div>
					</div> -->
					<!-- <div class="inputWrap" style="margin-top: 35px;">
						
						
						<div style="position: relative;z-index: 99;height: 100%;top:0px;width: 100%;" class="flexs_r_c">
							<div style="height: 80%;width: 45%;text-align: center;border-right: 1px solid #3e4d6e;line-height: 40px;color: #9ea9bd;font-size: 12px;">{{$t('确认密码')}}</div>
							<input v-model="confirmPw" :placeholder="$t('请再次输入密码')" :type="oldCanSee?'text':'password'" type="text"
								style="font-size: 12px;height: 100%;">
							
						
						</div>
					</div> -->
					<div class="mainBtnBG send canClick" @click="changePW">
						{{$t('提交')}}
					</div>
					
				</div>
			</div>


		</div>
	</div>






</template>

<script>
	import tools from "@/utils/tools";
			import {
				mapGetters,
				mapActions,
				mapMutations
			} from "vuex";
			import {
				http
			} from "@/components/protocol/api";
			import log from "@/utils/logger";
		
			import mqant from "@/components/protocol/mqantlib";
			import bus from "@/utils/bus.js"
		
		
			import {
				topic
			} from "@/components/protocol/api";
		
			import {
				getCurrentInstance
			} from 'vue'
			export default {
				data() {
					return {
						// wrap: this.$img("login_bg.png"),
						// close: this.$img("close.png"),
						// inputWrap: this.$img("inputWrap2.png"),
						// canSeeImg: this.$img("see.png"),
						// noSeeImg: this.$img("nosee.png"),
						oldPw: '',
						oldpw: '',
						oldCanSee: false,
						newCanSee: false,
						confirmCanSee:false,
						newPw: '',
						confirmPw: '',
						modelValue: true,
						mask: true,
						center: true,
						showClose: false,
						rules: {
							account: [{
								required: true,
								message: '请输入账号',
								trigger: 'blur'
							}],
							pw: [{
								required: true,
								message: '请输入密码',
								trigger: 'blur'
							}],
						}
					}
				},
				props: {
		
					diaLoginVisible: {
						type: Boolean,
						default: true
					}
				},
				computed: {
					...mapGetters("global", ["globalState", "uuid"]),
		
				},
				created() {},
				methods: {
					...mapActions("user", ["onLogged"]),
		
					handleClose() {
						this.$emit('handleClose')
		
					},
					showRegist() {
						this.$emit('showRegist')
					},
					 changePW() {
						
						if (!this.confirmPw.length || !this.oldpw.length || !this.newPw.length) {
		
							return
						}
						let params = {
							OldPwd: this.oldpw,
							NewPwd: this.newPw,
							ConfirmPwd: this.confirmPw,
		
						};
						var that = this
						mqant.request(topic.changePW, params, function(data, topicName, msg) {
								if (data.Code == 0) {
									that.$message({
										message: that.$t('修改密码成功！'),
										type: 'success'
									});
									that.handleClose()
		
		
								} else {
									that.$message({
										message: data.ErrMsg,
										type: 'error'
									});
								}
							
						});
				},
		
		
		
			}
			}
</script>

<style scoped lang="scss">
	.wrap {
		background-color: rgba(155, 155, 155, 0.2);
		opacity: 1;

	}

	input{
		//background-color: transparent !important;
		height: 100%;
		width: 100%;
		
		vertical-align: middle;
		
		padding-left: 15px;
		color: white;
	}

	p {
		
		color: white;
		font-size: 12px;
		text-align: center;
	}

	.redWord {
		color: red;

	}

	.blueWord {
		color: #31c9ed;

	}

	

	.leftImg {
		width: 13px;
		margin-left: 20px;
		margin-top: 0px;
	}

	.deepBG {
		/* background: url(@/assets/pc2/login_bg.png); */
		/* width: 386px; */

		/* max-width: 53.5vh;
		max-height: 70vh; */
		position: relative;
		top: 50%;
		width: 396px;
		height: 461px;
		margin: auto;
		background-color:#474443 ;
		transform: translateY(-52%);

	}
	.send {
		width: 200px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		border-radius: 15px;
		font-size: 15px;
	    margin: 30px auto;
		background-color:#0071df ;
	}
	.inputWrap{
		width: 336px;
		height: 46px;
		border-radius: 5px;
		
		text-align: left;
		margin: 20px auto;
		margin-bottom: 0;
		// display: flex;
		// flex-direction: row;
		align-items: center;
		position: relative;
		background-color: #303030;
		border: 1px solid #585449;
	}
	
</style>
