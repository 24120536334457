<template>
	<div class="flexs_colum" style="margin-top: 10px;padding: 20px 40px;">

		<div class="flexs_r_c" style="width: 100%;">
			<div class="flexs_colum">
				<span class="bigSize mainTitle" style="font-size: 14px;"> {{$t('开始时间').toUpperCase()}}：</span>
				<el-date-picker class="middleSize" style="height: 28px;width: 160px;margin-top: 10px;" size='large' :clearable='false'
					v-model="startDate" type="date" :placeholder="$t('选择日期').toUpperCase()" @change="dateChange">
				</el-date-picker>
			</div>
			<div class="flexs_colum" style="margin-left: 218px;">
				<span class="bigSize mainTitle" style="font-size: 14px;">{{$t('结束时间').toUpperCase()}}：</span>
				<el-date-picker class="middleSize" style="height: 28px;width: 160px;margin-top: 10px;" size='large' :clearable='false'
					v-model="endDate" type="date" :placeholder="$t('选择日期'.toUpperCase())" @change="endDateChange">
				</el-date-picker>
			</div>
			
		</div>
		<div class="flexs_sb" style="margin-top: 25px">
			<div class="topColum  boldSize" style="width: 100px;height: 45px;line-height: 45px;font-size: 14px;" :class="index==selectIndex?'mainBtnBG':'nolmal'"
				v-for="(item,index) in columData" @click="topClick(index)" :key="item">
				{{item.title}}
			</div>

		</div>
		<div class="flexs_r_c" style="margin-top: .5vw;flex-wrap: wrap;">
			<div class="flexs_r_c" v-for="(item,index) in columData[selectIndex].data" :key="item"
				style="width: 20%;margin-top: 8px">

				<img :src="$img('wxz_img.png')" @click="check(index)" v-if="!item.check" class="checkImg canClick"
					alt="">
				<img :src="$img('xz_img.png')" v-else @click="check(index)" class="checkImg canClick" alt="">
				<span v-if="item.check" style="margin-left: .5vw;color: #dedbdd;" class="smallSize">{{index+1}}.{{item[pin].toUpperCase()}}</span>
				<span v-else style="margin-left: .5vw;color: #afa194;" class="smallSize">{{index+1}}.{{item[pin].toUpperCase()}}</span>
				
			</div>

		</div>
		<div @click="getLSData()" class=" topColum middleSize boldSize mainBtnBG"
			style="width: 150px;height:45px ;margin: 20px auto;line-height: 45px;font-size: 14px;">
			{{$t('确认')}}</div>
			
			
		<div style="width: 100%;position: relative;height: 28px;" class="">
			<div style="position: absolute;right: 0;" class="flexs_r_c">
				<div v-for="(item,index) in timeArr" @click="setTimeSeach(index)" :key="item" class="canClick" :style="index<timeArr.length?'margin-right:10px':''" style="width: 80px;height: 28px;text-align: center;line-height: 28px;border-radius: 3px;font-size: 12px;" :class="timeSelectIndex==index?'selected':'nolmal'">
					{{item}}
				</div>
			</div>
			
		</div>	
			
		<div v-show="tableData.length>0" style="margin-top: 20px;">
			<!-- <el-pagination small  :page-size="query.pageSize" @current-change='currentChange'
				@size-change="changeSizeHandler" :current-page="query.pageNum" layout="prev, pager, next"
				:total="totalCount">
			</el-pagination> -->
			<div
				style="position: relative;width: 100%;margin: 0px auto;border: 1px solid #2e2d2c;">
				<div class="flexs_r_c" style="width: 100%;background-color: rgb(33,33,33);">
					<div v-for="(item,index) in 5" :key="index" :class="{ borderRight: index!=titleArr.length-1,one:index==0,two:index==1,three:index==2,four:index==3,five:index==4 }"
						class="header middleSize boldSize" >
						{{titleArr[index]}}
					</div>
			
				</div>
			
				<div  style="width: 100%;background-color: rgb(39,39,39);" class="flexs_colum" >
					<div v-for="(item,index) in tableData.slice((this.query.pageNum - 1) * this.query.pageSize, this.query.pageNum * this.query.pageSize)" :key="index" style="width: 100%;">
						<div class="flexs_r_c" :class="{ double: index % 2 === 0, single: index % 2 === 1}">
							<div class="one whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
								{{index+1}}
							</div>
							<div class="two whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
								{{ $tools.formatDate(item.startDate, "yyyy-MM-dd")  }}
								{{ $tools.formatDate(item.startDate, "hh:mm") }}
							</div>
							<div class="three whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
								{{ $tools.formatDate(item.endDate, "yyyy-MM-dd")  }}
								{{ $tools.formatDate(item.endDate, "hh:mm") }}
							</div>
							<div class="four whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
								{{ item.factory_name+'/'+item.game_type }}
							</div>
							<div class="five whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
								{{$tools.formatNum2k(item.valid_bet_amount)}}
							</div>
						</div>
						
					</div>
					<div class="flexs_r_c">
						<div class="one whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
							{{getSummaries(0)}}
						</div>
						<div class="two whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
							{{getSummaries(1)}}
						</div>
						<div class="three whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
							{{getSummaries(2)}}
						</div>
						<div class="four whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
							{{getSummaries(3)}}
						</div>
						<div class="five whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
							{{getSummaries(4)}}
						</div>
					</div>
				</div>
			
			
			</div>
			<div style="position: relative;">
				<el-pagination style="position: absolute;right: 0;" small :page-size="query.pageSize" @current-change='currentChange'
					@size-change="changeSizeHandler" :current-page="query.pageNum" layout="prev, pager, next"
					:total="totalCount">
				</el-pagination>
			</div>


		</div>
		<NoData v-show="tableData.length==0" style="margin-top: 100px;width: 100%;background-color: transparent;"></NoData>

	</div>
</template>

<script>
	import {theme} from '@/views/theme'
		
		let NoData = require('@/views/'+ theme +'/components/NoData.vue').default
	import moment from 'moment'
	import {
			req,
			qs
		} from "@/utils/request";
		import mqant from "@/components/protocol/mqantlib";
		import {
			mapGetters,
			mapActions,
			mapMutations
		} from "vuex";
		import bus from "@/utils/bus.js"
	
		import {
			topic
		} from "@/components/protocol/api";
	
		export default {
			components:{
				NoData
			},
			data() {
				return {
					query: {
						pageNum: 1,
						pageSize: 10
					},
					totalCount:0,
					timeArr:[this.$t('今日'),this.$t('昨日'),this.$t('一周'),this.$t('上月')],
					timeSelectIndex:0,
					titleArr:[this.$t('序号').toUpperCase(),this.$t('开始时间').toUpperCase(),this.$t('结束时间').toUpperCase(),this.$t('供应商').toUpperCase(),this.$t('流水').toUpperCase()],
					startDate: '',
					endDate: '',
					columData: [{
							title: this.$t('全部'),
							data: []
						},
						{
							title: this.$t('体育'),
							data: []
						},
						{
							title: this.$t('真人视讯'),
							data: []
						},
						{
							title: this.$t('棋牌'),
							data: []
						},
						{
							title: this.$t('老虎机'),
							data: []
						},
						{
							title: this.$t('捕鱼'),
							data: []
						},
						{
							title: this.$t('彩票'),
							data: []
						}
	
					],
					selectIndex: 0,
					factoryData: [],
					checkData: [
	
	
					],
					pin: '',
					tableWidth: '100%',
					
					
					tableData: [],
	
				}
			},
			computed: {
	
				...mapGetters("global", ["lobbyInfo"]),
				// ...mapGetters("user", ["token","tcpInfo"]),
				...mapGetters("tcp", [ "wallet","isLogged","isConnect"]),
			},
			props: {
	
				// diaLoginVisible:{
				// 	type:Boolean,
				// 	default:true
				// }
			},
			watch: {
	
			},
			mounted() {
	
			},
			created() {
				this.startDate = this.getDate()
				this.endDate = this.getDate()
				let language = (navigator.language || navigator.browserLanguage).toLowerCase()
				// if (language == 'zh-cn') {
				// 	this.pin = 'zh'
				// } else {
				// 	this.pin = 'vi'
				// }
				this.pin = 'vi'
				var that = this
				
				// this.columData[0].data = this.lobbyInfo.FactoryList
	
	
	
				for (let obj of this.lobbyInfo.FactoryList) {
					this.columData[0].data.push(obj)
					this.checkData.push(obj.Factory)
					obj.check = true
					if (obj.Factory.indexOf('LiveSports') >= 0) {
						this.columData[1].data.push(obj)
					} else if (obj.Factory.indexOf('LiveCasino') >= 0) {
						this.columData[2].data.push(obj)
					} else if (obj.Factory.indexOf('Card') >= 0) {
						this.columData[3].data.push(obj)
					} else if (obj.Factory.indexOf('Slot') >= 0) {
						this.columData[4].data.push(obj)
					} else if (obj.Factory.indexOf('Fish') >= 0) {
						this.columData[5].data.push(obj)
					} else if (obj.Factory.indexOf('Lottery') >= 0) {
						this.columData[6].data.push(obj)
					}
				}
				this.columData[0].data.splice(0, 1)
	
				this.checkData.splice(0, 1)
				
				var that = this
				bus.on('onConnected', () => {
						that.getLSData()
					
				})
				if(this.isConnect){
					this.getLSData()
					
				}
			},
			methods: {
				setTimeSeach(index){
					this.timeSelectIndex = index
					
					if(index==0){
						var obj = this.getToday()
						this.startDate = obj.starttime
						this.endDate = obj.endtime
					}else if(index==1){
						var obj = this.getYesterday()
						this.startDate = obj.starttime
						this.endDate = obj.endtime
					}else if(index==2){
						var obj = this.getLastWeekDays()
						this.startDate = obj.starttime
						this.endDate = obj.endtime
					}else if(index==3){
						var obj = this.getLastMonthDays()
						this.startDate = obj.starttime
						this.endDate = obj.endtime
					}
					this.getLSData()
					
				},
				// 获取今日的开始结束时间
				    getToday() {
				        let obj = {
				            starttime: '',
				            endtime: ''
				        }
				        obj.starttime = moment(moment().startOf("day").valueOf()).format("YYYY-MM-DD");
				        obj.endtime = moment(moment().valueOf()).format("YYYY-MM-DD");
				        return obj
				    },
				    // 获取昨日的开始结束时间
				    getYesterday() {
				        let obj = {
				            starttime: '',
				            endtime: ''
				        }
				        obj.starttime = moment(moment().add(-1, 'days').startOf("day").valueOf()).format("YYYY-MM-DD");
				        obj.endtime = moment(moment().add(-1, 'days').endOf('day').valueOf()).format('YYYY-MM-DD');
				        return obj
				    },
				  // 获取上一周的开始结束时间
				    getLastWeekDays() {
				        let obj = {
				            starttime: '',
				            endtime: ''
				        }
						var date = moment()
				        obj.starttime = date.subtract(7, 'days').format('YYYY-MM-DD') 
				        obj.endtime = moment(moment().valueOf()).format("YYYY-MM-DD");
				        return obj
				    },
					 // 获取上一个月的开始结束时间
					    getLastMonthDays() {
					        let obj = {
					            starttime: '',
					            endtime: ''
					        }
					        var date = moment()
					        obj.starttime = date.subtract(30, 'days').format('YYYY-MM-DD') 
					        obj.endtime = moment(moment().valueOf()).format("YYYY-MM-DD");
							return obj
					    },
					
				
				getSummaries(index) {
					var sum = []
					sum[0] = this.$t("总计");
					sum[1] = '/'
					sum[2] = '/'
					sum[3] = '/'
					var num = 0;
					this.tableData.forEach(function(item,index){
						
						num += item.valid_bet_amount
					})
					sum[4] = this.$tools.formatNum2k(num) 
					//console.log('1111',sum)
					return sum[index]
				 
				     },
				getLSData() {
					this.tableData = []
					var that = this
					var obj = {
						FactoryName: this.checkData,
						startDate: this.startDate + ' 00:00:00',
						endDate: this.endDate + ' 23:59:59'
					}
					console.log("xxxx",obj)
					
					mqant.request(topic.betRecord, obj, function(data, topicName, msg) {
						for (var i = 0; i < data.Data.BetRecord.length; i++) {
							var obj = data.Data.BetRecord[i]
							obj.index = i + 1
							obj.startDate = that.startDate + ' 00:00:00'
							obj.endDate = that.endDate + ' 23:59:59'
							that.tableData.push(obj)
						}
						that.totalCount = data.Data.BetRecord.length
	
					});
				},
				topClick(index) {
					this.selectIndex = index
					this.checkData = []
					for (var i = 0; i < this.columData[this.selectIndex].data.length; i++) {
						this.checkData.push(this.columData[this.selectIndex].data[i].Factory)
					}
				},
				getDate() {
					const nowDate = new Date();
					const date = {
						year: nowDate.getFullYear(),
						month: nowDate.getMonth() + 1,
						date: nowDate.getDate(),
					};
					const newmonth = date.month >= 10 ? date.month : "0" + date.month;
					const day = date.date >= 10 ? date.date : "0" + date.date;
					return date.year + "-" + newmonth + "-" + day;
				},
				dateFormat(dateData) {
					var date = new Date(dateData)
					var y = date.getFullYear()
					var m = date.getMonth() + 1
					m = m < 10 ? ('0' + m) : m
					var d = date.getDate()
					d = d < 10 ? ('0' + d) : d
					const time = y + '-' + m + '-' + d
					return time
				},
				endDateChange(val) {
					this.endDate = this.dateFormat(val)
				},
				dateChange(val) {
					this.startDate = this.dateFormat(val)
				},
				changeSizeHandler(e) {
					this.query.pageSize = size
				},
	
	
				currentChange(e) {
					this.query.pageNum = e
				},
				check(e) {
	
					this.columData[this.selectIndex].data[e].check = !this.columData[this.selectIndex].data[e].check
					if (this.columData[this.selectIndex].data[e].check) {
						this.checkData.push(this.columData[this.selectIndex].data[e].Factory)
					} else {
						for (var i = 0; i < this.checkData.length; i++) {
							var obj = this.checkData[i]
							if (obj.Factory == this.columData[this.selectIndex].data[e].Factory) {
								this.checkData.splice(i, 1)
							}
						}
	
					}
	
				}
	
	
	
			}
		}
	
</script>

<style  scoped>
	.topColum {
		width: 98px;
		height: 35px;
		border-radius: 5px;
		line-height: 35px;
		text-align: center;
		
		
		cursor: pointer;

	}

	.checkImg {
		width: 21px;
	}

	.selected {
		/* color:white ; */
		background-color: #d4ac7e;
	}

	.nolmal {
		background-color: #3b3b3a;
		color: #dedbdd;

	}
	.vxe-cell--label{
		
	}
	.borderRight {
		border-right: 1px solid #2e2d2c;
		border-bottom: 1px solid #2e2d2c;
		
	}
	
	.double {
		background-color: rgb(32, 43, 69);
	
	}
	
	.single {
		background-color: rgb(32, 43, 69);
	
	}
	.header {
		/* background-color: rgb(126, 103, 70); */
		color: #fdfeff;
		width: 20%;
		height: 40px;
		line-height: 40px;
		text-align: center;
	
	}
	.one{
		width: 10%;
	}
	.two{
		width: 20%;
	}
	.three{
		width: 20%;
	}
	.four{
		width: 25%;
	}
	.five{
		width: 25%;
	}
	/* :deep.el-table__footer-wrapper tbody td.el-table__cell{
	  background-color: #333333 !important;
	  color: #b9b9b9;
	}
	:deep.el-table .el-table__footer-wrapper .cell{
	    background-color: #333333 !important;
		color: #b9b9b9;
		
	}
	
 */

</style>
