<template>
  <div class="root">
    <div class="wrapper">
      <div class="logo"></div>
      <div class="spinner"></div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.root {
  background: #000;
  height: 100vh;
  opacity: 1;
  position: absolute;
  width: 100%;
  left: 0;
  overflow: hidden;
  top: 0;
  z-index: 9999;

  .wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;

    .logo {
     
     
      background: url('@/assets/pc5/hdbet_splash.png') no-repeat center transparent;
      background-size: contain;

      margin:0 auto;
      // margin-top:calc(50vh - 53);
       height: 123px;
       width:733px;
       
    }

    .spinner {
      --loading-v1-spinner-top: calc(50% + 1px);
      --loading-v1-spinner-position: absolute;
      --loading-v1-spinner-height: 48px;
      --loading-v1-spinner-width: 48px;
      --loading-v1-spinner-border-width: 8px;
      bottom: 0;
      display: block;
      overflow: hidden;
      right: 0;
      text-indent: -9999px;
      top: var(--loading-v1-spinner-top);
      animation: 1.1s linear infinite loadingspinnerrotate;
      border: var(--loading-v1-spinner-border-width) solid rgba(255, 204, 0, .2);
      border-left: var(--loading-v1-spinner-border-width) solid #dbab7b;
      border-radius: 50%;
      height: var(--loading-v1-spinner-height);
      margin: auto;
      outline: rgba(255, 255, 255, 0) solid 1px;
      position: var(--loading-v1-spinner-position);
      transform: translateZ(0);
      width: var(--loading-v1-spinner-width);
      z-index: 9999;
      left: 0;
    }
  }

  @keyframes loadingspinnerrotate {
    0% {
      transform: rotate(0);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>