<template>
  <home-nav active="live" />
  <div style="display: flex;flex-direction: row;flex-wrap: wrap;padding: 0 3vw;">
	  <div style="width: 46vw;position: relative;" :class="{ leftMargin:index%2==1  }" v-for="(item,index) in showList" @click="onClick(item)">
		  <van-image :src="$img(item.bg)" :class="{ img_gray: isGrey(item) }" />
		 <!-- <div class="icon">
		    <van-image
		      :src="$img(item.icon)"
		      height="90%"
		      :class="{ img_gray: isGrey(item) }"
		      fit="scale-down"
		    />
		  </div> -->
		  <div class="maintena" v-if="isGrey(item)">
		    <img :src="$img('warn.png')" />
		    <div v-if="factoryStatus(item) == apiStatus.pre">
		      {{ $t("即将上线") }}
		    </div>
		    <div v-else>{{ $t("正在维护") }}</div>
		  </div>
	  </div>
  </div>
  <!-- <van-grid :border="false" :column-num="2" :gutter="0">
    <van-grid-item v-for="item in showList">
      <div class="panel" @click="onClick(item)">
        <van-image :src="$img(item.bg)" :class="{ img_gray: isGrey(item) }" />
        <div class="icon">
          <van-image
            :src="$img(item.icon)"
            height="90%"
            :class="{ img_gray: isGrey(item) }"
            fit="scale-down"
          />
        </div>
        <div class="maintena" v-if="isGrey(item)">
          <img :src="$img('warn.png')" />
          <div v-if="factoryStatus(item) == apiStatus.pre">
            {{ $t("即将上线") }}
          </div>
          <div v-else>{{ $t("正在维护") }}</div>
        </div>
      </div>
    </van-grid-item>
  </van-grid> -->
</template>

<script>
	import {theme} from '@/views/theme'
		
		let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default
		
	import home from "../../mobile/home";
	
	export default {
	  components: { HomeNav },
	  name: "LiveView",
	  mixins: [home],
	  data() {
	    return {
	      curType: "LiveCasino",
	      list: [
	        {
	          id: "apiAwc",
	          bg: "mLiveCasino_apiAwc.png",
	          icon: "live_ae_icon.png",
	        },
	        {
	          id: "apiDg",
	          bg: "mLiveCasino_apiDg.png",
	          icon: "live_dg_icon.png",
	        },
	        {
	          id: "apiWm",
	          bg: "mLiveCasino_apiWm.png",
	          icon: "live_wm_icon.png",
	        },
	        {
	          id: "apiXg",
	          bg: "mLiveCasino_apiXg.png",
	          icon: "live_xg_icon.png",
	        },
	        {
	          id: "apiBg",
	          bg: "mLiveCasino_apiXg.png",
	          icon: "live_bg_icon.png",
	        },
	        {
	          id: "apiSa",
	          bg: "mLiveCasino_apisa.png",
	          icon: "live_sa_icon.png",
	        },
	        {
	          id: "apiYb",
	          bg: "mLiveCasino_apiyb.png",
	          icon: "live_yb_icon.png",
	        },
	        {
	          id: "apiAllBet",
	          bg: "mLiveCasino_apiob.png",
	          icon: "live_ab_icon.png",
	        },
	      ],
	    };
	  },
	  computed: {
	    showList() {
	      let res = [];
	      for (let i = 0; i < this.list.length; ++i) {
	        let item = this.list[i];
	        res.push(item);
	      }
	      return res;
	    },
	  },
	};
</script>
<style lang="scss" scoped>
.panel {
  position: relative;
  > .icon {
    position: absolute;
    top: 0%;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // > img {
    //   width: 65%;
    // }
  }
}
.leftMargin{
	margin-left: 2vw;
}
.maintena {
  position: absolute;
  top: 16%;
  width: 100%;
  text-align: center;
  > img {
    width: 11vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  > div {
    margin-left: auto;
    margin-right: auto;
    background-color: #ffd21e;
    border-radius: 7px;
    width: 80%;
    border: solid 2px #373737;
    padding: 7px 0;
    margin-top: 1%;
    font-weight: bold;
    white-space: pre-wrap;
  }
}
</style>

<style lang="scss" >
</style>