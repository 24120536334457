	import axios from "axios"; // 引入axios
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	export default {
		components: {

		},
		data() {
			return {
				wordText: '',
				selectIndex: 0,
				content:'',
				topDatas: [this.$t('关于我们'), this.$t('联系我们'), 'eCOGRA', this.$t('条款和规则'), this.$t('公平派彩'), ]
			}
		},
		computed: {
			...mapGetters("global", [ "lobbyInfo"]),

		},

		created() {
			// this.getWordText();
			this.readyContent(0)

		},
		methods: {
			readText(path) {
			  axios.get(path).then((rsp) => {
			    if (rsp.status == 200) {
			      this.content = rsp.data;
			    }
			  });
			},
			readyContent(index) {
				this.selectIndex = index
			  switch (this.selectIndex) {
			    case 0:
			      this.readText("./static/about.txt");
			      break;
			    case 2:
			      this.readText("./static/eCOGRA.txt");
			      break;
			    case 3:
			      this.readText("./static/termsConditions.txt");
			      break;
			    case 4:
			      this.readText("./static/fair.txt");
			      break;
			  }
			},
			getClass(index) {
				var classStr = ''
				if (index > 0) {
					classStr += "border "
				}
				if (index == this.selectIndex) {
					classStr += 'select'
				} else {
					classStr += 'nolmal'
				}
				return classStr
			}

		},

	};