<template>
  <div class="notice_main">
    <div class="notice">
      <img :src="$img('float_panel.png')" />
      <div class="n_content">
        <img
          :src="$img('icon_close.png')"
          class="n_close"
          @click="closePanel"
        />
        <div class="n_title">{{ title }}</div>
        <div class="n_msg">
          {{ msg }}
        </div>
      </div>
    </div>
  </div>
  <div
    class="panel"
    @click="
      onTitleClick();
      showNotice = false;
    "
  ></div>
</template>

<script>
export default {
  props: {
    title: String,
    msg: String,
    close: Function,
  },
  data() {
    return {};
  },
  methods: {
    closePanel() {
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.icon_copy {
  width: 4vw;
  object-fit: contain;
  margin-left: 3%;
}
.panel {
  position: fixed;
  top: 0%;
  width: 100vw;
  height: 100vh;
  z-index: 90;
}
.notice_main {
  position: fixed;
  // background-color: grey;
  width: 100%;
  height: 100%;
  // padding: 30vh 0 0 22vw;
  z-index: 99;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .notice {
    position: relative;
    width: 57vw;
    .n_close {
      position: absolute;
      top: 1%;
      right: 2%;
      padding: 5%;
      width: 4vw;
    }
    > img {
      width: 57vw;
    }
    .n_content {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0%;
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      .n_title {
        margin-top: 4%;
        font-family: SVN-Arial;
        font-size: 15px;
        font-weight: bold;
        color: #fff;
      }
      .n_msg {
        font-family: SVN-Arial;
        font-size: 12px;
        line-height: 1.53;
        color: #65c6e9;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
      }
    }
  }
}
</style>