
import { Toast } from "vant";
import { http, topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import pay from "@/views/mobile/pay";
	let MyDialog = require('@/views/'+ theme +'/components/MyDialog.vue').default
import { mapGetters, mapActions, mapMutations } from "vuex";
import { bus, busName } from "@/components/core/bus";
import {theme} from '@/views/theme'

export default {
  components: { MyDialog },
  mixins: [pay],
  
  data() {
    return {
      amount: "",
      amountValue: "0",
      account: localStorage.getItem('rechangeAccount'),
      bankName: "DUO YIN BANK TEST",
      bankCard: "1101 1011 0110",
      code: "",
      banks: ["name1", "name2", "name3"],
      curBank: {
        AccountName: "",
        BankName: "",
        CardNumber: "",
      },
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
	  actions:[]
    };
  },
  watch: {
    amount(n, o) {
      this.amountValue = this.$tools.formatNum(n * 1000);
    },
  },
  computed: {
	  ...mapGetters("tcp", [ "isConnect"]),
	  
  },
  
  mounted() {
	  
	  bus.on('requestPayInfo',(val)=>{
		 // console.log(this.payInfo.receiveBankList)
		  this.curBank = this.payInfo.receiveBankList[0];
		  
	  })
	  bus.off('freshCharge')
	  bus.on('freshCharge',(val)=>{
	  		 // console.log(this.payInfo.receiveBankList)
	  		   this.getPayInfo()
	  		  
	  })
	  
	  if(this.isConnect){
		  this.getPayInfo()
	  }
    if (this.payInfo.receiveBankList.length > 0) {
      this.curBank = this.payInfo.receiveBankList[0];
    }
  },
  
  methods: {
	  ...mapActions("pay", ["requestDoudouBtList", "requestPayInfo"]),
	  getPayInfo() {
	  	var that = this
		
	  	mqant.request(topic.payInfo, {}, function(data, topicName, msg) {
			that.code = data.Data.code
			that.curBank = data.Data.receiveBankList[0];
			
			for(var i=0;i<data.Data.receiveBankList.length;i++){
				var obj = data.Data.receiveBankList[i]
				var obj1 = {"name":obj.BankName}
				that.actions.push(obj1)
			}
			
	  	});
	},	
    copyText(text) {
      this.$tools.copyText(text).then(
        (succ) => {
          Toast(this.$t("复制成功"));
        },
        (err) => {
          Toast("Can not copy");
        }
      );
    },
	onSelect(val){
		
		  for (let i in this.payInfo.receiveBankList) {
		    let item = this.payInfo.receiveBankList[i];
			
		    if (item.BankName == val.name) {
		      this.curBank = item;
			  
		    }
		  }
		  this.showPicker = false;
		  
	},
	
    onConfirm(value) {
	this.curBank = value;
      for (let i in this.payInfo.receiveBankList) {
        let item = this.payInfo.receiveBankList[i];
		
        if (item.BankName == value) {
          this.curBank = item;
		  
        }
      }
      this.showPicker = false;
    },
    getMethod() {
      return this.getPayMethod("Official", "bank");
    },
    onSubmit() {
      if (this.amount <= 0) {
        return;
      }
      let method = this.getMethod();
      if (!method) {
        this.$log.warn("not found pay method of bank transfer");
        return;
      }
      let that = this;
      that.$tools.loading();
	  var obj =  {
          saveType: "",
          amount: this.amount * 1000,
          methodId: method.MethodId,
          accountName: this.account,
          receiveId: this.curBank.Oid,
        }
		//console.log('sqq',obj)
		if(theme=='mobile3'){
			obj.accountName = "default"
		}
      mqant.request(
        topic.payCharge,
        obj,
		
        function (res) {
			console.log('66666',res)
          that.$tools.dismissLoading();
          if (res.Code == 0) {
           // that.showDialog = true;
			that.$tools.toast(res.ErrMsg);
			
            // that.dialogTitle = that.$t("提示");
            // that.dialogMsg = res.ErrMsg;
			localStorage.setItem('rechangeAccount', that.account)
			that.amount = ''
			// setTimeout(() => {
			//   that.showDialog = false;
			// }, 2000)
          } else {
            that.$tools.toast(res.ErrMsg);
          }
        }
      );
    },
  },
};