<template>
	<div class="" style="width: 100%;min-width: 1200px;">
		<div style="height: 410px;background-color: #343230;width: 100%;padding-top: 30px;">
			<div class="flexs_sb" style="width: 800px;margin: 0px auto;position: relative;">
				<div style="width: 196px;position: relative;">
					<img :src="$img('shuom_ck_img.png')" alt="" style="width: 100%;position: absolute;z-index: 0;left: 0;top: 0;">
					<div style="position: relative;z-index: 9;">
						<div style="color:#c4af86;font-size: 10px;margin-top:80px;margin-left: 129px;">{{$t("秒").toUpperCase()}}</div>
						<div style="margin: 0 auto;width: 150px;white-space: nowrap;white-space: break-spaces;word-break: keep-all;color: #c4af86;font-size: 20px;margin-top: 25px;text-align: center;">{{$t("充值").toUpperCase()}}</div>
						
					</div>
								
				</div>
				<div style="width: 196px;position: relative;">
					<img :src="$img('shuom_js_img.png')" alt="" style="width: 100%;position: absolute;z-index: 0;left: 0;top: 0;">
					<div style="position: relative;z-index: 9;">
						<div style="color: #c4af86;font-size: 10px;margin-top:80px;margin-left: 129px;">{{$t("秒").toUpperCase()}}</div>
						<div style="margin: 0 auto;width: 150px;white-space: nowrap;white-space: break-spaces;word-break: keep-all;color: #c4af86;font-size: 20px;margin-top: 25px;text-align: center;">{{$t("提现").toUpperCase()}}</div>
						
					</div>
								
				</div>
				<div style="width: 196px;position: relative;">
					<img :src="$img('shuom_hz_img.png')" alt="" style="width: 100%;position: absolute;z-index: 0;left: 0;top: 0;">
					<div style="position: relative;z-index: 9;">
						<div style="color: #c4af86;font-size: 10px;margin-top:80px;margin-left: 129px;">{{$t("家").toUpperCase()}}</div>
						<div style="margin: 0 auto;width: 150px;white-space: nowrap;white-space: break-spaces;word-break: keep-all;color: #c4af86;font-size: 20px;margin-top: 25px;text-align: center;">{{$t("合作").toUpperCase()}}</div>
						
					</div>
								
				</div>
			</div>
			
			<div class="flexs_sb" style="width: 80vw;min-width: 1000px;margin: 0px auto;position: relative;margin-top: 80px;">
				<img :src="$img('bottom_ad.png')" alt="" style="width: 100%;">
			</div>
			
			
		</div>
		<div style="height: 345px;background-color: #282625;padding-top: 20px;text-align: center;position: relative;">
			<div class="flexs_sb" style="width: 600px;color: #ab9d86;font-size: 18px;margin: 0px auto;">
				<div class="canClick" @click="toPage(0)">{{$t("关于我们")}}</div>
				<div class="canClick" @click="toPage(1)">{{'CHÍNH SÁCH BẢO MẬT'.toUpperCase()}}</div>
				<div class="canClick" @click="toPage(2)">{{$t("常见问题").toUpperCase()}}</div>
			</div>
			<div style="background-color: rgb(100,100,100);height: 1px;width: 100%;margin-top: 20px;"></div>
			<img :src="$img('cs_logo_img.png')" alt="" style="width: 1155px;margin-top: 50px;">
		<!-- 	<img :src="$img('dibu_zuo1.png')" alt="" style="width: 808px;position: absolute;bottom: 0;left: 0;">
			<img :src="$img('dibu_you2.png')" alt="" style="width: 1397px;position: absolute;bottom: 0;right: 0;">
 -->			
			<div style="font-size: 14px;color: #787674;margin-top: 50px;">Copyright @ HDBet88</div>
			<div style="color: #787674;text-align: center;margin-top: 8px;" class="smallSize">V{{version}}</div>
			
		</div>
		
	</div>
	
</template>

<script>
	import { mapGetters, mapActions, mapMutations } from "vuex";
		
		export default {
			data() {
				return {
	
		
				}
			},
			computed:{
				...mapGetters("global", ["version"]),
				
			},
			created() {
			},
			methods: {
				toPage(index){
					
						var selectIndex
						if(index==0){
							selectIndex = 0
						}else if(index==1){
							selectIndex =5
						}else {
							selectIndex = 1
						}
						document.documentElement.scrollTop = 0;
						this.$router.push({
							name: 'aboutUs1',
							params: {
								selectIndex:selectIndex
							},
						});
						
				},
				openUrl(index){
					if(index==0){
						window.open('https://www.acmilan.com/it')
					}else {
						window.open('https://sevillafc.es/en')
					}
				}
		
			}
		}
</script>

<style lang="scss" scoped>
	.wrap{
		
		display: flex;
		min-width: 1200px;
		flex-direction: row;
		padding-bottom: 8px;
		margin-top: 0;
		
		.ad{
			padding-bottom: 5px;
			margin-left: 8.6vw;
			margin-top: 15px;
			margin-right: 8.6vw;
			border-top: 1px solid white;
			border-bottom: 1px solid white;
			color: white;
		}
	}
	.canClick:hover{
		color: #dfdddb;
	}
	
</style>