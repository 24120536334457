import { status, getApiLoginUrl } from "@/components/protocol/api";
import { img } from "@/utils/img";
export default {
  props: {
    imgList: Array,
    onChange: Function,
    act: Number,
  },
  data() {
    return {
      //   imgList: [
      //     {
      //       value: "jdb",
      //       img: img("factory_jdb.png"),
      //       imgAct: img("factory_jdb_act.png"),
      //       maintena: true,
      //     },
      //   ],
      curAct: this.act,
    };
  },
  computed: {
        // ...mapGetters("global", [ "staticPic"]),
        apiStatus() {
          return status;
        },
      },
  created() {
	  var that = this
	  if(this.$route.params.FactoryName){
		  this.imgList.forEach(function(item,index){
		  	if(item.value==that.$route.params.FactoryName){
		   			setTimeout(() => {
					that.change(item,index)
		   			}, 200)
		  	};
		  		
		  })
	  }
	 
	
  },
  methods: {
    change(item, index) {
      if (item.maintena) return;
      this.curAct = index;
      this.onChange(index, item);
    },
  },
};