<template>
	<div style="" class="wrap">

		<div class="top flexs_sb">
			<div class="flexs_r_c" style="margin-left: 45px;">
				
				<img :src="$img('wxz_img.png')" @click="allCheck()" v-if="!isCheckAll" class="checkImg canClick" alt="">
				<img :src="$img('xz_img.png')" v-else @click="allCheck()" class="checkImg canClick" alt="">
				<span class=" smallSize boldSize" style="margin-left: 15px;color:white;">{{$t('全选')}}</span>
			</div>
			<div class="flexs_r_c" style="margin-right: 45px;">
				<img :src="$img('messageDelete.png')" @click="deleteMessage()" class="canClick" alt="" style="width: 16px;margin-right: 16px;">
				<img :src="$img('update.png')"  @click="getMessageList()" class="canClick" alt="" style="width: 22px;">
				
			</div>	
		</div>
		<div class="messageContent">
			<NoData v-show="messageData.length==0" style="margin-top: 100px;width: 100%;background-color: transparent;"></NoData>

			<div class="messageCell  flexs_r canClick" @click="mailClick(index)" v-for="(item,index) in messageData" :key="item">
				<img :src="$img('wxz_img.png')" @click.stop="cellCheck(index)" v-if="!item.check" class="checkImg canClick" alt="" style="margin-top: 10px;">
				<img :src="$img('xz_img.png')" v-else @click.stop="cellCheck(index)" class="checkImg canClick" alt="" style="margin-top: 10px;">
				<div class="flexs_colum" style="margin-left: 12px;">
					<span class="time smallSize">{{countDate(item.SendTime)}}</span>
					<span :class="item.ReadState=='read'?'titleRead':'titleUnread'" class="middleSize">{{item.ContentTitle}}</span>
					<div class="content smallSize" v-if="item.show">{{item.Content}}</div>
				</div>	

				<div class="dot" v-if="item.ReadState!='read'"></div>
				
			</div>
			
			
			
		</div>

		<confirm :title="$t('是否删除选中邮件？')" @sure='comfirmDelete()' @cancel='showConfirm=false' v-if="showConfirm"></confirm>
		
	</div>
</template>

<script>
	import userMessage from "@/views/pc/userMessage"
	
	export default{
	  mixins:[userMessage]
	}
</script>

<style lang="scss" scoped>
	.wrap{
		// width: 100%;
		// height: 100%;
		//background-color: rgb(29, 29, 29);
		// margin-top: 40px;
		margin: 40px;
		height: 83%;
		//border-radius: 10px;
		overflow: hidden;
	}
	.top{
		height: 58px;
		 width: 100%;
		 border-radius: 10px;
		//padding-top: 10px;
		background: linear-gradient(to bottom,#464241,#4b4a48,#454140);
	}
	.checkImg {
		width: 20px;
		height: 20px;
	}
	.messageContent{
		height: 540px;
		
		white-space: nowrap;
		overflow-y: auto;
	}
	.messageCell{
		// width: 100%;
		border-radius: 10px;
		border-radius: 5px;
		padding: 15px 0;
		background-color: white;
		padding-left: 5px;
		position:relative;
		// margin-left: 15px;
		background-color: rgb(39, 39, 39);
		margin-top: 15px;
		//margin-right: 15px;
		.dot{
			position: absolute;
			left: 30px;
			top:20px;
			width:4px;
			height:4px;
			background-color: red;
			border-radius: 2px;
		}
		.time{
			color: #7e7e7e;
		}
		.titleUnread{
			color: white;
			font-weight: bold;
			margin-top: 8px;
		}
		.titleRead{
			color: #dedbdd;
			margin-top: 8px;
		}
		.content{
			margin-top: 8px;
			width: 550px;
			color:#dedbdd;
			border-top: 1px solid #505050;
			padding-top: 10px;
			padding-bottom: 20px;
			white-space: normal;
			height: auto;
		}
	}
</style>