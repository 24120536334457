<template>
	<HomeNav style="position: fixed;top: 0;z-index: 9999;" :selectIndex='2' />
	
<!-- 	<img :src="$img('vipbj.png')" alt="" style="width: 100%;position: absolute;top: 10vh;left: 0;z-index: 0;height: 82vh;">
 -->	<div style="position: relative;overflow-x: hidden;margin-top: 10vh;">
		
	
		
		<div class="flexs_sb" style="width: 94%;margin-left: 3%;margin-top: 30px;">
			<img v-if="userData.VipLevel==0" :src="$img('VIP1.png')" class="diseableImg" alt=""
				style="width: 50px;">
			<img v-else :src="$img('VIP'+userData.VipLevel+'.png')" alt="" style="width: 50px;">
			
			
			<div style="width: 70%;" class="flexs_colum">
				
				<div style="text-align: right;color: #808691;font-size: 12px;">{{$t("升级还需")}}&nbsp; <span style="color:#f1af2f;">{{$tools.formatNum2k(userData.GoalCharge-userData.CurCharge)}}</span></div>
				<van-progress :show-pivot='false' color="rgb(251,158,49)"
					:percentage="userData.CurCharge/parseFloat(userData.GoalCharge) * 100" style="margin-top: 10px;" />
				<div style="margin-top: 10px;color: #fdfeff;font-size: 12px;">{{$tools.formatNum2k(userData.CurCharge)}}/{{$tools.formatNum2k(userData.GoalCharge)}}</div>
			</div>
			
			<div v-if="userData.ActivityMemberNum==0">
				<img class="get diseableImg cannotClick"  :src="$img('liwu_img.png')" alt=""
					style="width: 40px;">
				<div style='color:white;margin-left:2px;'>Nhận</div>
			</div>
			<div  v-else >
				<img class="get canClick" @click="getVipFL()" :src="$img('liwu_img.png')" alt=""
					style="width: 40px;">
				<div style='color:white;margin-left:2px;'>{{$t("领取")}}</div>
			</div>
			
		</div>
		
		
		<div @click="toRecharge()" :class="userData.VipLevel==0||!isConnect?'canClick':''" style="width: 180px;height: 112px;margin: 0 auto;margin-top: 20px;position: relative;margin-bottom: 0;">
			<img :src="$img('getVipBG.png')" alt="" style="width: 100%;">
			<div   v-if="userData.VipLevel==0||!isConnect"
				style="font-weight: bold;position: absolute;top: 25px;left: 0;text-align: center;color: white;width: 100%;font-size: 10px;">
				{{$t("成为").toUpperCase()}}&nbsp;VIP
			</div>
			<div v-else
				style="font-weight: bold;position: absolute;top: 25px;left: 0;text-align: center;color: white;width: 100%;font-size: 10px;">
				{{$t("邀请码").toUpperCase()}}:{{userData.InviteCode}}
			</div>
		</div>
		<div style="width: 94vw;margin-left: 3vw;margin-top: -40px;">
			<div class="flexs_sb">
				<div style="color: white;font-size: 14px;">&nbsp;</div>
			<div @click="toVipInfo()" class="flexs_colum" style="text-align: center;">
					<img :src="$img('wenhao.png')" alt="" style="width: 17px;margin: 0 auto;">
					<div style="color:rgb(149,160,180);margin-top: 5px;">{{$t("说明")}}</div>
				</div>
 
			</div>
		</div>
		
		<img :src="$img('vipImgLeft.png')" alt="" style="width: 94vw;margin-top: 1vw;margin-left: 3vw;">
	
		<img :src="$img('vipImgRight.png')" alt="" style="width: 94vw;margin-top: 3vw;margin-left: 3vw;">
		<div style="margin-left: 3vw;color:rgb(251,41,68) ;font-size: 11px;">{{$t("注：降级后，只要完成原等级的保级要求，即可立即恢复原来等级。")}}</div>
	

	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import mqant from "@/components/protocol/mqantlib";
	import bus from "@/utils/bus.js"
	import i18n from "@/locales/i18n";
	import {
		theme
	} from '@/views/theme'

	let RedHeader = require('@/views/' + theme + '/components/RedHeader.vue').default
	let HomeNav = require('@/views/' + theme + '/components/HomeNav.vue').default

	import {
		req,
		qs
	} from "@/utils/request";
	import {
		topic
	} from "@/components/protocol/api";
	import { Dialog } from 'vant';
	import 'vant/es/dialog/style';
	export default {
		components: {
			RedHeader,
			HomeNav,
			 [Dialog.Component.name]: Dialog.Component,
		},
		data() {
			return {
				selectIndex: 0,
				progressWidth: {
					width: "50%"
				},
				progressWidth2: {
					width: "50%"
				},
				endDate: '',
				startDate: '',
				page: 1,
				configData: '',
				recordData: [],
				userData:{
					ActivityMemberNum:0,
					VipLevel:0
				},
				headerArr: [this.$t('等级'), this.$t('客服优先'), this.$t('邀请积分'),this.$t('邀请回报'), this.$t('充值优惠'),this.$t('亏损补贴'),this.$t('生日福利')],
				keyArr:["InvitePoint","InviteRebate","ChargeGift","LoseRebate","BirthDayGift"],
			
			}
		},
		computed: {
			...mapGetters("tcp", ["isLogged", "isConnect","info"]),
			...mapGetters("global", ["lobbyInfo"]),

		},

		created() {
			if(this.isConnect){
				this.getUserInfo()
				
			}
			
			var that = this
			
			bus.off('onConnected')
			bus.on('onConnected',(val)=>{
					  this.getUserInfo()
			})
			
			bus.off('receiveMemberAll')
			bus.on('receiveMemberAll',(val)=>{
					  let arr = val.data.Data
					  
					  let str = ''
					  for(var i=0;i<arr.length;i++){
						  
					  	var obj = arr[i]
					  	str += obj.Type
					  	str += that.$t('获得了')
					  	if(obj.Gold>0){
					  		str += obj.Gold
					  	}
					  	if(obj.Point>0){
					  		str += obj.Point
					  		str += '\n'
					  		
					  	}
						
					  }
					  Dialog.alert({
					    message: str,
					  }).then(() => {
					    // on close
					  });
					  
					  that.getUserInfo()
					 // that.$tools.toast(str);
			})
			

		},
		methods: {
			getVipFL(){
				var that = this
				mqant.request(topic.receiveMemberAll, {language: i18n.global.locale}, function(data, topicName, msg) {
					//console.log(topicName,data)
					
				});
					
			},
			getUserInfo() {
				var that = this
				mqant.request(topic.userInfo, {language: i18n.global.locale}, function(data, topicName, msg) {
					
					that.userData = data.Data
				});
			},
			toRecharge(){
				if(!this.isLogged){
					this.$router.push({
						name: "login"
					});
					return
				}
				if (this.userData.VipLevel == 0) {
					var that = this
					
				mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
					
					if (!data.Data.myDouDouBT.length) {
						that.$router.push({
							name: 'bindbank'
						});
						bus.emit('hiddeTab', {
							isShow: false
						})
					} else {
						that.$router.push({
							name: 'recharge'
						});
					}
				});
				
				}
				
					
			},
			toVipInfo() {
				bus.emit('hiddeTab', {
					isShow: false
				})
				this.$router.push({
					name: 'vipInfo'
				});
			},
			toHistory() {

				this.$router.push({
					name: 'vipRecord'
				});
			},
			currentChange(e) {
				this.page = e
			},
			getRecord() {

				var that = this

				mqant.request(topic.getGiftRecord, {}, function(data, topicName, msg) {
					that.recordData = data.Data
				});
			},
			getVIPScore() {
				var that = this

				mqant.request(topic.getVIPScore, {}, function(data, topicName, msg) {
					that.getVipConfig()
				});
			},
			getMonthGift() {
				var that = this
				
				mqant.request(topic.getMonthGift, {
					'level': this.configData.CurVipLevel,
					'language': i18n.global.locale
				}, function(data, topicName, msg) {
				
				});
			},
			getVipConfig() {
				var that = this

				mqant.request(topic.getVIPConfig, {}, function(data, topicName, msg) {

					that.configData = data.Data

					if (that.configData.CurVipBet > that.configData.GoalVipBet) {
						that.progressWidth.width = '100%'

					} else {
						that.progressWidth.width = that.configData.CurVipBet / parseFloat(that.configData
							.GoalVipBet) * 100 + '%'
					}
					if (that.configData.CurVipCharge > that.configData.GoalVipCharge) {
						that.progressWidth.width = '100%'

					} else {
						that.progressWidth2.width = that.configData.CurVipCharge / parseFloat(that.configData
							.GoalVipCharge) * 100 + '%'
					}
				});
			},
			endDateChange(val) {
				this.endDate = this.dateFormat(val)
			},
			dateChange(val) {
				this.startDate = this.dateFormat(val)
			},

		},

	};
</script>

<style scoped lang="scss">
	.header{
		color: rgb(255,183,89);
		font-size: 10px;
		width: 14.28%;
		width: 200px;
		text-align: center;
		height: 40px;
		background-color: rgb(21,34,63);
		line-height: 40px;
	}
	.colum{
		color: white;
		font-size: 10px;
		width: 14.28%;
		// width: 200px;
		text-align: center;
		height: 28px;
		background-color: rgb(32,43,69);
		line-height: 28px;
	}
	.borderRight {
		border-right: 1px solid rgb(57,70,100);
	}
	// .header {
	// 	background-color: rgb(126, 103, 70);
	// 	color: #d4bb78;
	// 	width: 25%;
	// 	height: 60px;
	// 	line-height: 60px;
	// 	text-align: center;
	
	// }
	.topBtn {
		width: 300px;
		height: 35px;
		line-height: 35px;
		text-align: center;
		color: white;
		font-size: 16px;
		background-image: linear-gradient(to right, rgb(1, 174, 246), rgb(0, 109, 221));
		margin: 37px auto;
		border-radius: 4px;
		margin-bottom: 0;
	}

	.columBtn {
		background-color: #d3b35c;
		color: white;
		border-radius: 3px;
		height: 18px;
		line-height: 18px;
		text-align: center;
		width: 86%;
		margin-left: 7%;
	}

	.level {
		width: 90%;
		margin-left: 5%;
		height: 50vw;
		border-radius: 4px;
		background-color: rgb(73, 73, 73);
		margin-top: 4vw;
		// padding: 2vw;
	}

	.select {
		background-color: #fdb000;
		color: white;		
	}

	.btn {
		padding: 3px 30px;
		margin-right: 10px;
		border-radius: 4px;
	}

	.nolmal {
		background-color: #323232;
		color: #c6c6c6;
	}

	.enable {
		background-color: #d3b35c;
		cursor: pointer;
	}

	.disable {
		background-color: gray;
	}

</style>
