import router from '@/router/index'
import log from "@/utils/logger";
import { http, topic } from "@/components/protocol/api";
import { req, qs } from "@/utils/request";
import tools from "@/utils/tools.js"
import { bus, busName } from "@/components/core/bus";

export const user = {
  namespaced: true,
  state: {
    tcpInfo: {
      TcpHost: "",
      WssPort: 19002
    },
    token: {
      Oid: "",
      AccessToken: "",
      Ip: "",
      SessionId: "",
      CreateAt: "",
      UpdateTime: ""
    },
    user: {
      Oid: "",
      ShowId: 0,
      Account: "",
      NickName: "",
      Avatar: "",
      Area: 0,
      Phone: 0,
      Type: 1,
      Channel: "game",
      Platform: "web",
      Remark: "",
      ProfitPerThousand: -1,
      ProfitType: 0,
      Status: 0,
      RegisterIp: "",
      RegisterUuid: "",
      Birthday: "",
      DomainName: "",
      CreateAt: "",
      UpdateAt: ""
    },
    config: {
      cdnDomain: '',
      host: '',
    },
	showDouji:false,
	outToken:null
  },
  mutations: {
    setConfig(state, config) {
      log.info("setConfig:" + JSON.stringify(config))
      state.config = config
    },
    setUserInfo(state, userInfo) {
      // 这里的 `state` 对象是模块的局部状态
      state.user = userInfo
    },
    setToken(state, token) {
      state.token = token
    },
	setTcpTnfo(state, tcpInfo) {
	  state.tcpInfo = tcpInfo
	},
	setOutToken(state, outToken) {
	  state.outToken = outToken
	},
	setShowDouji(state, showDouji) {
	  state.showDouji = showDouji
	},
    loginOut(state) {
      // state.user = {}
      state.token = {}
      // sessionStorage.clear()
      if (tools.platform() == 'web') {
        return
      }
      router.push({ name: 'login', replace: true })
      // console.log('loginOut')
      // window.location.reload()
    },
    ResetUserInfo(state, data) {
      state.user = data.user
      state.tcpInfo = data.tcpInfo
      state.token = data.token
    },

  },
  actions: {
    doLogout({ commit, dispatch, rootGetters, getters }, data) {
     // console.error("doLogout")
      commit("tcp/setLogged", false, { root: true })
      commit("loginOut")
      bus.emit(busName.onLogout, true)
    },
    autoLogin({ commit, dispatch, rootGetters, getters }, data) {
      return new Promise(async (resolve, reject) => {
        let token = getters["token"].AccessToken;
		
		if(getters["outToken"]&&getters["outToken"].length){
			token = getters["outToken"]
			
		}
		
        if (token != "") {
          dispatch("tokenBind", token).then((succ) => {
            commit("tcp/setLogging", false, { root: true })
			
            bus.emit(busName.onLogged, true)
            resolve(succ)
            return
          }, failed => {
            commit("tcp/setLogging", false, { root: true })
            bus.emit(busName.onLogged, false)
            resolve(false)
          });
        } else {
          commit("tcp/setLogging", false, { root: true })
          bus.emit(busName.onLogged, false)
          resolve(false)
        }
      });
    },
    tokenBind({ commit, dispatch, rootGetters, getters }, token) {
      return new Promise(async (resolve, reject) => {
        // console.log("tokenBind:" + token);
        let params = {
          token: token,
        };
		
        let rsp = await req({
          url: http.tokenBind,
          method: "get",
          params,
        });
        if (rsp.status == 200) {
          if (rsp.data.Code == 0) {
			  
			  commit('setTcpTnfo',rsp.data.Data.tcpInfo)
			  
			  commit('setToken',rsp.data.Data.token) 
            dispatch("onLogged", rsp.data.Data).then(succ => {
              resolve(rsp.data.Data)
            }, failed => {
              reject(failed)
            })
          } else if (rsp.data.Code == 401) {
            commit("loginOut")
            reject(rsp.data)
          } else {
            reject(rsp.data)
          }
        }
      });
    },
    doLogin({ commit, dispatch, rootGetters, getters }, data) {
      return new Promise(async (resolve, reject) => {
        let rsp = await req.post(data.url, qs.stringify(data.params));
        if (rsp.status == 200) {
          if (rsp.data.Code == 0) {
            commit('ResetUserInfo', rsp.data.Data)
            dispatch("onLogged", rsp.data.Data).then(succ => {
              resolve(rsp.data.Data)
            }, failed => {
              reject(failed)
            })
          } else if (rsp.data.Code == 401) {
            commit("loginOut")
            reject(rsp.data)
          } else {
            reject(rsp.data)
          }
        }
      });
    },
    onLogged({ commit, dispatch, rootGetters, getters }, data) {
      return new Promise((resolve, reject) => {
        dispatch("tcp/connect", {}, { root: true }).then(succ => {
          bus.emit("loginSuccess")
          dispatch("tcp/requestNotice", {}, { root: true })
          dispatch("pay/requestPayInfo", {}, { root: true })
          dispatch("tcp/requestBasicInfo", {}, { root: true })
          resolve()
        }, failed => { })
      });
    },
    init({ commit, dispatch, rootGetters, getters }) {
      let n = process.env.VUE_APP_COMPILETIME
      console.log("v:" + n)//必须打印，排查代码是否已更新
      // if (getters['config'].host == '' || parseInt(rootGetters['global/compileTime']) < parseInt(n)) {
      //   commit("setConfig", { ...getters['config'], ...{ host: process.env.VUE_APP_HOST } })
      // }
      commit("setConfig", { ...getters['config'], ...{ host: process.env.VUE_APP_HOST } })
      commit("global/setCompileTime", n, { root: true })
    }
  },
  getters: {
    userInfo(state) {
      return state.user
    },
    token(state) {
      return state.token
    },
	showDouji(state){
		return state.showDouji
	},
    config(state) {
      return state.config
    },
    tcpInfo(state) {
      return state.tcpInfo
    },
	outToken(state){
		return state.outToken
	},
    compileTime(state) {
      return state.compileTime
    }
  }
}
