<template>
  <red-header :title="$t('推广')" :showBack="true" />
 <!-- <img
    :src="$img('disocunt_bg.png')"
    alt=""
    style="
      width: 100%;
      object-fit: contain;
      opacity: 0.1;
      position: absolute;
      top: 9vw;
    "
  /> -->
  <div
    style="
      height: 80vh;
      white-space: nowrap;
      overflow-y: auto;
      position: relative;
      z-index: 99;
      margin-top: 8vw;
    "
    class="flexs_colum"
  >
    <div class="flexs_r">
      <div class="flexs_colum" style="text-align: center; margin-left: 16vw">
        <div @click="selectIndex=0" :class="selectIndex==0?'select':'nolmal'" class="topBtn  phoneMiddle boldSize" style="">{{ $t("红利") }}</div>
        <span
		class="phoneMiddle"
          style="
            color: rgb(242,209,97);
            margin: 1vw auto;
            font-weight: bold;
          "
          >{{ $tools.formatNum2k(wallet.BonusBalance) }}</span
        >
      </div>
      <div class="flexs_colum" style="text-align: center; margin-left: 12vw">
        <div @click="selectIndex=1" :class="selectIndex==1?'select':'nolmal'" class="topBtn mainBG phoneMiddle boldSize" style="">{{ $t("返水") }}</div>
        <span
		class="phoneMiddle"
		
          style="
            color: rgb(242,209,97);
            margin: 1vw auto;
            font-weight: bold;
          "
          >{{ $tools.formatNum2k(wallet.AgentBalance) }}</span
        >
      </div>
    </div>

    <div
      class="flexs_r"
      v-for="(item, index) in getList()"
      :key="item"
      style="margin-top: 5vw"
    >
      <div class="topBtn  item middleSize boldSize" style="">{{ item.ActivityName }}</div>
      <div class="topBtn  item2 middleSize">{{ $tools.formatNum(item.Get) }}</div>
      <div class="topBtn lingqu middleSize boldSize" @click="onClick(item)">{{ $t("领取") }}</div>
    </div>
  </div>

  <my-dialog
    v-if="showDialog"
    :close="
      function () {
        showDialog = false;
      }
    "
    :title="dialogTitle"
    :msg="dialogMsg"
  />
</template>

<script>
import discount from "@/views/mobile/discountDraw";

export default {
  mixins: [discount],
};
</script>

<style lang="scss" scoped>
.topBtn {
  color: white;
  width: 28vw;
  height: 8vw;
  text-align: center;
  line-height: 8vw;
  border-radius: 1vw;
}

.item {
  width: 32vw;
  height: 12vw;
  line-height: 12vw;
 background-color: #3a8c38;
  margin-left: 7.5vw;
  position: relative;
  z-index: 99;
}

.item2 {
  width: 32vw;
  height: 10vw;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  line-height: 10vw;
  margin-left: -1vw;
  margin-top: 2vw;
  position: relative;
  background-color: rgb(44,44,44);
  z-index: 1;
}

.lingqu {
  width: 15vw;
  height: 10vw;
  line-height: 10vw;
  background-color: #3a8c38;
  margin-top: 2vw;
  margin-left: 6vw;
}
	.select{
		color: black;
		background-color: rgb(242,209,97);
		
	}
	.nolmal{
		background-color: rgb(40,40,40);
		color: white;
	}
</style>
