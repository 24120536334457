
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/vi'
import locale1 from 'element-plus/lib/locale/lang/en'

import 'element-plus/dist/index.css'
import { Swipe, SwipeItem } from 'vant';
import 'vant/es/swipe/style';
import 'vant/es/swipe-item/style';
import { Image as VanImage } from 'vant';
import 'vant/es/image/style';
import { ConfigProvider } from 'vant';
import 'vant/es/config-provider/style';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import { Progress } from 'vant';
import 'vant/es/progress/style';
import {theme} from '@/views/theme'

var isEn = theme=='pc6'||theme=='mobile6'

let install = function(app){
 //    app.use(ElementPlus)
	 app.use(ConfigProvider)
	app.use(VueAwesomeSwiper)
	app.use(Progress)
	
	if(isEn){
		app.use(ElementPlus, { locale1 })
		
	}else {
		app.use(ElementPlus, { locale })
		
	}
	
	app.use(Swipe)
	app.use(SwipeItem)
	app.use(VanImage)
	
}

export {
    install
}