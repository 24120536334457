<template>
	<div style="position: relative;top: -1px;padding: 0vw 0;margin-bottom: -10px;padding-bottom: 30px;" class="">
		<img :src="$img('banner_shixun.png')" alt="" style="width: 100vw;min-width: 1200px;">		
		<!-- <div style="position: absolute;top: 3vw;left: 20%;color: white;">
			<div style="font-size: 40px;">{{$t('视频直播')}}</div>
			<div style="font-size: 30px;margin-top: 5px;">LIVE CASION</div>
			<div style="font-size: 20px;margin-top: 5px;">{{$t('性感荷官现场发牌  真实博彩体验')}}</div>
			
		</div> -->
		<div style="position: relative;width: 1315px;margin: 30px auto;margin-bottom: 0;" class="flexs_r">
			<div style="position: relative;" @click="hotClick(topList[0])" class="canClick">
				<img :src="$img('banner_sedie_img.png')" alt="" style="width: 450px;">
				<!-- <div style="position: absolute;width: 100%;font-size: 26px;color: white;text-align: center;bottom: 20px;">
					{{$t('色碟图')}}
				</div> -->
			</div>
			<div style="margin-left:19px;">
				<div style="position: relative;" @click="hotClick(topList[1])" class="canClick">
					<img :src="$img('banner_baijial_img.png')" alt="" style="width: 509px;">
					<!-- <div style="position: absolute;font-size: 26px;color: white;text-align: center;top: 60px;left: 16px;">
						{{$t('百家乐多台下注')}}
					</div> -->
				</div>
				<div style="position: relative;margin-top: 9px;" @click="hotClick(topList[2])" class="canClick">
					<img :src="$img('banner_test_img.png')" alt="" style="width: 509px;">
					<!-- <div style="position: absolute;font-size: 26px;color: white;text-align: center;top: 60px;left: 16px;">
						{{$t('特色厅')}}
					</div> -->
				</div>
				
				<div style="position: relative;margin-top: 9px;" @click="hotClick(topList[3])" class="canClick">
					<img :src="$img('banner_bjni_img.png')" alt="" style="width: 509px;">
					<!-- <div style="position: absolute;font-size: 26px;color: white;text-align: center;top: 60px;left: 16px;">
						{{$t('比基尼厅')}}
					</div> -->
				</div>
				
			</div>
			<!-- indicator-position="none" -->
			<div style="margin-left: 19px;position: relative;">
				<el-carousel ref="swiper" :loop="true" :interval="3000" height="480px" 
					arrow="never" style="width: 314px;">
				
					<el-carousel-item  :key="item" @click="hotClick(item)" class="canClick">
						<img :src="$img('banner_longh_img.png')" alt="" style="width: 314px;">
				
					</el-carousel-item>
					
					<el-carousel-item  :key="item" @click="hotClick(item)" class="canClick">
						<img :src="$img('banner_toubao_img.png')" alt="" style="width: 314px;">
									
					</el-carousel-item>
					
					<el-carousel-item   :key="item" @click="hotClick(item)" class="canClick">
						<img :src="$img('banner_yuxiaxie_img.png')" alt="" style="width: 314px;">
									
					</el-carousel-item>
				
				</el-carousel>
				<!-- <div style="position: absolute;width: 100%;font-size: 26px;color: white;text-align: center;bottom: 20px;">
					{{$t('龙虎斗')}}
				</div> -->
			</div>
		</div>
		<div style="position: relative;width: 1317px;margin: 10px auto;flex-wrap: wrap;" class="flexs_r">
			<div v-for="(item,index) in listData" :key="index" style="border-radius: 5px;margin-top: 20px;position: relative;" :style="index%4!=0?'margin-left:20px':''" class="canClick">
				<img   @click="columClick(index)"    :class="{ diseableImg: (item.Factory.Status==2||item.Factory.Status==3)}"  class="topColum"  :src="$img(item.Factory.FactoryName+'_C.png')" alt="" style="width: 314px;" >
				<div style="position: absolute;width: 100%;font-size: 26px;color: white;text-align: center;bottom: 10px;">
					{{item.Factory.FactoryShowName.toUpperCase()}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import bus from "@/utils/bus.js"
	import {
		status,
		getApiLoginUrl,
		checkFactoryUrl
	} from "@/components/protocol/api";

	export default {
		components: {

		},
		data() {
			return {
				listData: [],
				selectIndex: 0,
				topList:[],
				hoverIndex: 0,
				wrapStyle: {
					height: 0
				},
				urlList:['','https://t5x4ob.kh5688.com/Newindex?OType=2&amp;lang=vn','https://sport.api-ugaming.com/page/guest/demoPC.jsp']
			}
		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),
			...mapGetters("tcp", ["isLogged"]),

		},
		
		created() {
			var CasinoRecommend = this.lobbyInfo.CasinoRecommend
			var arr = []
			for(var i=0;i<CasinoRecommend.length;i++){
				var obj = CasinoRecommend[i]
				if(obj.PlatformType=='PC'){
					arr.push(obj)
					
				}
			}
			this.topList = this.sortData1(arr)
			
			this.listData = this.sortData(this.lobbyInfo.StaticPicture.LiveCasino)
		},
		methods: {
			getDatas(){
				var arr = []
				for(var i=0;i<this.topList.length;i++){
					if(i>3){
						arr.push(this.topList[i])
					}
				}
				return arr
			},
			columClick(index){
				if (this.listData[index].Factory.Status == 2) {
					this.$message({
						message: this.$t('维护中'),
						type: 'info'
					});
					return
				}
				if (this.listData[index].Factory.Status == 3) {
					this.$message({
						message: this.$t('即将上线'),
						type: 'info'
					});
					return
				}
				this.play(this.listData[index])
				
				
			},
			play(item) {
				
				if (!this.isLogged) {
					bus.emit('showLogin')
					return
				}

				let name = item.Factory.FactoryName;
				let url = getApiLoginUrl(name, item.Factory.GameCode);
				this.$act.openPage(url);
			},
			toLogin() {
				this.$router.push({
					name: 'login',



				})
			},
			iconClick(index) {
				if (this.listData[index].Factory.Status == 2 || this.listData[index].Factory.Status == 3) {
					return
				}
				this.selectIndex = index
			},
			sortData1(data) {
				var arr = []
				for (let key in data) {
					arr.push(data[key])
			
				}
			
				return arr.sort((a, b) =>  b.Sort - a.Sort)
			
			},
			sortData(data) {
				var arr = []
				for (let key in data) {
					arr.push(data[key])

				}

				return arr.sort((a, b) => a.Factory.Status - b.Factory.Status || b.Factory.Sort - a.Factory.Sort)

			},
			hotClick(val){
				
				if(val.PType==''){
					return
				
				}else if(val.PType=='LinkUrl'){
					if(val.GameCode.length){
						window.open(val.GameCode)
						
					}
				}else if(val.PType=='Activity'){
					if(val.GameCode.length){
						this.$router.push({
							name: 'activity',
							params: {
								id:val.GameCode
							}
						
						
						})
					}else {
						this.$router.push('discount')
					}
				}
				else {
					if(val.PType=='LiveCasino'||val.PType=='Lottery'||val.PType=='LiveSports'){
						if(val.Factory.length){
							if(!this.isLogged){
								bus.emit('showLogin')
								
								return
							}
							let name = val.Factory;
							
							let url = getApiLoginUrl(name, val.GameCode);
							this.$act.openPage(url);
						}else {
							
						}
					}else if(val.PType=='Card'||val.PType=='Fish'||val.PType=='Slot'){
						if(val.Factory.length){
							if(val.GameCode.length){
								if(!this.isLogged){
									bus.emit('showLogin')
									return
								}
								let url = getApiLoginUrl(val.Factory, val.GameCode);
								this.$act.openPage(url);
							}else {
								var name
								if(val.PType=='Fish'){
									name = 'fishing' 
								}else if(val.PType=='Slot'){
									name = 'slot'
									
								}else {
									name = 'chess'
									
								}
								this.$router.push({
									name: name,
									params: {
										FactoryName:val.Factory
									}
								})
							}
						}else {
							var name
							if(val.PType=='Fish'){
								name = 'fishing' 
							}else if(val.PType=='Slot'){
								name = 'slot'
								
							}else {
								name = 'chess'
								
							}
							this.$router.push({
								name: name,
							
							})
						}
					}
				}
			},
		},

	};
</script>

<style lang="scss" scoped>
	.listWrap {

		
		
	}
	.listBottom{
		display: flex;
		flex-direction: row;
		 flex-wrap: wrap;
		// white-space: nowrap;
		// overflow-y: auto;
		 margin-left: 0;
		width: 100%;
		
		// max-height: 48vw;
		// background-color: rgba(0, 0, 0,.4);
	}
	.topNavi{
		width: 100%;
		margin-left: 0;
		border-top-left-radius: 1vw;
		border-top-right-radius: 1vw;
		background-color: #1a1a1a;
		overflow: hidden;
	}

	.colum {
		margin-top: 2.25vw;
		margin-left: 2.25vw;
		position: relative;
		width:11.6vw ;height: 11.6vw;
	}
	.topImgWrap{
		background-color: #1a1a1a;
	}
	
	
	.el-pagination {
	    text-align: center; 
	}
	.mask{
		background-color: rgba(0, 0, 0, .55);
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;
		top: 0;
		left: 0;
		border-radius: 10px;
		.enter{
			width: 9vw;
			height: 2vw;
			line-height: 2vw;
			border-radius: 4px;
			text-align: center;
			font-size: 0.86vw;
			font-weight: bold;
		background-image: linear-gradient(to  top,#e0b626, #d7781e);
		color: white;
			margin-left: 1.3vw;
			margin-top: 4.6vw;
		}
	}
	.select{
		background: linear-gradient(to right,#01aef6,#006ddd);
		border-radius: 5px;
		height: 100%;
	}
	input{
		width: 400px;
		height: 45px;
		color: white;
		border-radius: 5px;
		padding-left: 10px;
		font-size:20px ;
		background-color: #0f192f;
	}
</style>

<style>
/* 为了不造成样式污染，单据加一个style标签来修改第三方组件样式，pagination表格分页整体居中，上边距20px */
.el-pagination{
  text-align:center;
  margin-top:20px;
} 
</style>


