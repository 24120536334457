<template>
  
  
  <!-- <Sportsgame></Sportsgame> -->
 <!-- <div class="gird">
    <home-panel-item
      v-for="(item, index) in panelList"
      :key="index"
      :class="{ panel_item_margin: index % 2 == 1 }"
      :name="item.name"
      :bg="item.bg"
      :icon="item.icon"
      @click="onPanelItemClick(item)"
      :before-change="onBeforeChange"
    />
  </div> -->
  <home-nav></home-nav>

</template>

<script>
	import {theme} from '@/views/theme'
	
	
	let HomeInfo = require('@/views/'+ theme +'/components/HomeInfo.vue').default
	let HomePanelItem = require('@/views/'+ theme +'/components/HomePanelItem.vue').default
	let Sportsgame = require('@/views/'+ theme +'/components/Sportsgame.vue').default
	let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default
	


import { img } from "@/utils/img";
import {bus, busName} from "@/components/core/bus"

export default {
  components: {
    HomeInfo,
    HomePanelItem,
    
	Sportsgame,
	HomeNav
  },
  name: "IndexView",
  data() {
    return {
      panelList: [
        {
          name: this.$t("体育"),
          bg: img("home_panel_foot_bg.png"),
          icon: img("home_panel_foot.png"),
          to: "foot",
        },
        {
          name: this.$t("真人视讯"),
          bg: img("home_panel_live_bg.png"),
          icon: img("home_panel_live.png"),
          to: "live",
        },
        {
          name: this.$t("棋牌"),
          bg: img("home_panel_games_bg.png"),
          icon: img("home_panel_games.png"),
          to: "games",
        },
        {
          name: this.$t("老虎机"),
          bg: img("home_panel_slot_bg.png"),
          icon: img("home_panel_slot.png"),
          to: "slot",
        },
        {
          name: this.$t("捕鱼"),
          bg: img("home_panel_fish_bg.png"),
          icon: img("home_panel_fish.png"),
          to: "fish",
        },
        {
          name: this.$t("彩票"),
          bg: img("home_panel_lottery_bg.png"),
          icon: img("home_panel_lottery.png"),
          to: "lottery",
        },
      ],
    };
  },
  methods: {
    onPanelItemClick(item) {
      // console.log("onPanelItemClick");
      // console.log(item);
	  
      bus.emit(busName.homeChildView, item.to)
      this.$router.push({name: item.to})
    },
    onBeforeChange(i){
        // console.log("onBeforeChange")
        // console.log(i)
        return true
    }
  },
};
</script>

<style lang="scss" scoped>
.gird {
  text-align: center;
}
.panel_item_margin {
  margin-left: 1vw;
}
</style>