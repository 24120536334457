<template>
	<red-header :title="$t('权益说明')"  :showBack="true"  />
	
	<div class="flexs_colum" style="overflow-x: hidden;position: relative;z-index: 3;height: calc(100vh - 44px);padding: 3vw;">
		<div class="flexs_r_c">
			<div style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				1
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('绑定银行卡')}}</div>
		</div>
		<div style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;">
			{{$t('vipInfo1')}}
		</div>
		
		<div class="flexs_r_c" style="margin-top: 20px;">
			<div style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				2
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('邀请积分')}}</div>
		</div>
		<div style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
			{{$t('vipInfo2')}}<br>
		{{$t('vipInfo3')}}
		</div>
		
		<div class="flexs_r_c" style="margin-top: 20px;">
			<div style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				3
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('邀请回报')}}</div>
		</div>
		<div style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
{{$t('vipInfo4')}}<br>
		{{$t('vipInfo5')}}		</div>
		
		<div class="flexs_r_c" style="margin-top: 20px;">
			<div style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				4
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('充值优惠')}}</div>
		</div>
		<div style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
{{$t('vipInfo6')}}<br>
		{{$t('vipInfo7')}}		</div>
		
		<div class="flexs_r_c" style="margin-top: 20px;">
			<div style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				5
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('亏损补贴')}}</div>
		</div>
		<div style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
{{$t('vipInfo8')}}<br>
		{{$t('vipInfo9')}}		</div>
		
		<div class="flexs_r_c" style="margin-top: 20px;">
			<div style="border-radius: 7.5px;width: 15px;height: 15px;background-color: rgb(36,180,227);color: white;font-size: 13px;line-height: 15px;text-align: center;">
				6
			</div>
			<div style="color: white;font-size: 14px;margin-left: 10px;">{{$t('生日福利')}}</div>
		</div>
		<div style="color: rgb(128,134,145);font-size: 12px;margin-top: 20px;white-space: break-spaces;word-break: break-all;">
{{$t('vipInfo10')}}<br>
		{{$t('vipInfo11')}}		</div>
		
	</div>
</template>

<script>
	import {
		theme
	} from '@/views/theme'
	let RedHeader = require('@/views/' + theme + '/components/RedHeader.vue').default
	import {
		defineComponent,
		reactive
	} from 'vue'
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import mqant from "@/components/protocol/mqantlib";
	import bus from "@/utils/bus.js"
	import i18n from "@/locales/i18n";
	
	import {
		req,
		qs
	} from "@/utils/request";
	import {
		topic
	} from "@/components/protocol/api";
	
	export default {
		components: {
			RedHeader
		},
	    computed: {
			...mapGetters("global", ["lobbyInfo"]),
			
		},
	 
	  mounted() {
	   
		//this.getVipConfig()
	  },
	  methods: {
		  change(e){
			this.selectIndex = e
		  },
		  getVipConfig() {
		  	var that = this
		  
		  	mqant.request(topic.getVIPConfig, {}, function(data, topicName, msg) {
		  		that.configData = data.Data
		  
		  	});
		  },
	  },
	  data() {
	    return {
			selectIndex: 0,
			configData:'',
			titleArr: [this.$t("贵宾级别"), this.$t("有效投注"), this.$t("总充值"), this.$t("有效投注仍然存在"), this.$t("升级奖金"), this.$t(
				"每月奖金"), this.$t("贵宾护理专员")],
				titleArr2: [this.$t("贵宾级别"), this.$t("老虎机"), this.$t("捕鱼"), this.$t("体育"), this.$t("真人视讯"), this.$t(
					"棋牌")],
				headerType:["BetNeed","ChargeNeed","KeepGradeNeed","VipGetGold","WeekGet"],
			
	    };
	  },
	};
</script>

<style scoped>
	.indicator {
		width: 2vw;
		height: 2vw;
		border-radius: 1vw;
		margin-left: 1vw;
	}
	
	.select {
		background-color: #e9a12e;
	}
	
	.nolmal {
		background-color: #2f2f2f;
	}
	
	/* .header {
		background-color: rgb(126, 103, 70);
		color: #d4bb78;
		width: 25%;
		height: 60px;
		line-height: 60px;
		text-align: center;
	
	} */
	
	.colum {
		color: white;
		height: 30px;
		line-height: 30px;
		text-align: center;
	}
	
	.borderRight {
		border-right: 1px solid black;
	}
	
	.double {
		background-color: rgb(24, 26, 31);
	
	}
	
	.single {
		background-color: rgb(17, 20, 26);
	
	}
	
</style>
