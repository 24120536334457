	import {
		req,
		qs
	} from "@/utils/request";
	import mqant from "@/components/protocol/mqantlib";
	import confirm from '@/views/pc5/components/confirm.vue';
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import {
		topic
	} from "@/components/protocol/api";
	import bus from "@/utils/bus.js"
	import {
		theme
	} from '@/views/theme'
	let NoData = require('@/views/'+ theme +'/components/NoData.vue').default

	export default {
		data(){
			return {
				isCheckAll:false,
				showConfirm:false,
				messageData:[
					
				],
				chooseData:[]
			}
		},
		components:{
			confirm,
			NoData
		},
		props:{
			
			// diaLoginVisible:{
			// 	type:Boolean,
			// 	default:true
			// }
		},
		computed: {
			...mapGetters("tcp", ["isLogged","isConnect"]),
		
		
		},
		created() {
			
			var that = this
			bus.on('onConnected', () => {
					that.getMessageList()
				
			})
			if(this.isConnect){
				this.getMessageList()
				
			}
			
		},
		methods:{
			mailClick(index){
				var that = this
				
				this.messageData[index].show = !this.messageData[index].show
				this.messageData[index].ReadState = 'read'
				var that = this
				mqant.request(topic.updateMail, {Oid:that.messageData[index].Oid}, function(data, topicName, msg) {
					bus.emit('changeRead')
				});
			},
			countDate(val) {
				if(!val){
					return ''
				}
				return val.split('T')[0] +' ' + val.split('T')[1].substr(0,8)
			},
			getMessageList() {
				var that = this
				mqant.request(topic.mailList, {}, function(data, topicName, msg) {
					that.messageData = []
					for(var i=0;i<data.Data.mail.length;i++){
						var obj = data.Data.mail[i]
						obj.check = false
						obj.show = false
						that.messageData.push(obj)
					}
				});
			},
			allCheck(){
				this.isCheckAll = !this.isCheckAll
				if(this.isCheckAll){
					this.chooseData = []
					for (var i = 0; i < this.messageData.length; i++) {
						this.messageData[i].check = this.isCheckAll
						this.chooseData.push(this.messageData[i].Oid)
					}
				}else {
					this.chooseData = []
					for (var i = 0; i < this.messageData.length; i++) {
						this.messageData[i].check = false
					}
				}
				
			},
			comfirmDelete(){
				var that = this
				var num = 0;
				
					for(var j=0;j<this.messageData.length;j++){
						var obj = this.messageData[j]
						if(obj.check){
							mqant.request(topic.deleteMail, {Oid:obj.Oid}, function(data, topicName, msg) {
					
								num ++
									bus.emit('changeRead')
									that.showConfirm = false
									that.getMessageList()
								
							});
						}
						
					}
					
				
			
			},
			deleteMessage(){
				if(!this.chooseData.length){
					
					return
				}
				
				this.showConfirm = true
				
			},
			cellCheck(index){
				
				this.messageData[index].check = !this.messageData[index].check
				if(this.messageData[index].check){
					var canAdd = true
					for(var i=0;i<this.chooseData.length;i++){
						if(this.chooseData[i] == this.messageData[index].Oid){
							canAdd = false
						}
					}
					if(canAdd){
						this.chooseData.push(this.messageData[index].Oid)
					}
				}else {
					for(var i=0;i<this.chooseData.length;i++){
						if(this.chooseData[i] == this.messageData[index].Oid){
							this.chooseData.splice(i,1)
						}
					}
				}
				
				
			}
			
		}
	}