<template>
	<div class="toolBar">
		<div class="list" style="margin-left: 8.6vw;">
			<div class="flexs_r_c" style="border-bottom: 1px solid white;">
				<img :src="$img('LiveSports_Bottom.webp')" alt="" style="width: 31px;">
				<span class="title middleSize boldSize">{{$t("体育")}}</span>
				
			</div>
			<div v-for="item in sortData(lobbyInfo.StaticPicture.LiveSports)" class="">
				<span v-if="item.Factory.Status == 1" class="canClick son smallSize" @click="toDetail(item,1)"
					>{{item.Factory.FactoryShowName}}</span>
					
			</div>
			
	
		</div>
		<div class="list">
			<div class="flexs_r_c" style="border-bottom: 1px solid white;">
				<img :src="$img('LiveCasino_Bottom.webp')" alt="" style="width: 31px;">
				
				<span class="title middleSize boldSize">{{$t("真人视讯")}}</span>
				
			</div>
			<div v-for="item in sortData(lobbyInfo.StaticPicture.LiveCasino)" class="">
				<span v-if="item.Factory.Status == 1" class="canClick son smallSize" @click="toDetail(item,1)"
					>{{item.Factory.FactoryShowName}}</span>
					
			</div>
			
	
		</div>
		<div class="list">
			<div class="flexs_r_c" style="border-bottom: 1px solid white;">
				<img :src="$img('Card_Bottom.webp')" alt="" style="width: 31px;">
				
				<span class="title middleSize boldSize">{{$t("棋牌")}}</span>
				
			</div>
			<div v-for="item in sortData(lobbyInfo.StaticPicture.Card)" class="">
				<span v-if="item.Factory.Status == 1" class="canClick son smallSize" @click="toDetail(item,2)"
					>{{item.Factory.FactoryShowName}}</span>
			</div>
			
	
	
		</div>
		<div class="list">
			<div class="flexs_r_c" style="border-bottom: 1px solid white;">
				<img :src="$img('Slot_Bottom.webp')" alt="" style="width: 31px;">
				
				<span class="title middleSize boldSize">{{$t("老虎机")}}</span>
				
			</div>
			<div v-for="item in sortData(lobbyInfo.StaticPicture.Slot)" class="">
				<span class="canClick son smallSize" v-if="item.Factory.Status == 1"  @click="toDetail(item,3)"
					>{{item.Factory.FactoryShowName}}</span>
			</div>
			

	
	
		</div>
		<div class="list">
			<div class="flexs_r_c" style="border-bottom: 1px solid white;">
				<img :src="$img('Fish_Bottom.webp')" alt="" style="width: 31px;">
				
				<span class="title middleSize boldSize">{{$t("捕鱼")}}</span>
				
			</div>
			<div v-for="item in sortData(lobbyInfo.StaticPicture.Fish)" class="">
				<span  class="canClick son smallSize" @click="toDetail(item,4)"  v-if="item.Factory.Status == 1" 
					>{{item.Factory.FactoryShowName}}</span>
			</div>
			
	
	
		</div>
		<div class="list">
			<div class="flexs_r_c" style="border-bottom: 1px solid white;">
				<img :src="$img('Lottery_Bottom.webp')" alt="" style="width: 31px;">
				
				<span class="title middleSize boldSize">{{$t("彩票")}}</span>
				
			</div>
			<div v-for="item in sortData(lobbyInfo.StaticPicture.Lottery)" class="">
				<span  class="canClick son smallSize" @click="toDetail(item,4)"  v-if="item.Factory.Status == 1" 
					>{{item.Factory.FactoryShowName}}</span>
			</div>
	
		</div>
		<div class="flexs_r" style="margin-right: 8.6vw;">
			<div class="" style="display: flex;flex-direction: column;">
				<img :src="$img('GooglePlay.png')" style="width: 163px;" alt="">
	
				<vue-qr :text="lobbyInfo.VersionGet.android.UrlPath" :size="145" :margin='15' style="width: 163px;margin-top: 10px"></vue-qr>
	
			</div>
			<div class="" style="display: flex;flex-direction: column;margin-left: 20px;">
				<img ref="qrCodeUrl" :src="$img('AppStore.png')" style="width: 163px;" alt="">
				<vue-qr :text="lobbyInfo.VersionGet.ios.UrlPath" :size="145" :margin='15' style="width: 163px;margin-top: 10px;"></vue-qr>
			</div>
		</div>
	</div>
	
</template>

<script>
	import bottomNav from "@/views/pc/bottomNav"
	
	export default{
	  mixins:[bottomNav]
	}
</script>

<style lang="scss" scoped>
	.toolBar {
		background-color:#191919;
		padding: 22px 0vw 10px 0vw;
		margin-top: 17px;
		display: flex;
		min-width: 1200px;
		width: 100%;
		flex-direction: row;
		justify-content: space-between;
		color: white;
	
		.list {
			display: flex;
			flex-direction: column;
		}
	
		.title {
			// border-bottom: 1px solid white;
			 margin-left: 5px;
		}
	
		.son {
			margin-top: 12px;
			display: block;
			color: #c8c8c8;
			text-align: center;
		}
		.son:hover{
			color: #f2d161;
		}
	}
</style>