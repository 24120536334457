import {
	mapGetters,
	mapActions,
	mapMutations
} from "vuex";
import {
	status,
	getApiLoginUrl,
	checkFactoryUrl
} from "@/components/protocol/api";
import action from '@/components/core/action'
import {
		bus,
		busName
	} from "@/components/core/bus";
export default {
	name: "FullWidthBanner",
	data() {
		return {

		}
	},
	computed: {
		...mapGetters("global", ["lobbyInfo"]),
		...mapGetters("tcp", ["isLogged", "isConnect"]),

	},
	created() {
		this.lobbyInfo.FirstPageBanner = this.lobbyInfo.FirstPageBanner.sort((a, b) => {
			return b.Sort - a.Sort

		})
	},
	methods: {
		bannerClick(val) {

			if (val.PType == '') {
				return
			} else if (val.PType == 'LinkUrl') {
				if (val.GameCode.length) {
					action.openBrowser(val.GameCode, '')

				}
			} else if (val.PType == 'Activity') {
				
				if(val.GameCode=='Member'){
					this.$router.push({
					  name: "vip"
					  
					});
					return
				}
				if (val.GameCode.length) {
					
					var list = this.lobbyInfo.ActivityConf.Rebate.concat(this.lobbyInfo.ActivityConf.Bonus)
					
					list = list.sort((a, b) => b.Sort - a.Sort)
					
					for (var i = 0; i < list.length; i++) {
						var obj = list[i]
						if (obj.ActivityId == val.GameCode) {
							bus.emit('hiddeTab', {
								isShow: false
							})
							this.$router.push({
								name: 'giftInfo',
								params: {
									data: JSON.stringify(obj)
								}
							
							
							})
							
						}
					}
					
				} else {
					this.$router.push({
						name: 'gift',
						params: {

						}


					})
				}
			} else {
				if (val.PType == 'LiveCasino' || val.PType == 'Lottery' || val.PType == 'LiveSports') {
					if (val.Factory.length) {
						if (!this.isLogged) {
							this.$router.push({
								name: "login"
							});

							return
						}
						let name = val.Factory;
						let url = getApiLoginUrl(name, val.GameCode?val.GameCode:"");
						action.openPage(url, '')
					} else {
						var name
						var type 
						if(val.PType=='LiveCasino'){
							name = 'game'
							type = 1
						
						}else if(val.PType=='LiveSports'){
							name = 'game'
							type = 0
						}
						this.$router.push({
							name: name,
							params: {
								type:type,
							}
						})
					}
				} else if (val.PType == 'Card' || val.PType == 'Fish' || val.PType == 'Slot') {
					if (val.Factory.length) {
						if (val.GameCode.length) {
							if (!this.isLogged) {
								this.$router.push({
									name: "login"
								});

								return
							}
							let url = getApiLoginUrl(val.Factory, val.GameCode);
							action.openPage(url, '')

						} else {
							var name
							var type
							if(val.PType=='Fish'){
								name = 'game'
								type = 3
							}else if(val.PType=='Slot'){
								name = 'game'
								type = 2
								
							}else {
								name = 'game'
								type = 4
								
							}
							this.$router.push({
								name: name,
								params: {
									type:type,
									FactoryName:val.Factory
								}
							})
						}
					}
				}
			}
		},
	}
};
