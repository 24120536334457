import {theme} from '@/views/theme'
	let MyDialog = require('@/views/'+ theme +'/components/MyDialog.vue').default

import pay from "@/views/mobile/pay";
import { http, topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import axios from 'axios' // 引入axios

export default {
  components: { MyDialog },
  mixins: [pay],
  data() {
    return {
      list: [100, 500, 1000, 2000, 5000, 10000],
      amount: "",
      amountValue: "0",
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
	  showPicker:false,
	  columNum:this.$t('快捷选择充值金额')
    };
  },
  watch: {
    amount(n, o) {
      this.amountValue = this.$tools.formatNum(n * 1000);
    },
  },
  methods: {
    onClickItem(item) {
		//console.log(item)
		this.showPicker = false
      this.amount = item;
    },
    getMethod() {
      return this.getPayMethod("VgPay", "MomoPay");
    },
    onSubmit() {
      if (this.amount <= 0) {
        return;
      }
      let method = this.getMethod()
      if (!method) {
        this.$log.warn("not found pay method of MomoPay ");
        return;
      }
      let params = {
        amount: this.amount * 1000,
        methodId: method.MethodId,
      };
	  if(theme=='mobile3'){
		  params.accountName="default"
		  
	  }
	  
      let that = this;
      that.$tools.loading();
	  let oWindow 
	  if(theme=='mobile4'||theme=='mobile5'||theme=='mobile1'||theme=='mobile3'){
		   oWindow = window.open('', '_blank');
		  
	  }
	  
      mqant.request(topic.payCharge, params, function (res) {
		  console.log('vvvvv',res)
        that.$tools.dismissLoading();
        if (res.Code == 0) {
			that.amount = ''
			if(theme=='mobile4'||theme=='mobile1'){
				var param = ''
				for(var key in res.Data.ReqParam){
					param += (key + '=')
					param += res.Data.ReqParam[key]
					param += '&'
				}
				param = param.substr(0,param.length-1)
				
				var url = 'https://88bet88bet.com/static/918PAY.html?'+param
				//that.$act.openBrowser(url);
				
				
				oWindow.location = url;
				
				
				return
				window.open(url)
				return
				
			}else if (theme=='mobile5'){
				var param = ''
				for(var key in res.Data.ReqParam){
					param += (key + '=')
					param += res.Data.ReqParam[key]
					param += '&'
				}
				param = param.substr(0,param.length-1)
				
				var url = 'https://hdbet88.com/static/918PAY.html?'+param
				//that.$act.openBrowser(url);
				oWindow.location = url;
				
				return
			}
			oWindow.location = res.Data.TargetUrl
          //that.$act.openBrowser(res.Data.TargetUrl);
        } else {
          that.$tools.toast(res.ErrMsg);
        }
      });
    },
  },
};