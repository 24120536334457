<template>
  <div class="form">
<!-- 	  <img :src="$img('recharg_bg.png')" alt="" style="width: 100%;position: absolute;z-index: 0;top: 0;left: 0;">
 -->	  
    <div class="form_panel2" style="position: relative;z-index: 3;padding-top: 4vw;padding-bottom: 10vw;">
      <div class="row" style="border-bottom: 1px solid rgb(74,74,73);width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;">
        <input
          class="input blue_border phoneMiddle"
          type="text"
          :placeholder="
            $tools.formatNum2k(getMethod().Mini) +
            '-' +
            $tools.formatNum2k(getMethod().Max)
          "
          v-model="amount"
        />
        <div class="vndk phoneSmall" style="color: #f2d161;">VND(K)</div>
      </div>
      <div class="row tips phoneSmall" style="color: white;">= <span style="color:rgb(241,175,47)"> &nbsp{{ amountValue }}&nbsp</span> VND</div>
	  <!-- <div style="display: flex;flex-direction: row;flex-wrap: wrap;margin-top: 2vw;height: 22vw;">
		  <div style="line-height: 8vw;text-align: center;width: 20vw;height: 8vw;background-color: rgb(213, 188, 145);margin-left: 5vw;color: white;" @click="onClickItem(item)" v-for="item in list">
			  {{ item }}
		  </div>
	  </div> -->
	  <div class="row"
	  	style="width: 94%;margin-left: 3%;margin-top: 0;padding:5px 0;border-bottom: 1px solid rgb(74,74,73);">
	  	<div class="down_menu">
	  		<div class="bank_menu phoneMiddle " @click="showPicker = true" style="color: rgb(128,134,145);">
	  			{{ columNum }}
	  			<img :src="$img('icon_dropdown.png')" />
	  		</div>
	  		<van-popup v-model:show="showPicker" round position="bottom" >
	  			<van-picker class="phoneMiddle"  :columns="list" @cancel="showPicker = false"
	  				@confirm="onClickItem" confirm-button-text="Xác nhận" cancel-button-text="Hủy" />
	  		</van-popup>
	  	</div>
	  </div>
	  
      <!-- <div style="margin-top: 2vw">
        <van-grid :border="false" :column-num="3" :gutter="0">
          <van-grid-item v-for="item in list" @click="onClickItem(item)">
            <van-image
              :src="$img('recharge_item_bg.png')"
              class="box"
              :class="{ act_box: item == amount }"
            />
            <div class="money phoneMiddle boldSize">{{ item }}</div>
          </van-grid-item>
        </van-grid>
      </div> -->
    </div>

    <div class="form_panel"></div>

   <div class="loginBtn">
   	<img :src="$img('ann_bottom.png')" alt="" style="width: 100%;">
   	<div @click="onSubmit" :style="showPicker?'color:white':'color:black'" style="text-align: center;position: absolute;z-index: 9;width: 100%;top: 3.5vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
   		{{$t('提交')}}
   	</div>
   	
   </div>
  </div>
  <my-dialog
    v-if="showDialog"
    :close="
      function () {
        showDialog = false;
      }
    "
    :title="dialogTitle"
    :msg="dialogMsg"
  />
</template>

<script>
import momoPay from "@/views/mobile/momoPay";

export default {
  mixins: [momoPay],
};
</script>

<style lang="scss" scoped>
.box {
  border: 3px solid #fff;
}

.act_box {
  border: 3px solid #65c6e9;
  border-radius: 7px;
}
.loginBtn{
		position: relative;
		margin-top: 24px;
		
	}
input{
	color: white;
}
.down_menu {
		flex: 1;
		margin-right: 3%;

	}
.bank_menu {
		border-radius: 12px;
		padding: 2.5vw 3vw;
		position: relative;

		>img {
			width: 2vw;
			position: absolute;
			top: 46%;
			right: 0%;
		}
	}
.money {
  position: absolute;
}
.input {
  border-radius: 11px;
  padding: 2vw 0vw;
}
.blue_border {
  flex: 1;
}
.row {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5%;
  position: relative;
  > .label {
    width: 25vw;
    text-align: right;
    padding-right: 4px;
  }
  > .vndk {
    position: absolute;
    top: auto;
    bottom: auto;
    right: 0%;
  }
}
.form {
  padding: 2vw 4vw;
  position: relative;
  
  > .form_panel {
    border-radius: 11px;
    margin-top: 3vh;
    padding: 1vw 11vw;
  }
  > .form_panel2 {
   margin-top: 2vh;
   // padding-top: .5vw;
			background-color: rgb(42,42,42);
   // border: 1px solid #65c6e9;
   padding-bottom: 1vw;
   border-radius: 5px;
    > .row {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5%;
    }
    > .tips {
      justify-content: start;
      margin-left: 3%;
      margin-top: 0%;
	  padding: 2vw 0vw;
	  
    }
  }
}
.submit_tips {
  color: red;
}
.submit {
  > button {
    background-color: rgb(213, 188, 145);;
    color: white;
    border: 0;
    border-radius: 12px;
    padding: 2vw 6vw;
    margin-bottom: 10vw;
  }
}
</style>