<template>
  <HomeNav style="position: fixed;top: 0;z-index: 9999;" />

  <div class="main" style="margin-top: 22vw;height: 82vh;">
    <!-- <img class="main_bg" :src="$img('recharg_bg.png')" /> -->

    <div class="radio_box">
      <charge-radio-item :style="index>4?'margin-top:15px;':''" v-for="(item, index) in showPayList()" :actve="$route.name == item.id" :bg="item.bg" :title="item.title" @click="onClickTab(item)" />
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive v-if="$route.meta.keepAlive">
        <component :is="Component" />
      </keep-alive>
      <component :is="Component" v-else />
    </router-view>
  </div>
</template>

<script>
import { theme } from '@/views/theme'
let ChargeRadioItem = require('@/views/' + theme + '/components/ChargeRadioItem.vue').default
let RedHeader = require('@/views/' + theme + '/components/RedHeader.vue').default


import { mapGetters, mapActions, mapMutations } from "vuex";
import store from "@/store";
import auth from "@/components/core/auth";
let HomeNav = require('@/views/' + theme + '/components/HomeNav.vue').default

export default {
  mixins: [auth],
  components: { ChargeRadioItem, RedHeader, HomeNav },
  name: "Recharge",
  setup() { },
  data() {
    return {};
  },
  computed: {
    ...mapGetters("pay", ["payInfo"]),
  },
  mounted() {

  },
  methods: {
    ...mapActions("pay", ["requestPayInfo"]),

    onCopy() {
    },
    onCopyError() {
    },

    showPayList() {
      const list = {
        Official_bank: {
          id: "charge_bank",
          oid: "",
          bg: "recharge_icon_bank.png",
          title: "BANK",
        },
        // VgPay_MomoPay: {
        //   id: "charge_momo",
        //   methodType: "MomoPay",
        //   bg: "recharge_icon_momo.png",
        //   title: "MOMO",
        // },
        // VgPay_bankQr: {
        //   id: "charge_qr",
        //   methodType: "bankQr",
        //   bg: "recharge_icon_qr.png",
        //   title: "QR",
        // },
        // VgPay_direct: {
        //   id: "charge_link",
        //   methodType: "direct",
        //   bg: "recharge_icon_link.png",
        //   title: "LINK",
        // },
        // giftCode_giftCode: {
        //   id: "charge_gift",
        //   methodType: "giftCode",
        //   bg: "recharge_icon_gift.png",
        //   title: "CODE",
        // },
        // NapTuDong_naptudong: {
        //   id: "charge_phone",
        //   methodType: "naptudong",
        //   bg: "recharge_icon_phone.png",
        //   title: "THẺ CÀO",
        // },
      }
      let res = [];
      for (let i = 0; i < this.payInfo.payList.length; ++i) {
        let item = this.payInfo.payList[i];
        let key = item.Merchant + "_" + item.MethodType;
        let payItem = list[key];
        if (typeof payItem != "undefined") {
          res.push(payItem)
        }
      }
      return res
    },
    onClickTab(item) {
      for (let i in this.showPayList()) {
        if (this.showPayList()[i] == item) {
          this.showPayList()[i].active = true;
        } else {
          this.showPayList()[i].active = false;
        }
      }
      this.$router.push({ name: item.id });
    },
    copyText(text) {
      this.$copyText(text).then(
        function (e) {
          alert("Copied");
        },
        function (e) {
          alert("Can not copy");
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  margin-top: 10vh;
}
.main_bg {
  opacity: 0.15;
  width: 100vw;
  position: absolute;
  top: 0%;
}
.radio_box {
  width: 100vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // justify-content: space-around;
  margin-top: 4vw;
}
</style>
