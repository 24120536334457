<template>
  <div class="header flexs_colum">
    <img :src="$img('noData.png')" alt="">
	<div style="color:#b4b4b4" v-if="title.length" class="smallSize boldSize">{{title}}</div>
	<div style="color:#b4b4b4" v-else  class="smallSize boldSize">{{$t("暂无数据")}}</div>
  </div>
</template>

<script>
export default {
  name: "NoData",
  props: {
   title:{
   	type:String,
   	default:''
   }
  },
  methods: {
   
  },
};
</script>

<style lang="scss" scoped>
    .header{
        text-align: center;
        img{
            width: 50px;
			margin: 20px auto;
			
        }
    }
</style>