<template>
  <div class="card_panel" style="width: 100%;">
	  	<div class="flexs_r">
	  		<div style="display: flex;flex-direction: column;width: 100%;">
	  			
	  			
	  				<div style="position: relative;width: 250px;margin: 0 auto;margin-top: 15px;">
	  					<img :src="$img('bank_img.png')" alt="" style="width: 100%;">
	  					<div class="bankBG">
						
	  
	  						<div v-if="payInfo.myDouDouBT.length>0"
	  							style="display: flex;flex-direction: column;margin-left: 15px;">
	  							<span style="margin-top: 10px;"
	  								class="phoneBig boldSize">{{payInfo.myDouDouBT[0].BtName}}</span>
	  							
	  							<span style="margin-top: 35px;"
	  								class="phoneMiddle boldSize">{{payInfo.myDouDouBT[0].AccountName}}</span>
	  							<span style="margin-top: 5px;"
	  								class="phoneMiddle boldSize">{{payInfo.myDouDouBT[0].CardNum}}</span>
	  						</div>
	  						<div v-else @click="bindCard()" class="canClick"
	  							style="display: flex;flex-direction: column;text-align: center;align-items: center;">
	  							<img :src="$img('bankAdd.png')" alt=""
	  								style="margin: 0 auto;width: 20px;margin-top: 8vw;">
	  							<span style="margin: 0 auto;margin-top: 5px;"
	  								class="phoneMiddle boldSize">{{$t('添加银行卡')}}</span>
	  
	  						</div>
	  					</div>
	  
	  				</div>
	  				<div style="position: relative;width: 250px;margin: 0 auto;margin-top: 15px;">
	  					<img :src="$img('bank_img.png')" alt="" style="width: 100%;">
	  					<div class="bankBG">
	  						<div v-if="payInfo.myDouDouBT.length>1"
	  							style="display: flex;flex-direction: column;margin-left: 15px;">
	  						<span style="margin-top: 10px;"
	  								class="phoneBig boldSize">{{payInfo.myDouDouBT[1].BtName}}</span>
	  							
	  							<span style="margin-top: 35px;"
	  								class="phoneMiddle boldSize">{{payInfo.myDouDouBT[1].AccountName}}</span>
	  							<span style="margin-top: 5px;"
	  								class="phoneMiddle boldSize">{{payInfo.myDouDouBT[1].CardNum}}</span>
	  						
	  						</div>
	  						<div v-else @click="bindCard()" class="canClick"
	  							style="display: flex;flex-direction: column;text-align: center;align-items: center;">
	  							<img :src="$img('bankAdd.png')" alt=""
	  								style="margin: 0 auto;width: 20px;margin-top: 8vw;">
	  							<span style="margin: 0 auto;margin-top: 5px;"
	  								class="phoneMiddle boldSize">{{$t('添加银行卡')}}</span>
	  
	  						</div>
	  					</div>
	  
	  				</div>
	  				<div style="position: relative;width: 250px;margin: 0 auto;margin-top: 15px;">
	  					<img :src="$img('bank_img.png')" alt="" style="width: 100%;">
	  					<div class="bankBG">
	  						<div v-if="payInfo.myDouDouBT.length>2"
	  							style="display: flex;flex-direction: column;margin-left: 15px;">
	  							<span style="margin-top: 10px;"
	  									class="phoneBig boldSize">{{payInfo.myDouDouBT[2].BtName}}</span>
	  								
	  								<span style="margin-top: 35px;"
	  									class="phoneBig boldSize">{{payInfo.myDouDouBT[2].AccountName}}</span>
	  								<span style="margin-top: 5px;"
	  								class="phoneMiddle boldSize">{{payInfo.myDouDouBT[2].CardNum}}</span>
	  							
	  						</div>
	  						<div v-else @click="bindCard()" class="canClick"
	  							style="display: flex;flex-direction: column;text-align: center;align-items: center;">
	  							<img :src="$img('bankAdd.png')" alt=""
	  								style="margin: 0 auto;width: 20px;margin-top: 8vw;">
	  							<span style="margin: 0 auto;margin-top: 5px;"
	  								class="phoneMiddle boldSize">{{$t('添加银行卡')}}</span>
	  
	  						</div>
	  					</div>
	  
	  				</div>
					<div style="text-align: center; color: red; margin-top: 1vw" class="phoneSmall">
					  * Mỗi tài khoản chỉ liên kết tối đa 3 ngân hàng.
					</div>
					<div style="height: 30px;width: 100%;"></div>
	  		</div>
	  
	  
	  	</div>
	 
   <!-- <div
      class="card"
      v-for="(item, index) in payInfo.myDouDouBT"
      @click="chooseCard(item)"
    >
      <img v-if="index==0" :src="$img('bank_card_bg.png')" class="bank_card_bg" />
     <img v-if="index==1" :src="$img('bank_card_bg2.png')" class="bank_card_bg" />
     <img v-if="index==2" :src="$img('bank_card_bg3.png')" class="bank_card_bg" />
     
	  <img
        :src="$img('icon_act.png')"
        class="icon_act"
        :class="{ act: item.active }"
      />
      <div class="content">
        <div class="phoneMiddle boldSize">{{ item.BtName }}</div>
        <div class="phoneMiddle boldSize">{{ item.CardNum}}</div>
        <div class="phoneMiddle boldSize">{{ item.AccountName }}</div>
      </div>
    </div>
    <div class="card" @click="bindCard" v-if="payInfo.myDouDouBT.length!=3" >
      <img v-if="payInfo.myDouDouBT.length==0" :src="$img('bank_card_bg.png')" class="bank_card_bg" />
      <img v-if="payInfo.myDouDouBT.length==1&&index1==1" :src="$img('bank_card_bg2.png')" class="bank_card_bg" />
      <img v-if="payInfo.myDouDouBT.length==1&&index1==2" :src="$img('bank_card_bg3.png')" class="bank_card_bg" />
      <img v-if="payInfo.myDouDouBT.length==2&&index1==0" :src="$img('bank_card_bg3.png')" class="bank_card_bg" />
      
	  <div class="content">
        <img :src="$img('icon_add.png')" class="icon_add" />
        <div style="margin-top: 4%" class="phoneMiddle boldSize">THÊM NGÂN HÀNG</div>
      </div>
    </div> -->
  </div>
</template>

<script>
import bankCard from "@/views/mobile/bankCard";
export default {
  mixins: [bankCard],
};
</script>

<style lang="scss" scoped>
.content {
  position: absolute;
  top: 0%;
  bottom: 0%;
  right: 0%;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
  line-height: 20px;
}
.card_panel {
  display: flex;
  margin-top: 1vh;
  flex-direction: column;
}
.card {
  width: auto;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 1vh;
}
.bank_card_bg {
  width: 52vw;
  display: block;
}
.icon_add {
  width: 5vw;
}

.icon_act {
  position: absolute;
  bottom: 0%;
  right: 0%;
  width: 3vw;
  display: none;
}
.act {
  display: block;
}
.bankBG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  color: white;
  span {
  }
}
</style>