<template>
	<HomeNav style="position: fixed;top: 0;z-index: 9999;" :selectIndex='1' />
	
	<div style="position: relative;">
		<img :src="$img('turnPhoneBg.png')" alt="" style="width: 100%;">
		<div @click="showRecord=true" class="record canClick" style="position: absolute;top: 120px;text-align: center;font-size: 10px;z-index: 999;color:#e9af40;width:100vw;" >THÔNG TIN QUY ĐỔI</div>
		
		<div style="position: absolute;left: 0;top: 10vh;width: 100%;overflow-x: hidden;">
			<div style="display: flex;flex-direction: row;justify-content: space-between;width: 80%;margin-left: 10%;margin-top:10vw;">
				<div style="position: relative;">
					<img :src="$img('turnTableNo.png')" alt="" style="width: 95px;">
					<div
						style="color:#e9af40;position: absolute;left: 0;top:20px;right:0;bottom:0;text-align: center;">
						<div style="text-align: center;font-size: 10px;">Tích điểm</div>
						<div style="text-align: center;font-size: 16px;" v-if="config.Points>=0">{{Math.floor(config.Points)}}</div>
			
					</div>
				</div>
			
				<div style="position: relative;">
					<img :src="$img('turnTableNo.png')" alt="" style="width: 95px;">
					<div
						style="color:#e9af40;position: absolute;left: 0;top:25px;text-align: center;right:0;bottom:0;">
						<div style="font-size: 16px;">{{Math.floor(config.Points/config.TurnNeedPoints)}}<span style="font-size: 10px;">lần</span></div>
			
					</div>
				</div>
			
			
			
			</div>
			<div style="position: relative;margin: 0 auto;width: 300px;margin-top: -20px;">
<!-- 				<img :src="$img('light.png')" alt="" style="width: 300px;position: relative;z-index: 5;">
 -->				<img :src="$img('000.png')" alt=""
					style="width: 300px;position: absolute;top: 2.5px;left: 0;">
				<div style="position: absolute;width: 260px;left: 20px;top: 20px;">
					<zhuanPan ref="zhuanpan"></zhuanPan>
			
				</div>
				<img :src="$img('zhuanAc.png')" class="canClick" @click="toDraw()" alt="" style="width: 60px;position: absolute;z-index: 5;left: 120px;top: 120px;">
				
			</div>
			
			<div
				style="width: 247px;border: 1.5px solid rgba(246, 175, 23, 1);margin: 25px auto;height: 85px;border-radius: 10px;margin-top:340px;">
				<div style="width: 160px;margin: 3px auto;" class="flexs_sb">
			
			
					<img :src="$img('num_L.png')" v-if="config.LNum>0" alt="" style="width: 20px;">
					<img :src="$img('num_L_H.png')" v-else alt="" style="width: 20px;">
			
					<img :src="$img('num_U.png')" v-if="config.UNum>0" alt="" style="width: 25.5px;">
					<img :src="$img('num_U_H.png')" v-else alt="" style="width: 25.5px;">
			
					<img :src="$img('num_C.png')" v-if="config.CNum>0" alt="" style="width:22px;">
					<img :src="$img('num_C_H.png')" v-else alt="" style="width:22px;">
			
					<img :src="$img('num_K.png')" v-if="config.KNum>0" alt="" style="width: 27.5px;">
					<img :src="$img('num_K_H.png')" v-else alt="" style="width: 27.5px;">
			
					<img :src="$img('num_Y.png')" v-if="config.YNum>0" alt="" style="width: 28px;">
					<img :src="$img('num_Y_H.png')" v-else alt="" style="width: 28px;">
				</div>
				<div style="width: 210px;border: 1.5px solid rgba(246, 175, 23, 1);margin:2px auto;height:30.5px;border-radius: 10px;"
					class="flexs_r_c">
					<img :src="$img('iphone14.png')" alt="" style="width: 14px;margin-left:9px;">
					<div style="font-size:10px;color:white;font-weight:bold;margin-left:12.5px;">IPHONE 14 PRO
						MAX 256GB</div>
				</div>
				<div
					style="color: white;font-size: 10px;margin-left: 23.5px;  font-style: italic;margin-top: -2px;">
					*Thu thập 5 chữ cái để nhận thưởng</div>
			</div>
			<div style="width: 296px;margin: 20px auto;">
				<div v-for="(item,index) in jiangList" :key="item" style="position: relative;margin-top: 10px;">
					<img :src="$img('jiangWrap.png')" alt="" style="width: 100%;">
					<div class="flexs_r_c" style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;">
						<div class="flexs_r_c" style="position: relative;">
							<div class="numWrap">
				
								<div style="position: relative;z-index: 11;">{{item.num}}</div>
								<img v-if="index==0" :src="$img('No1.png')" alt="">
								<img v-else-if="index==1" :src="$img('No2.png')" alt="">
								<img v-else-if="index==2" :src="$img('No3.png')" alt="">
								<img v-else :src="$img('No_other.png')" alt="">
				
							</div>
				
				
						</div>
						<img :src="$img('jiang_'+item.word+'.png')" alt=""
							style="height:26px;margin-left: 32px;">
				
						<div
							style="font-size: 12px;position: absolute;left:195px;height: 32px;line-height: 32px;color: white;">
							{{item.money}}</div>
							
					</div>
				</div>
				
			</div>
			
			<div style="250px;margin: 0px auto;">
				<img :src="$img('winner.png')" alt="" style="width: 70%;margin-left: 10%;">
				<div style="position: relative;">
					<img :src="$img('whiteBg.png')" alt="" style="width: 90%;margin-top: 10px;margin-left: 5%;">
					<div style="position: absolute;width: 100%;height: 90%;left: 0;top: 5%;overflow: hidden;">
						<div  class="gameList"  :style="{'margin-top': tableTop + 'px'}">
							<div v-for="(item,index) in tzList" :key="index"  style="height: 35px;width: 80%;margin-left: 10%;border-bottom: 1px solid #293659;position: relative;" >
								<div  class="flexs_sb" style="margin-top: 3px;">
									<div style="color:#f0f0f0;font-size: 14px;">{{$tools.formatDate(item.CreateAt, "yyyy-MM-dd")}}</div>
									
									<div style="color:#95a0b4;font-size: 14px;">{{item.NickName.substr(0,2)+'***'+item.NickName.substr(item.NickName.length-2,2)}}</div>
									
								</div>
								
								<div  class="flexs_sb" style="margin-top: 3px;">
									<div>&nbsp;</div>	
									
									<div  style="color:white;font-size: 12px;color:#ffe400;" v-if="item.GetGold>0" class="canClick">{{item.WordType}}-{{item.GetGold/1000}}K</div>
									<div  style="color:white;font-size: 12px;color:#ffe400;" v-else class="canClick">{{item.WordType}}-{{item.GetPoint}}điểm</div>
									
								</div>
											
								
							</div>
						</div>
					</div>
				</div>
				<img :src="$img('dipan.png')" alt="" style="width: 100%;margin-top: -60px;">
				
			</div> 
			
			<div
						style="width: 280px;margin: 20px auto;position: relative;border-radius: 10px;padding: 0 18px;padding-top: 45px;color:#0a194a;font-size:13px;font-weight:bold;background-color: white;padding-bottom:25px ;line-height: 28px;margin-top: 47px;">
								<div style="position: absolute;left: 83px;top:-25px;"  >
									<div style="position: relative;">
										<img :src="$img('turnTableBtn.png')" alt="" style="width: 150px;">
										<div style="color:red;font-size:10px;width:100%;text-align: center;font-weight: bold;top: 0px;position: absolute;">QUY TẮC TÍCH ĐIỂM</div>
									</div>
									
								</div>
								<div>Tích điểm có lợi ích gì?<br>
									Điểm có thể được sử dụng để tham gia vòng quay may mắn và mỗi lần quay cần 10 điểm.<br><br>
			
									Làm thế nào để kiếm được tích điểm?<br>
									Nạp tiền<br>
									Mỗi lần nạp sẽ sinh ra điểm, nạp tích lũy 3,500,000 sẽ được 1 điểm.<br><br>
			
									Đặt cược<br>
									Mỗi cược hợp lệ của bạn sẽ tạo ra tích điểm và bạn sẽ nhận được 1 điểm cho cược tích lũy
									10,000,000.<br>
									Đặt cược hợp lệ là gì? Cược thực sự thắng hoặc thua là một cược hợp lệ.<br>
			
									<!-- Mời bạn bè<br>
									Bạn có thể nhận được tích điểm bằng cách nhờ bạn bè đăng ký và điền đầy đủ thông tin cơ bản
									thông qua mã mời độc quyền của bạn , Vui lòng theo dõi trên trang khuyến mãi .<br><br> -->
			
									Nhận khuyến mãi <br>
									Không cố định thời gian chúng tôi sẽ thực hiện một số khuyến mãi , phần thưởng khuyến mãi sẽ bao
									gồm tích điểm và các phần thưởng hấp dẫn khác . Vui lòng theo dõi trên trang khuyến mãi .<br>
									Haoli, hãy chú ý nhiều hơn đến trang sự kiện.<br><br>
									<span style='color:#ff0000;'>
										*Lưu ý:<br>
										1. Bất kỳ nhóm hoặc cá nhân nào gian lận hoặc lợi dụng nhầm trục lợi khuyến mãi , HDBET88 có
										quyền hủy tài khoản và tất cả quyền lợi của hội viên đó ;<br>
										2. HDBET88 có quyền thực hiện, sửa đổi và chấm dứt hoạt động của mình theo quyết định riêng
										của mình mà không cần thông báo trước;<br>
										3. Tất cả các khyến mãi được thực hiện theo các quy tắc ưu đãi của HDBET88.</span>
								</div>
							</div> -->
			
			<div>
				
			</div>

		</div>
		<showAward v-if="showDia" @close='showDia=false' :awardData='awardObj'></showAward>
		<turnRecord v-if="showRecord" @cancel='showRecord=false'></turnRecord>

	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import mqant from "@/components/protocol/mqantlib";
	import bus from "@/utils/bus.js"
	import i18n from "@/locales/i18n";
	import {
		theme
	} from '@/views/theme'

	let zhuanPan = require('@/views/' + 'mobile5' + '/components/zhuanPan.vue').default
	let showAward = require('@/views/' + 'mobile5' + '/components/showAward.vue').default
	let turnRecord = require('@/views/' + 'mobile5' + '/components/turnRecord.vue').default
	
		import  tools from "@/utils/tools";

	import {
		req,
		qs
	} from "@/utils/request";
	let HomeNav = require('@/views/' + theme + '/components/HomeNav.vue').default
	
	import {
		topic
	} from "@/components/protocol/api";
			import { http } from "@/components/protocol/api";
	import {
		Dialog
	} from 'vant';
	import 'vant/es/dialog/style';
	export default {
		components: {
			zhuanPan,
			showAward,
			HomeNav,
			turnRecord
		},
		data() {
			return {
				config:{},
				tableTop:0,
				timer:null,
				tzList:[],
				showRecord:false,
				
				jiangList: [{
						'num': 1,
						'word': 'y',
						'money': '8888K'
					},
					{
						'num': 2,
						'word': 'k',
						'money': '5888K'
					},
					{
						'num': 3,
						'word': 'c',
						'money': '888K'
					},
					{
						'num': 4,
						'word': 'u',
						'money': '30 ĐIỂM'
					},
					{
						'num': 5,
						'word': 'u',
						'money': '388K'
					},
					{
						'num': 6,
						'word': 'l',
						'money': '128K'
					},
					{
						'num': 7,
						'word': 'l',
						'money': '18K'
					},
					{
						'num': 8,
						'word': 'l',
						'money': '2 ĐIỂM'
					}

				],
				canTurn:true,
				awardObj:null,
				showDia:false

			}
		},
		computed: {
			...mapGetters("tcp", ["isLogged", "isConnect", "info"]),
			...mapGetters("global", ["lobbyInfo"]),

		},

		created() {
			this.getRecord()
			if(this.isConnect){
				this.getConfigInfo()
				
			}
			var that = this
			bus.off('HD_StartTurnTable')
			bus.on('HD_StartTurnTable', (val) => {
					console.log('zsd1111',val)
				 var obj = val.data.Data
				 that.awardObj = obj
				 that.$refs.zhuanpan.toDraw(obj.No)
			})
			
			
			bus.off('canTurn')
			bus.on('canTurn', (val) => {
					//console.log('zsd1111',val)
				that.showDia = true
				 that.canTurn = true
				 that.getConfigInfo()
				 
			})
			
			this.timer = setInterval(() => {
								  if( -(this.tableTop)>this.tzList.length*35 ){
									  this.tableTop = 0;
								  }else {
									  this.tableTop -= 1;
									  
								  }
			}, 100);

		},
		methods: {
			async getRecord(){
			//turnTableAllRecord
			
				let params = {
				 TurnTableAllRecord:'',
				  platformType: tools.platform(),
				}
				var that = this
				let rsp = await req.post(http.lobbyConf, qs.stringify(params));
				if (rsp.status == 200 && rsp.data.Code == 0) {
					that.tzList = rsp.data.Data.TurnTableAllRecord
				} else {
				 
				 }
			},
			getConfigInfo(){
				let params = {
				
				
				};
				let that = this;
				mqant.request(topic.getTurnTableConf, params, function(res) {
					if (res.Code == 0) {
						that.config = res.Data.Conf
						
					} else {
						that.$message({
							message: that.$t(res.ErrMsg),
							type: 'error'
						});
					}
				});
			},
			toDraw() {
				
				if (!this.isLogged) {
					bus.emit('showLogin', {})
					return
				}
				if(!this.canTurn){
					return
				}
				this.canTurn = false
				let params = {
				
				
				};
				let that = this;
				mqant.request(topic.startTurnTable, params, function(res) {
					//console.log('asdaaz',res)
					if (res.Code == 0) {
						// var obj = res.Data
						// that.$refs.zhuanpan.toDraw(obj.No)
						
						
					} else {
						
						that.$message({
							message: that.$t(res.ErrMsg),
							type: 'error'
						});
					}
				});
			}

		},

	};
</script>

<style scoped lang="scss">
	.numWrap {
		line-height: 26px;
		text-align: center;
		margin-left: 10px;
		position: relative;
		font-size: 17.5px;
		width: 26px;
		height: 26px;
		z-index: 1;

		img {

			width: 26px;
			position: absolute;
			left: 0;
			top: 0;
			z-index: 0;
		}
	}
</style>
