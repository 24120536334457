<template>
	<div style="width: 70%;margin-left: 15%;padding-bottom: 50px;">


		<div v-for="(item,index) in dataList" :key="item" style="margin-top: 30px;background-color:#2e2d2c;padding: 20px;border-radius:8px;">
			<div class="title">{{item.ActivityName}}</div>
			<div class="flexs_sb"
				style="padding-top: 10px;margin-top: 1px;border-top: 1px solid rgb(100,100,100);color:white;">
				<div  style="font-size: 18px;">{{$t('参与时间')}}:{{$tools.formatDate(item.JoinTime)}}</div>
				<div v-if="item.Type=='SportGuess'">
			 				<div v-if="item.ActivityStatus=='Undo'" class="Undo btn mainBtnBG" style="width:250px;">{{$t('次日领取')}}</div>
									
							<div v-if="item.ActivityStatus=='NoAward'" class="Undo btn">{{$t('猜错')}}</div>
							
								<div v-if="item.ActivityStatus=='Done'" class="Done btn canClick mainBtnBG" @click="getActivity(item)">{{$t('领取')}}</div>
							
					 		
				</div>
				<div v-else>
					<div v-if="item.Type=='Charge300'">
						<div v-if="item.ActivityStatus=='Undo'&&item.CurBets>=item.GoalBets" @click="toCharge()" class="canClick btn mainBtnBG">{{$t('充值')}}</div>
						<div v-if="item.ActivityStatus=='Undo'&&item.CurBets<item.GoalBets"  class="Undo btn">{{$t('已领取')}}</div>
						
						<div v-if="item.ActivityStatus=='Done'" class=" btn canClick mainBtnBG" @click="getActivity(item)">{{$t('领取')}}</div>
						
					</div>
					<div v-else>
						<div v-if="item.ActivityStatus=='Undo'" class="Undo btn ">{{$t('未完成')}}</div>
						
							<div v-if="item.ActivityStatus=='Done'" class=" btn canClick mainBtnBG" @click="getActivity(item)">{{$t('领取')}}</div>
							
							
										
						<div v-if="item.ActivityStatus=='Restart'" class=" btn canClick mainBtnBG" @click="restart()">{{$t('重新开始')}}</div>
						
					</div>
										
				</div>
							
				
			</div>

			<div v-if="item.Type=='Sign'||item.Type=='FirstChargeGift'" style="">
				<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 19px;">
					<div>{{$t('充值金额')}}</div>

					<div style="color:rgb(128,134,145);font-size: 18px;">{{$t('剩余')}}<span
							style="color: white;">&nbsp;{{item.RemainDays}}&nbsp;</span>{{$t('天')}}</div>

				</div>
				
				<van-progress v-if="item.CurCharge==0&&item.GoalCharge==0" :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="0" style="margin-top: 13px;" />
				
				<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="item.CurCharge<item.GoalCharge?item.CurCharge/parseFloat(item.GoalCharge)*100:100" style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
					{{$tools.formatNum2k(item.GoalCharge)}}
				</div>

				<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 10px;">
					<div>{{$t('有效投注')}}</div>


				</div>
				
				<van-progress v-if="item.CurBets==0&&item.GoalBets==0" :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="0" style="margin-top: 13px;" />
				
				<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="item.CurBets<item.GoalBets?item.CurBets/parseFloat(item.GoalBets)*100:100" style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
					{{$tools.formatNum2k(item.GoalBets)}}
				</div>

			</div>
			<div v-else-if="item.Type=='Regist'">
				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div style="font-size: 18px;">{{$t('充值金额')}}</div>

					<div style="color:rgb(128,134,145);font-size: 18px;">{{$t('剩余')}}<span
							style="color: white;">&nbsp;{{item.RemainDays}}&nbsp;</span>{{$t('天')}}</div>

				</div>
				
				<van-progress v-if="item.CurCharge==0&&item.GoalCharge==0" :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="0" style="margin-top: 13px;" />
				
				<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="item.CurCharge<item.GoalCharge?item.CurCharge/parseFloat(item.GoalCharge)*100:100" style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
					{{$tools.formatNum2k(item.GoalCharge)}}
				</div>

				<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 10px;">
					<div>{{$t('有效投注')}}</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
						{{$tools.formatNum2k(item.CurBets)}}
					</div>

				</div>


			</div>
			<div v-else-if="item.Type=='FishMan'">
				<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 10px;">
					<div>{{$t('今日盈亏')}}</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
						{{$tools.formatNum2k(item.CurIncome)}}
					</div>

				</div>
			</div>
			
			
			<div v-else-if="item.Type=='SportGuess'">
				<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 10px;">
					<div>{{$t('今天赢')}}</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
						{{$tools.formatNum2k(item.CurIncome)}}
					</div>
			
				</div>
			</div>
			<div v-else-if="item.Type=='Charge300'" style="color: white;font-size: 18px;margin-top: 10px;">
				<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 19px;">
					<div>&nbsp;</div>
				
					<div style="color:rgb(128,134,145);font-size: 18px;">{{$t('剩余')}}<span
							style="color: white;">&nbsp;{{item.RemainDay}}&nbsp;</span>{{$t('天')}}</div>
				
				</div>
				<div v-if="item.ActivityStatus=='Done'||item.CurBets<item.GoalBets">
					<div class="flexs_sb" >
						<div>{{$t('返利')}}</div>
						<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
							{{$tools.formatNum2k(item.Get)}}
						</div>
					</div>
					<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 10px;">
						<div>{{$t('流水')}}</div>
					
					
					</div>
					
					<van-progress v-if="item.CurBets==0&&item.GoalBets==0" :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
						:percentage="0" style="margin-top: 13px;" />
					
					<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
						:percentage="item.CurBets<item.GoalBets?item.CurBets/parseFloat(item.GoalBets)*100:100" style="margin-top: 13px;" />
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
						{{$tools.formatNum2k(item.CurBets)}}/{{$tools.formatNum2k(item.GoalBets)}}
					</div>
					<!-- <div class="flexs_sb" >
						<div>{{$t('第一笔流水')}}</div>
						<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
							{{$tools.formatNum2k(item.GoalBets)}}
						</div>
					</div> -->
				</div>
				<div v-else>
					<div class="flexs_sb" >
						<div>{{$t('第'+item.ChargeType+'笔充值')}}</div>
						<!-- <div>{{$t('剩余')}}{{item.RemainDay}}{{$t('天')}}</div> -->
						<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
							>={{$tools.formatNum2k(item.MinCharge)}}
						</div>
						
					</div>
					<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 10px;">
						<div>{{$t('最高返利')}}</div>
						<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
							{{$tools.formatNum2k(item.MaxGet)}}
						</div>
					
					</div>
				</div>
				
					
				
				<!-- <div class=" btn canClick mainBtnBG" @click="getActivity(item)">{{$t('领取')}}</div> -->
				
			
			</div>
			<div v-else-if="item.Type=='SportWin'">
				
				<div class="flexs_r_c"
					style="padding-top: 10px;margin-top: 1px;border-top: 1px solid rgb(100,100,100);color:white;flex-wrap: wrap;font-size: 16px;">
						<div class="flexs_r_c" style="width: 25%;margin-top: 20px;position: relative;" v-for="(item1,index1) in gameList" :key="item1">
							<div style="margin: 0 auto;">{{item1}}</div>
							<img v-if="item.CurLoseNo-1==index1" :src="$img('cuowu.png')" alt="" style="width: 30px;position: absolute;right: 70px;">
							<img v-if="item.CurWinCnt>index1" :src="$img('zhengque.png')" alt="" style="width: 30px;position: absolute;right: 70px;">
							
						</div>
						
				</div>
				
				
				
				<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 10px;">
					<div>{{$t('有效投注')}}</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
						{{$tools.formatNum2k(item.CurBets)}}
					</div>
				
				</div>
				
			</div>
			
			<div v-else-if="item.Type=='Lobby'">
				
				
				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div style="font-size: 18px;">{{$t('充值金额')}}</div>
				
					<div style="color:rgb(128,134,145);font-size: 18px;">{{$t('剩余')}}<span
							style="color: white;">&nbsp;{{item.RemainDays}}&nbsp;</span>{{$t('天')}}</div>
				
				</div>
				<van-progress v-if="item.CurCharge==0&&item.NeedCharge==0" :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="0" style="margin-top: 13px;" />
				<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="item.CurCharge<item.NeedCharge?item.CurCharge/parseFloat(item.NeedCharge)*100:100" style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
					{{$tools.formatNum2k(item.NeedCharge)}}
				</div>
				
				
				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div style="font-size: 18px;">{{$t('体育有效投注')}}</div>
				
				
				
				</div>
				
				<van-progress v-if="item.CurSportBets==0&&item.NeedSportBet==0" :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="0" style="margin-top: 13px;" />
				
				<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="item.CurSportBets<item.NeedSportBet?item.CurSportBets/parseFloat(item.NeedSportBet)*100:100" style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
					{{$tools.formatNum2k(item.NeedSportBet)}}
				</div>
				
				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div style="font-size: 18px;">{{$t('真人有效投注')}}</div>
				
				</div>
				
				<van-progress v-if="item.CurCasinoBets==0&&item.NeedCasinoBet==0" :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="0" style="margin-top: 13px;" />
				<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="item.CurCasinoBets<item.NeedCasinoBet?item.CurCasinoBets/parseFloat(item.NeedCasinoBet)*100:100" style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
					{{$tools.formatNum2k(item.NeedCasinoBet)}}
				</div>
				
			</div>
		</div>
		
		
		<div v-for="(item,index) in discountList" :key="item" style="margin-top: 30px;background-color:#2e2d2c;padding: 20px;border-radius:8px;">
			<div class="title">{{item.ActivityName}}</div>
			<div class="flexs_sb"
				style="padding-top: 10px;margin-top: 1px;border-top: 1px solid rgb(100,100,100);color:white;">
				<div style="font-size: 18px;">&nbsp;</div>
			
				
			</div>
			<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 0px;">
				<div class="flexs_r_c">
					<div>{{$t('金额')}}&nbsp;</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 0px;font-size: 18px;">
						{{$tools.formatNum3k(item.Get)}}
					</div>
				</div>
				<div class=" btn canClick mainBtnBG" @click="lingqu(item)">{{$t('领取')}}</div>
				
			
			</div>
		</div>
		
		<NoData v-show="dataList.length==0"  :title="$t('暂无活动可领取')" style="margin-top: 100px;width: 100%;background-color: transparent;"></NoData>
		<addBank v-if="showAddBankVisible" @handleClose='showAddBankVisible=false'></addBank>
		



	</div>
</template>

<script>
	import {
		ref
	} from "vue";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import {
		theme
	} from '@/views/theme'
	import i18n from "@/locales/i18n";

	import {
		bus,
		busName
	} from "@/components/core/bus";
	import {
		http,
		topic
	} from "@/components/protocol/api";
	import mqant from "@/components/protocol/mqantlib";
	let NoData = require('@/views/'+ theme +'/components/NoData.vue').default
		let addBank = require('@/views/'+ theme +'/components/addBank.vue').default

	export default {

		components: {
			NoData,
			addBank
		},

		computed: {
			...mapGetters("global", ["lobbyInfo"]),
		},
		setup() {

		},
		created() {
			//this.getData()
		},
		mounted() {
			this.getData()
			this.getDiscountList()
		},
		methods: {
			toCharge(){
				var that = this
				mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
					
					if (!data.Data.myDouDouBT.length) {
						that.$message({
							message: that.$t('请绑定银行卡'),
							type: 'info'
						});
						that.showAddBankVisible = true
						return
					} 
					that.$router.push({
						name:'recharge'
					})
				});
				
				
			},
			
			
			lingqu(obj){
				var that = this
				mqant.request(topic.discount, {ActivityId:obj.ActivityId}, function(data, topicName, msg) {
					if(data.Code==0){
						that.$message({
						          message: that.$t('领取成功')+'!',
						          type: 'success'
						        });
						that.getDiscountList()
					}else {
						that.$message({
						          message: data.ErrMsg,
						          type: 'error'
						        });
					}
				});
				
				
				
			},
			getDiscountList() {
				var that = this
				mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
					 that.discountList = data.Data.BonusList.concat(data.Data.RebateList)
				});
			},
			getActivity(val) {
				let that = this;
				var url;
				if (val.Type == 'Sign') {
					url = topic.receiveSign
				} else if (val.Type == 'Regist') {
					url = topic.receiveRegist

				} else if (val.Type == 'FishMan') {
					url = topic.receiveFishMan

				} else if (val.Type == 'FirstChargeGift') {
					url = topic.receiveFirstChargeGift

				}else if (val.Type == 'Lobby') {
					url = topic.receiveLobby

				}else if (val.Type == 'SportWin') {
					url = topic.receiveSportWin

				}else if (val.Type == 'SportGuess') {
					url = topic.receiveSportGuess

				}else if (val.Type == 'Charge300') {
					url = topic.receiveCharge300

				}
				console.log('vb',val.Type,url)

				mqant.request(url, {
					language: i18n.global.locale
				}, function(res) {
					
					if (res.Code == 0) {
						that.$message({
							message: that.$t('领取成功'),
							type: 'success'
						});
						
						that.$router.push({
							name:'getActivityRecord'
						})
						that.getData()
					} else {
						that.$message({
							message: that.$t(res.ErrMsg),
							type: 'error'
						});
					}
				});
			},
			restart(){
				let that = this;
				mqant.request(topic.restartSportWin, {
					language: i18n.global.locale
				}, function(res) {
					if (res.Code == 0) {
						that.getData()
					} else {
						that.$message({
							message: that.$t(res.ErrMsg),
							type: 'error'
						});
					}
				});
			},
			getData() {


				let that = this;
				mqant.request(topic.getActivityUserInfo, {
					language: i18n.global.locale
				}, function(res) {
					console.log('zzzz',res)
					if (res.Code == 0) {
						that.dataList = res.Data
					} else {
						that.$message({
							message: that.$t(res.ErrMsg),
							type: 'error'
						});
					}
				});
			},
		},
		data() {
			return {
				showAddBankVisible:false,
				dataList: [],
				discountList:[],
				gameList:[this.$t('第一场'),this.$t('第二场'),this.$t('第三场'),this.$t('第四场'),this.$t('第五场'),this.$t('第六场'),this.$t('第七场'),this.$t('第八场')]
			};
		},
	};
</script>

<style lang="scss" scoped>
	.main {
		// margin-top: 10vh;
		position: relative;
		padding: 3vw;
		flex: 1;

		>.bottom {
			width: 100%;
			position: absolute;
			bottom: 0%;
		}
	}

	.title {
		width: 500px;
		height: 25px;
		line-height: 25px;
		font-size: 22px;
		color: white;
	// 	background: linear-gradient(to right, rgba(22, 64, 107, 0.32), rgb(25, 39, 60), rgb(11, 22, 44));
	// 
	}

	.btn {
		//width: 100px;
		padding: 0 10px;
		height: 35px;
		text-align: center;
		line-height: 35px;
		border-radius: 4px;
		font-size: 18px
	} 

	.Undo {
		background-color: rgb(62, 69, 81);
		width: 170px;
		
	}

	.Done {
	background: linear-gradient(to right,#6348ff,#4a5dff);
	}
</style>
<style>
	table {
		width: 100%;
		border-top: 1px solid white;
		border-left: 1px solid white;
	}

	table td,
	table th {
		border-bottom: 1px solid white;
		border-right: 1px solid white;
		padding: 3px 5px;
	}

	table th {
		border-bottom: 1px solid white;
		text-align: center;
	}
</style>
