<template>
  <panel active="games" cutType="Card"/>
</template>

<script>
import Panel from './panel.vue';
export default {
  components: {  Panel },
};
</script>

<style lang="scss" scoped>

</style>