<template>
  <div class="phone">
    <div class="center">
      <div class="flexs_r_c select-bank" style="justify-content: center;">
        <div v-for="(item, index) in payBankList" class="select-bank-item"
           @click="itemClick(index)">
          <img :src="$img(item.icon)" alt="">
         

          <div
          v-if="curPaySelectIndex != index"
          class="mask"
        ></div>
        </div>
      </div>
      <div style='color:red;text-align:left;margin:0 20px;margin-top:20px;'>LƯU Ý: Nếu chọn sai mệnh giá thì HDBET88 sẽ không chịu trách nhiệm !</div>

      <div class="select-money" @click="showPicker = true">
        <div class="flexs_r_c">
          <span class="span-1">Chọn mệnh giá</span>
        <span class="span-2">{{ $tools.formatNum(obj.curItemObj.Amount) }}</span>
        </div>
        
        <img :src="$img('icon_jiantou.png')" alt="">
      </div>
      <input v-model="obj.account" type="text" placeholder="Vui lòng nhập mã thẻ cào">

      <input v-model="obj.cardNumber" type="number" placeholder="Vui lòng nhập số sê-ri">

    </div>

   
   
        <div class="pay-btn" @click="onSubmit">
          Nạp ngay
        </div>
   
    <div class="card">
      <div class="info-class">
        <div class="title">
          <div class="title-text line">SỐ TIỀN</div>
          <div class="title-text">Quy đổi</div>
        </div>
        <div v-for="(item, index) in payBankList[curPaySelectIndex].data" class="title-item"
          :class="index % 2 == 0 ? 'singel-color' : ''">
          <div class="child-item line">{{ $tools.formatNum(item.Amount) }}</div>
          <div class="child-item right-item">{{ $tools.formatNum(item.Amount - item.FeePerThousand * item.Amount / 1000.0)
          }}</div>
        </div>
      </div>
    </div>
    <van-popup v-model:show="showPicker" round position="bottom">
      <van-picker :columns="obj.amountList" @cancel="showPicker = false" @confirm="onConfirm"
        confirm-button-text="confirm" cancel-button-text="cancel" />
    </van-popup>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, reactive, nextTick, watch, defineProps } from "vue";
import { Toast } from "vant";
import { useGetters, useActions, useMutations } from "@/store/hooks/storeState/index";
import { bus } from "@/components/core/bus";
import tools from "@/utils/tools";
import log from "@/utils/logger";
import { ElMessage } from "element-plus";
import { useRouter } from "vue-router";
	import i18n from "@/locales/i18n";

import  mqant  from "@/components/protocol/mqantlib";
import { topic } from "@/components/protocol/api";
import { getApiLoginUrl } from "@/components/protocol/api";
import { getCurrentInstance } from "vue";
const { isLogged, wallet } = useGetters("tcp", ["isLogged", "wallet"]);
const { lobbyInfo } = useGetters("global", ["lobbyInfo"]);
let { payInfo } = useGetters("pay", ["payInfo"]);




const router = useRouter();
const $act = getCurrentInstance().appContext.config.globalProperties.$act;
const { userInfo } = useGetters("user", ["userInfo"]);
const { onLogged, doLogin } = useActions("user", ["onLogged", "doLogin"]);
const loading = ref(true);
const props = defineProps({
  data: {}
})
let showPicker = ref(false);
let obj = reactive({
  amountList: [],
  curItemObj: '',
  account: '',
  cardNumber: '',
  MethodId: ''
})
let curPaySelectIndex = ref(0);
let payBankList = reactive([
  { icon: 'yinh_VH.png', text: '', data: [] },
  { icon: 'yinh_mb.png', text: '', data: [] },
  { icon: 'yinh_fh.png', text: '', data: [] },
])
onMounted(() => {
  // if (Object.keys(props.data).length <= 0) return;
  //console.log('mmmm',payInfo.value)

  bus.on('requestPayInfo',()=>{
    dealPay()
  })
  dealPay()
})

function dealPay() {
  for (let i = 0; i < payInfo.value.phoneChargeConf.length; i++) {
    let info = payInfo.value.phoneChargeConf[i];
    if (info.Name == "VIETTEL") {
      payBankList[1].data.push(info)
    } else if (info.Name == "VINAPHONE") {
      payBankList[0].data.push(info)
    } else {
      payBankList[2].data.push(info)
    }
  }
  refreshDataByIndex();
  for (let key in payInfo.value.payList) {
    let info = payInfo.value.payList[key];
    if (info.MethodType == "naptudong") {
      obj.MethodId = info.MethodId;
    }
  }
}


function refreshDataByIndex() {
  //reset
  obj.amountList = [];
  obj.account = '';
  obj.cardNumber = '';
  for (let i = 0; i < payBankList[curPaySelectIndex.value].data.length; i++) {
    let info = payBankList[curPaySelectIndex.value].data[i];
    if (i == 0) {
      obj.curItemObj = info;
    }
    obj.amountList.push(info.Amount);
  }
}

function itemClick(index) {
  curPaySelectIndex.value = index;
  refreshDataByIndex();
}
function onConfirm(value) {
  for (let i = 0; i < payBankList[curPaySelectIndex.value].data.length; i++) {
    let info = payBankList[curPaySelectIndex.value].data[i];
    if (value == info.Amount) {
      obj.curItemObj = info;
      break;
    }
  }
  showPicker.value = false;
}
function onSubmit() {
  if (!obj.account) {
    Toast.fail('请输入电话卡号');
    return;
  }
  if (!obj.cardNumber) {
    Toast.fail('请输入序列号');
    return;
  }
  let objSubmit = {
    amount: obj.curItemObj.Amount,
    methodId: obj.MethodId,
    serial: obj.cardNumber+'',
    code: obj.account,
    telco: obj.curItemObj.Name,
  }
   log.info('objSubmit', objSubmit)
  tools.loading()
  mqant.request(topic.payCharge, objSubmit, function (res) {
    log.info('data', res)

    tools.dismissLoading()
    if (res.Code == 0) {
      obj.account = '';
      obj.cardNumber = '';
      Toast.success(res.ErrMsg)
    } else {
      Toast.fail(res.ErrMsg)
    }
  })
}
</script>

<style lang="scss" scoped>
.phone {
  .center{
    margin-top:22px;
    width: 94vw;
    margin-left:3vw;
   padding-bottom: 10px;
    position:relative;
    border-radius: 6px;
    background-color:#2a2a2a ;
  }


  input{
    margin:20px 15px;
    height:35px;
    border-bottom:1px solid #4a4a49;
    width:calc(100% - 30px);
    color:#fff;
  }
    .channelTitle {
      height: 4.8vw;
      font-family: PingFangSC-Regular;
      font-size: 3.466667vw;
      color: #fff;
      font-weight: 400;
      margin-bottom: 2.666667vw;
    }

    :deep(.van-cell) {
      background-color: #1e2646;
      border-radius: 8px;
      font-size: 12px;
      --van-field-label-color: #424f81;
      --van-field-input-text-color: #fff;
    }
  

  .select-bank {
    width: 100%;
   padding-top: 30px;

    .select-bank-item {
      width: 100px;
      margin-left: 6px;
      position:relative;
      .mask{
        z-index: 9;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3);
      }
      

      img {
       // margin-top: 4px;
        width: 100%;
       // height: 43px;
      }

      span {
        margin-bottom: 8px;
        font-size: 12px;
        color: #8a9fdc;
      }
    }

    .is-select {
      background-color: #394674;
      border: solid 1px #90a2dc;

      span {
        color: #fff;
      }
    }
  }

  .select-money {
   padding:0 15px;
   margin-top: 20px;
    height: 49px;
    
  
    display: flex;
    justify-content: space-between;
    align-items: center;

    .span-1 {
      font-size: 12px;
      color: #b5b1ac;
      
    }

    .span-2 {
      font-size: 12px;
      color: #ffffff;
      margin-left:30px;
    }

    img {
      height: 11px;
    }
  }

  .input-bank {
    margin-top: 10px;
    
  }

  .input-number {
    margin-top: 10px;
  }

  .pay-btn {
    margin:25px 15px;
    text-align: center;
    line-height:44px;
    height: 44px;
    font-size:18px ;
    border-radius: 6px;
    background: linear-gradient(to right,#dbab7b,#e7c7a5,#f3e3ce,#dcb07f);
   
  }

  .info-class {
    margin-top: 25px;
    width: 94vw;
    margin-left:3vw;
    background-color: #232733;
    border-radius: 5px;
    border: solid 1px #4a4a49;

    .line {
      border-right: 1px solid #4a4a49;
    }

    .title {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      width: 100%;
      height: 29px;
      background-color: #242424;
      display: flex;

      .title-text {
        width: 50%;
        height: 100%;
        font-size: 12px;
        color: #e5c89b;
        text-align: center;
        line-height: 29px;
      }
    }

    .singel-color {
     // background-color: #303c68 !important;
    }

    .title-item {
      width: 100%;
      display: flex;
      height: 29px;
      background-color: #2a2a2a;
      border-bottom: 1px solid #4a4a49;



      .child-item {
        width: 50%;
        height: 100%;
        font-size: 10px;
        color: #fff;
        text-align: center;
        line-height: 29px;
      }

      .right-item {
        color: #fff;
      }
    }
  }
}
</style>
