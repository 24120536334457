<template>
  <div class="main">
    <div
      v-for="(item, index) in imgList"
      :class="{ margin: index > 0 }"
      :key="item.value"
      @click="change(item, index)"
      class="panel"
    >
      <img
        class="icon"
        :class="{ img_gray: item.maintena }"
        :src="index == curAct ? item.imgAct : item.img"
      />
      <div class="maintena" v-if="item.maintena">
        <img :src="$img('warn.png')" />
        <div class="content">
          <div v-if="item.status == apiStatus.pre">
            {{ $t("即将上线") }}
          </div>
          <div v-else>{{ $t("正在维护") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

	import HomeCat from "@/views/mobile/HomeCat"
	
	export default{
	  mixins:[HomeCat]
	}
</script>

<style lang="scss" scoped>
.maintena {
  position: absolute;
  top: 11%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  text-align: center;
  > img {
    width: 27%;
  }
  > .content {
    font-size: 12px;
    font-weight: bold;
    color: #373737;
    padding: 3% 3%;
    border: solid 1px black;
    background-color: #f8d34d;
    border-radius: 4px;
    transform: scale(0.8);
  }
}
.panel {
  position: relative;
}
.main {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  //   justify-content: space-around;
}
::-webkit-scrollbar {
  display: none;
}
.icon {
  display: block;
  width: 16vw;
}
.margin {
  margin-left: 3%;
}
</style>