<template>
	<div style="" class="rightTool">
		<div style="position: relative;text-align: left;">
			
						
					
			
				
				<img :src="$img('sanjiao.png')" alt=""  style="width: 14px;position: absolute;top: -8px;right:10px;">
				
					<div class="flexs_r_c canClick" @click="toUrl(lobbyInfo.CustomerInfo.customerLiveChat)" @mouseenter='hoverIndex=0' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);" >						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							<img :src="$img('kf_icon.png')" alt=""  style="width: 26px;">
							
							<div style="margin-left: 10px;font-size: 12px">
								<div style="color: #fdfeff;">7x24{{$t("小时")}}</div>
								<div style="color: #95a0b4;" >{{$t("在线客服")}}</div>
							</div>
						</div>
						
					</div>
					<div class="flexs_r_c canClick" @click="toUrl(lobbyInfo.CustomerInfo.customerTelegram.split('@')[0])" @mouseenter='hoverIndex=1' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
					
						
						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							
							<img :src="$img('icon_tg.png')"  alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 12px;">
								<div style="color: #fdfeff;">Telegram</div>
								<div style="color: #95a0b4;overflow:hidden; white-space: nowrap; text-overflow: ellipsis;width: 100px;" >{{lobbyInfo.CustomerInfo.customerTelegram.split('@')[1]}}</div>
							</div>
						</div>
						
					</div>
					<div class="flexs_r_c canClick" @click="toUrl(lobbyInfo.CustomerInfo.customerFaceBook)" @mouseenter='hoverIndex=2' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==2"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							
							<img :src="$img('icon_fb.png')"  alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 12px;">
								<div style="color: #fdfeff;">FaceBook</div>
<!-- 								<div style="color: #95a0b4;overflow:hidden; white-space: nowrap; text-overflow: ellipsis;width: 100px;" >{{lobbyInfo.CustomerInfo.customerFaceBook}}</div>
 -->							</div>
						</div>
						
						
					</div>
					<div class="flexs_r_c canClick" @click="copyUrl(lobbyInfo.CustomerInfo.customerTel[0])" @mouseenter='hoverIndex=3' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==3"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							
							<img :src="$img('icon_dh.png')"  alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 12px">
								<div style="color: #fdfeff;">{{$t("客服热线")}}</div>
								<div style="color: #95a0b4;" >{{lobbyInfo.CustomerInfo.customerTel[0]}}</div>
							</div>
						</div>
						
					</div>
					<div class="flexs_r_c canClick" @click="copyUrl(lobbyInfo.CustomerInfo.customerMail)" @mouseenter='hoverIndex=4' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==4"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							
							<img :src="$img('icon_yx.png')"  alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 12px">
								<div style="color: #fdfeff;">{{$t("官方邮箱")}}</div>
								<div style="color: #95a0b4;overflow:hidden; white-space: nowrap; text-overflow: ellipsis;width: 100px;" >{{lobbyInfo.CustomerInfo.customerMail}}</div>
							</div>
						</div>
						
					</div>
				
			
			
			
		</div>
	
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import mqant from "@/components/protocol/mqantlib";
	import bus from "@/utils/bus.js"
	import i18n from "@/locales/i18n";
	import vueQr from 'vue-qr/src/packages/vue-qr.vue'
	import action from '@/components/core/action'
	
	import {
		req,
		qs
	} from "@/utils/request";
	import {
		topic
	} from "@/components/protocol/api";
	export default {
		components: {
			vueQr
		},
		data() {
			return {
				show:false,
				showTel:false,
				showEmail:false,
				selectIndex:-1,
				hoverIndex:-1
			}
		},
		computed: {
	...mapGetters("global", ["lobbyInfo"]),
	
		},
	
		created() {
			
			
		},
		methods: {
			topClick(index){
				if(this.selectIndex==index){
					this.selectIndex=-1
				}else {
					this.selectIndex=index
				}
			},
			toPage(index){
				let name;
				if(index==0){
					name = 'vip'
					this.$router.push({
						name: name
					
					
					
					})
				}else if(index==1){
					name = 'activity'
					
					if(this.$route.path=='/activity'){
						bus.emit('activity', {
							id:'Sign'
						
						})
					}else {
						this.$router.push({
							name: name,
							params: {
								id:'Sign'
							}
							})
					}
					
						
					
					
					// })
				}else {
					
					name = 'activity'
					
					if(this.$route.path=='/activity'){
						bus.emit('activity', {
							id:'Sign'
						
						})
					}else {
						this.$router.push({
							name: name,
							params: {
								id:'FirstChargeGift'
							}
							})
					}
					
					
				}
				
				this.selectIndex = -1
			},
			rootClick(){
					this.show = !this.show
					this.showTel = false
					this.showEmail = false
			},
			copyUrl(text) {
				if(!text){
					return
				}
			      // 模拟 输入框
			      let cInput = document.createElement("input");
			      cInput.value = text;
			      document.body.appendChild(cInput);
			      // 选取文本框内容
			      cInput.select();
			
			      // 执行浏览器复制命令
			      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
			      // Input要在正常的编辑状态下原生复制方法才会生效
			      document.execCommand("copy");
			this.$tools.toast(this.$t("复制成功"))
			
			      // 复制成功后再将构造的标签 移除
			      document.body.removeChild(cInput);
				  this.selectIndex = -1
			},
				toUrl(url){
					
					//window.open(url)
					action.openBrowser(url, '')
					
					//this.selectIndex = -1
				}
	
		},
	
	};
	
</script>

<style lang="scss" scoped>
	
	.rightTool {
		
		position: fixed;		
		right: 15px;
		z-index: 9999;
		top: 10vh;
	
		width: 165px;
		background-color: #302e2b;
		border-radius: 6px;
		
	
		.line {
			margin-left: .3vw;
			background-color: white;
			height: 1px;
			width: 100%;
			margin-top: .75vw;
		}
	}
	
	.colum {
		height: 65px;
		width: 100%;
		text-align: center;
	}
	.select{
		background: linear-gradient(to bottom,#01aef6,#006ddd);
	}
	.nolmal {
		//background-color: rgba(33,42,61,.54)//#212a3d;
		
	}
</style>