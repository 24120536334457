<template>
	<div style="position: relative;margin-top: 20px;padding: 0 40px;">
		<!-- <div style="" class="flexs_r_c">
			<div style="width: 120px;text-align: right;color:white;" class="middleSize boldSize">{{$t('游戏种类')}}</div>
			<div class="chooseWrap mainBoder canClick" @click="showChosee=!showChosee"
				style="background-color: white;height: 30px;">
				<div style="" class="smallSize">{{gameType}}</div>
				<img :src="$img('downBlack.png')" alt="" style="width: 8px;position: absolute;right: 5px;top: 13px;">
				<div v-show="showChosee" class="selectArea">
					<div @click.stop="itemClick(index)" v-for="(item,index) in gameList" class="selectItem smallSize"
						:key="item">{{item}}</div>
				</div>
			</div>

		</div> -->

		<div class="flexs_r_c" style="">
			<div class="flexs_sb" style="width: 42%;">
				<div class="flexs_colum">
					<span  style="font-size:14px;"
						class="middleSize mainTitle">{{$t('开始时间').toUpperCase()}}</span>
					<el-date-picker class="mainBoder smallSize"
						style="margin-top: 12px;width: 180px;height: 33px;background-color: white;border-radius: 5px;text-align: left;"
						:clearable='false' v-model="startDate" type="date" placeholder="选择日期" @change="dateChange">
					</el-date-picker>
				</div>
				<div class="flexs_colum">
					<span style="font-size: 14px;" class="middleSize mainTitle">{{$t('结束时间').toUpperCase()}}</span>
					<el-date-picker class="mainBoder smallSize"
						style="margin-top: 12px;width: 180px;height: 33px;border-radius: 5px;" :clearable='false'
						v-model="endDate" type="date" placeholder="选择日期" @change="endDateChange">
					</el-date-picker>
				</div>
			</div>
			<div class="flexs_r_c" style="width: 55%;margin-left: 3%;margin-top: 23px;">
				<div style="margin-left: 30px;	width: 150px;height: 45px;font-size: 14px;" v-for="(item,index) in gameList" @click="itemClick(index)" :class="selectIndex==index?'selected mainBtnBG':'nolmal'" class="columBtn canClick">{{item}}</div>
				
			</div>
			
			



		</div>
		<div class="mainBG send canClick middleSize mainBtnBG" @click="getBillRecord()">
			{{$t('查询')}}
		</div>
		
		<div style="width: 100%;position: relative;margin-top:40px ;" class=" smallSize">
			<div style="position: absolute;right: 0;" class="flexs_r_c">
				<div v-for="(item,index) in timeArr" @click="setTimeSeach(index)" :key="item" class="canClick" :style="index<timeArr.length-1?'margin-right:10px':''" style="width: 80px;height: 28px;text-align: center;line-height: 28px;border-radius: 3px;" :class="timeSelectIndex==index?'selected':'nolmal'">
					{{item}}
				</div>
			</div>
			
		</div>	
		
		<div v-if="tableData.length>0" style="margin-top: 80px;">
			
			<div
				style="position: relative;width: 100%;margin: 0px auto;border: 1px solid #2e2d2c;">
				<div class="flexs_r_c" style="width: 100%;background-color: rgb(33,33,33);">
					<div v-for="(item,index) in 5" :key="index" :class="{ borderRight: index!=titleArr.length-1,one:index==0,two:index==1,three:index==2,four:index==3,five:index==4 }"
						class="header middleSize boldSize" >
						{{titleArr[index]}}
					</div>
			
				</div>
			
				<div  style="width: 100%;background-color: rgb(39,39,39);" class="flexs_colum" >
					<div v-for="(item,index) in tableData.slice((this.query.pageNum - 1) * this.query.pageSize, this.query.pageNum * this.query.pageSize)" :key="index" style="width: 100%;">
						<div class="flexs_r_c" >
							<div class="one whiteWord smallSize borderRight" style="height: 40px;text-align: center;line-height: 40px;">
								{{ $tools.formatDate(item.CreateAt, "yyyy-MM-dd") }}
								{{ $tools.formatDate(item.CreateAt, "hh:mm") }}
							</div>
							<div class="two whiteWord smallSize borderRight" style="height: 40px;text-align: center;line-height: 40px;">
								{{ $tools.formatNum2k(item.Amount)}}  
							</div>
							<div class="three whiteWord smallSize borderRight" style="height: 40px;text-align: center;line-height: 40px;">
								{{ item.TradeType }}
								
							</div>
							<div class="four whiteWord smallSize borderRight" style="height: 40px;text-align: center;vertical-align: middle;line-height: 40px;">
							
							{{ item.Remark }}
							</div>
							<div class="five whiteWord smallSize borderRight" style="height: 40px;text-align: center;line-height: 40px;">
								<div
								  v-if="item.Status==1"> {{$t('已被使用') }}</div> 
								 <div
								   v-else-if="item.Status==3" class=""
								 >  {{$t('处理中') }}</div>
								 <div
								    v-else-if="item.Status==4" class=""
								  >  {{$t('失败') }}</div>
								  <div
								     v-else-if="item.Status==9" class=""
								   >  {{$t('成功') }}</div> 
							</div>
						</div>
						
					</div>
					
				</div>
			
			
			</div>
			<div style="position: relative;">
				<el-pagination style="position: absolute;right: 0;" small :page-size="query.pageSize" @current-change='currentChange'
					@size-change="changeSizeHandler" :current-page="query.pageNum" layout="prev, pager, next"
					:total="totalCount">
				</el-pagination>
			</div>
			
		</div>
		<NoData v-show="tableData.length==0" style="margin-top: 100px;width: 100%;background-color: transparent;"></NoData>
	</div>

</template>

<script>
	import {theme} from '@/views/theme'
	
	let NoData = require('@/views/'+ theme +'/components/NoData.vue').default
	
	import moment from 'moment'
	
	import { req, qs } from "@/utils/request";
	import  tools from "@/utils/tools";
	import { mapGetters, mapActions, mapMutations } from "vuex";
	import { http } from "@/components/protocol/api";
	import log from "@/utils/logger";
	import bus from "@/utils/bus.js"
	import mqant from "@/components/protocol/mqantlib";
	import {
		topic
	} from "@/components/protocol/api";
	export default {
		components:{
			NoData
		},
		data() {
			return {
				 query: {
				          pageNum: 1,
				          pageSize: 10
				        },
				
				totalCount:10,
				showChosee:false,
					timeArr:[this.$t('今日'),this.$t('昨日'),this.$t('一周'),this.$t('上月')],
				timeSelectIndex:0,
				gameList:[this.$t('充值'),this.$t('提现')],
				selectIndex:0,
				startDate:'',
				endDate:'',
				gameType:'',
				titleArr:[this.$t('日期').toUpperCase(),this.$t('金额').toUpperCase(),this.$t('类别').toUpperCase(),this.$t('备注').toUpperCase(),this.$t('状态').toUpperCase()],
				tableData: [
				],
				
			}
		},
		props:{
			
			// diaLoginVisible:{
			// 	type:Boolean,
			// 	default:true
			// }
		},
		computed: {
		
			// ...mapGetters("user", ["token","tcpInfo"]),
			...mapGetters("tcp", [ "isConnect"]),
		},
		created() {
			var date = new Date()
			this.startDate = this.getDate()+ ' 00:00:00'
			this.endDate = this.getDate()+ ' 23:59:59'
			this.gameType = this.gameList[0]
			
			var that = this
			bus.on('onConnected', () => {
					that.getBillRecord()
				
			})
			if(this.isConnect){
				this.getBillRecord()
				
			}
			
		},
		methods:{
			setTimeSeach(index){
				this.timeSelectIndex = index
				
				if(index==0){
					var obj = this.getToday()
					this.startDate = obj.starttime
					this.endDate = obj.endtime
				}else if(index==1){
					var obj = this.getYesterday()
					this.startDate = obj.starttime
					this.endDate = obj.endtime
				}else if(index==2){
					var obj = this.getLastWeekDays()
					this.startDate = obj.starttime
					this.endDate = obj.endtime
				}else if(index==3){
					var obj = this.getLastMonthDays()
					this.startDate = obj.starttime
					this.endDate = obj.endtime
				}
				this.getBillRecord()
				
			},
			// 获取今日的开始结束时间
			    getToday() {
			        let obj = {
			            starttime: '',
			            endtime: ''
			        }
			        obj.starttime = moment(moment().startOf("day").valueOf()).format("YYYY-MM-DD HH:mm:ss");
			        obj.endtime = moment(moment().valueOf()).format("YYYY-MM-DD HH:mm:ss");
			        return obj
			    },
			    // 获取昨日的开始结束时间
			    getYesterday() {
			        let obj = {
			            starttime: '',
			            endtime: ''
			        }
			        obj.starttime = moment(moment().add(-1, 'days').startOf("day").valueOf()).format("YYYY-MM-DD HH:mm:ss");
			        obj.endtime = moment(moment().add(-1, 'days').endOf('day').valueOf()).format('YYYY-MM-DD HH:mm:ss');
			        return obj
			    },
			  // 获取上一周的开始结束时间
			    getLastWeekDays() {
			        let obj = {
			            starttime: '',
			            endtime: ''
			        }
					var date = moment()
			        obj.starttime = date.subtract(7, 'days').format('YYYY-MM-DD HH:mm:ss') 
			        obj.endtime = moment(moment().valueOf()).format("YYYY-MM-DD HH:mm:ss");
			        return obj
			    },
				 // 获取上一个月的开始结束时间
				    getLastMonthDays() {
				        let obj = {
				            starttime: '',
				            endtime: ''
				        }
				        var date = moment()
				        obj.starttime = date.subtract(30, 'days').format('YYYY-MM-DD HH:mm:ss') 
				        obj.endtime = moment(moment().valueOf()).format("YYYY-MM-DD HH:mm:ss");
						return obj
				    },
				
			countDate(val) {
				if(!val){
					return ''
				}
				return val.CreateAt.split('T')[0] +' ' + val.CreateAt.split('T')[1].substr(0,8)
			},
			dateFormat(dateData) {
			        var date = new Date(dateData)
			        var y = date.getFullYear()
			        var m = date.getMonth() + 1
			        m = m < 10 ? ('0' + m) : m
			        var d = date.getDate()
			        d = d < 10 ? ('0' + d) : d
			        const time = y + '-' + m + '-' + d
			        return time
			        },
			endDateChange(val){
				this.endDate = this.dateFormat(val)+ ' 23:59:59'
			},
			dateChange(val){
				this.startDate = this.dateFormat(val)+ ' 00:00:00'
			},
			getDate() {
			      const nowDate = new Date();
			      const date = {
			        year: nowDate.getFullYear(),
			        month: nowDate.getMonth() + 1,
			        date: nowDate.getDate(),
			      };
			      const newmonth = date.month >= 10 ? date.month : "0" + date.month;
			      const day = date.date >= 10 ? date.date : "0" + date.date;
			      return date.year + "-" + newmonth + "-" + day;
			    },
			
			getBillRecord() {
				var that = this
				var TradeType
				this.tableData = []
				if(this.selectIndex==0){
					TradeType = 'order'
				}else if(this.selectIndex==1){
					TradeType = 'douDou'
				}
				var obj = {
					TradeType:TradeType,
					startDate:this.startDate,
					endDate:this.endDate
				}
				var that = this
				mqant.request(topic.billRecord, obj, function(data, topicName, msg) {
					that.totalCount = data.Data.totalNum
					
					for (var i = 0; i < data.Data.data.length; i++) {
						var obj = data.Data.data[i]
						obj.index = i + 1
						
						that.tableData.push(obj)
					}
				});
			},
			changeSizeHandler(e){
				this.query.pageSize = size
			},
			itemClick(index){
					this.showChosee = false
					this.selectIndex = index
					this.gameType = this.gameList[index]
			},
			
			currentChange(e){
					this.query.pageNum = e
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.chooseWrap {
		width: 140px;
		height: 30px;
		border-radius: 5px;
		position: relative;
		margin-left: 20px;
		text-align: center;
		line-height: 30px;
	}

	.success {
		color: green;
	}

	.fail {
		color: red;
	}

	.yellow {
		color: #ff9600;
	}

	.selectArea {
		background-color: red;
		white-space: nowrap;
		overflow-y: auto;
		width: 140px;
		height: 150px;
		background-color: white;
		position: absolute;
		top: 31px;
		// left: 4.5vw;
		z-index: 9999;

		display: flex;
		flex-direction: column;

		.selectItem {
			text-align: center;

			height: 30px;
			line-height: 30px;
			cursor: pointer;

		}

		.selectItem:hover {
			background-color: rgb(244, 244, 244);

		}
	}

	.search {
		width: 90px;
		height: 30px;
		line-height: 30px;
		border-radius: 5px;

		text-align: center;
		color: white;
		margin-left: 10px;
		background-color: #f2d161;

	}
	.borderRight {
		border-right: 1px solid #2e2d2c;
		border-bottom: 1px solid #2e2d2c;
	}
	
	.double {
		background-color: rgb(24, 26, 31);
	
	}
	
	.single {
		background-color: rgb(17, 20, 26);
	
	}
	.header {
		color: #fdfeff;
		width: 20%;
		height: 40px;
		line-height: 40px;
		text-align: center;
	
	}
	.one{
		width: 20%;
	}
	.two{
		width: 20%;
	}
	.three{
		width: 20%;
	}
	.four{
		width: 20%;
	}
	.five{
		width: 20%;
	}
	.selected {
		background-color: #d4ac7e;
	}
	
	.nolmal {
		background-color: #3b3b3a;
		color: #dedbdd;
	
	}
	.columBtn{
		width: 23%;
		height: 45px;
		text-align: center;
		line-height: 45px;
		border-radius: 6px;
		font-size: 20px;
	}
	.send {
	
		
		
		width: 150px;
		height: 45px;
		text-align: center;
		line-height: 45px;
		border-radius: 8px;
		
		margin: 0 auto;
		margin-top: 30px;
		background-color: #df8300;
		font-size: 14px;
		
	}
</style>
