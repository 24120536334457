<template>
	<div style="position: relative;margin-top: 20px;padding: 0 40px;">
		
	
		<div class="flexs_r_c" style="">
			<div class="flexs_sb" style="width: 72%;">
				<div class="flexs_colum">
					<span style="font-size:14px;"
						class="middleSize mainTitle">{{$t('开始时间').toUpperCase()}}</span>
					<el-date-picker class="mainBoder"
						style="margin-top: 12px;width: 180px;height: 33px;border-radius: 5px;text-align: left;font-size:12px;"
						:clearable='false' v-model="startDate" type="date" placeholder="选择日期" @change="dateChange">
					</el-date-picker>
				</div>
				<div class="flexs_colum">
					<span style="font-size:14px;" class="middleSize mainTitle">{{$t('结束时间').toUpperCase()}}</span>
					<el-date-picker class="mainBoder mainTitle"
						style="margin-top: 12px;width: 180px;height: 33px;border-radius: 5px;font-size:12px;" :clearable='false'
						v-model="endDate" type="date" placeholder="选择日期" @change="endDateChange">
					</el-date-picker>
				</div>
				
				<div class="flexs_colum">
					
					<span style="font-size:14px;" class="middleSize mainTitle">{{$t('游戏类型').toUpperCase()}}</span>
					<div class="chooseWrap mainBoder canClick" @click="showChosee=!showChosee"
						style="background-color: white;height: 30px;width: 180px;margin-top: 12px;">
						<div style="font-size:12px;" >{{gameType}}</div>
						<img :src="$img('rechargeDown.png')" alt="" style="width: 8px;position: absolute;right: 5px;top: 13px;">
						<div v-show="showChosee" class="selectArea">
							<div @click.stop="itemClick(item)" v-for="(item,index) in lobbyInfo.FactoryList"
								class="selectItem smallSize" :key="item">{{item.vi}}</div>
						</div>
					</div>
				</div>
			</div>
			
			
			<!-- <div class="flexs_sb" style="width: 55%;margin-left: 3%;margin-top: 23px;">
				<div v-for="(item,index) in lobbyInfo.FactoryList" @click="itemClick(index)" :class="selectIndex==index?'selected':'nolmal'" class="columBtn">{{item.vi}}</div>
				
			</div> -->
			
			
	
	
	
		</div>
		<div class="mainBG send canClick middleSize mainBtnBG" style="font-size:14px;"  @click="getGameRecord()">
			{{$t('查询')}}
		</div>
		
		<div style="width: 100%;position: relative;margin-top:40px ;" class=" smallSize">
			<div style="position: absolute;right: 0;" class="flexs_r_c">
				<div v-for="(item,index) in timeArr" @click="setTimeSeach(index)" :key="item" class="canClick" :style="index<timeArr.length?'margin-right:10px':''" style="width: 80px;height: 28px;text-align: center;line-height: 28px;border-radius: 3px;font-size: 12px;" :class="timeSelectIndex==index?'selected':'nolmal'">
					{{item}}
				</div>
			</div>
			
		</div>	
		
		<div v-if="tableData.length>0" style="margin-top: 80px;">
			
			<div
				style="position: relative;width: 100%;margin: 0px auto;border: 1px solid #2e2d2c;">
				<div class="flexs_r_c" style="width: 100%;background-color: rgb(33,33,33);">
					<div v-for="(item,index) in 5" :key="index" :class="{ borderRight: index!=titleArr.length-1,one:index==0,two:index==1,three:index==2,four:index==3,five:index==4 }"
						class="header middleSize boldSize" >
						{{titleArr[index]}}
					</div>
			
				</div>
			
				<div  style="width: 100%;background-color: rgb(39,39,39)" class="flexs_colum" >
					<div v-for="(item,index) in tableData.slice((this.query.pageNum - 1) * this.query.pageSize, this.query.pageNum * this.query.pageSize)" :key="index" style="width: 100%;">
						<div class="flexs_r_c" >
							<div class="one whiteWord smallSize borderRight" style="height: 40px;text-align: center;line-height: 40px;">
								{{ $tools.formatDate(item.create_at, "yyyy-MM-dd") }}
								{{ $tools.formatDate(item.create_at, "hh:mm") }}
							</div>
							<div class="two whiteWord smallSize borderRight" style="height: 40px;text-align: center;line-height: 40px;">
								{{item.game_no}}  
							</div>
							<div class="three whiteWord smallSize borderRight" style="height: 40px;text-align: center;line-height: 40px;">
								{{ item.factory_name+'/'+item.game_type }}
								
							</div>
							<div class="four whiteWord smallSize borderRight" style="height: 40px;text-align: center;vertical-align: middle;line-height: 40px;">
							
							{{ $tools.formatNum2k(item.bet_amount) }}
							</div>
							<div class="five whiteWord smallSize borderRight" style="height: 40px;text-align: center;line-height: 40px;">
								{{$tools.formatNum2k(item.income)}}
							</div>
						</div>
						
					</div>
					
				</div>
				<div class="flexs_r_c">
					<div class="one whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
						{{getSummaries(0)}}
					</div>
					<div class="two whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
						{{getSummaries(1)}}
					</div>
					<div class="three whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
						{{getSummaries(2)}}
					</div>
					<div class="four whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
						{{getSummaries(3)}}
					</div>
					<div class="five whiteWord smallSize borderRight" style="height: 30px;text-align: center;line-height: 30px;">
						{{getSummaries(4)}}
					</div>
				</div>
			
			</div>
			<div style="position: relative;">
				<el-pagination style="position: absolute;right: 0;" small :page-size="query.pageSize" @current-change='currentChange'
					@size-change="changeSizeHandler" :current-page="query.pageNum" layout="prev, pager, next"
					:total="totalCount">
				</el-pagination>
			</div>
			
		</div>
		<NoData v-show="tableData.length==0" style="margin-top: 100px;width: 100%;background-color: transparent;"></NoData>
	</div>
	
</template>

<script>
	import {theme} from '@/views/theme'
	
	let NoData = require('@/views/'+ theme +'/components/NoData.vue').default
	
	import {
		req,
		qs
	} from "@/utils/request";
	import tools from "@/utils/tools";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import {
		http
	} from "@/components/protocol/api";
	import log from "@/utils/logger";
	import bus from "@/utils/bus.js"
	import mqant from "@/components/protocol/mqantlib";
	import moment from 'moment'
	
	import {
		topic
	} from "@/components/protocol/api";
	export default {
		components: {
			NoData
		},
		data() {
			return {
				query: {
					pageNum: 1,
					pageSize: 10
				},
	
				totalCount: 0,
				timeSelectIndex:0,
				
				showChosee: false,
					timeArr:[this.$t('今日'),this.$t('昨日'),this.$t('一周'),this.$t('上月')],
				
				gameList: ['ABS', 'LCOP', 'LCOP', 'LCOP', 'LCOP', 'LCOP'],
				startDate: '',
				endDate: '',
				selectItem: '',
				gameType: '',
				tableData: [],
				titleArr:[this.$t("日期").toUpperCase(),this.$t("下注单号").toUpperCase(),this.$t("游戏种类").toUpperCase(),this.$t("下注").toUpperCase(),this.$t("输赢").toUpperCase()],
			}
		},
		props: {
	
			// diaLoginVisible:{
			// 	type:Boolean,
			// 	default:true
			// }
		},
		computed: {
			...mapGetters("tcp", ["isConnect"]),
	
			...mapGetters("global", ["lobbyInfo"]),
			// ...mapGetters("user", ["token","tcpInfo"]),
		},
		created() {
			var date = new Date()
			this.startDate = this.getDate()
			this.endDate = this.getDate()
			this.gameType = this.lobbyInfo.FactoryList[0].vi
			this.selectItem = this.lobbyInfo.FactoryList[0]
			var that = this
			bus.on('onConnected', () => {
					that.getGameRecord()
				
			})
			if(this.isConnect){
				this.getGameRecord()
				
			}
			
		},
	
		methods: {
			setTimeSeach(index){
				this.timeSelectIndex = index
				
				if(index==0){
					var obj = this.getToday()
					this.startDate = obj.starttime
					this.endDate = obj.endtime
				}else if(index==1){
					var obj = this.getYesterday()
					this.startDate = obj.starttime
					this.endDate = obj.endtime
				}else if(index==2){
					var obj = this.getLastWeekDays()
					this.startDate = obj.starttime
					this.endDate = obj.endtime
				}else if(index==3){
					var obj = this.getLastMonthDays()
					this.startDate = obj.starttime
					this.endDate = obj.endtime
				}
				this.getGameRecord()
				
			},
			// 获取今日的开始结束时间
			    getToday() {
			        let obj = {
			            starttime: '',
			            endtime: ''
			        }
			        obj.starttime = moment(moment().startOf("day").valueOf()).format("YYYY-MM-DD");
			        obj.endtime = moment(moment().valueOf()).format("YYYY-MM-DD");
			        return obj
			    },
			    // 获取昨日的开始结束时间
			    getYesterday() {
			        let obj = {
			            starttime: '',
			            endtime: ''
			        }
			        obj.starttime = moment(moment().add(-1, 'days').startOf("day").valueOf()).format("YYYY-MM-DD");
			        obj.endtime = moment(moment().add(-1, 'days').endOf('day').valueOf()).format('YYYY-MM-DD');
			        return obj
			    },
			  // 获取上一周的开始结束时间
			    getLastWeekDays() {
			        let obj = {
			            starttime: '',
			            endtime: ''
			        }
					var date = moment()
			        obj.starttime = date.subtract(7, 'days').format('YYYY-MM-DD') 
			        obj.endtime = moment(moment().valueOf()).format("YYYY-MM-DD");
			        return obj
			    },
				 // 获取上一个月的开始结束时间
				    getLastMonthDays() {
				        let obj = {
				            starttime: '',
				            endtime: ''
				        }
				        var date = moment()
				        obj.starttime = date.subtract(30, 'days').format('YYYY-MM-DD') 
				        obj.endtime = moment(moment().valueOf()).format("YYYY-MM-DD");
						return obj
				    },
				
			countDate(val) {
				if (!val) {
					return ''
				}
				return val.create_at.split('T')[0] + ' ' + val.create_at.split('T')[1].substr(0, 8)
			},
			getSummaries(index) {
				
				var sum = []
				sum[0] = this.$t("总计");
				sum[1] = '/'
				sum[2] = '/'
				var num1 = 0;
				var num2 = 0;
				this.tableData.forEach(function(item,index){
					
					num1 += item.bet_amount
					num2 += item.income
				})
				sum[3] = this.$tools.formatNum2k(num1)
				sum[4] = this.$tools.formatNum2k(num2)
				return sum[index]
			 
			     },
			   
			dateFormat(dateData) {
				var date = new Date(dateData)
				var y = date.getFullYear()
				var m = date.getMonth() + 1
				m = m < 10 ? ('0' + m) : m
				var d = date.getDate()
				d = d < 10 ? ('0' + d) : d
				const time = y + '-' + m + '-' + d
				return time
			},
			endDateChange(val) {
				this.endDate = this.dateFormat(val)
			},
			dateChange(val) {
				this.startDate = this.dateFormat(val)
			},
			getDate() {
				const nowDate = new Date();
				const date = {
					year: nowDate.getFullYear(),
					month: nowDate.getMonth() + 1,
					date: nowDate.getDate(),
				};
				const newmonth = date.month >= 10 ? date.month : "0" + date.month;
				const day = date.date >= 10 ? date.date : "0" + date.date;
				return date.year + "-" + newmonth + "-" + day;
			},
	
			getGameRecord() {
				var that = this
				var obj = {
					FactoryName: this.selectItem.Factory,
					startDate: this.startDate + ' 00:00:00',
					endDate: this.endDate + ' 23:59:59'
				}
				var that = this
				mqant.request(topic.gameRecord, obj, function(data, topicName, msg) {
					that.totalCount = data.Data.TotalNum
					that.tableData = data.Data.BetRecord
				});
			},
	
			changeSizeHandler(e) {
				this.query.pageSize = size
			},
	
			itemClick(e) {
				this.selectItem = e
				this.showChosee = false
				this.gameType = e.vi
			},
	
			currentChange(e) {
				this.query.pageNum = e
			},
	
		}
	}
</script>

<style lang="scss" scoped>
	.chooseWrap {
		width: 140px;
		height: 30px;
		border-radius: 5px;
		position: relative;
		text-align: center;
		line-height: 30px;
	}

	.selectArea {
		background-color: red;
		white-space: nowrap;
		overflow-y: auto;
		width: 180px;
		height: 150px;
		background-color: #e0dcd7;
		position: absolute;
		top: 31px;
		// left: 4.5vw;
		z-index: 9999;
		// max-height: 10vw;

		display: flex;
		flex-direction: column;

		.selectItem {
			text-align: center;
			height: 30px;
			line-height: 30px;
			cursor: pointer;

		}

		.success {
			color: green;
		}

		.lose {
			color: red;
		}

		.yellow {
			color: #ff9600;
		}

		.selectItem:hover {
			color: white;
			background-color: #ae9978;

		}
	}

	.search {
		width: 90px;
		height: 30px;
		line-height: 30px;
		border-radius: 5px;
		text-align: center;
		color: white;
		margin-left: 10px;
		background-color: #f2d161;
	}
	// :deep.el-table__footer-wrapper tbody td.el-table__cell{
	//   background-color: #333333 !important;
	//   color: #b9b9b9;
	// }
	// :deep.el-table .el-table__footer-wrapper .cell{
	//  //    background-color: #333333 !important;
	// 	// color: #b9b9b9;
	// }
	.borderRight {
		border-right: 1px solid #2e2d2c;
		border-bottom: 1px solid #2e2d2c;
	}
	
	.double {
		background-color: rgb(24, 26, 31);
	
	}
	
	.single {
		background-color: rgb(17, 20, 26);
	
	}
	.header {
		color: #fdfeff;
		width: 20%;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}
	.one{
		width: 20%;
	}
	.two{
		width: 30%;
	}
	.three{
		width: 20%;
	}
	.four{
		width: 15%;
	}
	.five{
		width: 15%;
	}
	.send {
		width: 150px;
		height: 45px;
		text-align: center;
		line-height: 45px;
		border-radius: 8px;
		
		margin: 0 auto;
		margin-top: 30px;
		background-color: #df8300;
		font-size: 14px;
	}
	.selected {
		background-color: #d4ac7e;
	}
	
	.nolmal {
		background-color: #3b3b3a;
		color: #dedbdd;
	
	}
</style>
