import {
		req,
		qs
	} from "@/utils/request";
	import mqant from "@/components/protocol/mqantlib";
	import { mapGetters, mapActions, mapMutations } from "vuex";
	import bus from "@/utils/bus.js"
	
	import {
		topic
	} from "@/components/protocol/api";
	
	export default {
		data(){
			return {
				discountList:[],
				selectIndex:0,
				allData:''
			 }
		},
		props:{
			
			// diaLoginVisible:{
			// 	type:Boolean,
			// 	default:true
			// }
		},
		computed:{
			...mapGetters("tcp", [ "wallet","isLogged","isConnect"]),
			...mapGetters("user", ["token","userInfo"]),
			
			// ...mapGetters("user", ["token","tcpInfo"]),
			
		},
		watch:{
				isConnect(newVal,oldVal){
				}
		},
		mounted() {
			var that = this
			bus.on('onConnected', () => {
					that.getDiscountList()
				
			})
			if(this.isConnect){
				this.getDiscountList()
				
			}
			//that.getDiscountList()
			
		},
		created() {
			
			// if(this.isLogged){
			// 	this.getDiscountList()
				
			// }
		},
		methods:{
			getList(){
				return this.selectIndex==0?this.allData.BonusList:this.allData.RebateList
			},
			change(index){
				this.selectIndex = index
			},
			lingqu(obj){
				
				var that = this
				mqant.request(topic.discount, {ActivityId:obj.ActivityId}, function(data, topicName, msg) {
					if(data.Code==0){
						that.$message({
						          message: that.$t('领取成功')+'!',
						          type: 'success'
						        });
						that.getDiscountList()
					}else {
						that.$message({
						          message: data.ErrMsg,
						          type: 'error'
						        });
					}
				});
			},
			getDiscountList() {
				var that = this
				mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
					that.allData = data.Data
					// that.discountList = data.Data.BonusList.concat(data.Data.RebateList)
				});
			},
			
			
		}
	}