<template>
	<div @touchmove.prevent @mousewheel.prevent @click="handleClose()" class="wrap"
		style="width: 100%;height: 100%;font-weight: bold;position: fixed;top: 0;left: 0;z-index: 99;background-color: rgba(0, 0, 0, .7);text-align: center;">
			<div style="position: relative;">
			
				<div @click.stop="" style="margin: 0 auto;margin-top: calc(50vh - 290px);height: 580px;width: 400px;position: relative;text-align: center;border-radius: 20px;" class="mainBg">
					<div style="position: relative;z-index: 99;display: flex;flex-direction: column;align-items: center;">
						<div style="position: relative;text-align: center;color: white;margin-top: 15px;font-size: 20px;width: 100%;">
							{{$t('注册')}}
							<img @click="handleClose()" class="canClick" :src="$img('guanbi_img.png')" alt=""
								style="width: 14px; position: absolute;right:15px;top:0px;z-index: 999;">
						</div>
						
						<div style="text-align: center;margin-top: 30px;font-size: 15px;color:#dedbdd ;">Chào mừng gia nhập</div>
						<div class="inputWrap">
							<input v-model="account" type="text" :placeholder="$t('账号')" class="bigSize boldSize">
							
						</div>
						<div class="inputWrap">
						<input v-model="pw" type="password" :placeholder="$t('密码')" class="bigSize boldSize">
							
						</div>
						
						<div class="inputWrap">
						<input v-model="confirmPw" type="password" :placeholder="$t('确认密码')" class="bigSize boldSize">
							
						</div>
						
						<div class="inputWrap">
						<input v-model="phone" type="text" @input="money=checkNum(phone)" :placeholder="$t('手机号')" class="bigSize boldSize">
							
						</div>
						
						<div class="inputWrap inputWrap" style="position: relative;">
							<input v-model="code" type="text" @input="money=checkNum(code)" :placeholder="$t('验证码')" class="bigSize boldSize" style="margin-top: 0;width: 60%;">
								
								
								<img class="canClick" @click="getCode()" :src="codeImg" style="height: 100%;position: absolute;right: 0;z-index: 9999;background-color: rgb(222, 191, 110);top: 0;" alt="">
							
						</div>
						
						<div class="inputWrap">
						<input v-model="vipInviteCode" type="text" @input="vipInviteCode=checkNum(vipInviteCode)" :placeholder="$t('邀请码(选填)')+'（Không bắt buộc）'" class="bigSize boldSize">
							
						</div>
						
						<div class="loginBtn canClick mainBtnBG" @click="register()">{{$t('注册')}}</div>
						
						<div style="text-align: center;width: 400px;margin-top: 10px;">
							
							<span @click="toLogin()" style="color: #dbab7b;font-size: 14px;font-weight: bold;" class="canClick">&nbsp;&nbsp;{{$t('登录')}}</span>
						</div>
						
						</div>
				</div>
				
				
			
			
		</div>
	</div>
	
	





</template>

<script>
	import { req, qs } from "@/utils/request";
		import  tools from "@/utils/tools";
		import { mapGetters, mapActions, mapMutations } from "vuex";
		import { http } from "@/components/protocol/api";
		import log from "@/utils/logger";
		
		import {getCurrentInstance} from 'vue'
		export default {
			data(){
				return {
				 
					account:'',
					pw:'',
					confirmPw:'',
					phone:'',
					canSee:false,
					modelValue:true,
					mask:true,
					center:true,
					showClose:false,
					showErrer:false,
					errorText:'',
					code:'',
					codeId:'',
					codeImg:'',
					vipInviteCode:'',
					rules:{
						account : [{required: true, message: '请输入账号', trigger: 'blur'}],
						pw:[{required: true, message: '请输入密码', trigger: 'blur'}],
					}
				}
			},
			props:{
				
				diaLoginVisible:{
					type:Boolean,
					default:true
				}
			},
			computed:{
				...mapGetters("global", ["globalState", "uuid"]),
				
			},
			created() {
				this.getCode()
			},
			methods:{
				// ...mapActions("user", ["doLogin"]),
				
				...mapActions("user", ["onLogged","doLogin"]),
				
				handleClose(){
					this.$emit('handleClose')
					
				},
				showRegist(){
					
					
					this.$emit('showRegist')
					this.handleClose()
				},
				checkNum(data) {
				
					if (data) {
				
						return (data = Number(data.replace(/[^\d.%\\]/g, '')))
				
					}
				
				},
				close(){
						this.$router.push({
							name: 'home',
				
						})
				},
				toLogin(){
					this.$emit('showLogin')
					// this.$router.push({
					// 	name: 'login',
									
					// })
				},
				async getCode() {
					
				
					let params = {
					  
					};
					var that = this
					let rsp = await req.get(http.captcha, qs.stringify(params));
					if (rsp.data.Code == 0) {
						that.codeImg = rsp.data.Data.img
						that.codeId = rsp.data.Data.id
					}else {
						this.$message({
						          message: rsp.data.ErrMsg,
						          type: 'error'
						        });
					}
					
					
				},
				async register() {
					if(!this.account.length){
						this.$message({
							message: this.$t('请输入账号'),
							type: 'error'
						});
						
						return
					}
					if(!this.pw.length){
						this.$message({
							message: this.$t('请输入密码'),
							type: 'error'
						});
						
						return
					}
					if(!this.confirmPw.length){
						this.$message({
							message: this.$t('请输入确认密码'),
							type: 'error'
						});
						
						return
					}
					if(this.pw!=this.confirmPw){
						this.$message({
							message: this.$t('两次输入的密码不一致'),
							type: 'error'
						});
						return
					}
					if(!this.phone.length){
						this.$message({
							message: this.$t('请输入手机号'),
							type: 'error'
						});
						
						
						return
					}
				
					
					
					
					let params = {
						account: this.account,
						password: this.pw,
						phone: this.phone,
						platform: tools.platform(),
						channel: "game",
						uuid: this.uuid,
						uuid_web: this.uuid,
						code:this.code,
						codeId:this.codeId,
						vipInviteCode:this.vipInviteCode,
						prefix:'hdbet'

					};
					var that = this
					this.doLogin({
					  params: params,
					  url: http.userRegister,
					}).then(
					  (resolve) => {
					   that.handleClose()
					   that.close()
					  },
					  (reject) => {
						  that.$message({
						  	message: that.$t(reject.ErrMsg),
						  	type: 'error'
						  });
					  
					  
					  }
					);
					
				},
				
				
				
				
			}
		}
</script>

<style scoped lang="scss">
	.centerWrap{
		
	}
	input{
		//background-color: transparent !important;
		height: 100%;
		width: 100%;
		
		vertical-align: middle;
		
		padding-left: 15px;
		color: white;
		
	}
	.loginBtn{
		height: 40px;
		width: 344px;
		margin-top: 40px;
		line-height: 40px;
		text-align: center;
		// font-style: oblique;
		font-size: 16px;
		border-radius: 4px;
	}
	.inputWrap{
		width: 336px;
		height: 38px;
		border-radius: 5px;
		
		text-align: left;
		margin: 20px auto;
		margin-bottom: 0;
		// display: flex;
		// flex-direction: row;
		align-items: center;
		position: relative;
		background-color: #303030;
		border: 1px solid #585449;
	}
	.error{
		position: absolute;
		color: red;
		top: 50px;
		text-align: center;
		width: 100%;
		font-weight: 400;
	}
	.login{
		background: linear-gradient(to right,rgb(222, 191, 110),rgb(178, 143, 75));  
		color: white;
		width: 330px;
		height: 46px;
		line-height: 46px;
		border-radius: 23px;
		margin: 40px auto;
		text-align: center;
		font-size: 22px;
		font-weight: bold;
	}
</style>
