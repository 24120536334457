import { req, qs } from "@/utils/request";
	import  tools from "@/utils/tools";
	import { mapGetters, mapActions, mapMutations } from "vuex";
	import { http } from "@/components/protocol/api";
	import log from "@/utils/logger";
	
	export default {
		components:{
		},
		data() {
			return {
				list: [],
				showDetail:false,
				selectItem:''
			}
		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),
		
		},
		created() {
			this.getData()
		},
		methods: {
			async getData(){
				
				
				let params = {
				  
				};
				var that = this
				let rsp = await req.post(http.activity, qs.stringify(params));
				if (rsp.status == 200 && rsp.data.Code == 0) {
					this.list = rsp.data.Data.Bonus.concat(rsp.data.Data.Rebate)
					this.list = this.list.sort((a, b) => b.Sort- a.Sort)
					this.list.forEach(function(item,index){
						if(item.ActivityId==that.$route.params.id){
							that.selectItem = item
							that.showDetail = true
						}
							
					})
					
				}else {
					this.$message({
					          message: rsp.data.ErrMsg,
					          type: 'error'
					        });
				}
				
			},
			
			itemClick(e){
				this.showDetail = true
				this.selectItem = e
			}


		}
	}