<template>
  <div class="main">
		<img :src="$img('loginBg.png')" style="width: 100%;" />
    <div class="panel">
		<div style="color: white;font-size: 20px;font-weight: bold;position: relative;width: 100%;">
				<img :src="$img('login_back.png')" class="back" @click="onBackClick" />

				{{$t('申请代理').toUpperCase()}}
			</div>
      <div class="container">
        
        <div class="form">
          <input type="text" v-model="realName" :placeholder="$t('真实姓名').toUpperCase()">
          <input type="text" v-model="phone"  @input="phone=checkNum(phone)" :placeholder="$t('手机号').toUpperCase()">
		       <input type="text" v-model="zalo" placeholder="TELEGRAM">
           <input type="text" v-model="bankName" :placeholder="$t('银行').toUpperCase()">
           <input type="text" @input="cardNum=checkNum(cardNum)" v-model="cardNum" :placeholder="$t('卡号')">

            
          <div
            style="display: inline-flex; margin-top: 14%"
            class="login-input"
          >
           
		   
		   <div class="loginBtn" style="margin-left: 5%;width: 90%;">
		   	<img :src="$img('ann_bottom.png')" alt="" style="width: 100%;">
		   	<div @click="addAgent" style="position: absolute;z-index: 9;width: 100%;top: 2.5vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
          {{$t('提交')}}
		   	</div>
		   	
		   </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {theme} from '@/views/theme'

let LoginInput = require('@/views/'+ theme +'/components/LoginInput.vue').default
let ImageBtn = require('@/views/'+ theme +'/components/ImageBtn.vue').default

import { http } from "@/components/protocol/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import tools from "@/utils/tools";
import {
    topic
  } from "@/components/protocol/api";
  import mqant from "@/components/protocol/mqantlib";

export default {
components: { LoginInput, ImageBtn },
name: "RegisterView",
data() {
  return {
            phone:'',
						zalo:'',
						realName:'',
						bankName:'',
						cardNum:''
  };
},
computed: {
  ...mapGetters("global", ["uuid"]),
  ...mapGetters("tcp", ["isLogged"]),
  ...mapGetters("user", ["userInfo"]),
},
methods: {
  ...mapActions("user", ["doLogin"]),
  ...mapActions("global", ["init"]),
  onBackClick() {
     window.history.back()
    // console.log("go back");
  //   this.$router.push({ name: "home" });
  //
 },
 addAgent() {
						
						if (!this.realName.length||!this.zalo.length||!this.phone.length||!this.bankName.length||!this.cardNum.length) {
							
							return
						}
						let params = {
							phone: this.phone,
							zalo: this.zalo,
							realName: this.realName,
							bankName: this.bankName,
							cardNum: this.cardNum,
		
						};

						var that = this
						mqant.request(topic.agentRegister, params, function(data, topicName, msg) {
								
							if (data.Code == 0) {
									that.onBackClick()
                  that.$tools.toast('Đăng ký thành công! Chúng tôi sẽ liên hệ với zalo của bạn!');

                  
								} else {
                  that.$tools.toast(data.ErrMsg);

									
								}
							
						});
				},
  checkNum(data) {
					
					// }
				
					if (data) {
						
						return (data = data.replace(/[^0-9]/g, ''));  
	
					}
					
						},
 
},
mounted() {
  
},
};
</script>
<style lang="scss" scoped>
	.back {
		width: 6vw;
	
		position: absolute;
		left: 20px;
		top: -5px;
		// align-self: start;
	}
	input {
		width: 100%;
		height: 40px;
		border-bottom: 1px solid rgb(75, 98, 132);
		background-color: transparent;
		color: white;
		padding-top: 15px;
		font-size: 15px;
	}
	
	.loginBtn{
		position: relative;
		margin-top: 24px;
		
	}
.title {
  color: #fff;
  margin-top: 7%;
}
.des {
  margin-top: 22%;
  color: #eb7f8c;
}
.back {
  width: 6vw;
  padding: 2%;
  align-self: start;
}
.main {
  font-size: 14px;
  width: 100%;
  height: 100%;
  > img {
    width: 100%;
    min-width: 100%;
    min-height: 100%;
  }
  text-align: center;
  > .panel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5%;
    width: 100%;

    > .container {
      position: relative;
      width: 94vw;
	  margin-top: 10%;
      height: 100px;
      > .panel_bg {
        width: 94vw;
      }
      > .form {
        position: absolute;
        top: 0%;
		display: flex;
		flex-direction: column;
        left: 0%;
        right: 0%;
        //   background-color: red;
        .login-input {
          margin-top: 5%;
        }
      }
    }
  }
}
</style>