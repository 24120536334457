import {theme} from '@/views/theme'

import { mapGetters, mapActions, mapMutations } from "vuex";
import mqant from "@/components/protocol/mqantlib";
import { http, topic } from "@/components/protocol/api";
import MyDialog from "@/views/mobile5/components/MyDialog.vue";
import auth from "@/components/core/auth";
	let RedHeader = require('@/views/'+ theme +'/components/RedHeader.vue').default

export default {
  mixins: [auth],
  data() {
    return {
      showDialog: false,
      dialogTitle: "",
      dialogMsg: "",
	  selectIndex:0,
    };
  },
  components: {
    RedHeader,
    MyDialog,
  },
  props: {},
  created() {},
  mounted() {
  },
  computed: {
    ...mapGetters("tcp", ["basicInfo", "wallet"]),
  },
  methods: {
    ...mapActions("tcp", ["requestBasicInfo"]),
	getList(){
		return this.selectIndex==0?this.basicInfo.BonusList:this.basicInfo.RebateList
	},
    onClick(item) {
      let that = this;
      mqant.request(
        topic.discount,
        { ActivityId: item.ActivityId },
        function (res, topic, msg) {
          if (res.Code == 0) {
            that.showDialog = true;
            that.dialogTitle = that.$t("提示");
            that.dialogMsg = res.ErrMsg;
            that.requestBasicInfo();
          }
        }
      );
    },
  },
};