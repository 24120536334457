<template>
	<div style="position: relative;top: -1px;padding: 0vw 0;margin-bottom: -5px;background-color: #343230;" class="">
		<img :src="$img('doujiBanner.png')" alt="" style="width: 100vw;min-width: 1200px;" class="canClick" @click="play()">

		
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import bus from "@/utils/bus.js"
	import {
		status,
		getApiLoginUrl,
		checkFactoryUrl
	} from "@/components/protocol/api";

	export default {
		components: {

		},
		data() {
			return {
				
			}
		},
		computed: {
			...mapGetters("global", ["lobbyInfo"]),
			...mapGetters("tcp", ["isLogged"]),

		},

		created() {
			
		},
		methods: {
			
			play() {
				if (!this.isLogged) {
					bus.emit('showLogin')
					return
				}
				

				let name = this.lobbyInfo.StaticPicture.CockFight.apiSea.Factory.FactoryName;
				let url = getApiLoginUrl(name, "");
				this.$act.openPage(url);
			},
			
		},

	};
</script>

<style lang="scss" scoped>
	.send {
		width: 150px;
		height: 40px;
		color: white;
		text-align: center;
		line-height: 40px;
		border-radius: 8px;

		margin: 0 auto;
		margin-top: 20px;
		background-color: #df8300;
		font-size: 20px;
	}

	.noClick {
		position: absolute;
		background-color: rgba(27, 29, 35, .5);
		top: 0;
		text-align: center;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.listWrap {}

	.listBottom {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		// white-space: nowrap;
		// overflow-y: auto;
		margin-left: 0;
		width: 100%;

		// max-height: 48vw;
		// background-color: rgba(0, 0, 0,.4);
	}

	.topNavi {
		width: 100%;
		margin-left: 0;
		border-top-left-radius: 1vw;
		border-top-right-radius: 1vw;
		background-color: #1a1a1a;
		overflow: hidden;
	}

	.colum {
		margin-top: 2.25vw;
		margin-left: 2.25vw;
		position: relative;
		width: 11.6vw;
		height: 11.6vw;
	}

	.topImgWrap {
		background-color: #1a1a1a;
	}


	.el-pagination {
		text-align: center;
	}

	.mask {
		background-color: rgba(0, 0, 0, .55);
		position: absolute;
		width: 100%;
		height: 100%;
		text-align: center;
		top: 0;
		left: 0;
		border-radius: 10px;

		.enter {
			width: 9vw;
			height: 2vw;
			line-height: 2vw;
			border-radius: 4px;
			text-align: center;
			font-size: 0.86vw;
			font-weight: bold;
			background-image: linear-gradient(to top, #e0b626, #d7781e);
			color: white;
			margin-left: 1.3vw;
			margin-top: 4.6vw;
		}
	}

	.select {
		background: linear-gradient(to right, #01aef6, #006ddd);
		border-radius: 5px;
		height: 100%;
	}

	input {
		width: 400px;
		height: 45px;
		color: white;
		border-radius: 5px;
		padding-left: 10px;
		font-size: 20px;
		background-color: #0f192f;
	}
</style>

<style>
	/* 为了不造成样式污染，单据加一个style标签来修改第三方组件样式，pagination表格分页整体居中，上边距20px */
	.el-pagination {
		text-align: center;
		margin-top: 20px;
	}
</style>
