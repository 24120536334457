import { createRouter, createWebHashHistory } from 'vue-router'
import tools from '@/utils/tools'

let module = tools.isMobile() ? require('./mobile') : require('./pc')

const routes = [
  {
    path: '/test',
    name: 'test',
    component: () => import('../views/test/testView.vue')
  },
  {
    path: '/testpc',
    name: 'testpc',
    component: () => import('../views/test/testPc.vue')
  },
  ...module.routes
]
// if (tools.isMobile()) {
//   import('./mobile').then(rs => {
//     for (let index in rs.default) {
//       router.addRoute(rs.default[index])
//     }
//   })
// } else {
//   import('./pc').then(rs => {
//     for (let index in rs.default) {
//       router.addRoute(rs.default[index])
//     }
//   })
// }


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
