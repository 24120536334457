<template>
  <div class="maintena " :style="mainStyle">
    <img :src="$img('warn.png')" />
    <div :style="textStyle">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: {
    textWidth: String,
    text: String,
    textTransform: String,
  },
  computed: {
    textStyle() {
      return {
        width: this.textWidth,
        transform: this.textTransform,
      };
    },
    mainStyle() {
      return {};
    },
  },
};
</script>

<style lang="scss" scoped>
.maintena {
  position: absolute;
  top: 16%;
  width: 100%;
  text-align: center;
  > img {
    width: 11vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  > div {
    margin-left: auto;
    margin-right: auto;
    background-color: #ffd21e;
    border-radius: 7px;
    width: 80%;
    border: solid 2px #373737;
    padding: 7px 0;
    margin-top: 1%;
    font-weight: bold;
    white-space: pre-wrap;
  }
}
</style>