<template>
	<div style="position: relative;margin-bottom: -14px;top: -1px;padding: 0vw 0;padding-bottom: 30px;" class="">
		<img :src="$img('banner_buyu.png')" alt="" style="width: 100vw;min-width: 1200px;">
		
		<div style="" class="listWrap">
			
		
		
			<div   class="flexs_r" style="flex-wrap:wrap;flex-wrap:wrap;width: 100%;padding: 0 2.25vw;">
				<div v-for="(item,index) in factoryList" :key="index" style="border-radius: 5px;height: 88px;" :class="{'select mainBtnBG': selectIndex==index}" :style="index>0?'margin-left:15px':''" class="canClick topColum">
					<img   @click="changeFactory(index)"    :class="{ diseableImg: (item.Factory.Status==2||item.Factory.Status==3)}"  class=""  :src="$img(item.Factory.FactoryName+'_N.png')" alt="" style="width: 145px;" >
					
				</div>
				
			</div>
			
			<div style="margin-top: 30px;padding: 0 2.25vw;" class="flexs_sb">
				<div style="position: relative;">
					<input v-model="searchKey" v-on:input="search"  type="text" placeholder="Vui lòng điền tên game">
					<img :src="$img('shous_img.png')" alt="" style="width: 28px;right: 10px;top: 8px;position: absolute;">
				</div>
<!-- 				<div class="canClick" style="color: #0071df;font-size: 20px;">{{$t('最近游戏')}}</div>
 -->			</div>
			
			<div class="listBottom">
				<div  v-if="!isSearch" v-for="(item,index) in gameData.slice((this.page - 1) * 48, this.page * 48) " @click="onClick(item)" class="colum canClick" :key="item" @mouseenter='hoverIndex=index'
							@mouseleave="hoverIndex=-1">
					<img :src="getImg(item)"  style="width:11.6vw ;height: 11.6vw;background-color: white;object-fit: cover;border-radius: 10px;" alt="">
					<div class="whiteWord bigSize" style="text-align: center;margin-top: 5px;">{{item.GameName}}</div>
					<div v-show="hoverIndex==index" class="mask">
						<div class='mainBtnBG' style="width: 80%;margin-left: 10%;text-align: center;border-radius: 12px;height: 40px;line-height: 40px;color: white;margin-top: calc(5.8vw - 20px);font-size: 20px;">{{$t('立即游戏')}}</div>
					</div>
				</div>
				
				<div  v-else v-for="(item,index) in searchData.slice((this.page - 1) * 48, this.page * 48) " @click="onClick(item)" class="colum canClick" :key="index" @mouseenter='hoverIndex=index'
							@mouseleave="hoverIndex=-1">
					<img :src="getImg(item)"  style="width:11.6vw ;height: 11.6vw;background-color: white;object-fit: cover;border-radius: 10px;" alt="">
					<div class="whiteWord bigSize" style="text-align: center;margin-top: 5px;">{{item.GameName}}</div>
					<div v-show="hoverIndex==index" class="mask">
						<div class='mainBtnBG' style="width: 80%;margin-left: 10%;text-align: center;border-radius: 12px;height: 40px;line-height: 40px;margin-top: calc(5.8vw - 20px);font-size: 20px;">{{$t('立即游戏')}}</div>
					</div>
				</div>
			</div>
			<el-pagination v-if="!isSearch" align="center" style="text-align: center;margin-top: 50px;margin-right: 46px;display: flex;flex-direction: row-reverse;"  :page-size="48" @current-change='currentChange'
				 :current-page="page" layout="prev, pager, next"
				:total="gameData.length">
			</el-pagination>
			
			<el-pagination v-else align="center" style="text-align: center;margin-top: 50px;margin-right: 46px;display: flex;flex-direction: row-reverse;"  :page-size="48" @current-change='currentChange'
				 :current-page="page" layout="prev, pager, next"
				:total="searchData.length">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import fishing from "@/views/pc/fishing"
	
	export default{
	  mixins:[fishing]
	}
</script>

<style lang="scss" scoped>
	.listWrap {
		
		top: 2vw;
		position: relative;
		z-index: 2;
		width: 86vw;
		// padding: 0 3.8vw;
		margin-left: 7vw;

		
		
		text-align: center;
		// padding-top: 5vw;
		// padding-left: 3.8vw;
		padding-bottom: 2vw;
		scrollbar-face-color: #FFF;
		


	}
	.listBottom{
		display: flex;
		flex-direction: row;
		 flex-wrap: wrap;
		// white-space: nowrap;
		// overflow-y: auto;
		 margin-left: 0;
		width: 100%;
		
		 //max-height: 44vw;
	}
	.topNavi{
		width: 100%;
		margin-left: 0;
		border-top-left-radius: 1vw;
		border-top-right-radius: 1vw;
		background-color: #1a1a1a;
		overflow: hidden;
	}
	
	.colum {
		margin-top: 2.25vw;
		margin-left: 2.25vw;
		position: relative;
		width:11.6vw ;height: 11.6vw;
	}
	.topImgWrap{
		background-color: #1a1a1a;
	}
	
	.select{
		
		
	}
	
	input{
		width: 400px;
		height: 45px;
		color: white;
		border-radius: 5px;
		padding-left: 10px;
		font-size:20px ;
		background-color: #222120;
	}
	
	.mask{
		background-color: rgba(0, 0, 0, .55);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		text-align: center;
		left: 0;
		border-radius: 10px;
		.enter{
			width: 9vw;
			height: 2vw;
			line-height: 2vw;
			border-radius: 4px;
			text-align: center;
			font-size: 0.86vw;
			font-weight: bold;
		background-image: linear-gradient(to  top,#e0b626, #d7781e);
		color: white;
			margin-left: 1.3vw;
			margin-top: 4.6vw;
		}
	}
	
	.btn{
		width: 40px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		margin-left: 2px;
		vertical-align: middle;
		
	}
	.nolmal{
		background-color: white;
		color: black;
	}
	.select{
		border-radius: 5px;
	}
	.topColum{
		background-color: rgb(99,99,99);
		border-radius:5px;
		
	}
</style>




