<template>
  <div class="header flexs_colum">
    <img :src="$img('noData.png')" alt="">
	<div style="color: rgb(108,124,149);">{{$t("暂无记录")}}</div>
  </div>
</template>

<script>
export default {
  name: "NoData",
  props: {
    
  },
  methods: {
   
  },
};
</script>

<style lang="scss" scoped>
    .header{
        text-align: center;
        img{
            width: 16vw;
			margin: 2vw auto;
			margin-top: 25vw;
			
        }
    }
</style>