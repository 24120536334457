import {theme} from '@/views/theme'
	let MyDialog = require('@/views/'+ theme +'/components/MyDialog.vue').default

import { ref } from "vue";
import pay from "@/views/mobile/pay";
import { http, topic } from "@/components/protocol/api";
import mqant from "@/components/protocol/mqantlib";
import bus from "@/utils/bus.js"

export default {
    components: { MyDialog },
    mixins: [pay],
    setup() {
        return {};
    },
    data() {
        return {
            code: "",
            showDialog: false,
            dialogTitle: "",
            dialogMsg: "",
        };
    },
    created() {
        var that = this
        bus.off('giftCodeResult')
        bus.on('giftCodeResult', (val) => {
            if (val.data.Code == 0) {
                that.code = ''

                that.showDialog = true;

                that.dialogTitle = that.$t("提示");
                that.dialogMsg = that.$t('兑换成功')+'!'

            } else {
                that.$tools.toast(val.data.ErrMsg);


            }
        })
    },
    methods: {
        onSubmit() {
            let method = this.getPayMethod("giftCode", "giftCode");
            if (!method) {
                this.$log.warn("not found pay method of bank transfer");
                return;
            }
            let params = {
                code: this.code,
                // methodId: method.MethodId,
            };
            // console.log(params);
            let that = this;
            that.$tools.loading()
            mqant.request(topic.giftCode, params, function (res) {
                that.$tools.dismissLoading()
                // console.log(res);
                // if (res.Code == 0) {
                //   that.showDialog = true;
                //   that.dialogTitle = that.$t("提示");
                //   that.dialogMsg = res.ErrMsg;
                // } else {
                //   that.$tools.toast(res.ErrMsg);
                // }
            });
        },
    },
};