<template>
	<div >
		<!-- <iframe :src="url" frameborder="0" style="width:100vw;height:100vh;"></iframe> -->
    <iframe
  ref="iframe"
  id="bi_iframe"
  :src="url"
  frameborder="no"
  scrolling="auto"
  width="100%"
  @load="adjustIframe"
/>
	</div>
</template>

<script>
	import {encryptBy,decryptBy} from '@/utils/crypto';

export default {
  components: {
   
  },
  data() {
    return {
      url:''
    };
  },
  computed: {},
  created(){

  },
  mounted() {
    this.url = decryptBy(this.$route.query.url)
    
},
  methods: {
    adjustIframe() {
      var ifm = document.getElementById("bi_iframe");
      ifm.height = document.documentElement.clientHeight;
      ifm.width = document.documentElement.clientWidth;
    },
  },
};
</script>

<style lang="scss" scoped>

	
</style>

