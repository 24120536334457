<template>
	<div style="position: relative;margin-bottom: -10px;">
		<img :src="$img('brief.webp')" style="width: 100%;" alt="">
		
		<div class="flexs_colum"
			style="left: 0;top:0;position: absolute;width: 100%;z-index: 99;background-color: transparent;">
			<div style="width: 1100px;margin: 0 auto;height: 400px;">
				<div class="flexs_r_c" style="width: 100%;margin-top:49px;">
					<div v-for="(item,index) in topDatas" class="topItem mainBG2 canClick middleSize boldSize" @click="readyContent(index)"
						:class="getClass(index)">{{item}}
					</div>
				</div>
				
				<div class="content" v-if="selectIndex==1" style="position: relative;">
					
					<div class="flexs_colum" style="margin-left: 58px;color: white;">
						
						<div class="flexs_r_c" style="margin-top: 52px;">
							<img :src="$img('xiaoxi_icon.png')" style="width: 42px;" alt="">
							<div class="middleSize boldSize" style="line-height: 18px;margin-left: 25px;">
								Tư vấn trực tuyến để giải đáp thắc mắc ngay.<br>
								Dịch vụ luôn hỗ trợ 24/7.</div>
						</div>
						<div class="flexs_r_c" style="margin-top: 30px;">
							<img :src="$img('Gmail_icon.png')" alt="" style="width: 42px;">
							<div class="middleSize boldSize" style="line-height: 18px;margin-left: 25px;">
								Gửi mail<br>
								Gửi mail cho chúng tôi<br>
								Mail : {{lobbyInfo.CustomerInfo.customerMail}}
							</div>
						</div>
						<div class="flexs_r_c" style="margin-top: 30px;">
							<img :src="$img('dianhua_icon.png')" alt="" style="width: 42px;">
							<div class="middleSize boldSize" style="line-height: 18px;margin-left: 25px;">
								Gọi ngay cho chúng tôi<br>
								<div v-for="item in lobbyInfo.CustomerInfo.customerTel">
									{{item}}<br>
								</div>
								
							</div>
						</div>
					</div>
				
				
				</div>
				<div class="content smallSize" v-else>
					{{content}}
				
				
				</div>
				
				
			</div>
		
		</div>

	</div>
</template>

<script>
	import brief from "@/views/pc/brief"
	
	export default{
	  mixins:[brief]
	}
</script>

<style lang="scss" scoped>
	.topItem {
		padding: 6px 15px;
		// height: 3.23vw;
		// line-height: 3.23vw;
		// text-align: center;
		font-weight: bold;
	}

	.border {
	// 	border-left: 1px solid white;
	// 
	}

	.select {
		background-color: #c3a93c;
		color: white;

	}

	.nolmal {
		background-color: #404040;
		color: black;

	}

	.content {
		white-space: nowrap;
		overflow-y: auto;
		
		padding: 16px 30px;
		background-color: rgba(31, 31, 31, .9);
		color: white;
		// width: 100%;
		height: 50vw;
		white-space: break-spaces;
		word-break: keep-all;
		// white-space: normal;
		// word-break: break-all;
		// word-wrap: break-word;
		line-height: 18px;
	}
</style>
