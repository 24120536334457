<template>
	<red-header :title="$t('历史记录')"  :showBack="true" style="width: 100%;"/>
	<div style="width: 90%;margin-top: 20px;border: 1px solid  rgba(245, 232, 155,.5);margin-left: 5%;">
		<div  class="flexs_r_c" style="border-bottom: 1px solid white;">
			<div v-for="item in headerArr " class="header middleSize boldSize" style="width: 25%;">{{item}}</div>
		</div>
		<div class="flexs_r_c" style="border-bottom: 1px solid white;" v-for="item in recordData">
			<div class="header middleSize">{{ $tools.formatDate(item.CreateAt, "yyyy-MM-dd") }}
					{{ $tools.formatDate(item.CreateAt, "hh:mm") }}</div>
			<div class="header middleSize">
				<div v-if="item.ActivityID=='Week'">
					{{$t('每月奖金')}}
					
				</div>
				<div v-else>
					{{$t('升级奖励')}}
					
				</div>
				
			</div>
			<div class="header middleSize">
				{{$tools.formatNum(item.Get)}}
			</div>
			<div class="header middleSize">
				{{item.Level}}
			</div>
		</div>
	</div>
</template>

<script>
	import { mapGetters, mapActions, mapMutations } from "vuex";
	import {
		theme
	} from '@/views/theme'
	import mqant from "@/components/protocol/mqantlib";
	import bus from "@/utils/bus.js"
	import i18n from "@/locales/i18n";
	
	import {
		req,
		qs
	} from "@/utils/request";
	import {
		topic
	} from "@/components/protocol/api";
	let RedHeader = require('@/views/' + theme + '/components/RedHeader.vue').default
	
	export default {
		components: {
			RedHeader
		},
	    computed: {
		},
	 
	  mounted() {
	   
		this.getRecord()
	  },
	  methods: {
		  getRecord(){
		  	
		  	var that = this
		  	
		  	mqant.request(topic.getGiftRecord, {}, function(data, topicName, msg) {
		  		that.recordData = data.Data
		  	});
		  },
	  },
	  data() {
	    return {
	      headerArr:[this.$t('时间'),this.$t('类型'),this.$t('金额'),this.$t('等级')],
	      recordData:[]
	    };
	  },
	};
</script>

<style scoped>
	.header{
		text-align: center;
		width: 25%;
		color: white;
		height: 35px;
		line-height: 35px;
		border-right: 1px solid rgb(200, 200, 200);
	}
</style>
