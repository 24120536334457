<template>
  <div
    @click="handleClose()"
    style="
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 99999;
    "
  >
    <div
      @click.stop=""
      style="position: absolute; z-index: 9999; top: 62px; left: 17.2vw"
      class="flexs_r"
    >
      <div style="position: relative">
        <img :src="$img('messageLeft.png')" alt="" style="width: 14vw" />

        <div class="listWrap">
          <div
            class="bigSize boldSize"
            style="text-align: center; margin-top: 18px; color: white"
          >
            {{ $t("公告") }}
          </div>
          <div
            v-for="(item, index) in messageList"
            @click="selfIndex = index"
            :class="selfIndex == index ? 'choose' : ''"
            class="messageLeft canClick"
            :key="item"
          >
            <div
              :class="selfIndex == index ? 'choose' : ''"
              class="middleSize"
              style="
                height: 2vw;
                margin-top: 1vw;
                font-size: 0.96vw;
                color: white;
                white-space: normal;
                word-break: break-all;
                word-wrap: break-word;
              "
            >
              {{ item.Title }}
            </div>
            <div
              :class="selfIndex == index ? 'choose' : ''"
              class="middleSize"
              style="
                display: flex;
                flex-direction: row-reverse;
                margin-top: 0vw;
                color: white;
              "
            >
              <span class="smallSize">{{ countDate(item.CreateAt) }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="position: relative; margin-left: 0vw">
        <img
          :src="$img('close.png')"
          @click="handleClose()"
          class="canClick"
          alt=""
          style="width: 1.5vw; position: absolute; right: 0vw; top: 0vw; z-index: 999"
        />

        <img
          :src="$img('messageRight.png')"
          alt=""
          style="width: 48vw; position: absolute; top: 1.6vw"
        />
        <div style="width: 47.5vw; position: relative; z-index: 99">
          <div
            v-if="messageList.length"
            class="mainColor middleSize boldSize"
            style="
              font-size: 1.16vw;
              text-align: center;
              font-weight: bold;
              margin-top: 3vw;
              color: white;
            "
          >
            {{ messageList[selfIndex].Title }}
          </div>
          <div
            class=""
            style="
              display: flex;
              flex-direction: column;
              margin-top: 0vw;
              white-space: nowrap;
              overflow-y: auto;
              max-height: 31vw;
            "
          >
            <img
              v-if="messageList.length && messageList[selfIndex].Content"
              :src="$img('laba.webp')"
              alt=""
              style="width: 1.3vw; margin-left: 2vw"
            />
            <div
              v-if="messageList.length"
              class="smallSize"
              style="
                margin-top: -1.3vw;
                font-size: 0.98vw;
                white-space: normal;
                word-break: break-all;
                word-wrap: break-word;
                padding: 0 2vw;
                color: white;
                line-height: 28px;
              "
            >
              &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp{{ messageList[selfIndex].Content }}
            </div>
            <img
              v-if="messageList.length"
              :src="messageList[selfIndex].Url"
              alt=""
              style="width: 92%; margin-top: 3vw; margin-left: 4%"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import message from "@/views/pc/message";

export default {
  mixins: [message],
};
</script>

<style lang="scss" scoped>
div::-webkit-scrollbar {
  width: 6px;
}
div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
  opacity: 0.2;
  background: fade(white, 60%);
}
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(255, 255, 255, 0.2);
  border-radius: 0;
  background: fade(white, 30%);
}
.listWrap {
  white-space: nowrap;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 37vw;
}
.choose {
  text-shadow: 0.5px 0.9px 4.5px rgba(0, 0, 0, 0.57);
}
.listWrap::-webkit-scrollbar {
  display: none;
}
.messageLeft {
  margin-left: 1.4vw;
  margin-right: 1.45vw;
  // display: flex;
  // width: 100%;
  padding-bottom: 0.5vw;

  // flex-direction: column;
  border-bottom: 1px solid white;
  color: black;

  p {
    color: black;
    font-size: 1.2vw;
    font-weight: bold;
    text-align: left;
  }

  span {
    font-size: 0.9vw;
  }
}
</style>
