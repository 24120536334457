<template>
	<div style="position: relative;margin-bottom: -.8vw;">
		<img :src="$img('discountBG.png')" alt="" style="width: 100vw;min-width: 1200px;">
		
		<div class="discountWrap">
			<img :src="item.TitleUrl" class="canClick" alt="" @click="itemClick(item)" v-for="(item,index) in list" :key="item">
			
		</div>
		
		<div style="position: absolute;top: 15px;z-index: 999;width: 100vw;" v-if="showDetail">
			<div style="position: relative;margin: 30px auto;width: 1025px;">
				<img :src="$img('discountDetailBG.png')" alt="" style="width: 100%;">
				
				<img class="canClick" :src="$img('discountClose.png')" @click="showDetail=false" alt=""
					style="position: absolute;right: 8px;top: 8px;width: 25px;z-index: 999;">
				<div style="position: absolute;top: 0px;left: 0;" class="flexs_r">
					<div class="leftList" style="">
						<!-- <img :src="selectItem.TitleUrl" alt=""  :key="item"> -->
						<img :src="item.TitleUrl"  :class="selectItem==item?'':'nolmal'" class="canClick" alt="" @click="itemClick(item)" v-for="(item,index) in list" :key="item">
						
					</div>
					<div class="flexs_colum" style="width:630px;height: 560px;white-space: nowrap;overflow-y: auto;padding: 0px 10px;margin-top:60px;">
						<div class="content" v-if="selectItem.ContentPc" v-html="selectItem.ContentPc" style="width:100%;"></div>
						<img v-else :src="selectItem.DetailUrl" alt="" style="object-fit: contain;width: 100%;">
								
					</div>
					
				</div>
			</div>
		
		</div>
		
	</div>
	
</template>

<script>
	import discount from "@/views/pc/discount"
	
	export default{
	  mixins:[discount]
	}
</script>

<style lang="scss" scoped>
	.content{
		white-space: nowrap;
		overflow-y: auto;
		
		
		white-space: break-spaces;
		word-break: keep-all;
		// white-space: normal;
		// word-break: break-all;
		// word-wrap: break-word;
	}
	.discountWrap {
		position: absolute;
		top: 00;
		
		text-align: center;
		width: 100%;
		// min-width: 1200px;
		white-space: nowrap;
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		
		height: 800px;
		max-height: 90vh;

		img {
			width: 51.7vw;
			height: 6.3vw;
			margin: 0 auto;
			margin-top: 3.2vw;
		}

	}

	.discountWrap::-webkit-scrollbar {
		display: none;
	}

	.leftList {
		white-space: nowrap;
		overflow-y: auto;
		margin-top: 58px;
		display: flex;
		flex-direction: column;
		width: 362px;
		height: 600px;
		// max-height: 53.5vw;
		img{
			width: 300px;
			height: 36px;
			margin: 0 auto;
			margin-top: 20px;
		}
	}

	.leftList::-webkit-scrollbar {
		display: none;
	}
	.selected{
		
	}
	.nolmal{
		-webkit-filter: grayscale(100%);
		-moz-filter: grayscale(100%);
		-ms-filter: grayscale(100%);
		-o-filter: grayscale(100%);
	}
	
</style>
<style>
	table {
		width: 100%;
		border-top: 1px solid black;
		border-left: 1px solid black;
	  }
	  table td,
	  table th {
		border-bottom: 1px solid black;
		border-right: 1px solid black;
		padding: 3px 5px;
	  }
	  table th {
		border-bottom: 1px solid black;
		text-align: center;
	  }
</style>
