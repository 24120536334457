import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

import { user } from '@/store/module/user'
import { global } from '@/store/module/global'
import { tcp } from '@/store/module/tcp'
import { pay } from '@/store/module/pay'
import { record } from '@/store/module/record'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['user']
}) 
const store = createStore({
  modules: {
    user,
    global,
    tcp,
    pay,
    record,
  },
  plugins: [vuexLocal.plugin]
})
store.dispatch('user/init')

export default store

