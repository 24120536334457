<template>
	<red-header :title="$t('活动领取')" :showBack="true" />

	<div class="main" style="overflow-x: hidden;position: relative;z-index: 3;height: calc(100vh - 44px);">
		<NoData style='margin-top:20vw;' v-if="!dataList.length&&!discountList.length"></NoData>
		
		<div v-for="(item,index) in dataList" style="margin-top: 3vw;background-color:rgb(42,42,42);padding: 20px;border-radius:8px;">
			<div class="title">{{item.ActivityName}}</div>
			<div class="flexs_sb"
				style="padding-top: 10px;margin-top: 1px;border-top: 1px solid rgb(45,59,74);color:white;">
				<div >{{$t('参与时间')}}:{{$tools.formatDate(item.JoinTime)}}</div>
				<div v-if="item.Type=='SportGuess'">
					<div v-if="item.ActivityStatus=='Undo'" class="Undo btn">{{$t('次日领取')}}</div>

					<div v-if="item.ActivityStatus=='NoAward'" class="Undo btn">{{$t('猜错')}}</div>
					<div v-if="item.ActivityStatus=='Done'" class="Done btn canClick" @click="getActivity(item)">
						{{$t('领取')}}</div>

				</div>
				<div v-else>
					<div v-if="item.Type=='Charge300'">
						<div v-if="item.ActivityStatus=='Undo'&&item.CurBets>=item.GoalBets" @click="toCharge()" class="canClick btn mainBtnBG">{{$t('充值')}}</div>
						<div v-if="item.ActivityStatus=='Undo'&&item.CurBets<item.GoalBets"  class="Undo btn">{{$t('已领取')}}</div>
						
						<div v-if="item.ActivityStatus=='Done'" class=" btn canClick mainBtnBG" @click="getActivity(item)">{{$t('领取')}}</div>
						
					</div>
					<div v-else>
						<div v-if="item.ActivityStatus=='Undo'" class="Undo btn">{{$t('未完成')}}</div>
						
						<div v-if="item.ActivityStatus=='Done'" class="Done btn canClick" @click="getActivity(item)">
							{{$t('领取')}}</div>
						<div v-if="item.ActivityStatus=='Restart'" class="Done btn canClick" @click="restart()">
							{{$t('重新开始')}}</div>
					</div>
					

				</div>
			</div>




			<div v-if="item.Type=='Sign'||item.Type=='FirstChargeGift'" style="">
				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div>{{$t('充值金额')}}</div>

					<div style="color:rgb(128,134,145);">{{$t('剩余')}}<span
							style="color: white;">&nbsp;{{item.RemainDays}}&nbsp;</span>{{$t('天')}}</div>

				</div>
				<van-progress :show-pivot='false' color="rgb(251,158,49)"
					:percentage="item.CurCharge<item.GoalCharge?item.CurCharge/parseFloat(item.GoalCharge)*100:100"
					style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;">
					{{$tools.formatNum2k(item.GoalCharge)}}
				</div>

				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 10px;">
					<div>{{$t('有效投注')}}</div>


				</div>
				<van-progress :show-pivot='false' color="rgb(251,158,49)"
					:percentage="item.CurCharge<item.GoalCharge?item.CurCharge/parseFloat(item.GoalCharge)*100:100"
					style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;">
					{{$tools.formatNum2k(item.GoalBets)}}
				</div>

			</div>
			<div v-else-if="item.Type=='Regist'">
				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div>{{$t('充值金额')}}</div>

					<div style="color:rgb(128,134,145);">{{$t('剩余')}}<span
							style="color: white;">&nbsp;{{item.RemainDays}}&nbsp;</span>{{$t('天')}}</div>

				</div>
				<van-progress :show-pivot='false' color="rgb(251,158,49)"
					:percentage="item.CurCharge<item.GoalCharge?item.CurCharge/parseFloat(item.GoalCharge)*100:100"
					style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;">
					{{$tools.formatNum2k(item.GoalCharge)}}
				</div>

				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 10px;">
					<div>{{$t('有效投注')}}</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;">
						{{$tools.formatNum2k(item.CurBets)}}
					</div>

				</div>


			</div>
			<div v-else-if="item.Type=='FishMan'">
				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 10px;">
					<div>{{$t('今日盈亏')}}</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;">
						{{$tools.formatNum2k(item.CurIncome)}}K
					</div>

				</div>
			</div>
			<div v-else-if="item.Type=='Charge300'" style="color: white;font-size: 12px;margin-top: 0px;">
				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div>&nbsp;</div>
				
					<div style="color:rgb(128,134,145);font-size: 12px;">{{$t('剩余')}}<span
							style="color: white;">&nbsp;{{item.RemainDay}}&nbsp;</span>{{$t('天')}}</div>
				
				</div>
				<div v-if="item.ActivityStatus=='Done'||item.CurBets<item.GoalBets">
					<div class="flexs_sb" >
						<div>{{$t('返利')}}</div>
						<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 12px;">
							{{$tools.formatNum2k(item.Get)}}
						</div>
					</div>
					<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 10px;">
						<div>{{$t('流水')}}</div>
					
					
					</div>
					
					<van-progress v-if="item.CurBets==0&&item.GoalBets==0" :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
						:percentage="0" style="margin-top: 13px;" />
					
					<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
						:percentage="item.CurBets<item.GoalBets?item.CurBets/parseFloat(item.GoalBets)*100:100" style="margin-top: 13px;" />
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 12px;">
						{{$tools.formatNum2k(item.CurBets)}}/{{$tools.formatNum2k(item.GoalBets)}}
					</div>
					
				</div>
				<div v-else>
					<div class="flexs_sb" >
						<div>{{$t('第'+item.ChargeType+'笔充值')}}</div>
						<!-- <div>{{$t('剩余')}}{{item.RemainDay}}{{$t('天')}}</div> -->
						<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 12px;">
							>={{$tools.formatNum2k(item.MinCharge)}}
						</div>
						
					</div>
					<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 10px;">
						<div>{{$t('最高返利')}}</div>
						<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 12px;">
							{{$tools.formatNum2k(item.MaxGet)}}
						</div>
					
					</div>
				</div>
				
				
			
			</div>
			<div v-else-if="item.Type=='SportGuess'">
				<div class="flexs_sb" style="color: white;font-size: 18px;margin-top: 10px;">
					<div>{{$t('今天赢')}}</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
						{{$tools.formatNum2k(item.CurIncome)}}
					</div>
			
				</div>
			</div>
			


			<div v-else-if="item.Type=='SportWin'">

				<div class="flexs_r_c"
					style="padding-top: 10px;margin-top: 1px;border-top: 1px solid rgb(45,59,74);color:white;flex-wrap: wrap;font-size: 12px;">
					<div class="flexs_r_c" style="width: 25%;margin-top: 20px;position: relative;"
						v-for="(item1,index1) in gameList" :key="item1">
						<div style="margin: 0 auto;">{{item1}}</div>
						<img v-if="item.CurLoseNo-1==index1" :src="$img('cuowu.png')" alt=""
							style="width: 20px;position: absolute;right: -5px;">
						<img v-if="item.CurWinCnt>index1" :src="$img('zhengque.png')" alt=""
							style="width: 20px;position: absolute;right: -5px;">

					</div>

				</div>



				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 10px;">
					<div>{{$t('有效投注')}}</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 12px;">
						{{$tools.formatNum2k(item.CurBets)}}
					</div>

				</div>

			</div>

			<div v-else-if="item.Type=='Lobby'">


				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div style="font-size: 18px;">{{$t('充值金额')}}</div>

					<div style="color:rgb(128,134,145);font-size: 18px;">{{$t('剩余')}}<span
							style="color: white;">&nbsp;{{item.RemainDays}}&nbsp;</span>{{$t('天')}}</div>

				</div>
				<van-progress v-if="item.CurCharge==0&&item.NeedCharge==0" :show-pivot='false' track-color='#3e4551'
					color="rgb(251,158,49)" :percentage="0" style="margin-top: 13px;" />
				<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="item.CurCharge<item.NeedCharge?item.CurCharge/parseFloat(item.NeedCharge)*100:100"
					style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
					{{$tools.formatNum2k(item.NeedCharge)}}
				</div>


				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div style="font-size: 18px;">{{$t('体育有效投注')}}</div>



				</div>

				<van-progress v-if="item.CurSportBets==0&&item.NeedSportBet==0" :show-pivot='false'
					track-color='#3e4551' color="rgb(251,158,49)" :percentage="0" style="margin-top: 13px;" />

				<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="item.CurSportBets<item.NeedSportBet?item.CurSportBets/parseFloat(item.NeedSportBet)*100:100"
					style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
					{{$tools.formatNum2k(item.NeedSportBet)}}
				</div>

				<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 19px;">
					<div style="font-size: 18px;">{{$t('真人有效投注')}}</div>

				</div>

				<van-progress v-if="item.CurCasinoBets==0&&item.NeedCasinoBet==0" :show-pivot='false'
					track-color='#3e4551' color="rgb(251,158,49)" :percentage="0" style="margin-top: 13px;" />
				<van-progress v-else :show-pivot='false' track-color='#3e4551' color="rgb(251,158,49)"
					:percentage="item.CurCasinoBets<item.NeedCasinoBet?item.CurCasinoBets/parseFloat(item.NeedCasinoBet)*100:100"
					style="margin-top: 13px;" />
				<div style="text-align: right;color: rgb(241,175,47);margin-top: 10px;font-size: 18px;">
					{{$tools.formatNum2k(item.NeedCasinoBet)}}
				</div>

			</div>


		</div>

		<div v-for="(item,index) in discountList" :key="item"
			style="margin-top: 3vw;background-color:rgb(42,42,42);padding: 20px;border-radius:8px;">
			<div class="title">{{item.ActivityName}}</div>
			<div class="flexs_sb"
				style="padding-top: 10px;margin-top: 1px;border-top: 1px solid rgb(45,59,74);color:white;">
				<div style="font-size: 18px;">&nbsp;</div>


			</div>
			<div class="flexs_sb" style="color: white;font-size: 12px;margin-top: 0px;">
				<div class="flexs_r_c">
					<div>{{$t('金额')}}&nbsp;</div>
					<div style="text-align: right;color: rgb(241,175,47);margin-top: 0px;font-size: 12px;">
						{{$tools.formatNum3k(item.Get)}}
					</div>
				</div>
				<div class="Done btn canClick" @click="lingqu(item)">{{$t('领取')}}</div>


			</div>
		</div>
	</div>
</template>

<script>
	import {
		ref
	} from "vue";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import {
		theme
	} from '@/views/theme'
	import i18n from "@/locales/i18n";

	import {
		bus,
		busName
	} from "@/components/core/bus";
	import {
		http,
		topic
	} from "@/components/protocol/api";
	import mqant from "@/components/protocol/mqantlib";
	let NoData = require('@/views/'+ theme +'/components/NoData.vue').default
	
	let RedHeader = require('@/views/' + theme + '/components/RedHeader.vue').default

	let HomeNav = require('@/views/' + theme + '/components/HomeNav.vue').default

	export default {
		name: "Gift",
		components: {
			RedHeader,
			NoData
		},

		computed: {
			...mapGetters("global", ["lobbyInfo"]),
			...mapGetters("tcp", ["isLogged", "isConnect", "info"]),

		},
		setup() {

		},
		created() {
			//this.getData()
		},
		destroyed() {
			window.removeEventListener('popstate', this.back, false); //false阻止默认事件
		},
		mounted() {
			if (window.history && window.history.pushState) {

				window.addEventListener('popstate', this.back, false); //false阻止默认事件
			}
			if (this.isConnect) {
				this.getData()
				this.getDiscountList()


			}


			var that = this

			bus.off('onConnected')
			bus.on('onConnected', (val) => {
				that.getData()
				that.getDiscountList()
			})

		},
		methods: {
			toCharge(){
				
				var that = this
				mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
					
					if (!data.Data.myDouDouBT.length) {
						that.$router.push({
							name:'bindbank'
						})
						return
					} 
					bus.emit('hiddeTab', {
						isShow: true
					})
					that.$router.push({
						name:'recharge'
					})
				});
				
			},
			lingqu(obj) {

				var that = this
				mqant.request(topic.discount, {
					ActivityId: obj.ActivityId
				}, function(data, topicName, msg) {
					if (data.Code == 0) {
						that.$tools.toast(that.$t("领取成功"))


						that.getDiscountList()
					} else {
						that.$message({
							message: data.ErrMsg,
							type: 'error'
						});
					}
				});
			},
			getDiscountList() {
				var that = this
				mqant.request(topic.userInfo, {}, function(data, topicName, msg) {
					that.discountList = data.Data.BonusList.concat(data.Data.RebateList)
				});
			},
			back() {
				bus.emit('hiddeTab', {
					isShow: true
				})
			},
			getActivity(val) {
				let that = this;
				var url;
				if (val.Type == 'Sign') {
					url = topic.receiveSign
				} else if (val.Type == 'Regist') {
					url = topic.receiveRegist

				} else if (val.Type == 'FishMan') {
					url = topic.receiveFishMan

				} else if (val.Type == 'FirstChargeGift') {
					url = topic.receiveFirstChargeGift

				} else if (val.Type == 'Lobby') {
					url = topic.receiveLobby

				} else if (val.Type == 'SportWin') {
					url = topic.receiveSportWin

				}else if (val.Type == 'Charge300') {
					url = topic.receiveCharge300

				}


				that.$tools.loading();
				mqant.request(url, {
					language: i18n.global.locale
				}, function(res) {

					that.$tools.dismissLoading();
					if (res.Code == 0) {
						that.getData()
					} else {}
				});
			},
			getData() {


				let that = this;
				that.$tools.loading();
				mqant.request(topic.getActivityUserInfo, {
					language: i18n.global.locale
				}, function(res) {
					console.log('zzzz',res)
					that.$tools.dismissLoading();
					//console.log(res)
					if (res.Code == 0) {
						that.dataList = res.Data
					} else {
						that.$tools.toast(res.ErrMsg);
					}
				});
			},
		},
		data() {
			return {
				discountList: [],
				dataList: [],
				gameList: [this.$t('第一场'), this.$t('第二场'), this.$t('第三场'), this.$t('第四场'), this.$t('第五场'), this.$t('第六场'),
					this.$t('第七场'), this.$t('第八场')
				]


			};
		},
	};
</script>

<style lang="scss" scoped>
	.main {
		// margin-top: 10vh;
		position: relative;
		padding: 3vw;
		flex: 1;

		>.bottom {
			width: 100%;
			position: absolute;
			bottom: 0%;
		}
	}

	.title {
		width: 237px;
		height: 25px;
		line-height: 25px;
		font-size: 15px;
		color: white;
		//background: linear-gradient(to right, rgba(22, 64, 107, 0.32), rgb(25, 39, 60), rgb(11, 22, 44));
	}

	.btn {
		// width: 80px;
		padding: 0 10px;
		height: 25px;
		text-align: center;
		line-height: 25px;
		color: white;
		border-radius: 4px;
		font-size: 10px;
	}

	.Undo {
		background-color: rgb(62, 69, 81);
	}

	.Done {
		background-color:rgb(223,185,113);
		//background: linear-gradient(to right, rgb(1, 174, 246), rgb(0, 109, 221));
	}
	.mainBtnBG{
		background: linear-gradient(to right,#dbab7b,#f3e4ce,#dcb07f);
		color:#4d4b49;
	}
</style>
<style>
	table {
		width: 100%;
		border-top: 1px solid white;
		border-left: 1px solid white;
	}

	table td,
	table th {
		border-bottom: 1px solid white;
		border-right: 1px solid white;
		padding: 3px 5px;
	}

	table th {
		border-bottom: 1px solid white;
		text-align: center;
	}
</style>
