import {theme} from '@/views/theme'

let RootView = require('../views/'+ theme +'/RootView.vue').default

let home = require('../views/'+ theme +'/HomeView.vue').default
let chess = require('../views/'+ theme +'/chess.vue').default
let fishing = require('../views/'+ theme +'/fishing.vue').default
let slot = require('../views/'+ theme +'/slot.vue').default
let discount = require('../views/'+ theme +'/discount.vue').default
let userCenter = require('../views/'+ theme +'/userCenter.vue').default
let brief = require('../views/'+ theme +'/brief.vue').default

let userDiscount = require('../views/'+ theme +'/components/discount.vue').default
let checkLS = require('../views/'+ theme +'/components/checkLS.vue').default
let userInfo = require('../views/'+ theme +'/components/userInfo.vue').default
let recharge = require('../views/'+ theme +'/components/recharge.vue').default
let withdraw = require('../views/'+ theme +'/components/withdraw.vue').default
let gameRecord = require('../views/'+ theme +'/components/gameRecord.vue').default
let billRecord = require('../views/'+ theme +'/components/billRecord.vue').default
let userMessage = require('../views/'+ theme +'/components/userMessage.vue').default

let activity = require('../views/'+ theme +'/components/activity.vue').default
let getActivity = require('../views/'+ theme +'/components/getActivity.vue').default
let vip = require('../views/'+ theme +'/vip.vue').default
	
let aboutUs = require('../views/' + theme + '/aboutUs.vue').default

let getActivityRecord = require('../views/' + theme + '/components/getActivityRecord.vue').default

let turntable = require('../views/' + theme + '/turntable.vue').default
let casinoIframe = require('../views/' + theme + '/casinoIframe.vue').default



let userRoutes = [
			{
			  path: '/userDiscount',
			  name: 'userDiscount',
			  component: userDiscount
			},
			{
			  path: '/checkLS',
			  name: 'checkLS',
			  component: checkLS
			},
			{
			  path: '/userInfo',
			  name: 'userInfo',
			  component: userInfo
			},
			{
			  path: '/recharge',
			  name: 'recharge',
			  component: recharge
			},
			{
			  path: '/withdraw',
			  name: 'withdraw',
			  component: withdraw
			},
			{
			  path: '/gameRecord',
			  name: 'gameRecord',
			  component: gameRecord
			},
			{
			  path: '/billRecord',
			  name: 'billRecord',
			  component: billRecord
			},
			{
			  path: '/userMessage',
			  name: 'userMessage',
			  component: userMessage
			},
			{
			  path: '/activity',
			  name: 'activity',
			  component: activity
			},
			{
			  path: '/getActivity',
			  name: 'getActivity',
			  component: getActivity
			},
			
			{
			  path: '/getActivityRecord',
			  name: 'getActivityRecord',
			  component: getActivityRecord
			}
			
			
		]

let rootChilren = [
	{
        path: '/',
        name: 'home',
        component: home
      },
	  {
	    path: '/chess',
	    name: 'chess',
	    component: chess
	  },
	  {
	    path: '/fishing',
	    name: 'fishing',
	    component: fishing
	  },
	  {
	    path: '/slot',
	    name: 'slot',
	    component: slot
	  },
	  {
	    path: '/discount',
	    name: 'discount',
	    component: discount
	  },
	  {
	    path: '/userCenter',
	    name: 'userCenter',
		
	    component: userCenter,
		children:userRoutes
	  },
	  {
	    path: '/brief',
	    name: 'brief',
	    component: brief
		
	  },
	
	  {
	    path: '/aboutUs',
	    name: 'aboutUs1',
	    component: aboutUs
	  },
	  {
	    path: '/vip',
	    name: 'vip',
	    component: vip
	  },
	  {
	    path: '/turntable',
	    name: 'turntable',
	    component: turntable
	  },{
	    path: '/navigator',
	    name: 'casino',
	    component: casinoIframe
	  }
	
	  
	
	  
	  
]


			
			let liveCasino = require('../views/'+ theme +'/LiveCasino.vue').default
			let liveSport = require('../views/'+ theme +'/LiveSport.vue').default
			
			
			
				rootChilren.push( {
				    path: '/liveCasino',
				    name: 'liveCasino',
				    component: liveCasino
				  })
				  rootChilren.push( {
				      path: '/liveSport',
				      name: 'liveSport',
				      component: liveSport
				    })
			
			
			let lottery = require('../views/' + theme + '/lottery.vue').default
			
			rootChilren.push( {
			  path: '/lottery',
			  name: 'lottery',
			  component: lottery
			})
			
			let Douji = require('../views/' + theme + '/Douji.vue').default
			
			rootChilren.push( {
			  path: '/Douji',
			  name: 'Douji',
			  component: Douji
			})
			
			
			
		
		 
			  
		    
		
		




let routes = [
	{
		path: '/',
		name: 'root',
		redirect: '/home',
		component: RootView ,
		children: rootChilren
	},
	{
		  path: '/:pathMatch(.*)*',
		redirect:'/'
	}
]
export {
	routes,
} 