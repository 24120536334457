<template>


	<div @touchmove.prevent @mousewheel.prevent @click="handleClose()" class="wrap"
		style="width: 100%;height: 100%;font-weight: bold;position: fixed;top: 0;left: 0;z-index: 99;background-color: rgba(0, 0, 0, .7);text-align: center;">
		<div class="deepBG" @click.stop="" style="border: 1px solid rgb(41, 52, 72);border-radius: 20px;">
				<div style="position: relative;text-align: center;color: white;font-size: 20px;width: 100%;">
					{{$t('设置取款密码')}}
					<img @click="handleClose()" class="canClick" :src="$img('guanbi_img.png')" alt=""
						style="width: 14px; position: absolute;right:15px;top:0px;z-index: 999;">
				</div>
				
				<!-- <img @click="handleClose()" :src="close" class="canClick" alt=""
					style="width: 18px; position: absolute;right:15px;top:15px;z-index: 999;">

				<div class="middleSize boldSize" style="font-weight: bold;padding-top: 35px;text-align: center;color: white;">
					{{$t("绑定银行卡")}}
				</div> -->
				<div style="margin-top: 70px;">
					
					
					<div class="inputWrap" style="margin-top: 0px;">
						<div style="position: relative;z-index: 99;height: 100%;" class="flexs_r_c">
							<input v-model="pw" class="bigSize" @input="pw=checkNum(pw)" :placeholder="$t('请输入4位取款密码')"
								type="password" style="">
							

						</div>
					</div>
					<div class="inputWrap" style="margin-top: 35px;">
						<div style="position: relative;z-index: 99;height: 100%;" class="flexs_r_c">
							<input v-model="pw2" :placeholder="$t('请输入确认密码')" @input="pw2=checkNum(pw2)" class="bigSize" type="password" style="">


							
						</div>
					</div>
					
					
					<div class="mainBG send canClick middleSize boldSize mainBtnBG" @click="bindBank()">
						{{$t('提交')}}
					</div>
					<div class="flexs_sb" style="padding: 2vw 5vw;padding-right: 5.5vw;">

					</div>
				</div>
			


		</div>
	</div>






</template>

<script>
	import {
			topic
		} from "@/components/protocol/api";
	
		import {
			req,
			qs
		} from "@/utils/request";
		import mqant from "@/components/protocol/mqantlib";
		import bus from "@/utils/bus.js"
	
		import {
			getCurrentInstance
		} from 'vue'
		export default {
			data() {
				return {
					
					pw:'',
					pw2:''
				}
			},
			props: {
	
				diaLoginVisible: {
					type: Boolean,
					default: true
				}
			},
			created() {

			},
			watch:{
					cardNum(oldValue,newValue){
							if(newValue>0){
								this.showNoCard = false
							}
					},
			},
			methods: {
				
				bindBank() {
	
					var that = this
					if(this.pw.length!=4||this.pw2.length!=4){
						return
					}
					if(this.pw!=this.pw2){
						this.$message({
							message: this.$t('两次输入的密码不一致'),
							type: 'error'
						});
						return
					}
					var obj = {
						DoudouPwd: this.pw
						
					}
					mqant.request(topic.updateDoudouPwd, obj, function(data, topicName, msg) {
						if (data.Code == 0) {
							that.handleClose()
							that.$message({
								message: that.$t('设置成功'),
								type: 'success'
							});
							bus.emit('setWithdrawPw', {})
						} else {
							that.$message({
								message: data.ErrMsg,
								type: 'error'
							});
						}
	
					});
				},
				handleClose() {
					this.$emit('handleClose')
	
				},
				inputClick() {
					this.visible = true
				},
				itemClick(e) {
					this.visible = false
					this.banName = e.BtName
				},
				checkNum(data) {
					// console.log(data[0])
					// if(data[0]==0){
					// 	this.hasZero = true
						
					// }
				
					if (data) {
						if((data+'').length>=4){
							data = data.substr(0,4)
						}
						return (data = data.replace(/[^0-9]/g, ''));  
	
					}
	
				}
	
			}
		}
	
</script>

<style lang="scss" scoped>
	.wrap {
		background-color: rgba(155, 155, 155, 0.2);
		opacity: 1;

	}

	input {
		margin-left: 0;
		width: 100%;
		padding-left: 10px;
		background-color: transparent;
		height: 34px;
		position: relative;
		z-index: 9999;
		color: white;

	}

	.leftWord {
		// margin-top: 10px;

		margin-left: 3px;
		color: white;
		width: 182px;
	}

	p {

		color: white;
		text-align: center;
	}

	.redWord {
		color: #eb7f8c;

	}

	.blueWord {
		color: #31c9ed;

	}

	.inputWrap {
		
		width: 342px;
		height: 58px;
		border-radius: 5px;
		
		text-align: left;
		margin: 20px auto;
		margin-bottom: 0;
		// display: flex;
		// flex-direction: row;
		align-items: center;
		position: relative;
		background-color: #303030;
		border: 1px solid #585449;
		
	}

	.leftImg {
		width: 13px;
		margin-left: 20px;
		margin-top: 3px;
	}



	.selectArea {
		white-space: nowrap;
		overflow-y: auto;
		width: 342px;;

		height: 200px;
		background-color: #303030;
		position: absolute;
		top: 175px;
		left: 26px;
		z-index: 9999;


		display: flex;
		flex-direction: column;

		.selectItem {
			text-align: center;
			height: 45px;
			line-height: 45px;
			color: #9ea9bd;
			cursor: pointer;

		}

		.selectItem:hover {
			color: white;
			background-color: rgb(100, 100, 100);

		}
	}




	.deepBG {
		/* background: url(@/assets/pc1/login_bg.png); */
		/* width: 386px; */

		/* max-width: 53.5vh;
		max-height: 70vh; */
		position: relative;
		top: 50%;
		padding-top: 20px;
		width: 396px;
		height: 484px;
		background-color:#474443 ;
		margin: auto;
		transform: translateY(-52%);

	}

	.send {
		background-color: #0071df;
		height: 50px;
		width: 344px;
		margin: 0 auto;
		margin-top: 30px;
		line-height: 50px;
		text-align: center;
		// font-style: oblique;
		font-size: 18px;
		font-weight: bold;
		border-radius: 8px;
	}
</style>
