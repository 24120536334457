<template>
	<div @touchmove.prevent @mousewheel.prevent @click="close()" class="wrap" style="width: 100%;height: 100%;position: fixed;z-index: 99999;background-color: rgba(0, 0, 0, .4);top: 0;left: 0;">
		<div style="position: relative;width: 90vw;background-color: #282828;margin-left: 5vw;margin-top: 40vh;padding-bottom: 2vh;">
<!-- 			<img :src="$img('confirm.webp')" alt="" style="width:450px;position: absolute;left: 0;">
 -->			<div class="title phoneBig" style="color: white;">{{title}}</div>
			
			<img :src="$img('close_blue.png')" class="" style="width: 20px;position: absolute;right: -5px;top: -5px;"/>
			
			
			<div class="flexs_sb" style="padding: 3vw 5vw;position: relative;z-index: 99;background-color: rgb(40, 40, 40);">
				<div class="cancel  phoneMiddle" @click="left()">{{$t(cancelStr)}}</div>
				<div class="sure  phoneMiddle" @click="right()">{{$t(sureStr)}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data(){
			return {
			
			}
		},
		props:{
			
			title:{
				type:String,
				default:'1111'
			},
			cancelStr:{
				type:String,
				default:'取消'
			},
			sureStr:{
				type:String,
				default:'确认'
			},
		},
		created() {
	
		},
		methods:{
			close(){
				this.$emit('close')
				
			},
			left(){
				this.$emit('left')
			},
			right(){
				this.$emit('right')
				
			}
			
			
		}
	}
</script>

<style scoped lang="scss">
	.title{
		
		
		color:#999999;
		position: relative;
		z-index: 88;
		text-align: center;
		//margin-top: 30px;
		padding-top: 20px;
		// top: 30px;
	}
	.cancel{
		width: 35vw;
		height: 8vw;
		color: white;
		line-height: 8vw;
		text-align: center;
		border-radius: 5vw;
		background-color: rgb(242,209,97);
	}
	.sure{
		width: 35vw;
		height: 8vw;
		color: white;
		line-height: 8vw;
		text-align: center;
		border-radius: 5vw;
		background-color: rgb(242,209,97);
	}
</style>