<template>
	<div style="" class="rightTool">
		<div style="position: relative;">
			<div style="background-color: rgba(33,42,61,.54);">
				<div class="colum canClick" @mouseenter='selectIndex=0' @mouseleave='selectIndex=-1'  :class="selectIndex==0?'select':'nolmal'">
					<img :src="$img('kf_icon.png')" alt="" style="width: 31px;margin-top: 17px;">
				</div>
				<div style="height: 1px;width: 80%;background-color: rgb(54, 61, 78);margin-left: 10%;"></div>
				
				<div class="colum canClick" @mouseenter='selectIndex=1' @mouseleave='selectIndex=-1' :class="selectIndex==1?'select':'nolmal'">
					<img :src="$img('xz_icon.png')" alt="" style="width: 31px;margin-top: 17px;">
					
				</div>
				<div style="height: 1px;width: 80%;background-color: rgb(54, 61, 78);margin-left: 10%;"></div>
				
				<div class="colum canClick" @mouseenter='selectIndex=2' @mouseleave='selectIndex=-1'  :class="selectIndex==2?'select':'nolmal'">
					<img :src="$img('lihe_img.png')" alt="" style="width: 31px;margin-top: 17px;border-bottom: none;">
					
				</div>
				
			</div>
						
					
			
			<div v-if="selectIndex>=0" style="position: absolute;right: 64px;width: 238px;padding-top:10px ;padding-bottom: 20px;top: 0;background-color: #302e2b;">
				
				<div v-if="selectIndex==0" @mouseenter='selectIndex=0' @mouseleave='selectIndex=-1' >
<!-- 					<div style="font-size: 14px;color: #f98c30;text-align: center;">7x24{{$t("小时")}}</div>
 -->					<div class="flexs_r_c canClick" @click="toUrl(lobbyInfo.CustomerInfo.customerLiveChat)" @mouseenter='hoverIndex=0' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);" >
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==0"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						
						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							<img :src="$img('kf_icon_se.png')" v-if="hoverIndex==0" alt="" style="width: 26px;">
							<img :src="$img('kf_icon.png')" alt="" v-else style="width: 26px;">
							
							<div style="margin-left: 10px;font-size: 14px">
								<div style="color: #fdfeff;">7x24{{$t("小时")}}</div>
								<div style="color: #95a0b4;" :style="hoverIndex==0?'color:#b29969;':''">{{$t("在线客服")}}</div>
							</div>
						</div>
						
					</div>
					<div class="flexs_r_c canClick" @click="toUrl(lobbyInfo.CustomerInfo.customerTelegram.split('@')[0])" @mouseenter='hoverIndex=1' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==1"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						
						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							<img :src="$img('icon_tg_se.png')" v-if="hoverIndex==1" alt="" style="width: 26px;">
							
							<img :src="$img('icon_tg.png')" v-else alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 14px;">
								<div style="color: #fdfeff;">Telegram</div>
								<div style="color: #95a0b4;overflow:hidden; white-space: nowrap; text-overflow: ellipsis;width: 170px;" :style="hoverIndex==1?'color:#b29969;':''">{{lobbyInfo.CustomerInfo.customerTelegram.split('@')[1]}}</div>
							</div>
						</div>
						
					</div>
					<div class="flexs_r_c canClick" @click="toUrl(lobbyInfo.CustomerInfo.customerFaceBook)" @mouseenter='hoverIndex=2' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==2"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							<img :src="$img('icon_fb_se.png')" v-if="hoverIndex==2" alt="" style="width: 26px;">
							
							<img :src="$img('icon_fb.png')" v-else alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 14px;">
								<div style="color: #fdfeff;">FaceBook</div>
<!-- 								<div style="color: #95a0b4;overflow:hidden; white-space: nowrap; text-overflow: ellipsis;width: 65%;" :style="hoverIndex==2?'color:#b29969;':''">{{lobbyInfo.CustomerInfo.customerFaceBook}}</div>
 -->							</div>
						</div>
						
						
					</div>
					<div class="flexs_r_c canClick" @click="copyUrl(lobbyInfo.CustomerInfo.customerTel[0])" @mouseenter='hoverIndex=3' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==3"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							<img :src="$img('icon_dh_se.png')" v-if="hoverIndex==3" alt="" style="width: 26px;">
							
							<img :src="$img('icon_dh.png')" v-else alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 14px">
								<div style="color: #fdfeff;">{{$t("客服热线")}}</div>
								<div style="color: #95a0b4;" :style="hoverIndex==3?'color:#b29969;':''">{{lobbyInfo.CustomerInfo.customerTel[0]}}</div>
							</div>
						</div>
						
					</div>
					<div class="flexs_r_c canClick" @click="copyUrl(lobbyInfo.CustomerInfo.customerMail)" @mouseenter='hoverIndex=4' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==4"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							<img :src="$img('icon_yx_se.png')" v-if="hoverIndex==4" alt="" style="width: 26px;">
							
							<img :src="$img('icon_yx.png')" v-else alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 14px">
								<div style="color: #fdfeff;">{{$t("官方邮箱")}}</div>
								<div style="color: #95a0b4;" :style="hoverIndex==4?'color:#b29969;':''">{{lobbyInfo.CustomerInfo.customerMail}}</div>
							</div>
						</div>
						
					</div>
				</div>
				<div v-if="selectIndex==1" @mouseenter='selectIndex=1' @mouseleave='selectIndex=-1'>
					<div style="font-size: 14px;color: #f98c30;text-align: center;">{{$t("官方")}}&nbsp;APP</div>
					<div class="flexs_r_c" style="margin-top: 30px;">
						<div style="width: 50%;">
							<div style="text-align: center;font-size: 14px;color: white;">
								iOS
							</div>
							<vue-qr :text="lobbyInfo.VersionGet.ios.UrlPath" :size="90" :margin='10' style="width: 90px;margin-top: 10px;margin-left: 12px;"></vue-qr>
							
						</div>
						<div style="width: 50%;">
							<div style="text-align: center;font-size: 14px;color: white;">
								Android
							</div>
							<vue-qr :text="lobbyInfo.VersionGet.android.UrlPath" :size="90" :margin='10' style="width: 90px;margin-top: 10px;margin-left: 12px;"></vue-qr>
							
						</div>
						
					</div>
					<div style="font-size: 14px;color: white;text-align: center;margin-top: 20px;">{{$t("扫码进入APP下载页面")}}</div>
					
					
				</div>
				<div v-if="selectIndex==2" @mouseenter='selectIndex=2' @mouseleave='selectIndex=-1'>
					<div @click="toPage(0)"  class="flexs_r_c canClick" @mouseenter='hoverIndex=0' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==0"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						
						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							<img :src="$img('icon_cip_se.png')" v-if="hoverIndex==0" alt="" style="width: 26px;">
							
							<img :src="$img('icon_cip.png')" v-else alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 14px">
								<div style="color: #fdfeff;">VIP</div>
								<div style="color: #95a0b4;" :style="hoverIndex==0?'color:#b29969;':''">{{$t("全民福利")}}</div>
							</div>
						</div>
						
					</div>
					<div @click="toPage(1)"  class="flexs_r_c canClick" @mouseenter='hoverIndex=1' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==1"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							<img :src="$img('icon_qd_se.png')" v-if="hoverIndex==1" alt="" style="width: 26px;">
							
							<img :src="$img('icon_qd.png')" v-else alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 14px">
								<div style="color: #fdfeff;">{{$t("每日签到")}}</div>
								<div style="color: #95a0b4;" :style="hoverIndex==1?'color:#b29969;':''">{{$t("福利天天有")}}</div>
							</div>
						</div>
						
						
					</div>
					<div @click="toPage(2)" class="flexs_r_c canClick" @mouseenter='hoverIndex=2' @mouseleave='hoverIndex=-1' style="position: relative;padding-left: 18px;height: 51px;border-bottom: 1px solid rgb(54, 61, 78);">
<!-- 						<img :src="$img('hover_img.png')" v-if="hoverIndex==2"  alt="" style="width: 100%;position: absolute;left: 0;top: 0;z-index: 0;">
 -->						
						<div class="flexs_r_c" style="position: relative;z-index: 10;">
							<img :src="$img('icon_hl_se.png')" v-if="hoverIndex==2" alt="" style="width: 26px;">
							
							<img :src="$img('icon_hl.png')" v-else alt="" style="width: 26px;">
							<div style="margin-left: 10px;font-size: 14px">
								<div style="color: #fdfeff;">{{$t("充值豪礼")}}</div>
								<div style="color: #95a0b4;" :style="hoverIndex==2?'color:#b29969;':''">Iphone&nbsp;{{$t("等你拿")}}</div>
							</div>
						</div>
						
					</div>
					
				</div>
			</div>
			
		</div>
	
	</div>
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import mqant from "@/components/protocol/mqantlib";
	import bus from "@/utils/bus.js"
	import i18n from "@/locales/i18n";
	import vueQr from 'vue-qr/src/packages/vue-qr.vue'
	
	import {
		req,
		qs
	} from "@/utils/request";
	import {
		topic
	} from "@/components/protocol/api";
	export default {
		components: {
			vueQr
		},
		data() {
			return {
				show:false,
				showTel:false,
				showEmail:false,
				selectIndex:-1,
				hoverIndex:-1
			}
		},
		computed: {
	...mapGetters("global", ["lobbyInfo"]),
	
		},
	
		created() {
			
			
		},
		methods: {
			topClick(index){
				if(this.selectIndex==index){
					this.selectIndex=-1
				}else {
					this.selectIndex=index
				}
			},
			toPage(index){
				let name;
				if(index==0){
					name = 'vip'
					this.$router.push({
						name: name
					
					
					
					})
				}else if(index==1){
					name = 'activity'
					
					if(this.$route.path=='/activity'){
						bus.emit('activity', {
							id:'Sign'
						
						})
					}else {
						this.$router.push({
							name: name,
							params: {
								id:'Sign'
							}
							})
					}
					
						
					
					
					// })
				}else {
					
					name = 'activity'
					
					if(this.$route.path=='/activity'){
						bus.emit('activity', {
							id:'Sign'
						
						})
					}else {
						this.$router.push({
							name: name,
							params: {
								id:'FirstChargeGift'
							}
							})
					}
					
					
				}
				
				this.selectIndex = -1
			},
			rootClick(){
					this.show = !this.show
					this.showTel = false
					this.showEmail = false
			},
			copyUrl(text) {
				if(!text){
					return
				}
			      // 模拟 输入框
			      let cInput = document.createElement("input");
			      cInput.value = text;
			      document.body.appendChild(cInput);
			      // 选取文本框内容
			      cInput.select();
			
			      // 执行浏览器复制命令
			      // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
			      // Input要在正常的编辑状态下原生复制方法才会生效
			      document.execCommand("copy");
			
			      this.$message({type: "success", message:this.$t("复制成功")});
			      // 复制成功后再将构造的标签 移除
			      document.body.removeChild(cInput);
				  this.selectIndex = -1
			},
				toUrl(url){
					
					window.open(url)
					
					this.selectIndex = -1
				}
	
		},
	
	};
	
</script>

<style lang="scss" scoped>
	
	.rightTool {
		
		position: fixed;		
		right: 0px;
		z-index: 9999;
		top: 253px;
		width: 65px;
	
		
	
		.line {
			margin-left: .3vw;
			background-color: white;
			height: 1px;
			width: 100%;
			margin-top: .75vw;
		}
	}
	
	.colum {
		height: 65px;
		width: 100%;
		text-align: center;
	}
	.select{
		background: linear-gradient(to bottom,#cc9f6e,#eccca9);
	}
	.nolmal {
		//background-color: rgba(33,42,61,.54)//#212a3d;
		
	}
</style>