<template>
  <div style="overflow-x: hidden;" id="bottomWrap" ref="scrollDiv">
	  <HomeNav style="position: fixed;top: 0;z-index: 9999;"/>
	  <div v-if="offset>400" class="canClick" @click="toTop()" style="background-color: rgb(63, 69, 82);position: fixed;top:50vh;right: 0;width: 40px;height: 40px;z-index: 999;text-align: center;">
	  	<img :src="$img('toTop.png')" alt="" style="margin: 0 auto;width: 27px;margin-top: 11px;">
	  	
	  </div>
	  <div style="margin-top: 22vw;" >
		 
		  <div v-for="(item,index) in list" :key="item" style="" >
		  	<div class="wrap">
		  		<img :src="item.img" alt="" @click="columClick(index)" style="width: 100%;">
		  		<div style="position: absolute;color: rgb(233,231,225);font-size: 26px;left: 15px;top: 20px;" >{{item.title}}</div>
				<div style="width: 100%;display: flex;flex-direction: row;flex-wrap: wrap;" v-if="selectIndex==index">
					<div v-for="(item1,index1) in sortData(lobbyInfo.StaticPicture[item.key])" :key="item1" style="position: relative;width: 22%;margin-top: 2vw;" :style="index1%4!=0?'margin-left: 4%;':''">
						<img :src="$img('colum_no.png')" v-if="item.selectIndex!=index1" alt="" style="width: 100%;">
						<img :src="$img('colum_se.png')" v-if="item.selectIndex==index1" alt="" style="width: 100%;">
						
						
						<div style="position: absolute;top: 0;left: 0;width: 100%;" @click="gameClick(index1,item1)">
							<img class="diseableImg" v-if="item1.Factory.Status==2||item1.Factory.Status==3"
								:src="$img(item1.Factory.FactoryName+'_c.png')" alt="" style="width: 100%;">
							
							
							
							<img v-else :src="$img(item1.Factory.FactoryName+'_c.png')" alt="" style="width: 100%;">
							
							<div v-if="selectIndex!=2">
								<div v-if="item1.Factory.Status==2||item1.Factory.Status==3"
										 style="width: 100%;text-align: center;color: #797979;font-size: 12px;margin-top: -6vw;">
										{{item1.Factory.FactoryShowName.toUpperCase()}}</div>
									<div v-else style="width: 100%;text-align: center;color: white;font-size: 12px;margin-top: -6vw;">{{item1.Factory.FactoryShowName.toUpperCase()}}</div>
								
							</div>
							
								</div>
					</div>
				</div>
				
				<div v-if="item.selectIndex>=0&&selectIndex==index" style="width: 100%;">
					<div class="flexs_r_c">
						<div class="flexs_sb" style="padding:0 10px;font-size: 14px;background-color: rgb(43,41,39);width: 70%;height: 34px;border-radius: 4px;">
							<input type="text" v-model="searchKey" :placeholder="$t('请输入游戏名称')" v-on:input="search" style="color: white;font-size: 14px;">
							<img :src="$img('search.png')" alt="" style="width: 19px;">
						</div>
					</div>
					
					
					<div v-if="!isSearch" style="width: 100%;display: flex;flex-direction: row;flex-wrap: wrap;">
						<div v-for="(item,index) in selectItem.GameList"  :key="item" style="position: relative;width: 22%;margin-top: 2vw;" :style="index%4!=0?'margin-left: 4%;':''">
							<img :src="getImg(item)" @click="openUrl(item)" alt="" style="width: 100%;">
							<div style="width: 100%;text-align: center;color: white;font-size: 10px;margin-top: 10px;">{{item.GameName}}</div>
						</div>
					</div>
					<div v-else style="width: 100%;display: flex;flex-direction: row;flex-wrap: wrap;">
						<div v-for="(item,index) in searchData"  :key="item" style="position: relative;width: 22%;margin-top: 2vw;" :style="index%4!=0?'margin-left: 4%;':''">
							<img :src="getImg(item)" @click="openUrl(item)" alt="" style="width: 100%;">
							<div style="width: 100%;text-align: center;color: white;font-size: 10px;margin-top: 10px;">{{item.GameName}}</div>
						</div>
					</div>
				</div>
				
			</div>
		  </div>
	  </div>
	
 </div>
</template>
<script>
			import {theme} from '@/views/theme'
			let HomeNav = require('@/views/'+ theme +'/components/HomeNav.vue').default
			import {
				mapGetters,
				mapActions,
				mapMutations
			} from "vuex";	
			import { status, getApiLoginUrl } from "@/components/protocol/api";
			import i18n from "@/locales/i18n";
			
		
export default {
  components: {
	 	HomeNav
	 	
	
  },
  setup() {},
  name: "",
  data() {
    return {
		selectIndex:-1,
		selectItem:null,
		isSearch:false,
		searchKey:'',
		searchData:[],
		offset:0,
		list:[{title:this.$t("体育"),img:this.$img('game_sport.png'),key:'LiveSports',selectIndex:-1},
		
		{title:this.$t("真人视讯"),img:this.$img('game_live.png'),key:'LiveCasino',selectIndex:-1},
		{title:this.$t("斗鸡"),img:this.$img('doujiWrap.png'),key:'CockFight',selectIndex:-1},
		
		{title:this.$t("老虎机"),img:this.$img('game_slot.png'),key:'Slot',selectIndex:-1},
		{title:this.$t("捕鱼"),img:this.$img('game_fish.png'),key:'Fish',selectIndex:-1},
		{title:this.$t("棋牌"),img:this.$img('game_chess.png'),key:'Card',selectIndex:-1},
		{title:this.$t("彩票"),img:this.$img('game_lottery.png'),key:'Lottery',selectIndex:-1}
			
		]
	};
  },
  computed: {
  
  
  	...mapGetters("global", ["lobbyInfo"]),
  
  },
  mounted(){
	  document.querySelector("#bottomWrap").addEventListener('scroll',this.handleScroll)
	  var that = this
	  
	  if(this.$route.params.type){
		  this.selectIndex=this.$route.params.type
		  
		  var obj = {}
		  var arr = []
		  if(this.selectIndex==2){
			  obj = this.lobbyInfo.StaticPicture.Slot
			  
			  	
		  }else if(this.selectIndex==3){
			  obj = this.lobbyInfo.StaticPicture.Fish
			  
		  }else {
			  obj = this.lobbyInfo.StaticPicture.Card
			  
		  }
		  
		  for (let key in obj) {
		  	arr.push(obj[key])
		  			  
		  }
		  arr = arr.sort((a, b) => {
		  	return a.Factory.Status - b.Factory.Status || b.Factory.Sort - a.Factory.Sort
		  
		  })
		  
		  
		  if(this.$route.params.FactoryName){ 
			arr.forEach(function (item, index) {
				if (item.Factory.FactoryName == that.$route.params.FactoryName) {
					that.selectItem = item
					that.list[that.selectIndex].selectIndex = index
					
				}
			
			})
		  }
	  }
  },
 
  methods: {
	  toTop(){
	  	document.querySelector("#bottomWrap").scrollTop = 0;
	  	
	  },
	  toDouji(){
	  	let name = this.lobbyInfo.StaticPicture.CockFight.apiSea.Factory.FactoryName;
	  	let url = getApiLoginUrl(name, "");
	  	//console.log('ssszz',url)
	  	this.$act.openPage(url);
	  },
	   handleScroll(){
		   
	        let scrollTop = document.querySelector("#bottomWrap").scrollTop;
	        //console.log(scrollTop);
			this.offset = scrollTop
	      },
	  search(val){
		  if(this.searchKey.length==0){
			  this.isSearch = false
			  return
		  }
		  var that = this
		  this.searchData = []
		this.selectItem.GameList.forEach(function (item, index) {
			
				if (item.GameName.toLowerCase().indexOf(that.searchKey.toLowerCase())>=0) {
				that.searchData.push(item)
				
			}
		
		})
		this.isSearch = true
	},
	  gameClick(index,item){
		  if(this.selectIndex==0||this.selectIndex==1||this.selectIndex==2||this.selectIndex==6){
			  this.openUrl(item)
		  }else {
			  if(this.list[this.selectIndex].selectIndex==index){
				  this.selectItem = null
				  this.list[this.selectIndex].selectIndex = -1
			  }else {
				  this.selectItem = item
				  this.list[this.selectIndex].selectIndex = index
			  }
			
		  }
	  },
	  getImg(item) {
	  	return this.lobbyInfo.HotDomain + '/gameIcon2' + '/' + item.FactoryName + '/' + this.language() + '/' + item.GameCode + '.png'
	  },
	  language() {
	  	return i18n.global.locale;
	  },
	  openUrl(item){
		if (!this.$act.checkLogged()) return;
		if(item.Factory){
			let url = getApiLoginUrl(item.Factory.FactoryName, item.Factory.GameCode);
			this.$act.openPage(url);
			
		}else {
			let url = getApiLoginUrl(item.FactoryName, item.GameCode);
			this.$act.openPage(url);
		}
	  },
	  columClick(index){
		  if(this.selectIndex==index){
			  this.selectIndex = -1
			  this.selectItem = null
			  this.list[index].selectIndex = -1
		  }else {
			  this.searchKey = ''
			  this.isSearch = false
			  this.selectIndex = index
			  this.selectItem = null
			  
			  
		  }
		  if(index>4){
			  this.handleScrollBottom()
			  
		  }
		  
		 
	  },
	  // 滚动到底部
	  handleScrollBottom() {
	    this.$nextTick(() => {
	      let scrollElem = this.$refs.scrollDiv;
	      scrollElem.scrollTo({ top: 9999, behavior: 'smooth' });
	    });
	  },
	  sortData(data) {
	  	var arr = []
	  	for (let key in data) {
	  		arr.push(data[key])
	  
	  	}
	  
	  	return arr.sort((a, b) => a.Factory.Status - b.Factory.Status || b.Factory.Sort - a.Factory.Sort)
	  
	  },
  },
};
</script>
<style lang="scss" scoped>
	.wrap{
		width: 94vw;
		margin-left: 3vw;
		margin-top: 2vw;
		position: relative;
	}
</style>

