<template>
  <!-- <div class="item">
    <img :src="$img('home_panel_foot_bg.png')" class="item_img" />
    <div class="content">
      <div class="c_left">
        <img :src="$img('home_panel_foot.png')" class="icon" />
      </div>
      <div class="c_right">xxxxxx</div>
    </div>
  </div> -->
  <div class="item">
    <img :src="bg" class="item_img" />
    <div class="content">
      <div class="c_left">
        <!-- <img :src="icon" class="icon" /> -->
      </div>
      <div class="c_right phoneBig boldSize">{{name}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePanelItem",
  props: {
    icon: String,
    bg: String,
    name: String,
  },
};
</script>

<style lang="scss" scoped>
.c_right {
  flex: 1;
  // -webkit-text-stroke: .6px #fff;
  padding-right: 2vw;
  font-family: SVN-Arial;
  font-weight: bold;
  text-align: right;
  color: white;
}
.c_left {
  width: 43%;
  text-align: end;
}
.icon {
  width: 14vw;
}
.content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.item {
  position: relative;
  width: 48vw;
  display: inline-grid;
}
.item_img {
  width: 100%;
}
</style>