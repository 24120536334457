<template>
	<HomeNav style="position: fixed;top: 0;z-index: 9999;" />

	<div class="main">
		<!-- <img :src="$img('person_bottom.png')" class="bottom" alt="" /> -->
		<div style="position: relative;text-align: center;" class="flexs_colum">

			<img :src="lobbyInfo.HotDomain + '/head/' + userInfo.Avatar+'.png'" alt=""
				style="width: 65px;margin-top: 30px;margin-left: 40vw;border-radius: 32.5px;">
			<!-- 		<img  :src="$img('VIP_'+info.VipLevel+'.png')" style="width: 15vw;z-index: 9999;margin: 1vw auto;" class="" />
 -->
		</div>

		<div class="phoneBig boldSize" style="color: white;text-align: center;margin-top: 2vw;font-size: 18px;">
			{{ userInfo.Account }}</div>
		<div class="phoneBig whiteWord boldSize" style="text-align: center;margin-top: 2vw;color: rgb(241,175,47);">
			{{ $tools.formatNum2k(wallet.VndBalance)}}</div>


		<div style="margin-top: 5vw;padding-bottom: 3vw;	border-radius: 9px;overflow: hidden;">
			<div v-for="(item,index) in columData" @click="columClick(item)" class="cell flexs_sb"
				:style="index==columData.length-1?'border:none;':''">
				<div class="flexs_r_c">
					<img :src="item.icon" alt="" style="width: 5vw;margin-left: 2vw;">
					<div style="margin-left: 2vw;color: white;font-size: 14px;">{{item.title}}</div>
				
					<div v-if="index==7&&readNum>0" class="read">{{readNum}}</div>

				</div>

				<img :src="$img('iconu.png')" alt="" style="margin-right: 2vw;width: 10px;">
			</div>
		</div>

		<!-- 	<div @click="goLogout" style="width: 60vw;margin: 5vw auto;height: 10vw;border-radius: 4px;background-color: rgb(213, 188, 145);line-height: 10vw;color: white;text-align: center;">{{$t('退出登录')}}</div>
 -->
		<div class="loginBtn">
			<img :src="$img('ann_bottom.png')" alt="" style="width: 100%;">
			<div @click="goLogout"
				style="text-align: center;position: absolute;z-index: 9;width: 100%;top: 3.5vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
				{{$t('退出登录')}}
			</div>

		</div>

		<div class="version">
			V{{version}}
		</div>

	</div>
</template>

<script>
	import {
		theme
	} from '@/views/theme'
	import {
		bus,
		busName
	} from "@/components/core/bus";
	let HomeNav = require('@/views/' + theme + '/components/HomeNav.vue').default
	import mqant from "@/components/protocol/mqantlib";
	import { http, topic, getApiLoginUrl } from "@/components/protocol/api";
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	export default {
		components: {
			HomeNav
		},
		name: "Person",
		data() {
			return {
				readNum:0,
				columData: [{
						icon: this.$img("jbzl.png"),
						title: this.$t("基本资料").toUpperCase(),
						to: "UserInfo",
					},
					{
						icon: this.$img("lsjc.png"),
						title: this.$t("检查流水").toUpperCase(),
						to: "CheckWater",
					},
					{
						icon: this.$img("czjl.png"),
						title: this.$t("充值记录").toUpperCase(),
						to: "BillRecord",
						type:1
					},
					{
						icon: this.$img("qkjl.png"),
						title: this.$t("取款记录").toUpperCase(),
						to: "BillRecord",
						type:2
					},
					{
						icon: this.$img("yxjl.png"),
						title: this.$t("游戏记录").toUpperCase(),
						to: "GameRecord",
					},
					{
						icon: this.$img("hdlq.png"),
						title: this.$t("活动领取记录").toUpperCase(),
						to: "BillRecord",
						type:3
					},
					{
						icon: this.$img("sqdl.png"),
						title: this.$t("申请代理").toUpperCase(),
						to: "BeDelegate"
						
					},
					{
						icon: this.$img("mail_sj.png"),
						title: this.$t("消息").toUpperCase(),
						to: "Message"
						
					}

					



				],
			};
		},
		created() {
			var that = this
			bus.on('onConnected', () => {
				that.getMessageList()
				
			})
			if(this.isConnect){
				this.getMessageList()
				
			}
		},
		computed: {
			...mapGetters("tcp", ["isLogged", "wallet", "info","isConnect"]),
			...mapGetters("global", ["version", "lobbyInfo"]),
			...mapGetters("user", ["userInfo"]),


		},

		methods: {
			...mapActions("user", ["doLogout"]),
			getMessageList() {
				var that = this
				this.readNum = 0

				var num = 0
				mqant.request(topic.mailList, {}, function(data, topicName, msg) {
					that.messageData = []
					for(var obj of data.Data.mail){
						if(obj.ReadState!='read'){
							num ++
						}
					}
					that.readNum = num
				
				});
			},
			goLogout() {

				localStorage.removeItem('rechangeAccount')

				this.doLogout();
			},
			columClick(item) {
				bus.emit('hiddeTab', {
					isShow: false
				})
				var type;
				console.log('rr',item.to)

				if (item.to == 'BillRecord') {
					
					this.$router.push({
						name: item.to,
						params: {
							type: item.type
						}
					});
				} else {
					if(item.to=='BeDelegate'){
						
						var that = this
				mqant.request(topic.queryAgentRegister, {}, function(data, topicName, msg) {
					console.log('ee',data)
					if(data.Data.status==-1){

						that.$router.push({
						name: item.to
						});

					}else if(data.Data.status==4){
						//	提示
						that.$tools.toast(that.$t('申请失败')+' '+that.$t('原因')+':'+data.Data.remark);

						

					}else if(data.Data.status==9){
						//已是代理
						that.$tools.toast(that.$t('您已是代理'));

						
					}else {
						//审核中
						that.$tools.toast(that.$t('客服审核中'));

						
					}
						});

						return
					}
					this.$router.push({
						name: item.to
					});

				}
			},
		},
		setup() {},
	};
</script>

<style lang="scss" scoped>
	.loginBtn {
		width: 94%;
		margin-left: 3%;

		position: relative;
		margin-top: 24px;
		margin-bottom: 24px;
	}

	.version {
		position: fixed;
		bottom: 8vh;
		right: 0%;
		font-size: 12px;
		color: gray;
	}

	.main {
		margin-top: 18vw;
		position: relative;
		height: 100%;
		overflow-x: hidden;
		background-color: transparent;
	}

	.cell {
		width: 94%;
		margin-left: 3%;
		height: 15vw;
		// padding: 0 2vw;
		// border-bottom: 1px solid rgb(64, 87, 127);
		 background-color: rgb(42,42,42);

	}
	.read{
		width:16px;
		height:16px;
		background-color: red;
		color:white;
		border-radius: 8px;
		font-size: 12px;
		text-align: center;
		line-height: 16px;
		
		margin-left:5px;
	}
</style>
