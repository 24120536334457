<template>
	<red-header :title="$t('关于我们')" :showBack="true" />
	
	<div class="flexs_colum" style="overflow-x: hidden;position: relative;z-index: 3;height: calc(100vh - 44px);padding: 3vw;color: white;">
		
		<div class="flexs_r" style="flex-wrap: wrap;margin-bottom: 20px;">
			<div v-for="(item,index) in leftList" class="canClick" @click="selectIndex=index" :key="item" style="padding: 0 10px;height: 30px;text-align: center;line-height: 30px;font-size: 10px;position: relative;margin-left: 10px;margin-top: 10px;" :class="selectIndex==index?'select':'nolmal'">
				
				<div style="position: relative;z-index: 9;">{{item}}</div>
			</div>
		</div>
		<div  v-if="selectIndex==0">
			<p><span style="color: rgb(225, 60, 57);"><strong>Về chúng tôi</strong></span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 được đăng ký tại Manila, thủ đô của Philippines, là
					một trong những sòng bạc trực tuyến lớn nhất và phát triển nhanh nhất ở Châu Á, cung cấp các
					sòng bạc chia bài trực tuyến hấp dẫn, các trang cá cược thể thao, máy quay nổ hũ, trò chơi ảo và
					trò chơi poker .... Có Giấy phép kinh doanh hợp pháp Cá cược Thể thao và Sòng bạc của Cơ quan
					Quản lý Cagayan Economic Zone Authority.</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88, với tư cách là một công ty trò chơi trực tuyến nổi
					tiếng, cung cấp phạm vi rộng nhất và các sản phẩm cạnh tranh nhất.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 77, 79);"><strong>Chúng tôi hứa</strong></span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">Công bằng và trung thực</span></p>
			<p><span style="font-family: 微软雅黑;">Là một nhà điều hành trò chơi trực tuyến nổi tiếng quốc tế, Nhà cái
					HDBet88 đã kiểm tra nghiêm ngặt tất cả các game để có thể đưa lên trang web và chỉ chọn các nền
					tảng trò chơi trực tuyến nổi tiếng quốc tế. Đảm bảo rằng mọi người chơi đều chơi trong một môi
					trường trung thực và công bằng. Đồng thời, First Cagayan Leisure and Resort Corporation của
					chính phủ Philippines sẽ giám sát dữ liệu của nền tảng trò chơi và cuối cùng đảm bảo tính công
					bằng và xác thực của trò chơi.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">Nguyên tắc "Không giới hạn"</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 luôn tuân thủ nguyên tắc "không giới hạn khi doanh thu
					cược và không giới hạn khi rút tiền" để đảm bảo rằng tất cả người chơi có thể tận hưởng trò chơi
					một cách tối đa.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">Gửi và rút tiền hiệu quả</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 luôn chú trọng đến trải nghiệm người dùng. Hoạt động
					kinh doanh gửi và rút tiền hiệu quả và nhanh chóng là trải nghiệm thực tế nhất cho người dùng,
					vì vậy chúng tôi đã cam kết phát triển kinh doanh thu nhập và chi tiêu mới nhất và đào tạo nhân
					sự để đảm bảo gửi và rút tiền hiệu quả, đồng thời đảm bảo rằng các khoản tiền gửi và rút tiền sẽ
					đến trong vòng 5 phút.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">Đội ngũ dịch vụ chăm sóc khách hàng ưu
					tú</span></p>
			<p><span style="font-family: 微软雅黑;">Dịch vụ chăm sóc khách hàng của Nhà cái HDBet88 đã trải qua quá trình
					sàng lọc nghiêm ngặt và đào tạo chuyên nghiệp, đồng thời đã xây dựng được đội ngũ dịch vụ chăm
					sóc khách hàng ưu tú để mang đến cho người chơi chất lượng dịch vụ cao cấp. Chúng tôi cung cấp
					cho bạn các dịch vụ VIP chuyên nghiệp và chu đáo 24 giờ một ngày, khiến bạn cảm thấy như đang ở
					nhà.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">Đội ngũ kỹ thuật chuyên nghiệp</span></p>
			<p><span style="font-family: 微软雅黑;">Là một nhà điều hành trò chơi trực tuyến chuyên nghiệp, chúng tôi đã
					thuê một nhóm các chuyên gia và nhà kinh doanh trò chơi trực tuyến giàu kinh nghiệm trong ngành
					, để cung cấp cho người chơi những trò chơi giải trí và tiên tiến nhất, và đảm bảo tỷ lệ cược
					trên nền tảng trò chơi của chúng tôi là tỷ lệ cược tốt nhất để đảm bảo quyền lợi của đa số người
					chơi. Tất cả các trò chơi tại nhà cái HDBet88 đều được thiết kế bằng phần mềm hàng đầu thế
					giới.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">Bảo mật thông tin cá nhân</span></p>
			<p><span style="font-family: 微软雅黑;">Cung cấp một kênh bảo mật được mã hóa ổn định (chuẩn mã hóa SSL
					256-bit), và bản sao lưu máy chủ nhân bản đảm bảo an toàn cho cơ sở dữ liệu và thông tin khách
					hàng. Các giao dịch ngân hàng và thanh toán trực tuyến của người chơi được thực hiện bởi các tổ
					chức tài chính quốc tế trong một mạng lưới các tiêu chuẩn bảo mật và an toàn cao. Ngoài ra, mọi
					hoạt động của khách hàng trên nền tảng này đều được bảo mật nghiêm ngặt, và chúng tôi sẽ không
					tiết lộ thông tin cá nhân và thông tin của khách hàng cho bất kỳ bên thứ ba nào, kể cả chính
					phủ.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">Cá cược có trách nhiệm</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 cam kết "Cá cược có trách nhiệm" và đảm bảo rằng mọi
					khách hàng đều có thể tận hưởng niềm vui khi chơi game với chúng tôi. Tuy nhiên, chúng tôi cũng
					hiểu rằng một số ít người đôi khi không thể kiểm soát được hành vi cá cược của mình.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">Giấy phép của chính phủ Philippine</span></p>
			<p><span style="font-family: 微软雅黑;">Tất cả các sản phẩm và dịch vụ chúng tôi cung cấp đều được ủy quyền
					và quản lý bởi Chính phủ Philippines . Tập đoàn giải trí và nghỉ dưỡng First Cagayan
					www.firstcagayan.com. Đây là một cơ quan nằm trong Đặc khu Kinh tế Cagayan và Cảng Tự do
					(CSEZFP) và là người cấp phép và quản lý các trò chơi trực tuyến .</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><strong>丨 Chọn thương hiệu, nên chọn HDBet88, mười lý do để bạn yên tâm:</strong></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">1. Nền tảng đủ thẩm quyền</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 hợp tác kỹ thuật với BBIN và đạt chứng chỉ quốc tế
					GEOTRUST để đảm bảo rằng trang web là công bằng, trung thực và cởi mở.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">2. Thứ hạng đủ cao</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 là một trong những thương hiệu đáng tin cậy nhất ở
					Châu Á, với danh tiếng hàng đầu trong bảng xếp hạng, và nó đứng đầu trong số các thông tin về
					những sòng bạc uy tín .</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">3. Nguồn vốn đủ an toàn</span></p>
			<p><span style="font-family: 微软雅黑;">Chuỗi vốn mạnh mẽ của Nhà cái HDBet88 có danh tiếng tốt, số tiền gửi
					lớn không phải lo lắng, và sự an toàn của tiền được đảm bảo, loại bỏ mọi lo lắng của bạn.</span>
			</p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">4. Rút tiền miễn phí</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 miễn phí rút tiền 24 giờ một ngày và việc rút tiền sẽ
					đến sau 3-5 phút và miễn phí trọn đời.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">5. Các khuyến mãi hấp dẫn</span></p>
			<p><span style="font-family: 微软雅黑;">Sòng bạc HDBet88 thường xuyên cung cấp nhiều khuyến mãi tuyệt vời khác
					nhau và cung cấp chiết khấu không ngừng, cho phép bạn kiếm tiền thật.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">6. Bảo mật đầy đủ</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 áp dụng công nghệ mã hóa SSL 128-bit và hệ thống quản
					lý bảo mật nghiêm ngặt để đảm bảo bảo vệ an toàn dữ liệu của khách hàng một cách trọn vẹn
					nhất.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">7. Dịch vụ đủ thân mật</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 cung cấp dịch vụ khách hàng 24/7 với sự phục vụ ân cần
					và chu đáo đối với mọi người chơi.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">8. VIP là tối thượng</span></p>
			<p><span style="font-family: 微软雅黑;">Dịch vụ VIP độc quyền của Sòng bạc HDBet88 cho phép bạn tận hưởng cảm
					giác tận hưởng hoàng gia như đang ở nhà.</span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">9. Thao tác đơn giản</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 tuân thủ nguyên tắc lấy khách hàng làm trung tâm, và
					đã thiết kế nhiều chức năng và tính năng thân thiện với người dùng khi bắt đầu phát triển, giúp
					tiết kiệm những rắc rối tẻ nhạt và cho phép bạn vận hành dễ dàng.</span></p>
			<p><span style="font-family: 微软雅黑;"> </span></p>
			<p><span style="color: rgb(255, 0, 0); font-family: 微软雅黑;">10. Doanh nghiệp đủ hợp pháp</span></p>
			<p><span style="font-family: 微软雅黑;">Nhà cái HDBet88 là một công ty trò chơi Internet hợp pháp lớn được
					chứng nhận bởi chính phủ Philippines.</span></p>
			<p><strong>Chọn HDBet88 tương đương với chọn một tín dụng bảo đảm .</strong></p>
			<p> </p>
		
		</div>
		
		<div  v-if="selectIndex==1">
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Vấn đề thường
						gặp</strong></span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>1. Điểm mạnh của Nhà cái
						HDBet88 là gì?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Vui lòng tham khảo
					trang chủ "Giới thiệu".</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>2. Nó có an toàn để chơi
						trong công ty của bạn?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào, hệ thống
					của chúng tôi là hoàn toàn an toàn. Chúng tôi sẽ không bao giờ tiết lộ thông tin cá nhân của
					khách hàng cho bất kỳ tổ chức thương mại nào. Ngoài ra, chúng tôi cũng yêu cầu các ngân hàng và
					đại lý chuyển tiền thẻ tín dụng có giao dịch với mình phải tuyệt đối bảo mật thông tin của khách
					hàng. Tất cả các khoản tiền gửi sẽ được coi là tài khoản giao dịch và sẽ không được chuyển giao
					cho các bên khác.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>3. Cờ bạc trực tuyến có hợp
						pháp không?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Luật pháp địa phương
					của một số quốc gia hoặc khu vực nghiêm cấm cờ bạc. Trong trường hợp này, hãy đảm bảo tuân thủ
					luật pháp địa phương. Nếu bạn có bất kỳ câu hỏi nào, vui lòng tìm lời khuyên của bộ phận pháp lý
					địa phương. Công ty không thể và sẽ không chịu bất kỳ trách nhiệm pháp lý nào phát sinh từ bất
					kỳ hành vi vi phạm pháp luật địa phương của bất kỳ người nào.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>4. Có giới hạn độ tuổi để
						đặt cược tại Nhà cái HDBet88 không?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào bạn, độ tuổi
					hợp pháp để đặt cược phải từ 18 tuổi trở lên.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>5. Tôi có cần điền tên thật
						của mình khi mở tài khoản không?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Chào bạn, vì lý do
					bảo mật nên bộ phận tài chính sẽ rà soát lại tên đăng ký khi rút tiền, tên tài khoản thẻ ngân
					hàng phải trùng với tên đã đăng ký trước khi rút tiền, vì vậy bạn vui lòng điền đầy đủ thông tin
					khi mở tài khoản và tên tài khoản bạn nhé. sử dụng để rút tiền phải giống nhau.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>6. Tôi nên làm gì nếu quên
						mật khẩu?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Bạn có thể bấm vào
					chức năng quên mật khẩu trên trang chủ, điền tài khoản thành viên và mật khẩu rút tiền, sau đó
					có thể lấy lại mật khẩu đã đặt. Khi không nhận được email, bạn cũng có thể liên hệ với nhân viên
					chăm sóc khách hàng trực tuyến 24/24 để được tư vấn và hỗ trợ lấy lại mật khẩu tài khoản.</span>
			</p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Sòng phẳng</strong></span>
			</p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Trò chơi</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Nhà cái HDBet88 đảm bảo
					rằng tất cả các trò chơi đã được kiểm tra nghiêm ngặt trước khi mở cửa cho khách hàng. Hơn nữa,
					tất cả các nhà cung cấp phần mềm trò chơi sòng bạc HDBet88 đều sẽ trải qua quá trình thử nghiệm
					rộng rãi, bao gồm nhiều lần chạy thử trước khi bất kỳ trò chơi nào được phát hành chính thức
					trên trang web của chúng tôi.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Thiết bị tạo số ngẫu
					nhiên (RNG) có độ chính xác cao được sử dụng trong các trò chơi nhà cái HDBet88 sẽ tạo ra kết quả
					ngẫu nhiên cho mỗi trò chơi. Loại đặt cược hoặc số tiền mà khách hàng đặt cược không ảnh hưởng
					đến các con số do RNG tạo ra.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">RNG được chứng nhận
					bởi một số công ty thử nghiệm độc lập và một loạt các thử nghiệm được hoàn thành để đảm bảo rằng
					RNG là hoàn toàn ngẫu nhiên, bao gồm cả việc xác minh thử nghiệm của phòng thí nghiệm nghiên cứu
					trò chơi quốc tế.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Câu hỏi liên quan đến trò
						chơi</strong></span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>1. Nhà cái HDBet88 có những
						trò chơi gì?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Nhà cái HDBet88 hiện
					đang cung cấp cho bạn Sòng bạc casino trực tuyến, sảnh cá cược thể thao, Slots , Trò chơi ảo và
					Trò chơi Poker .... Có Giấy phép kinh doanh hợp pháp Cá cược Thể thao và Sòng bạc của Cơ quan
					Quản lý Cagayan Economic Zone Authority.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>2. Làm cách nào để nạp tiền
						vào tài khoản game?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Công ty hiện cung cấp
					nhiều cách nạp tiền khác nhau, 1. Nạp tiền công ty; </span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">2. Thanh toán trực
					tuyến qua ngân hàng trực tuyến; <br>3. Nạp tiền momo;<br>4. Nạp tiền bên thứ ba. <br>Vui lòng
					bấm vào mục gửi tiền trực tuyến để chọn một trong các phương thức gửi tiền, xin cảm ơn ! (Lưu ý:
					Đối với khách hàng chọn gửi tiền từ công ty, vui lòng liên hệ với chúng tôi để xác nhận tài
					khoản chuyển tiền trước mỗi lần gửi tiền. Nếu tiền gửi vào tài khoản hết hạn, công ty sẽ không
					thể kiểm tra và sẽ không chịu trách nhiệm. Xin hãy chú ý, cảm ơn bạn!)</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>3. Tại sao tiền thắng không
						được trả lại vào tài khoản trò chơi của tôi ngay sau khi thanh toán trực tuyến thành
						công?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Điều này là do hệ
					thống thanh toán trực tuyến cần phải trải qua quy trình chuyển đổi khóa và xác minh nội bộ, đây
					cũng là một trong những quy trình bảo mật của chúng tôi, vì vậy xin đừng lo lắng, tiền sẽ không
					bị mất. Nếu bạn vẫn chưa nhận được thanh toán của mình sau hơn 30 phút thanh toán trực tuyến,
					vui lòng liên hệ với bộ phận chăm sóc khách hàng của chúng tôi để được xử lý.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>4. Khi tôi thắng tiền trong
						trò chơi trực tiếp, làm thế nào để rút tiền?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Vui lòng nhấp vào
					"Rút tiền trực tuyến" để nhập mật khẩu rút tiền của bạn, điền số tiền bạn muốn rút theo yêu cầu,
					sau đó nhấp vào Đồng ý. Cảm ơn!</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>5. Làm thế nào để chứng minh
						rằng kết quả trò chơi là thời gian thực?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào các bạn có
					thể theo dõi qua video hình ảnh trò chơi, hình ảnh trong video đều được đồng bộ với hình ảnh do
					sòng bài trực tiếp chơi. Chúng tôi đảm bảo tất cả các kết quả trò chơi đều là kết quả đúng thời
					gian thực.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>6. Tôi có thể thử chơi qua
						trò chơi của bạn trước không?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào, chúng tôi
					hoan nghênh bạn chơi thử qua hệ thống trò chơi của chúng tôi. Tất nhiên, nó miễn phí. Bạn chỉ
					cần đăng ký trên trang web của chúng tôi để đăng nhập để duyệt các trò chơi của chúng
					tôi.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>7. Tại sao kết nối bị ngắt
						sau khi xem sáu ván cược ?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Chào bạn, vì 2 lý do:
					(1) Để bảo mật tài khoản của bạn, nếu bạn quên đóng trang và rời khỏi máy tính sau khi mở game
					thì việc thua lỗ của bạn sẽ rất khó khăn trong trường hợp lúc này có người khác dùng cược không
					kiểm soát được. . (2) Lúc nào cũng có rất nhiều khách hàng chơi game online, để tốc độ mạng
					nhanh và mượt, hệ thống của chúng tôi tự động tiết kiệm tài nguyên mạng và chăm sóc khách hàng
					trong game nên xem sáu trận sẽ dừng lại. sự kết nối. Chúng tôi xin lỗi vì sự bất tiện này có thể
					gây ra cho bạn.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>8. Tôi có thể tìm luật chơi
						ở đâu?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Trước khi đăng nhập,
					bạn có thể thấy các tùy chọn về luật chơi trong mỗi mục trò chơi, trong đó thông báo rõ ràng về
					cách chơi, quy tắc và phương thức thanh toán của trò chơi. Trong cửa sổ trò chơi cũng có tùy
					chọn "quy tắc", cho phép bạn tận hưởng niềm vui của trò chơi, và cửa sổ bật lên có thể nhắc nhở
					bạn về luật chơi bất cứ lúc nào.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>9. Có bất kỳ yêu cầu cấu
						hình hệ thống nào để chơi trò chơi không?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào, trang web
					chúng tôi thiết kế sẽ cung cấp thế hệ dịch vụ trình duyệt mới, cung cấp các tính năng tốt hơn,
					để bạn có thể tận hưởng niềm vui cá cược tốt hơn.</span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>10. Tại sao hệ thống đăng
						xuất tôi?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào, đây là một
					trong những quy trình bảo mật của trang web. Khi bạn không sử dụng trang web liên quan trong một
					khoảng thời gian nhất định, trang web liên quan sẽ không còn hoạt động và bạn không thể duyệt
					trang mới nhất.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Câu hỏi thường gặp về kỹ
						thuật</strong></span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Yêu cầu hệ thống phần cứng
						tối thiểu là gì?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">1. Bất kỳ máy tính
					nào có thể kết nối với Internet;</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">2. Bộ điều hợp màn
					hình SVGA - ít nhất 1204x768 pixel và 256 màu trở lên;</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">3. Băng thông rộng
					khu vực;</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">4. Hệ điều hành
					Windows, Mac OS X, Linux;</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">5. Trình duyệt
					Internet Explorer v6.0 trở lên (phiên bản 7.0 trở lên tốt hơn), Mozilla Firefox (trình duyệt
					v3.0 trở lên), Opera (trình duyệt v8.0 trở lên), Chrome (trình duyệt v6.0 trở lên), Safari
					(trình duyệt v4.0 trở lên)</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">6. Để duyệt sòng bạc
					trực tuyến, bạn có thể tải xuống tiện ích bổ sung cho trình duyệt Macromedia Flash Player (phiên
					bản 8.0 trở lên) từ trang web Macromedia.</span></p>
		
		</div>
		<div  v-if="selectIndex==2">
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Sòng phẳng</strong></span>
			</p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Trò chơi</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Nhà cái HDBet88 đảm bảo
					rằng tất cả các trò chơi đã được kiểm tra nghiêm ngặt trước khi mở cửa cho khách hàng. Hơn nữa,
					tất cả các nhà cung cấp phần mềm trò chơi sòng bạc HDBet88 đều sẽ trải qua quá trình thử nghiệm
					rộng rãi, bao gồm nhiều lần chạy thử trước khi bất kỳ trò chơi nào được phát hành chính thức
					trên trang web của chúng tôi.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Thiết bị tạo số ngẫu
					nhiên (RNG) có độ chính xác cao được sử dụng trong các trò chơi nhà cái HDBet88 sẽ tạo ra kết quả
					ngẫu nhiên cho mỗi trò chơi. Loại đặt cược hoặc số tiền mà khách hàng đặt cược không ảnh hưởng
					đến các con số do RNG tạo ra.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">RNG được chứng nhận
					bởi một số công ty thử nghiệm độc lập và một loạt các thử nghiệm được hoàn thành để đảm bảo rằng
					RNG là hoàn toàn ngẫu nhiên, bao gồm cả việc xác minh thử nghiệm của phòng thí nghiệm nghiên cứu
					trò chơi quốc tế.</span></p>
		</div>
		<div  v-if="selectIndex==3">
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Câu hỏi liên quan đến trò
						chơi</strong></span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>1. Nhà cái HDBet88 có những
						trò chơi gì?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Nhà cái HDBet88 hiện
					đang cung cấp cho bạn Sòng bạc casino trực tuyến, sảnh cá cược thể thao, Slots , Trò chơi ảo và
					Trò chơi Poker .... Có Giấy phép kinh doanh hợp pháp Cá cược Thể thao và Sòng bạc của Cơ quan
					Quản lý Cagayan Economic Zone Authority.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>2. Làm cách nào để nạp tiền
						vào tài khoản game?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Công ty hiện cung cấp
					nhiều cách nạp tiền khác nhau, 1. Nạp tiền công ty; </span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">2. Thanh toán trực
					tuyến qua ngân hàng trực tuyến; <br>3. Nạp tiền momo;<br>4. Nạp tiền bên thứ ba. <br>Vui lòng
					bấm vào mục gửi tiền trực tuyến để chọn một trong các phương thức gửi tiền, xin cảm ơn ! (Lưu ý:
					Đối với khách hàng chọn gửi tiền từ công ty, vui lòng liên hệ với chúng tôi để xác nhận tài
					khoản chuyển tiền trước mỗi lần gửi tiền. Nếu tiền gửi vào tài khoản hết hạn, công ty sẽ không
					thể kiểm tra và sẽ không chịu trách nhiệm. Xin hãy chú ý, cảm ơn bạn!)</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>3. Tại sao tiền thắng không
						được trả lại vào tài khoản trò chơi của tôi ngay sau khi thanh toán trực tuyến thành
						công?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Điều này là do hệ
					thống thanh toán trực tuyến cần phải trải qua quy trình chuyển đổi khóa và xác minh nội bộ, đây
					cũng là một trong những quy trình bảo mật của chúng tôi, vì vậy xin đừng lo lắng, tiền sẽ không
					bị mất. Nếu bạn vẫn chưa nhận được thanh toán của mình sau hơn 30 phút thanh toán trực tuyến,
					vui lòng liên hệ với bộ phận chăm sóc khách hàng của chúng tôi để được xử lý.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>4. Khi tôi thắng tiền trong
						trò chơi trực tiếp, làm thế nào để rút tiền?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Vui lòng nhấp vào
					"Rút tiền trực tuyến" để nhập mật khẩu rút tiền của bạn, điền số tiền bạn muốn rút theo yêu cầu,
					sau đó nhấp vào Đồng ý. Cảm ơn!</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>5. Làm thế nào để chứng minh
						rằng kết quả trò chơi là thời gian thực?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào các bạn có
					thể theo dõi qua video hình ảnh trò chơi, hình ảnh trong video đều được đồng bộ với hình ảnh do
					sòng bài trực tiếp chơi. Chúng tôi đảm bảo tất cả các kết quả trò chơi đều là kết quả đúng thời
					gian thực.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>6. Tôi có thể thử chơi qua
						trò chơi của bạn trước không?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào, chúng tôi
					hoan nghênh bạn chơi thử qua hệ thống trò chơi của chúng tôi. Tất nhiên, nó miễn phí. Bạn chỉ
					cần đăng ký trên trang web của chúng tôi để đăng nhập để duyệt các trò chơi của chúng
					tôi.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>7. Tại sao kết nối bị ngắt
						sau khi xem sáu ván cược ?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Chào bạn, vì 2 lý do:
					(1) Để bảo mật tài khoản của bạn, nếu bạn quên đóng trang và rời khỏi máy tính sau khi mở game
					thì việc thua lỗ của bạn sẽ rất khó khăn trong trường hợp lúc này có người khác dùng cược không
					kiểm soát được. . (2) Lúc nào cũng có rất nhiều khách hàng chơi game online, để tốc độ mạng
					nhanh và mượt, hệ thống của chúng tôi tự động tiết kiệm tài nguyên mạng và chăm sóc khách hàng
					trong game nên xem sáu trận sẽ dừng lại. sự kết nối. Chúng tôi xin lỗi vì sự bất tiện này có thể
					gây ra cho bạn.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>8. Tôi có thể tìm luật chơi
						ở đâu?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Trước khi đăng nhập,
					bạn có thể thấy các tùy chọn về luật chơi trong mỗi mục trò chơi, trong đó thông báo rõ ràng về
					cách chơi, quy tắc và phương thức thanh toán của trò chơi. Trong cửa sổ trò chơi cũng có tùy
					chọn "quy tắc", cho phép bạn tận hưởng niềm vui của trò chơi, và cửa sổ bật lên có thể nhắc nhở
					bạn về luật chơi bất cứ lúc nào.</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;"> </span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>9. Có bất kỳ yêu cầu cấu
						hình hệ thống nào để chơi trò chơi không?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào, trang web
					chúng tôi thiết kế sẽ cung cấp thế hệ dịch vụ trình duyệt mới, cung cấp các tính năng tốt hơn,
					để bạn có thể tận hưởng niềm vui cá cược tốt hơn.</span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>10. Tại sao hệ thống đăng
						xuất tôi?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">Xin chào, đây là một
					trong những quy trình bảo mật của trang web. Khi bạn không sử dụng trang web liên quan trong một
					khoảng thời gian nhất định, trang web liên quan sẽ không còn hoạt động và bạn không thể duyệt
					trang mới nhất.</span></p>
		</div>
		<div  v-if="selectIndex==4">
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Câu hỏi thường gặp về kỹ
						thuật</strong></span></p>
			<p style="text-align: left;"><span style="color: rgb(225, 60, 57);"><strong>Yêu cầu hệ thống phần cứng
						tối thiểu là gì?</strong></span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">1. Bất kỳ máy tính
					nào có thể kết nối với Internet;</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">2. Bộ điều hợp màn
					hình SVGA - ít nhất 1204x768 pixel và 256 màu trở lên;</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">3. Băng thông rộng
					khu vực;</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">4. Hệ điều hành
					Windows, Mac OS X, Linux;</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">5. Trình duyệt
					Internet Explorer v6.0 trở lên (phiên bản 7.0 trở lên tốt hơn), Mozilla Firefox (trình duyệt
					v3.0 trở lên), Opera (trình duyệt v8.0 trở lên), Chrome (trình duyệt v6.0 trở lên), Safari
					(trình duyệt v4.0 trở lên)</span></p>
			<p style="text-align: left;"><span style="color: white; font-family: 微软雅黑;">6. Để duyệt sòng bạc
					trực tuyến, bạn có thể tải xuống tiện ích bổ sung cho trình duyệt Macromedia Flash Player (phiên
					bản 8.0 trở lên) từ trang web Macromedia.</span></p>
		</div>
		
		<div  v-if="selectIndex==5">
			<p><span style="color: rgb(225, 60, 57);"><strong>Khả năng áp dụng của Chính sách Bảo mật
						này</strong></span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Bằng cách
					sử dụng trang web này, bạn đồng ý và chấp nhận các điều khoản của </span><span
					style="color: rgb(255, 0, 0); ; font-family: 微软雅黑;">Chính
					sách Bảo mật này .</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Chúng tôi
					sẽ xem xét Chính sách Bảo mật này thường xuyên và có quyền sửa đổi và cập nhật Chính sách Bảo
					mật này bất kỳ lúc nào. Nếu các thay đổi sẽ có hiệu lực ngay lập tức, chúng tôi cũng sẽ thông
					báo cho bạn trên trang web. Việc bạn tiếp tục sử dụng trang web này có nghĩa là bạn chấp nhận
					các điều khoản này. Vui lòng xem lại Chính sách Bảo mật này theo định kỳ và tạo một bản sao cho
					hồ sơ của bạn.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;"> </span>
			</p>
			<p><span style="color: rgb(225, 60, 57);"><strong>Thu thập và Sử dụng Thông tin Cá nhân</strong></span>
			</p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Khi bạn
					sử dụng trang web hoặc thẻ ngân hàng của chúng tôi để chuyển tiền, chúng tôi sẽ mã hóa và lưu
					trữ thông tin cá nhân của bạn để đảm bảo an toàn cho thông tin cá nhân của bạn.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Bất kỳ
					tài khoản nào bạn đăng ký với trang web của chúng tôi đều yêu cầu dữ liệu cá nhân của
					bạn.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Để cung
					cấp dịch vụ cần thiết và chất lượng của trang web, khi bạn sử dụng trang web này, hệ thống của
					chúng tôi sẽ lưu một số thông tin quản lý của bạn, bao gồm địa chỉ IP, ngày giờ đăng nhập, trang
					đăng nhập, ngôn ngữ sử dụng, báo lỗi phần mềm và loại trình duyệt .</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Để xác
					minh tài khoản và kiểm tra tình trạng tài chính và tín dụng của bạn, chúng tôi có thể thu thập
					dữ liệu cá nhân của bạn từ các bên thứ ba bao gồm các tổ chức tài chính, cơ quan xác minh danh
					tính, cơ quan tín dụng và cơ quan xếp hạng tín dụng.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;"> </span>
			</p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Bạn đồng
					ý cho chúng tôi và các bên thứ ba sử dụng dữ liệu cá nhân của bạn:</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">a) Thiết
					lập, xử lý và quản lý tài khoản của bạn, thực hiện xác minh danh tính, ngăn chặn rửa tiền và tài
					trợ khủng bố;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">b) Xác
					nhận phiếu đặt cược và đặt cược;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">c) Thống
					kê và kiểm toán;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">d) Nghiên
					cứu tiếp thị và phân tích dữ liệu;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">e) (Nếu
					cần) phân tích rủi ro tín dụng của bạn;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">f) Nâng
					cao chất lượng sản phẩm hoặc dịch vụ và trải nghiệm chơi game của người chơi;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">g) Tuân
					thủ các yêu cầu của cơ quan quản lý và giấy phép.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;"> </span>
			</p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Chúng tôi
					sẽ thông báo cho bạn về các sản phẩm và dịch vụ mà bạn có thể quan tâm. Nếu bạn không muốn nhận
					thông tin như vậy, vui lòng cho chúng tôi biết bằng cách gửi email đến onlineHDBet88@gmail.com.
					Các cuộc gọi của bạn có thể được ghi lại cho các mục đích đào tạo hoặc an toàn.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;"> </span>
			</p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Độ chính
					xác của Dữ liệu Cá nhân</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Nếu có
					bất kỳ thay đổi nào đối với dữ liệu cá nhân của bạn, bạn có trách nhiệm gửi email đến nhóm dịch
					vụ chăm sóc khách hàng email onlineHDBet88@gmail.com</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Để yêu
					cầu chỉnh sửa, chúng tôi có thể yêu cầu bạn cung cấp thông tin liên quan để xác minh danh tính
					của bạn.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Để yêu
					cầu dữ liệu cá nhân của bạn với chúng tôi, nếu bạn có bất kỳ câu hỏi nào về Chính sách Bảo mật
					này và việc xử lý dữ liệu cá nhân của bạn, vui lòng gửi email cho chúng tôi đến
					onlineHDBet88@gmail.com.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;"> </span>
			</p>
			<p><span style="color: rgb(225, 60, 57);"><strong>Tiết lộ</strong></span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Chúng tôi
					có thể tiết lộ dữ liệu cá nhân của bạn:</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">a) Nếu
					luật pháp yêu cầu, để ngăn chặn các hoạt động rửa tiền và tài trợ khủng bố, chúng tôi có thể
					tiết lộ thông tin cá nhân của bạn cho các bên thứ ba và chúng tôi cũng sẽ lấy thông tin cá nhân
					được phép của bạn từ các bên thứ ba để xác minh và nhận dạng;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">b), Về
					nguyên tắc, chúng ta phải làm:</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">i. Luật
					hoặc quy trình pháp lý mà chúng tôi cần tuân thủ;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">ii. Để
					bảo vệ các quyền hoặc tài sản của chúng tôi;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">iii. Để
					bảo vệ quyền an toàn cá nhân của người sử dụng trang web, dịch vụ hoặc công chúng;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">c), Đến
					bất kỳ cơ quan quản lý, bộ phận hoặc cơ quan có thẩm quyền nào;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">d), Cho
					một bên thứ ba với mục đích giải quyết hoặc thanh toán cá cược;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">e), Để xử
					lý thanh toán cho bất kỳ người chơi nào tham gia vào công ty quản lý thanh toán của chúng
					tôi;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">f), Cho
					các bên thứ ba hoặc đại diện cung cấp dịch vụ (bao gồm cả phần mềm) cho chúng tôi;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">g), Cho
					bất kỳ bên thứ ba nào mua lại chúng tôi hoặc doanh nghiệp của chúng tôi;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">h), Với
					sự đồng ý của bạn;</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">i), Cho
					mục đích khắc phục hậu quả thiên tai.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Vì các
					mục đích trên, chúng tôi cũng có thể tiết lộ dữ liệu cá nhân của bạn cho các cơ quan liên quan
					hoặc bên thứ ba của chúng tôi. Dữ liệu cá nhân của bạn cũng có thể được xử lý bên ngoài Châu Âu.
					Chúng tôi sẽ đảm bảo rằng dữ liệu của bạn được xử lý tuân thủ nghiêm ngặt luật bảo vệ dữ liệu
					trong quá trình này.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;"> </span>
			</p>
			<p><span style="color: rgb(225, 60, 57);"><strong>Cookie</strong></span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Cookie là
					một tệp văn bản nhỏ được lưu trữ trên máy tính của người chơi, ghi lại dữ liệu tham số về việc
					người chơi duyệt các trang web nhất định. người chơi với các trang web và dịch vụ tốt hơn. Bạn
					chấp nhận việc sử dụng cookie trên trang web của chúng tôi.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;"> </span>
			</p>
			<p><span style="color: rgb(225, 60, 57);"><strong>Sự an toàn</strong></span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Chúng tôi
					sẽ tuân theo các quy trình luật bảo vệ dữ liệu để đảm bảo rằng dữ liệu cá nhân thu thập được ghi
					lại chính xác và bảo mật.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Chúng tôi
					không thể đảm bảo tính bảo mật của thông tin bạn cung cấp cho chúng tôi qua Internet, bao gồm cả
					dữ liệu cá nhân, nhưng sau khi chúng tôi nhận được dữ liệu của bạn, chúng tôi sẽ tuân thủ luật
					bảo vệ dữ liệu để đảm bảo rằng dữ liệu của bạn không bị lạm dụng, bị mất hoặc bị sử dụng mà
					không được phép.</span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;"> </span>
			</p>
			<p><span style="color: rgb(225, 60, 57);"><strong>Phân phối mạng</strong></span></p>
			<p><span style="color: white; ; font-family: 微软雅黑;">Vì mạng
					là toàn cầu, việc sử dụng mạng để thu thập và xử lý dữ liệu cá nhân nhất định phải liên quan đến
					việc truyền qua biên giới. Do đó, hãy đăng nhập vào trang web của chúng tôi và liên hệ với chúng
					tôi để thông báo cho bạn rằng bạn chấp nhận và đồng ý với việc chúng tôi xử lý dữ liệu cá nhân
					của bạn trong cách này.</span></p>
		</div>
	</div>
	
	
</template>

<script>
	import {theme} from '@/views/theme'
	
	
		let RedHeader = require('@/views/'+ theme +'/components/RedHeader.vue').default
	
	export default {
	  
	  data() {
	    return {
		leftList:[this.$t('关于我们'),this.$t('常见问题'),this.$t('公平赔付'),this.$t('游戏常见问题'),this.$t('技术常见问题'),this.$t('隐私保护').toUpperCase()],
		selectIndex:0,
		
	    };
	  },
	  components: {
	    RedHeader, 
	  },
	  computed: {
	   
	  },
	  watch: {
	    
	  },
	  mounted() {
	   
	  },
	  created() {
		  this.selectIndex = this.$route.params.selectIndex ? this.$route.params.selectIndex : 0
	  },
	  methods: {
	
	  },
	};
</script>

<style scoped>
	.select{
		background-color: rgb(223,185,113);
		color:white;
		border-radius: 15px;
	}
	.nolmal{
		color: rgb(223,185,113);
		background-color: white;
		border-radius: 15px;
		
	}
</style>
