<template>
	<red-header :title="$t('绑定银行卡')" showBack />
	<div class="main">
<!-- 		<img :src="$img('recharg_bg.png')" alt="" style="width: 100%;position: absolute;z-index: 0;top: 0;left: 0;">
 -->
		<div class="content">
			<div class="row" style="margin-top: 2.5vh">
				<div class="font">
					<div class="select phoneMiddle" @click="showPickerClick()" style="color: white;text-align: left;">
						{{ curBack }}
						<img :src="$img('icon_dropdown.png')" />
					</div>
					<van-popup v-model:show="showPicker" round position="bottom">
						<van-picker class="phoneMiddle" :columns="chooseData" @cancel="showPicker = false"
							@confirm="onConfirm" confirm-button-text="confirm" cancel-button-text="cancel" />
					</van-popup>
				</div>
			</div>
<!-- 			<div class="error"><span v-if="false">* error</span></div>
 -->
			<div class="row">
				<div class="font">
					<input type="text" placeholder="Nhập số tài khoản" class="value phoneMiddle"
						v-model="accountName" />
				</div>
			</div>
<!-- 			<div class="error phoneSmall"><span v-if="!accountName.length">* {{$t("卡号不能为空")}}</span></div>
 -->			<div class="row">
				<div class="font">
					<input type="text" placeholder="Nhập họ tên số tài khoản" class="value phoneMiddle"
						v-model="cardNum" />
				</div>
			</div>
			<div class="error phoneSmall"><span>* {{$t("注意：最多可以绑定三张银行卡，姓名需一致")}}</span></div>

		</div>
		<div class="loginBtn">
			<img :src="$img('ann_bottom.png')" alt="" style="width: 100%;">
			<div @click="onSubmit"
				style="text-align: center;position: absolute;z-index: 9;width: 100%;top: 3vw;left: 0;right: 0;bottom: 0;font-size: 18px;">
				{{$t('确认')}}
			</div>
		
		</div>
	</div>
	<my-dialog v-if="showDialog" :close="
      function () {
        showDialog = false;
      }
    " :title="dialogTitle" :msg="dialogMsg" />
</template>

<script>
	import {theme} from '@/views/theme'
	
		let RedHeader = require('@/views/'+ theme +'/components/RedHeader.vue').default
		let MyDialog = require('@/views/'+ theme +'/components/MyDialog.vue').default
	
	import { mapGetters, mapActions, mapMutations } from "vuex";
	import mqant from "@/components/protocol/mqantlib";
	import { http, topic, getApiLoginUrl } from "@/components/protocol/api";
	import auth from "@/components/core/auth";
	
	export default {
	  name: "BindBankView",
	  components: { RedHeader, MyDialog },
	  mixins: [auth],
	  data() {
	    return {
	      showPicker: false,
	      curBack: "",
	      accountName: "",
	      cardNum: "",
	      showDialog: false,
	      dialogTitle: "",
	      dialogMsg: "",
		  chooseData:[]
	    };
	  },
	  computed: {
	    ...mapGetters("pay", ["douDouBtList"]), 
		...mapGetters("tcp", [ "isConnect"]),
		
	  },
	  methods: {
	    ...mapActions("pay", ["requestDoudouBtList", "requestPayInfo"]),
		...mapActions("user", ["autoLogin"]),

		showPickerClick(){
			if(this.isConnect&&this.chooseData.length){
				this.showPicker = true
			}else {
				var that = this
				this.autoLogin().then(
					(succ) => {
						that.requestDoudouBtList().then((btList) => {
					if (btList.length > 0) that.curBack = btList[0].BtName;
					let res = [];
					for (let k in btList) {
						let item = btList[k];
						res.push(item.BtName);
					}
					that.chooseData = res

					that.showPicker = true

				});
					},
					(reject) => { 
						console.log('autoLogin fail')
					}
				);
			}
		},
	    onConfirm(value) {
	      this.showPicker = false;
	      this.curBack = value;
	    },
	    showBanks() {
	      let res = [];
	      for (let k in this.douDouBtList) {
	        let item = this.douDouBtList[k];
	        res.push(item.BtName);
	      }
	      return res;
	    },
	    onSubmit() {
			
	      let params = {
	        btName: this.curBack,
	        accountName: this.cardNum.toString(),
	        cardNum:this.accountName.toString(),
	      };
	      let that = this;
	      that.$tools.loading();
	      mqant.request(topic.bindBank, params, function (res) {
	        that.$tools.dismissLoading();
	        that.$log.info(res);
	        if (res.Code == 0) {
				 that.$log.info('asdasdas');
	          // that.showDialog = true;
	          // that.dialogTitle = that.$t("提示");
	          // that.dialogMsg = that.$t('绑定成功')
			 that.cardNum = ''
			  that.$tools.toast(that.$t('绑定成功'));
	          that.requestPayInfo();
	        } else {
	          that.$tools.toast(res.ErrMsg);
	        }
	      });
	    },
	  },
	  mounted() {
		 
	    this.waitFinish().then((isLogged) => {
	      this.$log.info("mount");
	      if (isLogged) {
	        this.requestDoudouBtList().then((btList) => {
	          if (btList.length > 0) this.curBack = btList[0].BtName;
			  let res = [];
			  for (let k in btList) {
			    let item = btList[k];
			    res.push(item.BtName);
			  }
			  this.chooseData = res
	        });
	      }
	    });
	
	    if (this.douDouBtList.length > 0)
	      this.curBack = this.douDouBtList[0].BtName;
	  },
	};
</script>

<style lang="scss" scoped>
	.loginBtn{
		position: relative;
		margin-top: 0px;
		width: 94vw;
		margin-left: 3vw;
		margin-top: 20px;
		
	}
	.content {
		position: relative;
		z-index: 999;
		width: 94vw;
		margin-left: 3vw;
		margin-top: 2vh;
		// padding: 7vw 11vw;
		background-color: rgb(42,42,42);
		padding-bottom: 7vw;
		border-radius: 5px;
		
	}

	.error {
		color: red;
		margin-left: 3%;
		margin-top: 10px;
		height: 2.5vh;
	}

	input {
		background-color: transparent;
		color: white;
	}

	.font {
		position: absolute;
		top: 0%;
		left: 0%;
		right: 0%;
		bottom: 3%;
		display: flex;
		align-items: center;

		>.label {
			width: 25%;
			margin-left: 11%;

			line-height: 10vw;
			height: 100%;
			color: white;
			background-color: rgb(31, 31, 31)
		}

		>.value {
			flex: 1;
			margin-left: 0%;
			margin-right: 14%;
		}

		>.select {
			flex: 1;
			margin-right: 14%;

			>img {
				width: 2vw;
				position: absolute;
				top: 46%;
				right: 0%;
			}
		}
	}

	.inputbg {
		width: 77.3vw;
	}

	.main {
		position: relative;
		width: 100%;

	}

	.row {
		position: relative;
		text-align: center;
		
		border-bottom: 1px solid rgb(64, 87, 127);
		width: 94%;
		margin-left: 3%;
		margin-top: 0;
		padding: 20px 0;
	}

	.submit {
		margin-top: 2vh;

		>button {
			background-color: rgb(213, 188, 145);
			color: white;
			border: 0;
			border-radius: 12px;
			padding: 2vw 6vw;
			margin-bottom: 10vw;
		}
	}
</style>
