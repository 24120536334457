<template>

	<div @touchmove.prevent @mousewheel.prevent  class="wrap"
		style="width: 100%;height: 100%;font-weight: bold;position: fixed;top: 0;left: 0;z-index: 9999;background-color: rgba(0, 0, 0, .7);text-align: center;">
		<div class="deepBG" @click.stop="" style="border: 1px solid rgb(41, 52, 72);border-radius: 20px;">
			<!-- <img :src="wrap" style="width: 386px;height: 461px;" alt=""> -->
			<div style="position: absolute;top: 0;left: 0;z-index: 999;width: 100%;height: 100%;">
				<img @click="handleClose()" class="canClick" :src="$img('guanbi_img.png')" alt=""
					style="width: 14px; position: absolute;right:15px;top:15px;z-index: 999;">


				<div style="font-size: 15px;font-weight: bold;padding-top: 18px;text-align: center;color:white;">
					{{$t("申请代理").toUpperCase()}}</div>

					<div style="font-size: 12px;font-weight: bold;padding-top: 30px;text-align: center;color:white;">
						{{$t("欢迎加入")}}</div>


				<div  style="margin-top: 0px;">
					
					<div class="inputWrap">
						<input v-model="realName" type="text" :placeholder="$t('真实姓名').toUpperCase()" class="bigSize boldSize">
						
					</div>
					<div class="inputWrap">
						<input v-model="phone" type="text" @input="phone=checkNum(phone)" :placeholder="$t('手机号')" class="bigSize boldSize">
						
					</div>
					<div class="inputWrap">
						<input v-model="zalo" type="text" placeholder="TELEGRAM" class="bigSize boldSize">
						
					</div>
					<div class="inputWrap">
						<input v-model="bankName" type="text" :placeholder="$t('银行').toUpperCase()" class="bigSize boldSize">
						
					</div>
					<div class="inputWrap">
						<input v-model="cardNum" @input="cardNum=checkNum(cardNum)" type="text" :placeholder="$t('卡号')" class="bigSize boldSize">
						
					</div>
					
					<div class="mainBtnBG send canClick" @click="addAgent()">
						{{$t('提交')}}
					</div>
					
				</div>
			</div>


		</div>
	</div>






</template>

<script>
	import tools from "@/utils/tools";
			import {
				mapGetters,
				mapActions,
				mapMutations
			} from "vuex";
			import {
				http
			} from "@/components/protocol/api";
			import log from "@/utils/logger";
		
			import mqant from "@/components/protocol/mqantlib";
			import bus from "@/utils/bus.js"
		
		
			import {
				topic
			} from "@/components/protocol/api";
		
			import {
				getCurrentInstance
			} from 'vue'
			export default {
				data() {
					return {
						phone:'',
						zalo:'',
						realName:'',
						bankName:'',
						cardNum:''
					}
				},
				props: {
		
					diaLoginVisible: {
						type: Boolean,
						default: true
					}
				},
				computed: {
					...mapGetters("global", ["globalState", "uuid"]),
		
				},
				created() {},
				methods: {
					...mapActions("user", ["onLogged"]),
		
					handleClose() {
						this.$emit('handleClose')
		
					},
					
					checkNum(data) {
					
					// }
				
					if (data) {
						
						return (data = data.replace(/[^0-9]/g, ''));  
	
					}
					
						},
					 addAgent() {
						
						if (!this.realName.length||!this.zalo.length||!this.phone.length||!this.bankName.length||!this.cardNum.length) {
							
							return
						}
						let params = {
							phone: this.phone,
							zalo: this.zalo,
							realName: this.realName,
							bankName: this.bankName,
							cardNum: this.cardNum,
		
						};

						var that = this
						mqant.request(topic.agentRegister, params, function(data, topicName, msg) {
								
							if (data.Code == 0) {

								
									bus.emit('sendDelegate')
									that.$message({
										message:'Đăng ký thành công! Chúng tôi sẽ liên hệ với zalo của bạn!',
										type: 'success'
									});
									that.handleClose()
		
		
								} else {
									that.$message({
										message: data.ErrMsg,
										type: 'error'
									});
								}
							
						});
				},
		
		
		
			}
			}
</script>

<style scoped lang="scss">
	.wrap {
		background-color: rgba(155, 155, 155, 0.2);
		opacity: 1;

	}

	input{
		//background-color: transparent !important;
		height: 100%;
		width: 100%;
		
		vertical-align: middle;
		
		padding-left: 15px;
		color: white;
	}

	p {
		
		color: white;
		font-size: 12px;
		text-align: center;
	}

	.redWord {
		color: red;

	}

	.blueWord {
		color: #31c9ed;

	}

	

	.leftImg {
		width: 13px;
		margin-left: 20px;
		margin-top: 0px;
	}

	.deepBG {
		/* background: url(@/assets/pc2/login_bg.png); */
		/* width: 386px; */

		/* max-width: 53.5vh;
		max-height: 70vh; */
		position: relative;
		top: 50%;
		width: 396px;
		height: 540px;
		margin: auto;
		background-color:#474443 ;
		transform: translateY(-52%);

	}
	.send {
		width: 200px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		border-radius: 15px;
		font-size: 15px;
	    margin: 30px auto;
		background-color:#0071df ;
	}
	.inputWrap{
		width: 336px;
		height: 46px;
		border-radius: 5px;
		
		text-align: left;
		margin: 20px auto;
		margin-bottom: 0;
		// display: flex;
		// flex-direction: row;
		align-items: center;
		position: relative;
		background-color: #303030;
		border: 1px solid #585449;
	}
	
</style>
