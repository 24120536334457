<template>
	<div @touchmove.prevent @mousewheel.prevent @click="cancel()" class="wrap" style="width: 100%;height: 100%;font-weight: bold;position: fixed;z-index: 99999;background-color: rgba(0, 0, 0, .4);top: 0;left: 0;">
		<div style="position: relative;width: 350px;margin: 100px auto;" @click.stop="">
			<img :src="$img('recordBg.png')" alt="" style="width: 350px;">
			<div @click="cancel()" class="canClick"  style="width: 31px;height: 31px;background: linear-gradient(to bottom,#5800fa,#001fbd);border-radius: 15.5px;text-align: center;position: absolute;right: -10px;top:-20px ;z-index: 999;">
				<img :src="$img('closeWhite.png')" alt="" style="width: 17px;margin-top: 7px;">
				
			</div>
			<img :src="$img('shangyiye.png')" @click="prev()" :class="offSet>0?'canClick':'diseableImg'"  alt="" style="width: 20px;position: absolute;left: 5px;top: 140px;z-index: 999;">
			<img :src="$img('xiayiye.png')" @click="next()" alt=""  :class="offSet+5<allNum?'canClick':'diseableImg'" style="width: 20px;position: absolute;right: 3px;top: 140px;z-index: 999;">
			
			
			
			<div style="position: absolute;left: 0;top:0;width: 100%;">
				<div style="width: 200px;color:#e9af40;font-size: 11px;line-height: 27px;text-align: center;position: relative;margin: 7px auto;">
					
					<div style="position: relative;z-index: 8;color:black;">LỊCH SỬ QUAY</div>
					<img :src="$img('recordTop.png')" alt="" style="width: 100%;position: absolute;left: 0;top: 0;">
					<div style="position: absolute;right: -135px;top:1px;width: 100%;color:white;z-index:9999;color:black;">
						Trang : {{Math.ceil(offSet/5)+1}}
					</div>
				</div>
				<div style="width: 84%;margin-left: 8%;border: 2px solid #f6af17;border-radius: 10px;overflow: hidden;height: 200px;">
					<div class="flexs_r_c" style="height: 30px;background: linear-gradient(to bottom,#ff0000,#4d0024);width: 100%;">
						<div style="width: 30%;font-size: 12px;color:#e9af40;text-align: center;line-height: 30px;">PHIÊN</div>
						<div style="width: 30%;font-size: 12px;color:#e9af40;text-align: center;line-height: 30px;">THỜI GIAN</div>
						<div style="width: 40%;font-size: 12px;color:#e9af40;text-align: center;line-height: 30px;">TRÚNG THƯỞNG</div>
					</div>
					<div class="flexs_r_c" v-for="(item,index) in recordData" style="height: 34px;width: 100%;">
						<div style="width: 30%;font-size: 11px;color:#e9af40;text-align: center;line-height: 34px;">{{item.No}}</div>
						<div style="width: 30%;font-size: 11px;color:#e9af40;text-align: center;line-height: 34px;">{{$tools.formatDate(item.CreateAt, "yyyy-MM-dd")}}</div>
					
						<div  style="width: 40%;font-size: 11px;color:#e9af40;text-align: center;line-height: 34px;" v-if="item.GetVnd>0" class="canClick">{{item.WordType}}-{{item.GetVnd/1000}}K</div>
						<div  style="width: 40%;font-size: 11px;color:#e9af40;text-align: center;line-height: 34px;" v-else class="canClick">{{item.WordType}}-{{item.GetPoint}}điểm</div>
						
					</div>
				</div>
				<!-- <div class="flexs_sb" style="background-color: #090e46;width: 90%;margin-left: 5%;border: 2px solid #f6af17;border-radius: 10px;overflow: hidden;height: 54px;margin-top: 5px;">
					<div class="flexs_r_c">
							<img :src="$img('num_L_H.png')"  alt="" style="width: 30px;margin-left: 24px;">
						
							<img :src="$img('num_U_H.png')"  alt="" style="width: 38px;margin-left: 14px;">
						
							<img :src="$img('num_C_H.png')"  alt="" style="width: 33px;margin-left: 16px;">
						
							<img :src="$img('num_K_H.png')"  alt="" style="width: 40px;margin-left: 17px;">
						
							<img :src="$img('num_Y_H.png')"  alt="" style="width: 42px;margin-left: 7px;">
						
					</div>
					<div style="font-size: 22.5px;margin-right: 24px;color: #e9af40;">GIÁ TRỊ ĐỔI LUCKY: IPHONE 14 PRO MAX 256GB</div>
					
					
				</div> -->
				
			</div>
			
		</div>
	</div>
	
</template>

<script>
	import {
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	import mqant from "@/components/protocol/mqantlib";
	import bus from "@/utils/bus.js"
	import i18n from "@/locales/i18n";
	import {
		theme
	} from '@/views/theme'	
	
		import  tools from "@/utils/tools";
	
	import {
		req,
		qs
	} from "@/utils/request";
	
	import {
		topic
	} from "@/components/protocol/api";
			import { http } from "@/components/protocol/api";
	
	export default {
		data(){
			return {
				offSet:0,
				recordData:[],
				allNum:0
			}
		},
		props:{
			
			
		},
		created() {
			this.getData()
		},
		methods:{
			prev(){
				if(this.offSet>0){
					this.offSet-=5
					this.getData()
				}
			},
			next(){
				if(this.offSet+5<this.allNum){
					this.offSet += 5
					this.getData()
					
				}
			},
			cancel(){
				this.$emit('cancel')
			},
			getData(){
				let params = {
					Offset:this.offSet,
					PageSize:5
				
				};
				let that = this;
				mqant.request(topic.turnTableRecord, params, function(res) {
					console.log('ssss',res)
					if (res.Code == 0) {
						that.recordData = res.Data.Record
						that.allNum = res.Data.TotalNum
					} else {
						that.$message({
							message: that.$t(res.ErrMsg),
							type: 'error'
						});
					}
				});
			},
			
			
			
		}
	}
</script>

<style scoped lang="scss">
	.title{
		
		
		color:#999999;
		position: relative;
		z-index: 88;
		text-align: center;
		//margin-top: 30px;
		padding-top: 20px;
		// top: 30px;
	}
	.cancel{
		width: 144px;
		height: 40px;
		color: white;
		margin: 0 auto;
		line-height: 40px;
		text-align: center;
		border-radius: 20px;
		background-color: #d3b35c;
	}
	.sure{
		width: 144px;
		height: 40px;
		color: white;
		line-height: 40px;
		text-align: center;
		border-radius: 20px;
		background-color: #3a8c38;
	}
</style>