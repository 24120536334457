<template>
  <div class="item_panel" style="margin-left:3vw;">
    <div class="item" :style="actve?'background-color: #e0dcd7;':'background-color:#695d50;'" style="text-align: center;border-radius: 6px;width: 16.4vw;height: 16.4vw;">
     <div style="position: relative;z-index: 9;">
		 <img :src="$img(bg)"  style="height: 28px;margin: 5px auto;"/>
		<div :style="actve?'color: black;':'color: white;'" class="title" style="width: 100%;font-size: 10px;text-align: center;margin-bottom: -20px;">{{ title }}</div>
		 
	 </div>
      <img
        :src="$img('gouxuan_img.png')"
        class="act_icon"
        :class="{ act: actve }">
      
	  
    </div>
  </div>
</template>

<script>
export default {
  props: {
    actve: {
      type: Boolean,
      default: false,
    },
    bg: String,
    title: String,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
	.select{
		background-color: rgb(74,93,255);
	}
	.black{
		color: black;
	}
.title {
	color: white;
}
.item {
  position: relative;
  background-color: rgb(15,25,47);
  > .bg {
    width: 14vw;
    display: block;
  }
  > .act_icon {
    display: none;
    position: absolute;
    bottom: 0%;
    right: 0%;
	
	z-index: 0;
	
    width: 17px;
  }
  > .act {
    display: block;
  }
}
</style>