import { status, getApiLoginUrl } from "@/components/protocol/api";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {
	theme
} from '@/views/theme'
import bus from "@/utils/bus.js"
import {
	req,
	qs
} from "@/utils/request";
import i18n from "@/locales/i18n";

export default {
	data() {
		return {
			gameData: [],
			type: '',
			imgJson: '',
			hoverIndex: null,
			factoryList: [],
			hoverIndex: -1,
			selectIndex: 0,
			page: 1,
			searchKey:"",
			searchData:[],
			isSearch:false
		}
	},
	props: {

		// diaLoginVisible:{
		// 	type:Boolean,
		// 	default:true
		// }
	},
	computed: {
		...mapGetters("global", ["globalState", "uuid", "lobbyInfo"]),

		...mapGetters("tcp", ["isLogged"]),
		...mapGetters("user", ["token", "tcpInfo"]),

		// ...mapGetters("user", ["token","tcpInfo"]),

	},
	created() {
		document.documentElement.scrollTop = 0;
		
		var that = this
		//console.log('6666',this.language())
		bus.off('freshData')

		bus.on('freshData', (val) => {
			if (val.isPC3) {
				that.gameData = []
				for (var i = 0; i < that.factoryList.length; i++) {
					var gameList = that.factoryList[i].GameList

					that.gameData = that.gameData.concat(gameList)
				}
				return
			}
			that.gameData = JSON.parse(val.data).GameList

			var tmpData = that.factoryList
			var num = 0
			var obj = JSON.parse(val.data)
			for (let key in tmpData) {
				if (tmpData[key].Factory.FactoryShowName == obj.Factory.FactoryShowName) {
					that.selectIndex = num
				}
				num++
			}
		})


		var factoryList = this.lobbyInfo.StaticPicture.Fish

			for (let key in factoryList) {
				this.factoryList.push(factoryList[key])

			}
			this.factoryList = this.factoryList.sort((a, b) => {
				return a.Factory.Status - b.Factory.Status || b.Factory.Sort - a.Factory.Sort

			})

		if (this.$route.params.data||this.$route.params.FactoryName) {
			if(this.$route.params.data){
				this.factoryList.forEach(function (item, index) {
					if (item.Factory.FactoryName == JSON.parse(that.$route.params.data).Factory.FactoryName) {
						that.selectIndex = index
						that.gameData = item.GameList
				
					}
				
				})
			}else {
				this.factoryList.forEach(function (item, index) {
					if (item.Factory.FactoryName == that.$route.params.FactoryName) {
						that.selectIndex = index
						that.gameData = item.GameList
				
					}
				
				})
			}
			
		
		} else {
			that.selectIndex = 0
			var gameList = []
			


			for (let key in this.factoryList) {
				gameList = gameList.concat(this.factoryList[key].GameList)
				if (theme == 'pc1'||theme == 'pc4') {
					break
				}
			}


			this.gameData = gameList

		}

	},
	methods: {
		search(val){
			  if(this.searchKey.length==0){
				  this.isSearch = false
				  return
			  }
			  var that = this
			  this.searchData = []
			this.gameData.forEach(function (item, index) {
				
				if (item.GameName.toLowerCase().indexOf(that.searchKey.toLowerCase())>=0) {
					that.searchData.push(item)
					
				}
			
			})
			this.isSearch = true
		},
		language() {
			return i18n.global.locale;
		},
		getImg(item) {
			return this.lobbyInfo.HotDomain + '/gameIcon2' + '/' + item.FactoryName + '/' + this.language() + '/' + item.GameCode + '.png'
		},
		currentChange(e) {
			this.page = e
		},
		changeFactory(index) {
			if (this.factoryList[index].Factory.Status == 2) {
				this.$message({
					message: this.$t('维护中'),
					type: 'info'
				});
				return
			}
			if (this.factoryList[index].Factory.Status == 3) {
				this.$message({
					message: this.$t('即将上线'),
					type: 'info'
				});
				return
			}
			this.selectIndex = index;

			this.gameData = this.factoryList[index].GameList
			if(this.isSearch){
				this.search('')
			}
		},
		onClick(item) {
			if (!this.isLogged) {
				if (theme == 'pc3') {
					this.$router.push({
						name: 'login',

					})
				} else {
					bus.emit('showLogin', {
					})
				}


				return
			}

			let url = getApiLoginUrl(item.FactoryName, item.GameCode);
			this.$act.openPage(url);
		},
		confiImgUrl(val) {
			let language = this.$i18n.locale//(navigator.language || navigator.browserLanguage).toLowerCase()
			let pin = '';
			if (language == 'zh-cn' || language == 'zh-CN' || language == 'cn') {
				pin = 'CN'
			} else if (language == 'vi') {
				pin = 'VN'
			} else {
				pin = 'EN'
			}
			return this.lobbyInfo.HotDomain + '/' + this.imgJson[val.GameCode + '_' + pin]
		},
		async getImgJson() {
			let url = this.lobbyInfo.HotDomain + '/gameIcon/project.json?time=' + new Date().valueOf()


			let rsp = await req.get(url);
			this.imgJson = rsp.data.assets
			if (this.$route.params.FactoryName) {
				return
			}
			if (this.$route.params.factoryList) {

				this.gameData = JSON.parse(this.$route.params.data).GameList



			} else {
				if (this.$route.params.isPC3) {

					for (var i = 0; i < this.factoryList.length; i++) {
						var gameList = this.factoryList[i].GameList

						this.gameData = this.gameData.concat(gameList)
					}
				} else {
					for (var i = 0; i < this.factoryList.length; i++) {
						var gameList = this.factoryList[i].GameList

						this.gameData = this.gameData.concat(gameList)
					}

				}

			}
		},


	}
}